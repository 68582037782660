import React from 'react';

const CoPrimaryInstruction = () => {
  return (
    <div className="grant-instructions mb-3 mb-md-4 p-md-5 ">
      <div className="container">
        <div className="row">
          <div className="col py-md-0 text-center col-md-10 offset-md-1">
            <h2>
              <strong>Co-Primary Advisor</strong>
            </h2>
            <p className="mt-3 mb-0 mt-md-4 text-muted">
              Your are able to add a co-primary advisor.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPrimaryInstruction;
