import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ApiContext } from '../../ApiProvider';

const AchDonationsChooseGrantee = () => {
  const { getAchGrantees } = useContext(ApiContext);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const [selectedGrantee, setSelectedGrantee] = useState('');
  const [grantees, setGrantees] = useState([]);

  const history = useHistory();

  const sortAlphabetically = (a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  };

  useEffect(() => {
    async function getAllGrantees() {
      const data = await getAchGrantees();
      setGrantees(
        data
          .sort((a, b) => sortAlphabetically(a.name, b.name))
          .map((grantee) => ({
            label: grantee.name,
            value: { ...grantee },
          }))
      );
    }
    getAllGrantees();
  }, []);

  const submit = () => {
    history.push({
      pathname: '/grantee/achcontribution/choose/amount',
      state: { grantId: selectedGrantee.value.id, grantName: selectedGrantee.value.name },
    });
  };

  return (
    <div>
      <div className="ach-donations py-4 mt-n3 mt-md-n4">
        <div className="container">
          <div className="text-center col-md-12 ach-donations-wrapper">
            <h2>
              <strong>Support a Project or Fund through DonorsTrust</strong>
            </h2>
            <p className="my-4 text-muted">
              As the community foundation for liberty, DonorsTrust serves as a partner to a number
              of projects and institutions in addition to offering strategic venture funds and
              donor-advised funds for individuals and families.
              <br />
              <br /> Select the project or fund you wish to support from the drop-down menu below,
              or indicate if you are making an initial contribution into your own fund or to one of
              our strategic venture funds.
              <br />
              <br /> Thank you for your support of liberty.
              <br />
              <br />
              <i>
                (Existing account holders: please log into your account&nbsp;
                <Link className="my-1" to={{ pathname: '/login' }}>
                  here
                </Link>
              </i>
              )
            </p>
          </div>
        </div>
      </div>
      <div className="container py-2 py-md-5 mb-5 ach-container">
        <h2 className="text-center py-4">
          <strong>Choose Project or Fund</strong>
        </h2>
        <div className="col-md-6 px-0 offset-md-3 align-items-start">
          <Select
            {...register('selectedGrantee', { required: true })}
            isSearchable
            value={selectedGrantee}
            options={grantees}
            onChange={(value) => {
              setSelectedGrantee(value);
              setValue('selectedGrantee', value);
              if (value) {
                clearErrors('selectedGrantee');
              } else {
                setError('selectedGrantee', {
                  type: 'required',
                });
              }
            }}
          />
          {errors.selectedGrantee && (
            <div className="validation-alert text-left " style={{ width: 120 }}>
              Required field
            </div>
          )}
        </div>
      </div>
      <div className="container d-flex mt-4 justify-content-between justify-content-md-end">
        <button
          type="button"
          className="button button--gray d-block d-md-inline py-3 px-4 px-md-5 m-0 mr-3 m-md-2 ach-donations__navigation"
          onClick={() => {
            window.location.href = 'https://www.donorstrust.org/';
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button button--dark-blue d-block d-md-inline py-3 px-4 px-md-5  m-0 m-md-2 ach-donations__navigation"
          onClick={handleSubmit(submit)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AchDonationsChooseGrantee;
