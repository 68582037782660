import { PoolChoicesContext } from 'Providers/PoolChoices';
import React, { useContext } from 'react';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import Instructions from '../../Request/Instructions';

export const FinishChange = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const { configuration } = useContext(PoolChoicesContext);
  const selecedPool = fund?.poolFundUnits.find((g) => g.poolId === configuration.poolId) || null;
  const isExchange = configuration.type === 'Exchange';

  return (
    <>
      <Instructions
        title={`Finish ${configuration.type}`}
        currentStep={currentStep}
        totalSteps={totalSteps}
      >
        Once you have set up all changes, you can see what changes will be applied to the chosen
        investment pools. To finish the process, click <strong>Submit</strong>
      </Instructions>
      <div className="container">
        <div className="row">
          <div className="col finish-change-wrapper">
            <div className="col col-md-7 offset-md-3 mb-4">
              <strong className="h4 font-weight-bold">
                Look and check if all changes are set up properly
              </strong>
            </div>
            <div>
              <div className="col col-md-6 offset-md-2">
                <div className="d-flex flex-column flex-md-row mb-4">
                  <strong>{`${configuration.type} from:`}</strong>
                  {isExchange ? (
                    <tbody className="ml-0 ml-md-5">
                      <tr>
                        <td className="py-1 w-50 pool-mame-table">{selecedPool?.name}</td>
                        <td className="px-0 text-right text-md-center px-md-3 w-50">
                          ${displayCurrency(configuration.amountToExchange)}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="ml-0 ml-md-4">
                      {fund?.poolFundUnits?.map((pool) => (
                        <tr className="col-12" key={pool.poolId}>
                          <td className="border-bottom w-100 py-1 pool-mame-table">{pool.name}</td>
                          <td className="border-bottom px-0 px-md-3 text-right text-md-center">
                            {(
                              ((pool.poolFundValue - pool.pendingAmount) /
                                (fund.totalValue - fund.pendingGrantAmount)) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="py-3 w-50">
                          <strong>Total:</strong>
                        </td>
                        <td className="w-50 text-right text-md-left">
                          <strong className="px-0 px-md-3">
                            {isExchange
                              ? `$${displayCurrency(configuration.amountToExchange)}`
                              : '100%'}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </div>
                <div className="ml-0 ml-md-5 d-flex flex-column flex-md-row mb-3 mb-md-4">
                  <strong className="mx-0 mx-md-5">To:</strong>
                  <tbody className="mt-3 mt-md-0">
                    {configuration.allocations?.map((pool) => (
                      <tr key={pool.poolId}>
                        <td className="border-bottom w-50 py-1 pool-mame-table">{pool.name}</td>
                        <td className="border-bottom w-50 text-right text-md-left px-0 px-md-3">
                          {isExchange
                            ? `$${displayCurrency(pool.amount)}`
                            : `${displayCurrency(pool.amount)}%`}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="py-3 w-50">
                        <strong>Total:</strong>
                      </td>
                      <td className="w-50 text-right text-md-left">
                        <strong className="px-0 px-md-3">
                          {isExchange
                            ? `$${displayCurrency(configuration.amountToExchange)}`
                            : '100%'}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </div>
                <div className="mr-3 mb-md-0">
                  <strong className="mr-0 mr-md-3 mb-md-0">Submitted Date:</strong>
                  {displayDate(new Date())}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
