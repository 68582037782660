import React, { useState, useEffect, useContext, useRef } from 'react';
import { GrantsContext, grantRequestValidationOptions } from 'Providers/Grants';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { roundToCents } from 'util/money';
import moment from 'moment';
import { displayDate } from 'util/dates';
import Instructions from '../../Instructions';
import { AcknowledgementForm } from './AddAcknowledgement';
import { DesignationForm } from './AddDesignation';
import { TimingForm } from './AddTiming';
import { AmountForm } from '../AddAmount';

const schema = yup
  .array()
  .min(1, 'You must choose at least one grantee')
  .required()
  .of(
    yup.object().shape({
      granteeId: yup.mixed().required('Grantee ID is required'),
      fundId: yup.number().required('Fund ID is required'),
      totalAmount: yup
        .number()
        .when('$step', (step, s) => {
          return step > 1
            ? s
                .required({ name: 'amount', message: 'Amount is required' })
                .min(grantRequestValidationOptions.grantAmountMinValue, {
                  name: 'amount',
                  message: grantRequestValidationOptions.invalidGrantAmountMessage,
                })
            : s;
        })
        .when('$remainingFunds', (remainingFunds, s) => {
          return remainingFunds < 0
            ? s.oneOf(['error'], { name: 'amount', message: 'Not enough funds for grant amount' })
            : s;
        }),
      designation: yup
        .string()
        .when('$step', (step, s) => {
          return step > 2 ? s.required('Designation is required') : s;
        })
        .test('customInput', { name: 'designation', message: 'Required field' }, (value) => {
          return (
            ['In support of a specific project', 'In memory of', 'In honor of', 'Other'].filter(
              (a) => value === a
            ).length === 0
          );
        }),
      acknowledgement: yup
        .string()
        .when('$step', (step, s) => {
          return step > 2 ? s.required('Acknowledgement is required') : s;
        })
        .test('customInput', { name: 'acknowledgement', message: 'Required field' }, (value) => {
          return value !== 'Recognize Someone Else';
        }),
      timing: yup
        .string()
        .when('$step', (step, s) => {
          return step > 2 ? s.required('Timing is required') : s;
        })
        .test('futureDate', { name: 'timing', message: 'Required field' }, (value) => {
          return value !== 'On a specific future date:';
        })
        .test(
          'futureDate',
          {
            name: 'timing',
            message: 'Please enter a valid date or select a date from the calendar picker',
          },
          (value) => {
            const dateString = value.replace(/On a specific future date: /g, '');
            const momentDate = moment(dateString);

            return (
              !momentDate.isValid() ||
              (moment(displayDate(dateString)) >= moment(displayDate(new Date())) &&
                momentDate.year() <= 9999)
            );
          }
        )
        .test(
          'futureDate',
          {
            name: 'timing',
            message: `The closest available date for selection is ${displayDate(
              moment(displayDate(new Date())).add(4, 'days')
            )}`,
          },
          (value) => {
            const dateString = value.replace(/On a specific future date: /g, '');
            const momentDate = moment(dateString);

            return (
              !momentDate.isValid() || momentDate >= moment(displayDate(new Date())).add(4, 'days')
            );
          }
        ),
      recurring: yup.boolean().default(false),
      startDate: yup.date().when('recurring', {
        is: (recurring) => recurring,
        then: yup
          .date()
          .required({ name: 'startDate', message: 'Required field' })
          .test(
            'startDate',
            {
              name: 'startDate',
              message: 'Please enter a valid date or select a date from the calendar picker',
            },
            (value) => {
              const momentDate = moment(value);

              return (
                moment(displayDate(momentDate)) >= moment(displayDate(new Date())) &&
                momentDate.year() <= 9999
              );
            }
          )
          .test(
            'startDate',
            {
              name: 'startDate',
              message: `The closest available date for selection is ${displayDate(
                moment(displayDate(new Date())).add(4, 'days')
              )}`,
            },
            (value) => {
              const momentDate = moment(value);

              return momentDate >= moment(displayDate(new Date())).add(4, 'days');
            }
          ),
      }),
      frequency: yup.string().when('recurring', {
        is: true,
        then: yup
          .string()
          .required({ name: 'frequency', message: 'Frequency is required' })
          .oneOf(['Monthly', 'Quarterly', 'Yearly']),
      }),
      endDate: yup.date().notRequired(),
    })
  );

export const EditGrantModal = ({
  grants,
  grant,
  fund,
  updateGrant,
  goToStep,
  isEditGrantModalShow,
  closeModal,
  currentStep,
}) => {
  const [oldAmount] = useState(grant.totalAmount);
  const [validationErrors, setValidationErrors] = useState([]);
  const { updateSavedGrant, updateGrantsBySavedGrant, isValid, grantsTotal } =
    useContext(GrantsContext);
  const designationRef = useRef(null);
  const acknowledgementRef = useRef(null);
  const timingRef = useRef(null);
  const amountRef = useRef(null);

  useEffect(() => {
    updateSavedGrant(grant);
  }, []);

  useEffect(() => {
    if (validationErrors?.find((e) => e.name === 'amount')?.message) {
      amountRef.current.scrollIntoView();
    } else if (validationErrors?.find((e) => e.name === 'designation')?.message) {
      designationRef.current.scrollIntoView();
    } else if (validationErrors?.find((e) => e.name === 'acknowledgement')?.message) {
      acknowledgementRef.current.scrollIntoView();
    } else if (
      validationErrors?.find((e) => e.name === 'timing')?.message ||
      validationErrors?.find((e) => e.name === 'startDate')?.message ||
      validationErrors?.find((e) => e.name === 'frequency')?.message
    ) {
      timingRef.current.scrollIntoView();
    }
  }, [validationErrors]);

  const clearValidationErrors = () => {
    setValidationErrors([]);
  };

  const clearChanges = () => {
    updateGrantsBySavedGrant();
    closeModal();
  };

  const saveChanges = async () => {
    const remainingFunds = Number(
      (fund.totalValue - grantsTotal() - fund.pendingGrantAmount).toFixed(2)
    );

    try {
      await schema.validate(grants, {
        context: { step: 5, remainingFunds: roundToCents(remainingFunds) },
        abortEarly: false,
      });

      const valid = await isValid(currentStep);
      const currentTotalAmount = grant.totalAmount;

      if (valid) {
        updateSavedGrant(grant);
        closeModal();
      } else if (currentTotalAmount !== oldAmount) {
        goToStep(4);
      }

      clearValidationErrors();

      return true;
    } catch (e) {
      setValidationErrors(e.errors);
      return null;
    }
  };

  return (
    <Modal className="edit-grant-mobile-modal" show={isEditGrantModalShow} centered size="md">
      <Modal.Body>
        <Instructions isModal hideStepCount title={`Edit Grant: ${grant.granteeName}`} />
        <div className="edit-grant-mobile container">
          <div className="row">
            <div className="col col-md-12 p-md-0" ref={amountRef}>
              <AmountForm
                grant={grant}
                updateGrant={updateGrant}
                editGrantValidationErrors={validationErrors}
                ref={amountRef}
                isLastStep
              />
              <span ref={designationRef} />
              <DesignationForm
                grant={grant}
                updateGrant={updateGrant}
                editGrantValidationErrors={validationErrors}
              />
              <span ref={acknowledgementRef} />
              <AcknowledgementForm
                grant={grant}
                updateGrant={updateGrant}
                editGrantValidationErrors={validationErrors}
              />
              <span ref={timingRef} />
              <TimingForm
                grant={grant}
                updateGrant={updateGrant}
                editGrantValidationErrors={validationErrors}
              />
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="text-left d-inline-block modal-button">
            <button
              className="button button--gray py-3 px-3 px-md-5 mr-3"
              type="button"
              onClick={clearChanges}
            >
              Back
            </button>
          </div>
          <div className="text-right d-inline-block modal-button">
            <button
              className="button button--dark-blue py-3 px-3 px-md-5"
              type="button"
              onClick={saveChanges}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
