import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import ContributionsHistoryTable from 'components/Main/ReviewFund/components/ContributionsHistoryTable';
import useCurrentFund from 'Hooks/CurrentFund';

export default () => {
  const fund = useCurrentFund();

  if (!fund.id) {
    return <Redirect to="/" />;
  }

  const [contributions, setContributions] = useState([]);
  const { getContributions } = useContext(ApiContext);

  useEffect(() => {
    async function getFundContributions() {
      const c = await getContributions(fund.id);
      setContributions(c);
    }

    getFundContributions();
  }, []);

  return (
    <div className="container review-contributions">
      <div className="row">
        <div className="col col-md-6">
          <h1>Review your contributions</h1>
          <h2 className="text-secondary">{fund.name}</h2>
          <h4 className="my-4 my-md-5">Contribution History</h4>
          <ContributionsHistoryTable history={contributions} />
        </div>
      </div>
    </div>
  );
};
