/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import {
  bytesToMB,
  findDuplicates,
  handleFiles,
  truncateFileNameTo50,
  getAllIndexesOfName,
} from '../../common/helpers';
import { documentTypes } from '../../common/constants';

export const DafIntentUploadFiles = ({ updateFiles, setSubmitDisabled }) => {
  const { files, setFiles, removeFile } = useFileUpload();

  const [handledFiles, setHandledFiles] = useState([]);

  const inputRef = useRef();
  const handleKeyDown = () => {};

  const validTypes = [
    documentTypes.PDF,
    documentTypes.DOC,
    documentTypes.DOCX,
    documentTypes.XLS,
    documentTypes.XLSX,
  ];

  useEffect(() => {
    setHandledFiles(handleFiles(files));
    updateFiles(handleFiles(files));
  }, [files]);

  useEffect(() => {
    const duplicatesArray = findDuplicates(handledFiles);

    if (
      handledFiles.some(({ size }) => bytesToMB(size) > 20) ||
      handledFiles.some(({ type }) => !validTypes.includes(type)) ||
      handledFiles.length > 10 ||
      duplicatesArray.length > 0
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [handledFiles]);

  const validateFile = (name, type, size, filesArray, index) => {
    if (bytesToMB(size) > 20) {
      return <div className="validation-files">File size exceeds 20MB</div>;
    }
    if (!validTypes.includes(type)) {
      return (
        <div className="validation-files">
          File type is not supported(.pdf,.doc,.docx,.xls,.xlsx)
        </div>
      );
    }
    const repeatedIndexes = getAllIndexesOfName(filesArray, name);
    if (repeatedIndexes.includes(index) && repeatedIndexes.length > 1) {
      return <div className="validation-files">File name is used by another file</div>;
    }
    return null;
  };

  return (
    <>
      <div>
        <strong>Upload your document if you have any changes to your donor intent</strong>
      </div>
      <div
        type="button"
        onClick={() => inputRef.current.click()}
        className="daf-dropzone-upload mt-2"
        onKeyDown={handleKeyDown}
        role="menu"
        tabIndex={0}
      >
        <button type="button" className="button">
          <i className="bi bi-cloud-arrow-up-fill" />
        </button>
        <div className="ml-3 font-weight-bold">Upload document</div>

        <input
          ref={inputRef}
          type="file"
          multiple
          className="d-none"
          accept={`${documentTypes.PDF}, ${documentTypes.DOC}, ${documentTypes.DOCX}, ${documentTypes.XLS}, ${documentTypes.XLSX}`}
          onChange={(e) => {
            setFiles(e, 'a');
            inputRef.current.value = null;
          }}
        />
      </div>
      {handledFiles.length ? (
        <div className="my-2">
          <strong>Files:</strong>
        </div>
      ) : null}
      {handledFiles &&
        handledFiles.map(({ name, type, size }, i) => (
          <div className="d-flex justify-content-between" key={i}>
            <div
              className={
                validateFile(name, type, size, handledFiles, i)
                  ? 'validation-files d-flex'
                  : 'd-flex'
              }
            >
              <div>
                {type === documentTypes.PDF && <i className="bi bi-filetype-pdf mr-2" />}
                {type === documentTypes.DOC && <i className="bi bi-filetype-doc mr-2" />}
                {type === documentTypes.DOCX && <i className="bi bi-filetype-docx mr-2" />}
                {type === documentTypes.XLS && <i className="bi bi-filetype-xls mr-2" />}
                {type === documentTypes.XLSX && <i className="bi bi-filetype-xlsx mr-2" />}
              </div>
              <div>{truncateFileNameTo50(name)}</div>
              <button
                type="button"
                className="button ml-2"
                onClick={() => removeFile(handledFiles.length - i - 1)}
              >
                <i className="bi bi-x" />
              </button>
            </div>
            {validateFile(name, type, size, handledFiles, i)}
          </div>
        ))}
      {handledFiles.length > 10 && (
        <div className="validation-files mt-2">
          <i className="bi bi-exclamation-circle-fill" /> You are able to upload 10 files per one
          time
        </div>
      )}
    </>
  );
};
