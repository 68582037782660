import { PoolChoicesContext } from 'Providers/PoolChoices';
import React, { useContext, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { displayCurrency } from 'util/money';
import ReactTooltip from 'react-tooltip';
import Instructions from '../../Request/Instructions';

export const ExchangeForm = ({ fund, configuration, updateConfiguration, errorMessage }) => {
  const updateConfigurationValues = (field, value) => {
    const getAllAvaliableAmount = () => {
      const pool = fund?.poolFundUnits.find((g) => g.poolId === configuration.poolId);
      updateConfiguration('amountToExchange', pool.poolFundValue - pool.pendingAmount);
    };
    if (field === 'poolId') {
      updateConfiguration('poolId', value);
      if (configuration.exchangeMode === 'All') {
        getAllAvaliableAmount();
      }
    } else {
      updateConfiguration('exchangeMode', value);
      updateConfiguration('amountToExchange', null);
      if (configuration.exchangeMode === 'All') {
        getAllAvaliableAmount();
      }
    }
  };

  useEffect(() => {
    updateConfiguration(
      'poolId',
      fund?.poolFundUnits.find((g) => g.poolFundValue - g.pendingAmount > 0)?.poolId
    );
  }, []);

  return (
    <div className="col col-md-6 offset-md-3">
      <div className="mr-4 text-center mt-3 mt-md-4">
        <h3 className="m-0 py-2 text-left text-md-center font-weight-bold">
          Choose the investment you want to exchange
        </h3>
      </div>
      <div className="mr-4 text-center my-4">
        <select
          onChange={(e) => updateConfigurationValues('poolId', Number(e.target.value))}
          className="form-control"
          defaultValue={configuration?.poolId}
        >
          {fund.poolFundUnits.map(
            (pool) =>
              pool.poolFundValue - pool.pendingAmount > 0 && (
                <option key={pool.poolId} value={pool.poolId}>
                  {`${pool.name}  $${displayCurrency(pool.poolFundValue - pool.pendingAmount)}`}
                </option>
              )
          )}
        </select>
      </div>
      <div className="mr-4 text-center mt-4">
        <h3 className="m-0 py-2 text-left text-md-center font-weight-bold">Amount to exchange:</h3>
      </div>
      <div>
        <Form.Check
          checked={configuration?.exchangeMode === 'All'}
          onChange={() => updateConfigurationValues('exchangeMode', 'All')}
          type="radio"
          value="All"
          id="pool-choice-all"
          htmlFor="pool-choice-all"
          name="Amount"
          label="All Available"
          className="my-2 col pool-choice__checkbox-exchange"
        />
        <Form.Check
          checked={configuration?.exchangeMode === 'Some'}
          type="radio"
          onChange={() => updateConfigurationValues('exchangeMode', 'Some')}
          value="Some"
          id="pool-choice-some"
          htmlFor="pool-choice-some"
          name="Amount"
          label="Some"
          className="my-2 col pool-choice__checkbox-exchange"
        />
      </div>
      {configuration.exchangeMode === 'Some' && (
        <>
          <div className="mt-4 mt-md-0 mb-3 mb-md-0  row d-flex justify-content-end">
            <InputGroup className="col-12 col-md-6">
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <CurrencyInput
                required
                customInput={Form.Control}
                allowNegativeValue={false}
                decimalsLimit={2}
                onValueChange={(value) => updateConfiguration('amountToExchange', Number(value))}
                decimalScale={2}
                decimalSeparator="."
                value={configuration.amountToExchange}
                className={`text-right ${
                  !errorMessage?.includes('amount') ? String.empty : 'border-danger'
                }`}
              />
            </InputGroup>
          </div>
        </>
      )}
    </div>
  );
};

export const ChooseTypeOfChange = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const { configuration, addconfiguration, updateConfiguration, errorMessage } =
    useContext(PoolChoicesContext);
  const configurationTypes = ['Exchange', 'Rebalancing'];

  useEffect(() => {
    addconfiguration();
  }, []);

  return (
    <>
      <Instructions
        title="Choose the type of change"
        currentStep={currentStep}
        totalSteps={totalSteps}
      >
        You are able to choose one of the following types of change:
        <strong> Exchange or Rebalancing. </strong> Once you have chosen the one, to proceed through
        the flow, click <strong>Continue</strong>
      </Instructions>
      <div className="container ">
        <div className="row">
          <div className="col-auto col-md-1 col-md-1 offset-md-5">
            <div className="mb-3 font-weight-normal h5">
              {configurationTypes.map((type) => (
                <div className="d-flex align-items-center align-items-md-start">
                  <Form.Check
                    key={type}
                    checked={configuration.type === type}
                    onChange={() => updateConfiguration('type', type)}
                    type="radio"
                    value={type}
                    name="Type"
                    id={type}
                    htmlFor={type}
                    label={type}
                    className="my-2 col"
                  />
                  <i
                    data-tip
                    data-for={type}
                    className="bi bi-question-circle my-2 ml-0  ml-md-5 pl-0 pl-md-5 h6 col"
                  />
                </div>
              ))}
            </div>
          </div>
          <ReactTooltip
            id="Exchange"
            type="dark"
            className="tooltip tooltip-rebalance col-md-3"
            place="top"
            event="click"
            globalEventOff="click"
          >
            <div className="h6">
              <div>
                <strong>Exchange</strong> means the process of redeeming shares or dollars from one
                investment pool and purchasing shares in another investment pool.
              </div>
            </div>
          </ReactTooltip>
          <ReactTooltip
            id="Rebalancing"
            type="dark"
            className="tooltip col-md-3 h6 tooltip-rebalance"
            place="top"
            event="click"
            globalEventOff="click"
          >
            <div className="h6">
              <strong>Rebalancing</strong> means moving your current investment pool balances to
              more than one investment pool at the same time. Rebalancing enables you to change the
              way assets are distributed throughout your portfolio.
            </div>
          </ReactTooltip>
        </div>
        <div className="row">
          {configuration.type === 'Exchange' && (
            <ExchangeForm
              configuration={configuration}
              updateConfiguration={updateConfiguration}
              errorMessage={errorMessage}
              fund={fund}
            />
          )}
        </div>
      </div>
    </>
  );
};
