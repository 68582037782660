import React from 'react';
import { Link } from 'react-router-dom';
import { AllocationInfo } from './Common/AllocationInfo';
import { DownloadPdf } from './Pdf/index';

const RecurringAchContributionAllocationSubmitted = (props) => {
  const { contributionAmount, fee, fullName, frequency, paymentLastDigits, fund } = props;
  const allocation = { poolId: 0, name: 'Money Market', amount: 100 };

  return (
    <>
      <div className="container allocation-submitted">
        <div className="row">
          <div className="col">
            <div className="col-md-8 offset-md-2 allocation-submitted__wrapper">
              <h1 className="text-center">Investment for your donation has been submitted.</h1>
              <p className="my-3 my-md-5 text-center text-md-left">
                100% of contributions are allocated to the Money Market investment by default. You
                can change your allocation on your Fund page by clicking the &apos;Reallocate your
                Investments&apos; link.
                <br />
                Please, check your email to see details.
              </p>
            </div>
            <AllocationInfo
              fund={fund}
              contributionAmount={contributionAmount}
              fee={fee}
              allocations={[allocation]}
              fullName={fullName}
              frequency={frequency}
              paymentLastDigits={paymentLastDigits}
            />
            <div className="col col-md-8 offset-md-2 allocation-submitted__wrapper">
              <div className="my-3 my-md-4 my-md-5 d-flex flex-column flex-md-row align-items-start align-items-md-center">
                <p className="mb-0 mt-2 mt-md-0 col-8 col-md-auto px-0">
                  To save contribution information, click the icon
                </p>
                <button
                  type="button"
                  className="mx-md-3 mt-2 mb-3 my-md-3 border-0 pool-choice__download-pdf"
                >
                  <DownloadPdf
                    fund={fund}
                    amount={contributionAmount}
                    fee={fee}
                    allocations={[allocation]}
                    fullName={fullName}
                    frequency={frequency}
                    paymentLastDigits={paymentLastDigits}
                  />
                </button>
              </div>
              <div className="my-0 my-md-5">
                <Link
                  to={{ pathname: '/fund/overview', state: { fund } }}
                  className="button button--gray d-block d-md-inline text-center px-4 py-3 mr-0 mr-md-3"
                >
                  Return to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecurringAchContributionAllocationSubmitted;
