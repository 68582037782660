import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import useCurrentFund from 'Hooks/CurrentFund';
import PlaidLink from 'components/PlaidLink';
import { ApiContext } from 'components/ApiProvider';
import { AuthenticationContext } from 'auth/AuthProvider';
import { AlertContext } from 'Providers/Alert';
import RecurringContributions from 'components/Main/Contributions/components/Recurring';
import CreateContributionForm from './components/CreateContributionForm';

const AddContribution = () => {
  const fund = useCurrentFund();

  const [recurringContributions, setRecurringContributions] = useState([]);
  const {
    checkBankAuth,
    getRecurringContributions,
    removeRecurringContribution: rc,
  } = useContext(ApiContext);
  const { user } = useContext(AuthenticationContext);
  const { addAlert } = useContext(AlertContext);

  if (!fund.id) {
    return <Redirect to="/" />;
  }

  const [hasBankAuth, setHasBankAuth] = useState(true);

  useEffect(() => {
    async function checkIfBankAuth() {
      setHasBankAuth(await checkBankAuth(user.id, fund.id));
    }
    checkIfBankAuth();
  }, []);

  useEffect(() => {
    async function getAllRecurringContributions() {
      const data = await getRecurringContributions(fund.id);
      setRecurringContributions(data);
    }

    getAllRecurringContributions();
  }, []);

  const handleSuccessAuth = () => {
    setHasBankAuth(true);
    addAlert('success', 'Successfully connected your bank account! You may now add contributions!');
  };

  const handleErrorAuth = () => {
    addAlert('error', 'An error ocurred. Please try again');
  };

  const removeRecurringContribution = async (id) => {
    try {
      await rc(id);
      const c = recurringContributions.filter((obj) => {
        return obj.id !== id;
      });

      setRecurringContributions(c);
    } catch {
      addAlert('error', 'There was an error removing recurring contribution');
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="text-center">Add / Manage Contributions</h1>
          <h2 className="text-center text-secondary">{fund.name}</h2>
          <div className="row">
            <div className="col col-md-6 offset-md-3">
              <h4 className="my-4">Recurring ACH Contributions</h4>
              <RecurringContributions
                contributions={recurringContributions}
                removeCallback={removeRecurringContribution}
              />
              {hasBankAuth ? (
                <div className="row">
                  <div className="col">
                    <h4 className="my-4">Add ACH Contribution</h4>
                    <div className="row">
                      <div className="col mx-auto">
                        <div className="card my-4 shadow-sm">
                          <div className="card-body">
                            <CreateContributionForm fundId={fund.id} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="my-4" style={{ maxWidth: '800px' }}>
                    The simplest way to make a contribution to your fund is through an automatic,
                    seamless ACH transfer from your bank account. We integrate directly with your
                    bank such that making contributions only takes a few seconds.
                  </p>
                  <PlaidLink successCallback={handleSuccessAuth} errorCallback={handleErrorAuth} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContribution;
