import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const GrantRequestComplete = () => {
  const location = useLocation();
  const history = useHistory();
  const fund = location.state?.fund;

  return (
    <div className="container grant-request__complete">
      <div className="row">
        <div className="col col-md-8 offset-md-2">
          <h1 className="text-left text-md-center">Your grant request has been submitted.</h1>
          <p className="mt-3 my-md-5">
            Grant requests are reviewed on a rolling basis. In most cases, a grant will be issued
            within three to six business days after the request is received. Grants to organizations
            new to us may take longer, depending upon the responsiveness of the grantee to our due
            diligence questions. Should you need to expedite a grant request, please contact us
            directly.
          </p>
          <p className="mt-3 my-md-5">
            Grants must be made to a qualified public supported 501(c)(3) organizations, you may not
            receive any benefit as a result of a grant, and grants from your account may not satisfy
            a legally binding, personal pledge. Personal benefits include, but are not limited to,
            admission to an event if the event has a monetary value and memberships if the
            membership allows you access to anything not available to the general public. You may
            not bi-furcate a grant to avoid the personal benefit rule.
          </p>
          <p className="mt-3 my-md-5">
            An example of bi-furcation is if an organization holds an event that you may attend if
            you provide a $1,000 donation. If you were to donate $1,000 to the organization
            directly, you would be allowed to deduct $900 as a charitable donation. The
            nondeductible $100 portion of the donation is the value of a meal provided at the event.
            Rather than donate $1,000 directly to the organization, you ask DonorsTrust to make a
            grant of $900 to the organization and you directly give the organization $100 so that
            you may attend the event. This is an example of bi-furcation, and the Internal Revenue
            Service has explicitly stated that you may not request a grant from a donor-advised fund
            that results in bi-furcation. Note that the IRS is expected to issue additional guidance
            with respect to these rules in the near future, so this may change.
          </p>
          <div className="mt-3 my-md-5 grant-request__complete-buttons grant-request__navigation-buttons justify-content-md-start">
            <button
              className="button button--gray px-md-5 py-3 mr-3"
              onClick={() => history.push({ pathname: '/' })}
              type="button"
            >
              Return to Home
            </button>
            <button
              className="button button--dark-blue px-md-5 py-3"
              onClick={() => history.push({ pathname: '/fund/grants/request', state: { fund } })}
              type="button"
            >
              Submit another request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantRequestComplete;
