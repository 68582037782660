import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Pdf } from './Pdf';

export const DownloadPdf = (props) => {
  const { amount, allocations, type, sourcePool, fund } = props;

  return (
    <PDFDownloadLink
      document={
        <Pdf
          amount={amount}
          allocations={allocations}
          type={type}
          sourcePool={sourcePool}
          fund={fund}
        />
      }
      fileName="allocations.pdf"
    >
      <i className="bi bi-cloud-arrow-down-fill text-dark" />
    </PDFDownloadLink>
  );
};
