import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ApiContext } from '../../ApiProvider';

const CapitalFundDonationsChooseGrantee = () => {
  const { getCapitalFundGrantees } = useContext(ApiContext);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const [selectedGrantee, setSelectedGrantee] = useState('');
  const [grantees, setGrantees] = useState([]);

  const history = useHistory();

  const sortAlphabetically = (a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  };

  useEffect(() => {
    async function getAllGrantees() {
      const data = await getCapitalFundGrantees();
      setGrantees(
        data
          .sort((a, b) => sortAlphabetically(a.name, b.name))
          .map((grantee) => ({
            label: grantee.name,
            value: { ...grantee },
          }))
      );
    }
    getAllGrantees();
  }, []);

  const submit = () => {
    history.push({
      pathname: '/capital-fund-grantee/achcontribution/choose/amount',
      state: { grantId: selectedGrantee.value.id, grantName: selectedGrantee.value.name },
    });
  };

  return (
    <div>
      <div className="ach-donations py-4 mt-n3 mt-md-n4">
        <div className="container">
          <div className="text-center col-md-12 ach-donations-wrapper">
            <h2>
              <strong>Support a Project or Grantee through Donors Capital Fund</strong>
            </h2>
            <p className="my-4 text-muted">
              Donors Capital Fund works closely with DonorsTrust to support a number of projects and
              institutions, including new non-profits for which we serve as a fiscal sponsor, a
              select group of qualified foreign non-profit organizations, and other partners. All of
              them carry-out programs that are compatible with Donors Trust’s mission and might
              otherwise be carried-out directly by Donors Trust.
              <br />
              <br /> Select the project or grantee you wish to support from the drop-down menu
              below, then press “Continue” to make your contribution by credit card or ACH
              electronic funds transfer.
              <br />
              <br /> Thank you for helping us support liberty.
              <br />
              <br />
              <i>
                (If you are a DonorsTrust account holder and wish to contribute through your DAF
                account, please log into the DonorsTrust Member Portal Portal&nbsp;
                <Link className="my-1" to={{ pathname: '/login' }}>
                  here.
                </Link>
              </i>
              )
            </p>
          </div>
        </div>
      </div>
      <div className="container py-2 py-md-5 mb-5 ach-container">
        <h2 className="text-center py-4">
          <strong>Choose a Project</strong>
        </h2>
        <div className="col-md-6 px-0 offset-md-3 align-items-start">
          <Select
            {...register('selectedGrantee', { required: true })}
            isSearchable
            value={selectedGrantee}
            options={grantees}
            onChange={(value) => {
              setSelectedGrantee(value);
              setValue('selectedGrantee', value);
              if (value) {
                clearErrors('selectedGrantee');
              } else {
                setError('selectedGrantee', {
                  type: 'required',
                });
              }
            }}
          />
          {errors.selectedGrantee && (
            <div className="validation-alert text-left " style={{ width: 120 }}>
              Required field
            </div>
          )}
        </div>
      </div>
      <div className="container d-flex mt-4 justify-content-between justify-content-md-end">
        <button
          type="button"
          className="button button--gray d-block d-md-inline py-3 px-4 px-md-5 m-0 mr-3 m-md-2 ach-donations__navigation"
          onClick={() => {
            window.location.href = 'http://donorscapitalfund.org/';
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button button--dark-blue d-block d-md-inline py-3 px-4 px-md-5  m-0 m-md-2 ach-donations__navigation"
          onClick={handleSubmit(submit)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default CapitalFundDonationsChooseGrantee;
