import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { ApiContext } from 'components/ApiProvider';
import { DownloadPdf } from './TransactionsHistoryPdf';

export const FundTransactionsHistoryHeader = ({
  fund,
  pool,
  filteredTransactions,
  dateFrom,
  dateTo,
}) => {
  const [isDownloadDropdownOpened, setIsDownloadDropdownOpened] = useState(false);
  const modalRef = useRef(null);
  const { getTransactionHistoryXlsx } = useContext(ApiContext);
  useOnClickOutside(modalRef, () => setIsDownloadDropdownOpened(false));
  const buildGranteeHistoryExcelAsync = async () => {
    try {
      const data = await getTransactionHistoryXlsx({
        fundId: fund?.id,
        poolId: pool?.poolId,
        dateFrom,
        dateTo,
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data.data);
      link.download = data.filename;
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="mt-n3 header-navigate mt-md-n4">
        <div className="back-btn container  my-md-4">
          <Link to={{ pathname: '/fund/overview', state: { fund } }}>
            <strong>← Back to Fund Account</strong>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-end mb-5 pb-md-4 mb-md-4 my-4">
          <h2 className="mb-0">
            <strong>All transactions history</strong>
          </h2>
          <div className="d-flex mt-1 mt-md-0 justify-content-between align-items-center transactions-header-pool">
            <div className="transactions-title text-blue">
              <strong>Pool: {pool?.name}</strong>
            </div>
            <Dropdown ref={modalRef} show={isDownloadDropdownOpened} className="d-block d-md-none">
              <button
                type="button"
                className="button transaction-dropdown"
                onClick={() => setIsDownloadDropdownOpened(!isDownloadDropdownOpened)}
                disabled={filteredTransactions.length === 0}
              >
                <i className=" bi bi-download download-icon" />
              </button>
              {isDownloadDropdownOpened && (
                <div
                  className={
                    isDownloadDropdownOpened
                      ? 'dropdown-menu dropdown-menu__active'
                      : 'dropdown-menu dropdown-menu__inactive'
                  }
                >
                  <div className="row mx-1">
                    <small className="col text-center px-0">Choose the format type</small>
                  </div>
                  <div className="row m-1 mh-100">
                    <div className="col text-center">
                      <button type="button" className="m-0 border-0 btn btn-link btn-lg p-0">
                        <DownloadPdf
                          transactionsHistory={filteredTransactions}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          poolName={pool.name}
                        />
                      </button>
                    </div>
                    <div className="col text-center">
                      <button
                        type="button"
                        className="m-0 border-0 btn btn-link btn-lg p-0"
                        onClick={() => buildGranteeHistoryExcelAsync()}
                      >
                        <i className="bi bi-filetype-xls text-success" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};
