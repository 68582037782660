import axios from 'axios';
import env from 'environment';
import React, { useState, useContext, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthenticationContext } from 'auth/AuthProvider';
import { showErrorToast, showExpireTokenToast } from 'util/showToast';

export const ApiContext = createContext();

const ApiProviderComponent = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, accessToken, removeToken, user } = useContext(AuthenticationContext);

  const instance = axios.create({
    baseURL: env.API_BASE_URL,
  });

  instance.interceptors.request.use((req) => {
    if (accessToken && req.url !== '/auth/change-password') {
      req.headers.Authorization = `Bearer ${accessToken}`;
    }

    return req;
  });

  instance.interceptors.response.use(
    (res) => {
      const { headers } = res;
      const newAuthToken = headers['x-dt-token'];

      if (newAuthToken) {
        setToken(newAuthToken);
      }

      return res;
    },
    async (err) => {
      if (err.response?.status === 401) {
        showExpireTokenToast();
        try {
          removeToken();
          // await instance.post('/Auth/logout');
        } catch (e) {
          console.error(e);
        }
      } else {
        const { headers } = err.response;
        const newAuthToken = headers['x-dt-token'];

        if (newAuthToken) {
          setToken(newAuthToken);
        }
      }

      return Promise.reject(err);
    }
  );

  const request = async (method, url, params, data, headers) => {
    try {
      // don't load indicator on search grantees endpoint
      if (url !== '/grantees' && url !== '/DonorAdvisedFund/searchPublicCharities') {
        setIsLoading(true);
      }

      const res = await instance.request({
        method,
        url,
        headers,
        params,
        data,
      });

      return res.data;
    } catch (e) {
      showErrorToast(url);

      return Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  };

  const get = async (path, params, headers) => {
    return request('get', path, params, null, headers);
  };

  const post = async (path, data, headers) => {
    return request('post', path, null, data, headers);
  };

  const patch = async (path, data, headers) => {
    return request('patch', path, null, data, headers);
  };

  const del = async (path, data, headers) => {
    return request('delete', path, null, data, headers);
  };

  const endpoints = {
    login: async (username, password) => {
      return post('/auth/login', { username, password });
    },
    getFunds: async (userId) => {
      try {
        return await get(`/users/${userId}/funds`);
      } catch {
        return [];
      }
    },
    getFundHistory: async (fundId) => {
      try {
        const res = await instance.request({
          method: 'get',
          url: `/funds/${fundId}/history`,
          responseType: 'blob',
        });
        let filename = 'history.xlsx';
        const h = res.headers['content-disposition'];
        const match = h.match(/filename=(.+);/);

        if (match) {
          // eslint-disable-next-line prefer-destructuring
          filename = `${match[1]}.xlsx`;
        }

        return {
          data: res.data,
          filename,
        };
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
    getFund: async (fundId) => {
      try {
        const data = await get(`/funds/${fundId}`);
        return data;
      } catch {
        return null;
      }
    },
    getFundNameAndPrivacyElections: async (fundId) => {
      try {
        const data = await get(`/DonorAdvisedFund/getFundNameAndPrivacyElections?fundId=${fundId}`);
        return data;
      } catch {
        return [];
      }
    },
    getAdvisorCommunicationElections: async (fundId) => {
      try {
        const data = await get(
          `/DonorAdvisedFund/getAdvisorCommunicationElections?fundId=${fundId}`
        );
        return data;
      } catch {
        return [];
      }
    },
    getDafSunsettingAndFinalDistributionElections: async (fundId) => {
      try {
        const data = await get(
          `/DonorAdvisedFund/getDafSunsettingAndFinalDistributionElections?fundId=${fundId}`
        );
        return data;
      } catch {
        return [];
      }
    },
    getDafStatement: async (fundId) => {
      try {
        const data = await get(`/DonorAdvisedFund/getDafStatement?fundId=${fundId}`);
        return data;
      } catch {
        return [];
      }
    },
    searchPublicCharities: async (query) => {
      try {
        const data = await get('/DonorAdvisedFund/searchPublicCharities', { searchTerm: query });
        return data;
      } catch {
        return [];
      }
    },
    updateDafSunsettingAndFinalDistributionElections: async (sunsettingData) => {
      try {
        const data = await post(
          '/DonorAdvisedFund/updateDafSunsettingAndFinalDistributionElections',
          sunsettingData
        );
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    updateDafStatement: async (dafIntentData) => {
      try {
        const data = await post('/DonorAdvisedFund/updateDafStatement', dafIntentData);
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    sendNotificationAboutDonorAdvisedFundChanges: async (pdfData) => {
      try {
        const data = await post(
          '/DonorAdvisedFund/sendNotificationAboutDonorAdvisedFundChanges',
          pdfData
        );
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    updateFundNameAndPrivacyElections: async (formData) => {
      try {
        const data = await post('/DonorAdvisedFund/updateFundNameAndPrivacyElections', formData);
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    updateAdvisorCommunicationElections: async (advisorCommunicationData) => {
      try {
        const data = await post(
          '/DonorAdvisedFund/updateAdvisorCommunicationElections',
          advisorCommunicationData
        );
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    updateSuccessorAdvisorElection: async (successorAdvisorsData) => {
      try {
        const data = await post(
          '/DonorAdvisedFund/updateSuccessorAdvisorElection',
          successorAdvisorsData
        );
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    getSuccessorAdvisorsInfo: async (fundId) => {
      try {
        const data = await get(`/DonorAdvisedFund/getSuccessorAdvisorsInfo?fundId=${fundId}`);
        return data;
      } catch {
        return [];
      }
    },
    getContributions: async (fundId, limit) => {
      try {
        const data = await get(`/funds/${fundId}/contributions`, { limit });
        return data.items;
      } catch {
        return [];
      }
    },
    getGrantRequests: async (fundId, limit) => {
      try {
        const data = await get('/grants/requests', { fundId, limit });
        return data.items;
      } catch {
        return [];
      }
    },
    getGrants: async (fundId, limit, isGrantsPage) => {
      try {
        const data = await get('/grants', { fundId, limit, isGrantsPage, userId: user.id });
        return data.items;
      } catch {
        return [];
      }
    },
    getRecurringGrants: async (fundId, limit) => {
      try {
        const data = await get('/grants/recurringGrants', { fundId, limit });
        return data;
      } catch {
        return [];
      }
    },
    updateRecurringGrant: async (grant) => {
      try {
        const data = await post('/grants/updateRecurringGrant', { ...grant });
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    getGrantProgress: async (id, isRequest) => {
      try {
        const data = await get('/grants/grantProgress', { id, isRequest });
        return data;
      } catch {
        return [];
      }
    },
    getPoolsForReallocation: async (fundId) => {
      try {
        const data = await get(`/pools/getPoolsForReallocation/${fundId}`);
        return data;
      } catch {
        return [];
      }
    },
    exchangeInvestments: async (configuration) => {
      return post('/pools/exchangeInvestments', { ...configuration });
    },
    rebalanceInvestments: async (configuration) => {
      return post('/pools/rebalanceInvestments', { ...configuration });
    },
    getTransactionSubtypes: async () => {
      try {
        const data = await get('/pools/getTransactionSubtypes');
        return data;
      } catch {
        return [];
      }
    },
    getTransactionsHistory: async ({ fundId, poolId, dateFrom, dateTo, transactionSubtypes }) => {
      try {
        const data = await post('/pools/getTransactionsHistory/', {
          fundId,
          poolId,
          dateFrom,
          dateTo,
          transactionSubtypes,
        });
        return data;
      } catch {
        return [];
      }
    },
    getTransactionHistoryXlsx: async (transactionsData) => {
      try {
        const res = await instance.request({
          method: 'post',
          url: '/Pools/getTransactionHistoryXlsx',
          responseType: 'blob',
          data: transactionsData,
        });
        let filename = 'transactionsHistory.xlsx';
        const h = res.headers['content-disposition'];
        const match = h.match(/filename=(.+);/);

        if (match) {
          // eslint-disable-next-line prefer-destructuring
          filename = `${match[1]}.xlsx`;
        }

        return {
          data: res.data,
          filename,
        };
      } catch (e) {
        showErrorToast();
        return Promise.reject(e);
      }
    },
    checkBankAuth: async (userId, fundId) => {
      try {
        await get(`/users/${userId}/bank`, { fundId });

        return true;
      } catch {
        return false;
      }
    },
    initBankAuth: async (userId, fundId) => {
      return post(`/users/${userId}/bank/link`, { fundId });
    },
    exchangeBankToken: async (token, userId, fundId) => {
      return post(`/users/${userId}/bank/authorize`, {
        fundId,
        publicToken: token,
      });
    },
    resetPassword: async (username) => {
      return post('/auth/reset-password', { username });
    },
    changePassword: async (password, resetPasswordToken) => {
      return post('/auth/change-password', { password, resetPasswordToken });
    },
    updatePassword: async (userId, currentPassword, newPassword) => {
      return post(`/users/${userId}/update-password`, { currentPassword, newPassword });
    },
    getGrantees: async (query) => {
      try {
        return await get('/grantees', { name: query });
      } catch {
        return [];
      }
    },
    getGranteesByFundId: async (fundId) => {
      try {
        return await get('/grantees/getGranteesByFundId', { fundId });
      } catch {
        return [];
      }
    },
    getGranteeHistory: async (granteeHistoryRequest) => {
      try {
        return await post('/grantees/granteeHitory', { ...granteeHistoryRequest });
      } catch {
        return [];
      }
    },
    buildGranteeHistoryExcel: async (buildGranteeHistoryExcelRequest) => {
      try {
        const res = await instance.request({
          method: 'post',
          url: '/grantees/buildGranteeHistoryExcel',
          responseType: 'blob',
          data: { ...buildGranteeHistoryExcelRequest },
        });
        let filename = 'granteeHistory.xlsx';
        const h = res.headers['content-disposition'];
        const match = h.match(/filename=(.+);/);

        if (match) {
          // eslint-disable-next-line prefer-destructuring
          filename = `${match[1]}.xlsx`;
        }

        return {
          data: res.data,
          filename,
        };
      } catch (e) {
        showErrorToast();
        return Promise.reject(e);
      }
    },
    getAchGrantees: async () => {
      try {
        return await get('/grantees/getAchGrantees');
      } catch {
        return [];
      }
    },
    getCapitalFundGrantees: async () => {
      try {
        return await get('/grantees/getCapitalFundGrantees');
      } catch {
        return [];
      }
    },
    getUser: async (userId) => {
      try {
        return await get(`/users/${userId}`);
      } catch {
        return {};
      }
    },
    updateUser: async (userId, userForUpdate) => {
      return patch(`/users/${userId}`, { ...userForUpdate });
    },
    createGrant: async (grant) => {
      const grantRequest = grant;
      grantRequest.userId = user.id;
      return post('/grants/request', { ...grantRequest });
    },
    contact: async (from, firstName, lastName, message) => {
      return post('/communications/contact', { from, firstName, lastName, message });
    },
    getStatements: async (fundId, year) => {
      try {
        return await get('/statements', { fundId, year });
      } catch {
        return [];
      }
    },
    getStatement: async (statementId) => {
      return get(`/statements/${statementId}`);
    },
    getRecurringContributions: async (fundId) => {
      try {
        return await get('/contributions/recurring', { fundId });
      } catch {
        return [];
      }
    },
    createContribution: async (fundId, contribution) => {
      return post('/contributions', { fundId, ...contribution });
    },
    removeRecurringContribution: async (recurringId) => {
      return del(`/contributions/recurring/${recurringId}`);
    },
    allocateAchContribution: async (allocateAchContributionRequest) => {
      return post('/contributions/allocateAchContribution', { ...allocateAchContributionRequest });
    },
    isChangePasswordLinkExpired: async (resetPasswordToken) => {
      try {
        return await get('/auth/isChangePasswordLinkExpired', { resetPasswordToken });
      } catch {
        return [];
      }
    },
  };

  return <ApiContext.Provider value={{ ...endpoints, isLoading }}>{children}</ApiContext.Provider>;
};

export const ApiProvider = withRouter(ApiProviderComponent);
