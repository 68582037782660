import React, { useState, useContext, useEffect } from 'react';
import { AuthenticationContext } from 'auth/AuthProvider';
import { ApiContext } from 'components/ApiProvider';
import Banner from 'components/Banner';
import MessageModal from 'components/MessageModal';
import env from 'environment';
import FundGreeting from './components/FundGreeting';
import FundCard from './components/FundCard';

const FundsPage = () => {
  const [funds, setFunds] = useState([]);

  const { user } = useContext(AuthenticationContext);
  const { getFunds } = useContext(ApiContext);

  useEffect(() => {
    async function getUserFunds() {
      const f = await getFunds(user.id);
      setFunds(f);
    }

    getUserFunds();
  }, []);

  return (
    <div className="your-funds-wrapper mt-md-n4 ">
      {env.IS_SHOW_BANNER && (
        <>
          <MessageModal popupId="1" />
          <Banner bannerId="1" />
        </>
      )}
      <div className="container">
        <FundGreeting />
        <div className="d-flex py-3 py-md-2 flex-wrap justify-content-center">
          {funds.map((fund) => (
            <FundCard key={fund.id} fund={fund} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FundsPage;
