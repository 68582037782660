import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Pdf } from './Pdf';

export const DownloadPdf = (props) => {
  const { fund, amount, fee, allocations, fullName, frequency, paymentLastDigits } = props;

  return (
    <PDFDownloadLink
      document={
        <Pdf
          fund={fund}
          amount={amount}
          fee={fee}
          allocations={allocations}
          fullName={fullName}
          frequency={frequency}
          paymentLastDigits={paymentLastDigits}
        />
      }
      fileName="DonorsTrust Contribution.pdf"
    >
      <i className="bi bi-cloud-arrow-down-fill text-dark" />
    </PDFDownloadLink>
  );
};
