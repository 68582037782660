import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import env from 'environment';
import ChooseAmountCard from 'components/Common/ChooseAmountCard/ChooseAmountCard';
import { ContributionWarningModal } from 'components/Common/ChooseAmountCard/ContributionWarningModal';
import ReactTooltip from 'react-tooltip';

const AchDonationsChooseAmount = () => {
  const location = useLocation();
  const history = useHistory();
  const [isShowModal, setIsShowModal] = useState(false);
  const [chosenContributionInfo, setChosenContributionInfo] = useState(null);
  const dafAccountOpeningOptionId = 100002;
  const granteeId = location.state?.grantId ?? dafAccountOpeningOptionId;
  const granteeName = location.state?.grantName ?? 'Donor-advised fund account opening donation';
  const achDonationsBottomQuantityUrl = `${env.ACH_GRANTEE_DONATION_BOTTOM_QUANTITY_URL}?dt_grantee_id=${granteeId}&donors_trust_grantee_name=${granteeName}`;
  const achDonationsUpperQuantityUrl = `${env.ACH_GRANTEE_DONATION_UPPER_QUANTITY_URL}?dt_grantee_id=${granteeId}&donors_trust_grantee_name=${granteeName}`;
  const achDonationMore10kUrl = `${env.ACH_GRANTEE_DONATION_MORE_10K_URL}?dt_grantee_id=${granteeId}&donors_trust_grantee_name=${granteeName}`;
  const achDonationMore1kUrl = `${env.ACH_GRANTEE_DONATION_MORE_1K_URL}?dt_grantee_id=${granteeId}&donors_trust_grantee_name=${granteeName}`;

  return (
    <>
      <div className="grant-request mt-n3 mt-md-n4">
        <div className="grant-instructions text-center p-3 p-md-5">
          <h2>
            {granteeId !== dafAccountOpeningOptionId && (
              <strong>Choose amount of contribution</strong>
            )}
            {granteeId === dafAccountOpeningOptionId && <strong>Fund Account Opening Gift</strong>}
          </h2>
        </div>
      </div>
      <div className="container mt-5">
        {granteeId !== dafAccountOpeningOptionId && (
          <div className="d-flex justify-content-between flex-column flex-md-row ach-contribution-cards-wrapper">
            {' '}
            <ChooseAmountCard
              text={
                <div>
                  To request a contribution amount less than $8,500.00, click on the link below.
                  <br />
                  <br />
                  Once you click, you will be redirected to the appropriate payment form.
                </div>
              }
              navigation={
                <button
                  type="button"
                  className="button text-link"
                  onClick={() => {
                    setChosenContributionInfo({
                      url: achDonationsBottomQuantityUrl,
                      isGreater8500: false,
                    });
                    setIsShowModal(true);
                  }}
                >
                  <strong>Contribute &lt; $8,500.00</strong>
                </button>
              }
            />
            <ChooseAmountCard
              text={
                <div>
                  To request a contribution amount greater or equal to $8,500.00, click on the link
                  below.
                  <br />
                  <br />
                  Once you click, you will be redirected to the appropriate payment form.
                </div>
              }
              navigation={
                <button
                  type="button"
                  className="button text-link"
                  onClick={() => {
                    setChosenContributionInfo({
                      url: achDonationsUpperQuantityUrl,
                      isGreater8500: true,
                    });
                    setIsShowModal(true);
                  }}
                >
                  <strong>Contribute &ge; $8,500.00</strong>
                </button>
              }
            />
          </div>
        )}
        {granteeId === dafAccountOpeningOptionId && (
          <div className="d-flex justify-content-between flex-column flex-md-row ach-contribution-cards-wrapper">
            <ChooseAmountCard
              text={
                <div>
                  <span className="subtitle font-weight-bold">
                    Contribution for opening an account
                  </span>
                  <br />
                  <br />
                  Please note that the contribution must be greater than or equal to $10,000. To
                  make a contribution, click on the link below.
                  <br />
                  <br />
                  Once you click, you will be redirected to the appropriate payment form.
                </div>
              }
              navigation={
                <button
                  type="button"
                  className="button text-link"
                  onClick={() => {
                    window.location.href = achDonationMore10kUrl;
                  }}
                >
                  <strong>Contribute &ge; $10,000.00</strong>
                </button>
              }
            />
            <ChooseAmountCard
              text={
                <div>
                  <span className="subtitle font-weight-bold">
                    Contribution for opening a Novus account <NovusAccountInformation />
                  </span>
                  <br />
                  <br />
                  Please note that the contribution must be greater than or equal to $1,000. To make
                  a contribution, click on the link below.
                  <br />
                  <br />
                  Once you click, you will be redirected to the appropriate payment form.
                </div>
              }
              navigation={
                <button
                  type="button"
                  className="button text-link"
                  onClick={() => {
                    window.location.href = achDonationMore1kUrl;
                  }}
                >
                  <strong>Contribute &ge; $1,000.00</strong>
                </button>
              }
            />
          </div>
        )}
        <div className="mt-5 d-flex justify-content-end">
          <button
            type="button"
            className="button button--dark-blue px-4 px-md-5 py-3 mr-0 text-center col-12 col-md-auto"
            onClick={() => history.push({ pathname: '/grantee/achcontribution/choose' })}
          >
            Back
          </button>
        </div>
      </div>
      {isShowModal && (
        <ContributionWarningModal
          isShowModal={isShowModal}
          setIsShowModal={(value) => setIsShowModal(value)}
          chosenContributionInfo={chosenContributionInfo}
        />
      )}
    </>
  );
};
export default AchDonationsChooseAmount;

const NovusAccountInformation = () => {
  const iconId = 'tooltip-icon';
  const highlightedIconClass = 'highlight-tooltip-icon';

  return (
    <span className="contribution-tooltip">
      <i
        id={iconId}
        className="bi bi-exclamation-circle my-auto ml-2 contribution-tooltip-icon"
        data-tip
      />
      <ReactTooltip
        place="top"
        type="light"
        className="tooltip custom-tooltip"
        border
        effect="solid"
        clickable
        event="click"
        afterShow={() => document.getElementById(iconId).classList.add(highlightedIconClass)}
        afterHide={() => document.getElementById(iconId).classList.remove(highlightedIconClass)}
        globalEventOff="click"
      >
        <div className="tooltip-text">
          <strong>DonorsTrust’s Novus Society</strong> offers DAF accounts for givers under age 40,
          with lower minimum opening contribution requirements. See{' '}
          <a href="https://www.novussociety.org">www.novussociety.org</a> for more information.
        </div>
      </ReactTooltip>
    </span>
  );
};
