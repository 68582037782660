export const FORMS = [
  {
    name: 'Grant Request Form',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DT%20Grant%20Distribution%20Request%20Form-1.pdf',
  },
  {
    name: 'Asset Reallocation Form',
    src: ' https://consider.donorstrust.org/hubfs/DT%20Forms%20and%20Files/DT%20Investment%20Re-allocation%20Form.pdf',
  },
  {
    name: 'Additional Contribution Form',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DT%20Additional%20Contribution%20Form.v20161101.pdf',
  },
  {
    name: 'Update Donor Intent Statement',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/Donor%20Intent%20Update%20form.pdf',
  },
  {
    name: 'Add an Advisor',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DT%20DAF_SVF%20Advisor%20Appointment%20Form.v201805a.pdf',
  },
  {
    name: 'Remove an Advisor',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DT%20DAF_SVF%20Advisor%20Removal%20Form.v201805a.pdf',
  },
  {
    name: 'DonorsTrust DAF Application',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DonorsTrust%20application%20with%20instructions%20and%20MOU.pdf',
  },
];

export const RESOURCES = [
  {
    name: 'Donor’s Guide to DonorsTrust',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/Donors%20Guide%20to%20DonorsTrust.pdf',
  },
  {
    name: 'Asset Transfer Instructions',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/DT%20Asset%20Transfer%20Instructions%20with%20donor%20tips.pdf',
  },
  {
    name: '8 Steps to Securing Your Donor Intent',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/Giveaway%20Content/8_Steps%20to%20Securing%20Your%20Donor%20Intent.pdf',
  },
  {
    name: 'Planned Giving Resources Guide',
    src: 'https://cdn2.hubspot.net/hubfs/5879483/DT%20Forms%20and%20Files/Planned%20Giving%20Resource%20Booklet%202018.pdf',
  },
  {
    name: 'DonorsTrust Blog',
    src: 'https://www.donorstrust.org/blog/',
  },
  {
    name: 'Whitney Ball Memorial Fund',
    src: 'https://www.donorstrust.org/what-we-offer/whitneyballmemorialfund/',
  },
  {
    name: 'DonorsTrust’s 2020 Progress Report',
    src: 'https://consider.donorstrust.org/hubfs/DonorsTrust%20Progress%20Report%202020.pdf',
  },
  {
    name: 'Novus Society',
    src: 'https://www.novussociety.org/',
  },
];
