import React from 'react';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import { capitalizeFirstLetter } from 'util/capitalizeFirstLetter';

export const AllocationInfo = (props) => {
  const { fund, contributionAmount, fee, allocations, fullName, frequency, paymentLastDigits } =
    props;

  return (
    <>
      <div className="col-sm-8 text-center d-flex mx-md-auto finish-investment-wrapper allocation-info ">
        <table className="d-block d-md-table w-100 general-info">
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="text-left text-md-right col-sm-3 finish-investment-wrapper">
              <strong>Fund name:</strong>
            </td>
            <td className="text-left d-none d-md-table-cell col-sm-3" />
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
              {fund.name}
            </td>
          </tr>
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="text-left text-md-right col-sm-3 finish-investment-wrapper">
              <strong>Fund number:</strong>
            </td>
            <td className="text-left d-none d-md-table-cell col-sm-3" />
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
              {fund.id}
            </td>
          </tr>
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="text-left text-md-right col-sm-3 finish-investment-wrapper">
              <strong>Contribution:</strong>
            </td>
            <td className="text-left d-none d-md-table-cell col-sm-3" />
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">{`$${displayCurrency(
              contributionAmount
            )}`}</td>
          </tr>
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="text-left text-md-right col-sm-3 finish-investment-wrapper">
              <strong>Processing fee:</strong>
            </td>
            <td className="text-left d-none d-md-table-cell col-sm-3" />
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">{`$${displayCurrency(
              fee
            )}`}</td>
          </tr>
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="text-left text-md-right col-sm-3 finish-investment-wrapper">
              <strong>Investible amount:</strong>
            </td>
            <td className="text-left d-none d-md-table-cell col-sm-3" />
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
              <strong>{`$${displayCurrency(contributionAmount - fee)}`}</strong>
            </td>
          </tr>
        </table>
      </div>
      <div className="col-sm-8 text-center d-flex mx-auto allocation-info my-0 my-md-2 finish-investment-wrapper py-1 py-md-0">
        <div className="container pool-info">
          <div className="row ">
            <div className="col-sm-4 text-left text-md-center my-auto">
              <div className="row">
                <div className="col-sm-6" />
                <div className="col-sm-6 my-auto finish-investment-wrapper">
                  <strong>To:</strong>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              {allocations?.map((pool) => (
                <div key={pool.poolId} className="row py-1">
                  <div className="col-9 text-left col-sm-6 border-bottom finish-investment-wrapper">
                    {pool.name}
                  </div>
                  <div className="col-3 text-right text-md-left col-sm-6 border-bottom finish-investment-wrapper">{`${displayCurrency(
                    pool.amount
                  )}%`}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-flex justify-content-between finish-investment-wrapper py-2">
            <div className="col-sm-4 d-none d-md-table-cell text-right finish-investment-wrapper" />
            <div className="text-left col-6 col-sm-4 finish-investment-wrapper">
              <strong>Total:</strong>
            </div>
            <div className="text-right col-6 text-md-left col-sm-4 finish-investment-wrapper">
              <strong>{`${displayCurrency(100)}%`}</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-8 text-center d-flex mx-auto allocation-info my-0 my-md-2 finish-investment-wrapper">
        <table className="d-block d-md-table w-100 general-info">
          {frequency && (
            <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
              <td className="col-sm-3 text-left text-md-right finish-investment-wrapper">
                <strong>Frequency:</strong>
              </td>
              <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
                {capitalizeFirstLetter(frequency)}
              </td>
              <td className="text-left col-sm-3 d-none d-md-table-cell" />
            </tr>
          )}
          {fullName && (
            <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
              <td className="col-sm-3 text-left text-md-right finish-investment-wrapper">
                <strong>Account name:</strong>
              </td>
              <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
                {fullName}
              </td>
              <td className="text-left col-sm-3 d-none d-md-table-cell" />
            </tr>
          )}
          {paymentLastDigits && (
            <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
              <td className="col-sm-3 text-left text-md-right finish-investment-wrapper">
                <strong>Payment details:</strong>
              </td>
              <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">{`****${paymentLastDigits}`}</td>
              <td className="text-left col-sm-3 d-none d-md-table-cell" />
            </tr>
          )}
          <tr className="d-flex d-md-table-row justify-content-between py-1 py-md-0">
            <td className="col-sm-3 text-left text-md-right finish-investment-wrapper">
              <strong>Submitted Date:</strong>
            </td>
            <td className="text-right text-md-left col-sm-3 finish-investment-wrapper">
              {displayDate(new Date())}
            </td>
            <td className="text-left col-sm-3 d-none d-md-table-cell" />
          </tr>
        </table>
      </div>
    </>
  );
};
