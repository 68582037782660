import { useContext, useState } from 'react';
import { AuthenticationContext } from 'auth/AuthProvider';
import { ApiContext } from 'components/ApiProvider';
import { AlertContext } from 'Providers/Alert';
import { formatErrors } from 'util/errors';
import useLocalStorage from 'Hooks/LocalStorage';

export const useLogin = () => {
  const [loginLoading, setLoginLoading] = useState(false);

  const { setToken } = useContext(AuthenticationContext);
  const { login: loginUser } = useContext(ApiContext);
  const { addAlert, removeAlert } = useContext(AlertContext);

  const [, setShowPopup] = useLocalStorage('showPopup', true);

  const login = async ({ username, password }) => {
    setLoginLoading(true);
    setShowPopup(true); // on login, reset showPopup in localstorage

    try {
      removeAlert();
      const { token } = await loginUser(username, password);
      setToken(token);
    } catch (e) {
      const msg = formatErrors(e.response.data);
      console.log('err', e);
      addAlert('error', msg);
    } finally {
      setLoginLoading(false);
    }
  };

  return { login, loginLoading };
};
