import React, { useState } from 'react';

const downloadPdf = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
};

export const DafIntentFilesList = ({ fileLinks, removeFile }) => {
  const [areLinksShow, setLinksShow] = useState(false);

  const toggleDafLinksShow = () => {
    setLinksShow(!areLinksShow);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setLinksShow(false);
  };

  return (
    <div className="d-flex flex-column my-2 my-md-1 no-gutters">
      <div
        className="d-flex"
        type="button"
        onClick={toggleDafLinksShow}
        onKeyDown={handleKeyDown}
        role="menu"
        tabIndex={0}
      >
        <strong>Donors mission statement</strong>
        <div className="ml-3">
          <span className={`daf-links-accordion__${areLinksShow} d-inline-block`}>
            <i className="bi bi-chevron-up" />
          </span>
        </div>
      </div>
      <ul className="daf-links daf-files-list pt-2 pl-0">
        <div className={`daf-links-content__${areLinksShow}`}>
          {fileLinks?.map(({ fileUrl, fileName, id }) => (
            <li className="d-flex">
              <button
                type="button"
                className="button my-2 my-md-1"
                onClick={() => downloadPdf(fileUrl)}
              >
                {fileName}
              </button>
              <button type="button" className="button ml-2" onClick={() => removeFile(id)}>
                <i className="bi bi-x" />
              </button>
            </li>
          ))}
        </div>
        {fileLinks?.length ? (
          <div className="mt-3 daf-links__text text-muted">
            Click on the link above to view and download your donor mission statement file(s).
          </div>
        ) : (
          <div className="mt-3 daf-links__text text-muted">
            You have not provided a donor intent\mission statement
          </div>
        )}
      </ul>
    </div>
  );
};
