import React, { memo } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { displayDate } from 'util/dates';
import { TransactionsHistoryPdf } from './TransactionsHistoryPdf';

export const DownloadPdf = memo(({ transactionsHistory, dateFrom, dateTo, poolName }) => {
  return (
    <PDFDownloadLink
      document={
        <TransactionsHistoryPdf
          transactionsHistory={transactionsHistory}
          dateFrom={dateFrom}
          dateTo={dateTo}
          poolName={poolName}
        />
      }
      fileName={`Transactions ${displayDate(dateFrom)}-${displayDate(dateTo)} ${poolName}.pdf`}
    >
      <i className="bi bi-filetype-pdf text-danger" />
    </PDFDownloadLink>
  );
});
