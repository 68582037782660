import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const InputComponent = ({ value, type, onChange, placeholder, className, min }) => {
  if (type === 'textarea') {
    return (
      <Form.Control
        value={value}
        as={type}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
      />
    );
  }

  return (
    <Form.Control
      className={`radio-input-toggle__input ${className}`}
      value={value}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      min={min}
    />
  );
};

const RadioInputToggle = ({
  name,
  label,
  onChange,
  inputPlaceholder,
  isActive,
  inputValue,
  type = 'text',
  validationErrors,
  min,
  withoutInput,
}) => {
  const [show, setShow] = useState(false);
  const [inputVal, setInputVal] = useState(inputValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(validationErrors?.find((e) => e.name === name)?.message);
  }, [validationErrors]);

  useEffect(() => {
    setShow(isActive);
  }, [isActive]);

  const checkHandler = (e) => {
    setShow(e.target.checked);
    onChange(label, inputVal);
  };
  const inputHandler = (e) => {
    const val = e.target.value;
    setInputVal(val);
    onChange(label, val);
  };

  return (
    <div className="my-2">
      <Form.Check
        checked={isActive}
        onChange={checkHandler}
        type="radio"
        id={`radio-input-toggler-${label}`}
        htmlFor={`radio-input-toggler-${label}`}
        name={name}
        label={label}
      />
      {!withoutInput && show && (
        <div className="mt-2 ml-0 ml-md-3">
          <InputComponent
            value={inputVal}
            type={type}
            onChange={inputHandler}
            placeholder={inputPlaceholder}
            className={error != null ? 'invalid' : ''}
            min={min}
          />
          <Form.Text class className="validation-message">
            {error}
          </Form.Text>
        </div>
      )}
    </div>
  );
};

export default RadioInputToggle;
