import _ from 'lodash';

export const getLabelById = (id, array) => {
  const [result] = array.filter((level) => level.id === id);
  return result.label;
};

export const handleDataPrimaryAdvisors = (data, coPrimaryData) => ({
  firstName: data.firstName?.trim() || null,
  lastName: data.lastName?.trim() || null,
  prefix: data.prefix?.trim() || null,
  suffix: data.suffix?.trim() || null,
  donorsTrustSalutation: data.donorsTrustSalutation?.trim() || null,
  granteeSalutation: data.granteeSalutation?.trim() || null,
  mailingAddress: data.mailingAddress?.trim() || null,
  city: data.city?.trim() || null,
  state: data.state?.trim() || null,
  zip: data.zip?.trim() || null,
  preferredPhone: data.preferredPhone?.trim() || null,
  preferredPhoneType: data.preferredPhoneType ? Number(data.preferredPhoneType) : null,
  secondaryPhone: data.secondaryPhone ? data.secondaryPhone.trim() : null,
  secondaryPhoneType: data.secondaryPhoneType ? Number(data.secondaryPhoneType) : null,
  email: data.email?.trim() || null,
  preferElectronicCommunication: Boolean(data.preferElectronicCommunication),
  acceptMailingFromDonorsTrust: Boolean(data.acceptMailingFromDonorsTrust),
  coPrimaryAdvisor: coPrimaryData
    ? {
        firstName: coPrimaryData.firstName.trim(),
        lastName: coPrimaryData.lastName.trim(),
        prefix: coPrimaryData.prefix.trim(),
        suffix: coPrimaryData.suffix?.trim() || null,
        donorsTrustSalutation: coPrimaryData.donorsTrustSalutation.trim(),
        granteeSalutation: coPrimaryData.granteeSalutation.trim(),
        mailingAddress: coPrimaryData.mailingAddress.trim(),
        city: coPrimaryData.city.trim(),
        state: coPrimaryData.state.trim(),
        zip: coPrimaryData.zip.trim(),
        preferredPhone: coPrimaryData.preferredPhone.trim(),
        preferredPhoneType: Number(coPrimaryData.preferredPhoneType),
        secondaryPhone: coPrimaryData.secondaryPhone ? coPrimaryData.secondaryPhone.trim() : null,
        secondaryPhoneType: coPrimaryData.secondaryPhoneType
          ? Number(coPrimaryData.secondaryPhoneType)
          : null,
        email: coPrimaryData.email.trim(),
        preferElectronicCommunication: Boolean(coPrimaryData.preferElectronicCommunication),
        acceptMailingFromDonorsTrust: Boolean(coPrimaryData.acceptMailingFromDonorsTrust),
        sendStatementsAndGrantsMails: Boolean(coPrimaryData.sendStatementsAndGrantsMails),
      }
    : null,
});

export const findChangedValues = (oldData, newData) => {
  return Object.entries(oldData).reduce((result, [key, value]) => {
    if (value !== newData[key]) {
      if (key === 'coPrimaryAdvisor') {
        if (_.isEqual(value, newData[key])) {
          return result;
        }
        Object.assign(result, {
          communicationCoPrimaryAdvisor: {
            old: {
              acceptMailingFromDonorsTrust:
                oldData.coPrimaryAdvisor?.acceptMailingFromDonorsTrust || false,
              preferElectronicCommunication:
                oldData.coPrimaryAdvisor?.preferElectronicCommunication || false,
              sendStatementsAndGrantsMails:
                oldData.coPrimaryAdvisor?.sendStatementsAndGrantsMails || false,
            },
            new: {
              acceptMailingFromDonorsTrust:
                newData.coPrimaryAdvisor?.acceptMailingFromDonorsTrust || false,
              preferElectronicCommunication:
                newData.coPrimaryAdvisor?.preferElectronicCommunication || false,
              sendStatementsAndGrantsMails:
                newData.coPrimaryAdvisor?.sendStatementsAndGrantsMails || false,
            },
          },
        });

        return Object.assign(result, { [key]: { old: value, new: newData[key] } });
      }
      if (['preferElectronicCommunication', 'acceptMailingFromDonorsTrust'].includes(key)) {
        Object.assign(result, {
          communicationPrimary: {
            old: {
              acceptMailingFromDonorsTrust: oldData.acceptMailingFromDonorsTrust,
              preferElectronicCommunication: oldData.preferElectronicCommunication,
            },
            new: {
              acceptMailingFromDonorsTrust: newData.acceptMailingFromDonorsTrust,
              preferElectronicCommunication: newData.preferElectronicCommunication,
            },
          },
        });
      }
      return Object.assign(result, { [key]: { old: value, new: newData[key] } });
    }
    return result;
  }, {});
};

export const handleLoadedDataSunsettingDaf = (data) => ({
  isCustomPlan: data?.isCustomPlan ?? true,
  dafTermLimitOption: data?.dafTermLimitOption || null,
  dafTermLimitYearsAfterDeath: data?.dafTermLimitYearsAfterDeath
    ? Number(data?.dafTermLimitYearsAfterDeath)
    : null,
  dafTermLimitSpecificDate: data.dafTermLimitSpecificDate
    ? new Date(data.dafTermLimitSpecificDate)
    : null,
  finalDestributionToWitneyBallFundPercentage:
    data?.finalDestributionToWitneyBallFundPercentage || null,
  whitneyBallDistribution: !!data.finalDestributionToWitneyBallFundPercentage,
  averageOfPastGivingPercentage: data?.averageOfPastGivingPercentage || null,
  weightedAverageDistribution: !!data.averageOfPastGivingPercentage,
  publicCharitiesDistribution: !!data.publicCharityDistributions?.length,
  publicCharityDistributions: data.publicCharityDistributions?.length
    ? data.publicCharityDistributions.map((p) => ({ ...p, idFront: _.uniqueId() }))
    : [],
});

export const handleSubmittedDataSunsettingDaf = (data, publicCharities) => ({
  isCustomPlan: data?.isCustomPlan,
  dafTermLimitOption: data?.dafTermLimitOption || null,
  dafTermLimitYearsAfterDeath:
    data.dafTermLimitOption === 1 ? Number(data?.dafTermLimitYearsAfterDeath) : null,
  dafTermLimitSpecificDate:
    data.dafTermLimitOption === 3 ? new Date(data?.dafTermLimitSpecificDate) : null,
  finalDestributionToWitneyBallFundPercentage: data.whitneyBallDistribution
    ? data?.finalDestributionToWitneyBallFundPercentage
    : null,
  averageOfPastGivingPercentage: data.weightedAverageDistribution
    ? data?.averageOfPastGivingPercentage
    : null,
  publicCharityDistributions: publicCharities || [],
});

export const todayPlus25Years = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 25);

  return date.toLocaleDateString('en-CA');
};

export const handleInputNumberValue = (e) => {
  if (e.target.value === '0') {
    return 0;
  }
  if (e.target.value) {
    return Number(e.target.value);
  }
  return null;
};

export const findChangedValuesSunsetting = (oldData, newData) => {
  return Object.entries(oldData).reduce((result, [key, value]) => {
    if (value !== newData[key]) {
      if (key === 'publicCharityDistributions') {
        if (_.isEqual(value, newData[key])) {
          return result;
        }
      }
      if (
        [
          'averageOfPastGivingPercentage',
          'finalDestributionToWitneyBallFundPercentage',
          'publicCharityDistributions',
        ].includes(key)
      ) {
        Object.assign(result, {
          percentages: {
            old: {
              finalDestributionToWitneyBallFundPercentage:
                oldData.finalDestributionToWitneyBallFundPercentage,
              averageOfPastGivingPercentage: oldData.averageOfPastGivingPercentage,
              publicCharityDistributions: oldData.publicCharityDistributions,
            },
            new: {
              finalDestributionToWitneyBallFundPercentage:
                newData.finalDestributionToWitneyBallFundPercentage,
              averageOfPastGivingPercentage: newData.averageOfPastGivingPercentage,
              publicCharityDistributions: newData.publicCharityDistributions,
            },
          },
        });
      }
      return Object.assign(result, { [key]: { old: value, new: newData[key] } });
    }
    return result;
  }, {});
};
export const findChangedValuesSuccessorAdvisors = (oldData, newData) => {
  return Object.entries(oldData).reduce((result, [key, value]) => {
    if (value !== newData[key]) {
      if (key === 'successorAdvisors') {
        if (_.isEqual(value, newData[key])) {
          return result;
        }
      }
      return Object.assign(result, { [key]: { old: value, new: newData[key] } });
    }
    return result;
  }, {});
};

export const addHyphen = (value, previousValue) => {
  if (previousValue?.length >= value?.length) {
    return value;
  }
  return value?.length === 2 ? `${value}-` : value;
};

export const handleDataSuccessorAdvisors = (data) => ({
  successorAdvisorOption: String(data?.successorAdvisorOption) || '1',
  successorAdvisors: data?.successorAdvisors?.length
    ? data.successorAdvisors.map((advisor) => ({
        idFront: advisor.idFront || _.uniqueId(),
        id: advisor.id,
        firstName: advisor.firstName?.trim(),
        lastName: advisor.lastName?.trim(),
        prefix: advisor.prefix?.trim(),
        suffix: advisor.suffix?.trim() || null,
        mailingAddress: advisor.mailingAddress?.trim(),
        city: advisor.city?.trim(),
        state: advisor.state?.trim(),
        zip: advisor.zip?.trim(),
        preferredPhone: advisor.preferredPhone?.trim(),
        secondaryPhone: advisor.secondaryPhone?.trim() || null,
        email: advisor.email?.trim(),
        relationshipToPrimaryAdvisor: advisor.relationshipToPrimaryAdvisor?.trim() || null,
        continueHoldingAccount: Boolean(advisor.continueHoldingAccount),
        jointlyHeldPercentage: advisor.jointlyHeldPercentage || null,
        coOwnershipChoice: advisor.jointlyHeldPercentage ? '1' : '2',
      }))
    : [],
});

export const findDeletedIds = (oldArray, newArray) => {
  const oldArrayIds = oldArray.map((o) => o.id);
  const newArrayIds = newArray.map((n) => n.id);
  return oldArrayIds.filter((id) => !newArrayIds.includes(id));
};

export const bytesToMB = (size) => {
  return (size / 1024 / 1024).toFixed(4);
};

export const findChangedValuesDafDonorIntent = (
  previousSettings,
  statementFiles,
  files,
  message
) => {
  const changedOptions = {};
  if (previousSettings.message || message) {
    if (previousSettings.message !== message)
      Object.assign(changedOptions, {
        message: {
          old: previousSettings.message,
          new: message,
        },
      });
  }
  if (!_.isEqual(previousSettings.statementFiles, statementFiles) || files.length > 0) {
    Object.assign(changedOptions, {
      newStatementFiles: {
        old: previousSettings.statementFiles,
        new: [...statementFiles, ...files],
      },
    });
  }
  return changedOptions;
};

export const handleDataDonorIntent = (data) => ({
  message: data?.message || '',
  statementFiles: data?.statementFiles || [],
});

export const truncateFileNameTo50 = (fileName) => {
  const dotIndex = fileName.lastIndexOf('.');
  const extension = fileName.substring(dotIndex);
  const dateLength = 11;
  const maxFileNameLength = 50 + extension.length + dateLength;
  const symbolsQuantityBeforeEllipsis = 23;
  const ellipsis = '...';

  return fileName.length > maxFileNameLength
    ? `${fileName.substring(0, symbolsQuantityBeforeEllipsis)}${ellipsis}${fileName.substring(
        fileName.length - (maxFileNameLength - symbolsQuantityBeforeEllipsis - ellipsis.length)
      )}`
    : fileName;
};

const addDateToFileName = (fileName, date) => {
  const dotIndex = fileName.lastIndexOf('.');
  return `${fileName.substring(0, dotIndex)}_${date}${fileName.substring(dotIndex)}`;
};

export const handleFiles = (files) => {
  const currentDate = new Date().toJSON().slice(0, 10);
  const handledFiles = files.map((file) => {
    const nameWithCurrentDate = addDateToFileName(file.name, currentDate);
    return new File([file], nameWithCurrentDate, {
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
    });
  });
  return handledFiles.reverse();
};

export const findDuplicates = (array) => {
  const arrayOfNames = array.map((el) => el.name);
  const duplicates = arrayOfNames.reduce((acc, el, i) => {
    if (arrayOfNames.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
    return acc;
  }, []);
  return duplicates;
};

export const getAllIndexesOfName = (arr, val) => {
  const indexes = [];
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].name === val) indexes.push(i);
  }
  return indexes;
};
