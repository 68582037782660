import React from 'react';
import { Modal } from 'react-bootstrap';

export const ContributionWarningModal = ({
  isShowModal,
  setIsShowModal,
  chosenContributionInfo,
}) => {
  const { url, isGreater8500 } = chosenContributionInfo;
  const handleClose = () => setIsShowModal(false);
  const handleOk = () => {
    window.location.href = url;
    setIsShowModal(false);
  };

  return (
    <Modal show={isShowModal} centered dialogClassName="contribution-warning-modal">
      <Modal.Body>
        <div className="grant-instructions text-center">
          <div className="container p-3">
            {isGreater8500 ? (
              <h2>
                Contribution amount
                <br className="d-block d-md-none" /> &ge; $8,500.00
              </h2>
            ) : (
              <h2>
                Contribution amount
                <br className="d-block d-md-none" /> &lt; $8,500.00
              </h2>
            )}
            {isGreater8500 ? (
              <div className="text-muted mt-3">
                Are you positive your contribution is of $8,500 or more?
                <br /> If not, press the
                <strong> Cancel</strong> button.
                <br />
                <br />
                If you continue and enter an amount less than $8,500, the transaction will not be
                processed.
                <br />
                <br />
                Press <strong>Cancel</strong> to leave or <strong>Continue</strong> to proceed.
              </div>
            ) : (
              <div className="text-muted mt-3">
                Are you positive your contribution is less than $8,500?
                <br /> If not, press the
                <strong> Cancel</strong> button.
                <br />
                <br />
                If you continue and enter an amount greater or equal to $8,500, the processing
                vendor will charge you an extra fee.
                <br />
                <br />
                Press <strong>Cancel</strong> to leave or <strong>Continue</strong> to proceed.
              </div>
            )}
          </div>
        </div>
        <div className="text-muted mt-3 text-center note">
          <br />
          <div className="note-header">
            <strong>&nbsp;&nbsp;&nbsp;&nbsp;Note&nbsp;&nbsp;&nbsp;&nbsp;</strong>
          </div>
          <div className="note-text">
            <div className="first-sentense">
              Clicking <strong>Continue</strong> will take you to the third-party payment platform.
              <br />
            </div>
            Please note that each platform may have its own terms and policies.
          </div>
        </div>
        <div className="grant-request warning-popup">
          <div className="navigation-buttons text-center mt-4 d-flex justify-content-center">
            <button
              className="button button--gray py-3 py-md-2 px-3 px-md-5 mr-3 mr-md-5"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button button--dark-blue py-3 py-md-2 px-3 px-md-5"
              type="button"
              onClick={handleOk}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
