import React from 'react';
import { Card } from 'react-bootstrap';

const ChooseAmountCard = ({ text, navigation }) => {
  return (
    <Card className="shadow-sm col-12 col-md-5 text-center py-md-5">
      <Card.Body>{text}</Card.Body>
      <Card.Footer className="px-0 bg-white pt-0 pb-3 pt-md-4 border-0">
        <div className="my-0 my-md-1">{navigation}</div>
      </Card.Footer>
    </Card>
  );
};

export default ChooseAmountCard;
