import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const resolver = yupResolver(
  yup.object().shape({
    email: yup.string().email().required('Email is required'),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    message: yup.string().required('Message is required'),
  })
);
