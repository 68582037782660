import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import { pdf } from '@react-pdf/renderer';
import { State, City } from 'country-state-city';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import NumberFormat from 'react-number-format';
import PrimaryAdvisorElectionInstruction from './Instruction/PrimaryAdvisorElectionInstruction';
import { Pdf } from './Pdf/Pdf';
import { PrefixRadios } from './PrefixRadios/PrefixRadios';
import { PHONE_TYPES, regExp, resolver } from '../common/constants';
import { findChangedValues, handleDataPrimaryAdvisors } from '../common/helpers';
import { InputWithValidation } from './InputWithValidation/InputWithValidation';
import { NavigateButtons } from '../common/NavigateButtons/NavigateButtons';
import { InputWithoutValidation } from './InputWithoutValidation/InputWithoutValidation';
import CheckBoxWithLabel from './CheckBoxWithLabel/CheckBoxWithLabel';
import CoPrimaryAdvisorModal from './CoPrimaryAdvisorModal/CoPrimaryAdvisorModal';
import CoPrimaryAdvisorInfo from './CoPrimaryAdvisorInfo/CoPrimaryAdvisorInfo';

const PrimaryAdvisorsElection = () => {
  const {
    getAdvisorCommunicationElections,
    updateAdvisorCommunicationElections,
    sendNotificationAboutDonorAdvisedFundChanges,
    getFund,
  } = useContext(ApiContext);
  const history = useHistory();
  const { fundId } = useParams();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange ',
    resolver,
  });

  const [prefix, setPrefix] = useState('');
  const [isCoPrimaryAdvisorModalOpened, setIsCoPrimaryAdvisorModalOpened] = useState(false);
  const [coprimaryAdvisor, setCoprimaryAdvisor] = useState(null);
  const [cities, setCities] = useState();
  const [states, setStates] = useState();

  const [fund, setFund] = useState();
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [previousSettings, setPreviousSettings] = useState({});
  const [advisorySettings, setAdvisorySettings] = useState(null);

  useEffect(() => {
    async function getAdvisorElections() {
      const data = await getAdvisorCommunicationElections(fundId);
      const fundData = await getFund(fundId);
      setFund(fundData);
      const handledData = handleDataPrimaryAdvisors(data, data.coPrimaryAdvisor);
      setPreviousSettings(handledData);
      setPrefix(handledData.prefix);
      setSecondaryPhone(handledData.secondaryPhone);
      setCoprimaryAdvisor(handledData.coPrimaryAdvisor);
      setAdvisorySettings({
        ...data,
        prefixOther: regExp.IS_PREFIX_OTHER.test(data.prefix) ? '' : data.prefix,
      });
    }
    getAdvisorElections();
    setStates(
      State.getStatesOfCountry('US')
        .filter((state) => state.isoCode.length === 2)
        .map((state) => state.isoCode)
    );
    setCities([...new Set(City.getCitiesOfCountry('US').map((city) => city.name))]);
  }, []);

  useEffect(() => {
    reset(advisorySettings);
  }, [advisorySettings]);

  const submit = async (data) => {
    const newSettings = handleDataPrimaryAdvisors(data, coprimaryAdvisor);
    const changedOptions = findChangedValues(previousSettings, newSettings);

    if (Object.keys(changedOptions).length) {
      const blobPdf = await pdf(Pdf(changedOptions)).toBlob();

      const formData = new FormData();
      formData.append(
        'updatesFile',
        blobPdf,
        'Primary advisors and default communication elections.pdf'
      );

      await updateAdvisorCommunicationElections({
        fundId,
        ...newSettings,
      })
        .then(async () => {
          await sendNotificationAboutDonorAdvisedFundChanges(formData);
        })
        .then(() => {
          history.push({
            pathname: '/daf/submitted',
            state: { startingPage: 'primaryAdvisors', blobPdf, fund },
          });
        })
        .catch(console.log);
    } else {
      history.push({ pathname: '/fund/overview', state: { fund } });
    }
  };

  useEffect(() => {
    if (!secondaryPhone) {
      clearErrors('secondaryPhoneType');
      setValue('secondaryPhoneType', '');
    }
  }, [secondaryPhone]);

  useEffect(() => {
    if (regExp.IS_PREFIX_OTHER.test(prefix)) {
      setValue('prefixOther', '');
      clearErrors('prefixOther');
    }
  }, [prefix]);

  return (
    <>
      <PrimaryAdvisorElectionInstruction />
      <div className="container daf-container">
        <div>
          <strong>Primary and Co-Primary Advisor information.</strong> All correspondence will be
          sent to the primary advisor listed below.
        </div>
        <div className="my-3">
          <strong>Primary Advisor:</strong>
        </div>
        <div className="row">
          <InputWithValidation
            register={register}
            name="firstName"
            errors={errors}
            id="daf-first-name"
            label="First name: "
            labelWidth={4}
            inputWidth="auto"
          />
          <InputWithValidation
            register={register}
            name="lastName"
            errors={errors}
            id="daf-last-name"
            label="Last name:"
            labelWidth={4}
            inputWidth="auto"
          />
          <InputWithoutValidation
            register={register}
            name="suffix"
            id="daf-suffix"
            label="Suffix:"
            labelWidth={4}
            inputWidth="auto"
          />
        </div>
        <div className="my-3 d-flex daf-prefixes-container">
          <PrefixRadios prefix={prefix} setPrefix={setPrefix} setValue={setValue} />
          <div className="w-25">
            <Controller
              name="prefixOther"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Form.Control
                  {...field}
                  className="p-1 w-100"
                  value={value}
                  disabled={regExp.IS_PREFIX_OTHER.test(prefix)}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setPrefix(e.target.value);
                    setValue('prefix', e.target.value);
                  }}
                  isInvalid={errors.prefixOther}
                />
              )}
            />
            {errors.prefixOther && (
              <div className="validation-alert text-left " style={{ width: 120 }}>
                {errors.prefixOther.message}
              </div>
            )}
          </div>
        </div>
        <div className="row my-3">
          <InputWithValidation
            register={register}
            name="donorsTrustSalutation"
            errors={errors}
            id="daf-donortrust-salutation"
            label="Preferred DonorsTrust salutation:"
            labelWidth={12}
            inputWidth={4}
          />
        </div>
        <div className="row my-3">
          <InputWithValidation
            register={register}
            name="granteeSalutation"
            errors={errors}
            id="daf-grantee-salutation"
            label="Preferred Grantee salutation:"
            labelWidth={12}
            inputWidth={4}
          />
        </div>
        <div className="row my-3">
          <InputWithValidation
            register={register}
            name="mailingAddress"
            errors={errors}
            id="daf-mailing-address"
            label="Mailing address:"
            labelWidth={12}
            inputWidth={4}
          />
        </div>
        <div className="row my-3">
          <label htmlFor="daf-city-input" className="col-4 no-gutters d-flex align-items-start">
            <strong className="col-auto mt-1">City:</strong>
            <div className="ml-3 col-9">
              <Controller
                name="city"
                control={control}
                render={({ field: { value: cityValue, onChange, ...field } }) => (
                  <Typeahead
                    {...field}
                    renderInput={({ value, ...inputProps }) => (
                      <TypeaheadInputSingle
                        value={cityValue}
                        {...inputProps}
                        isInvalid={!!errors.city}
                        id="daf-city-input"
                      />
                    )}
                    minLength={3}
                    options={cities}
                    id="daf-city"
                    filterBy={(option, props) =>
                      option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                    }
                    onInputChange={(text) => {
                      onChange(text);
                    }}
                    className="typeahead-city w-100"
                    onChange={([city]) => {
                      onChange(city);
                    }}
                  />
                )}
              />
              {errors.city && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.city.message}
                </div>
              )}
            </div>
          </label>
          <label
            htmlFor="daf-state-input"
            className="col-2 mr-3 no-gutters d-flex align-items-start"
          >
            <strong className="col-auto mt-1">State:</strong>
            <div className="ml-3 col-7">
              <Controller
                name="state"
                control={control}
                render={({ field: { value: stateValue, onChange, ...field } }) => (
                  <Typeahead
                    {...field}
                    renderInput={({ value, ...inputProps }) => (
                      <TypeaheadInputSingle
                        value={stateValue}
                        {...inputProps}
                        isInvalid={!!errors.state}
                        maxLength="2"
                        id="daf-state-input"
                      />
                    )}
                    options={states}
                    id="daf-state"
                    filterBy={(option, props) =>
                      option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                    }
                    onInputChange={(text) => {
                      onChange(text);
                    }}
                    className="typeahead-state w-100"
                    onChange={([state]) => {
                      onChange(state);
                    }}
                  />
                )}
              />
              {errors.state && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.state.message}
                </div>
              )}
            </div>
          </label>
          <InputWithValidation
            register={register}
            name="zip"
            errors={errors}
            id="daf-zip"
            label="Zip:"
            labelWidth={2}
            inputWidth={8}
          />
        </div>
        <div className="row my-3">
          <InputWithValidation
            register={register}
            name="preferredPhone"
            errors={errors}
            id="daf-preferred-phone"
            label="Preferred phone:"
            labelWidth={6}
            inputWidth={6}
            isPhoneInput
            control={control}
          />
          <label
            htmlFor="daf-preferred-phone-type"
            className="col-6 no-gutters d-flex align-items-start"
          >
            <strong className="col-auto mt-1">Phone Type:</strong>
            <div className="ml-3 col-6">
              <Form.Control
                as="select"
                className="py-2 px-1 w-100"
                id="daf-preferred-phone-type"
                {...register('preferredPhoneType')}
                isInvalid={errors.preferredPhoneType}
              >
                {PHONE_TYPES.map(({ label, id }) => (
                  <option key={id} value={id} hidden={id === ''}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              {errors.preferredPhoneType && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.preferredPhoneType.message}
                </div>
              )}
            </div>
          </label>
        </div>
        <div className="row my-3">
          <Controller
            name="secondaryPhone"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Form.Label
                htmlFor="daf-secondary-phone"
                className="col-6 no-gutters d-flex align-items-start"
              >
                <strong className="col-auto mt-1">Secondary phone:</strong>
                <div className="ml-3 col-6">
                  <NumberFormat
                    {...field}
                    value={value}
                    type="tel"
                    format="### ### ####"
                    onValueChange={(v) => {
                      onChange(v.formattedValue);
                      setSecondaryPhone(v.formattedValue);
                    }}
                    className={`form-control p-1 w-100 ${
                      errors.secondaryPhone ? 'is-invalid' : ''
                    }`}
                    id="daf-secondary-phone"
                  />
                  {errors.secondaryPhone && (
                    <div className="validation-alert text-left ">
                      {errors.secondaryPhone.message}
                    </div>
                  )}
                </div>
              </Form.Label>
            )}
          />
          <label
            htmlFor="daf-secondary-phone-type"
            className="col-6 no-gutters d-flex align-items-start"
          >
            <strong className="col-auto mt-1">Phone Type:</strong>
            <div className="ml-3 col-6">
              <Form.Control
                as="select"
                className="py-2 px-1 w-100"
                disabled={!secondaryPhone}
                {...register('secondaryPhoneType')}
                id="daf-secondary-phone-type"
                isInvalid={errors.secondaryPhoneType}
              >
                {PHONE_TYPES.map(({ label, id }) => (
                  <option key={id} value={id} hidden={id === ''}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              {errors.secondaryPhoneType && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.secondaryPhoneType.message}
                </div>
              )}
            </div>
          </label>
        </div>
        <div className="row my-3">
          <InputWithValidation
            register={register}
            name="email"
            errors={errors}
            id="daf-email"
            label="Email address:"
            labelWidth={12}
            inputWidth={4}
          />
        </div>
        <div className="my-3">
          <CheckBoxWithLabel
            register={register}
            name="preferElectronicCommunication"
            label="I would prefer only electronic communication concerning my account."
            id="prefer-electronic-communication"
          />
        </div>
        <div className="my-3">
          <CheckBoxWithLabel
            register={register}
            name="acceptMailingFromDonorsTrust"
            label="I will accept mail/email from DonorsTrust concerning philanthropy and DonorsTrust
            generally."
            id="accept-mailing-from-donors-trust"
          />
        </div>
        <div className="my-4">
          <div>
            <button
              type="button"
              className="button p-0 d-flex align-items-center"
              disabled={coprimaryAdvisor}
              onClick={() => setIsCoPrimaryAdvisorModalOpened(true)}
            >
              <i className="bi bi-plus-circle daf-plus-icon" />
              <strong className="ml-2">Add Co-Primary Advisor:</strong>
            </button>
            {coprimaryAdvisor && (
              <CoPrimaryAdvisorInfo
                openPopup={() => setIsCoPrimaryAdvisorModalOpened(true)}
                setCoprimaryAdvisor={(data) => setCoprimaryAdvisor(data)}
                {...coprimaryAdvisor}
              />
            )}
          </div>
        </div>
        <NavigateButtons
          onClickCancel={() => history.push({ pathname: '/fund/overview', state: { fund } })}
          onClickSubmit={handleSubmit(submit)}
          submitButtonText="Submit"
        />
      </div>
      {isCoPrimaryAdvisorModalOpened && (
        <CoPrimaryAdvisorModal
          isGranteeHistoryModalOpened={isCoPrimaryAdvisorModalOpened}
          closeModal={() => setIsCoPrimaryAdvisorModalOpened(false)}
          coprimaryAdvisor={coprimaryAdvisor}
          setCoprimaryAdvisor={(data) => setCoprimaryAdvisor(data)}
          isCoPrimaryAdvisorModalOpened={isCoPrimaryAdvisorModalOpened}
          cities={cities}
          states={states}
        />
      )}
    </>
  );
};

export default PrimaryAdvisorsElection;
