import React, { useContext, useState, useEffect } from 'react';
import { GrantsContext } from 'Providers/Grants';
import { Form } from 'react-bootstrap';
import Instructions from 'components/Main/Grant/Request/Instructions';
import RadioInputToggle from 'components/Form/Input/RadioInputToggle';

export const AcknowledgementForm = ({ grant, updateGrant, editGrantValidationErrors }) => {
  const { validationErrors } = useContext(GrantsContext);
  const [editGrantErrors, setEditGrantErrors] = useState(editGrantValidationErrors);

  useEffect(() => {
    setEditGrantErrors(editGrantValidationErrors);
  }, [editGrantValidationErrors]);

  const RADIO_OPTIONS = [
    'Anonymous',
    'Account Name Only',
    'Account Name and Full Name',
    'Account Name, Full Name, and Address',
  ];

  let acknowledgementInputDefaultValue = '';

  if (grant.acknowledgement.includes('Recognize Someone Else')) {
    acknowledgementInputDefaultValue = grant.acknowledgement
      .replace('Recognize Someone Else', '')
      .trim();
  }

  return (
    <Form className="text-left">
      <Form.Group>
        <Form.Label as="legend" className="mt-3 mb-2 subtitle">
          Select your preferred style of acknowledgement:
        </Form.Label>
        {RADIO_OPTIONS.map((o) => {
          return (
            <Form.Check
              key={o}
              checked={grant.acknowledgement === o}
              onChange={(e) => updateGrant('acknowledgement', e.target.value)}
              type="radio"
              id={`add-grant-acknowledgement-${o}`}
              htmlFor={`add-grant-acknowledgement-${o}`}
              value={o}
              name="acknowledgement"
              label={o}
              className="my-2"
            />
          );
        })}

        <RadioInputToggle
          name="acknowledgement"
          label="Recognize Someone Else*"
          onChange={(value, custom) =>
            updateGrant('acknowledgement', 'Recognize Someone Else', custom)
          }
          isActive={grant.acknowledgement.includes('Recognize Someone Else')}
          type="textarea"
          inputPlaceholder="Enter Name (Address is optional)"
          inputValue={acknowledgementInputDefaultValue || grant.acknowledgementCustomInput}
          validationErrors={validationErrors?.some((x) => x) ? validationErrors : editGrantErrors}
        />
      </Form.Group>
    </Form>
  );
};

const AddAcknowledgement = ({ grant, updateGrant }) => {
  return (
    <>
      <div className="acknowledgement-popup">
        <Instructions hideStepCount isModal title={`Grant Acknowledgement: ${grant.granteeName} `}>
          <div className="text-left px-4 description">
            Grants to organizations are accompanied by a letter from DonorsTrust requesting the
            organization acknowledge the person who requested the grant. Choose below how you would
            like this grant acknowledged.
          </div>
        </Instructions>
        <div className="container">
          <div className="row">
            <div className="col">
              <AcknowledgementForm grant={grant} updateGrant={updateGrant} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAcknowledgement;
