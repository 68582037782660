import React, { createContext, useState, useContext } from 'react';
import * as yup from 'yup';
import { formatErrors } from 'util/errors';
import { ApiContext } from 'components/ApiProvider';
import { displayDate } from 'util/dates';

export const AchContributionAllocationContext = createContext();

const schema = yup.object().shape({
  fundId: yup.number().required(),
  approve: yup.string().required().oneOf(['Yes']),
  investedAmount: yup
    .number()
    .nullable()
    .required()
    .when('$needToInvest', (needToInvest, amount) => {
      return amount.min(needToInvest).max(needToInvest);
    }),
});

export const AchContributionAllocationProvider = (props) => {
  const { fund, contributorId, achContributionId, contributionAmount, fee, accountName, children } =
    props;
  const date = displayDate(new Date());
  const newConfiguration = {
    fundId: fund.id,
    fundName: fund.name,
    allocations: [],
  };
  const [configuration, setConfiguration] = useState(newConfiguration);
  const [errorMessage, setErrorMessage] = useState(null);
  const { allocateAchContribution } = useContext(ApiContext);

  const updateConfiguration = (key, value) => {
    if (value || value === 0) {
      configuration[key] = value;
    } else {
      delete configuration[key];
    }

    setConfiguration({ ...configuration });
  };

  const isValid = async () => {
    const investedAmount =
      (configuration?.allocations?.length &&
        configuration?.allocations?.reduce((a, b) => a + b.amount, 0)) ||
      null;
    const needToInvest = 100;
    try {
      const data = await schema.validate(
        { ...configuration, investedAmount },
        {
          context: { investedAmount, needToInvest },
        }
      );
      setErrorMessage(null);

      return data;
    } catch (e) {
      setErrorMessage(e.errors && e.errors[0]);

      return false;
    }
  };

  const submit = async () => {
    const errors = [];
    const { fundId, fundName, allocations } = configuration;
    const newAllocations = allocations.map((pool) => ({
      poolId: pool.poolId,
      allocationPercentage: pool.amount,
    }));
    try {
      await allocateAchContribution({
        fundId,
        fundName,
        allocations: newAllocations,
        contributorId,
        achContributionId,
        contributionAmount,
        fee,
        accountName,
        date,
      });
    } catch (e) {
      const msg = formatErrors(e.response.data);
      errors.push({ errorMsg: msg });
    }

    if (errors.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <AchContributionAllocationContext.Provider
      value={{
        configuration,
        updateConfiguration,
        isValid,
        submit,
        errorMessage,
      }}
    >
      {children}
    </AchContributionAllocationContext.Provider>
  );
};
