import React from 'react';

const GrantInstructions = ({
  children,
  title,
  hideStepCount,
  currentStep,
  totalSteps,
  isModal,
  className,
}) => {
  return (
    <div
      className={`grant-instructions mb-3 mb-md-4 ${
        !isModal ? 'p-md-5 mt-md-n4' : String.empty
      } ${className}`}
    >
      <div className="container">
        <div className="row">
          <div
            className={`instruction-container col p-3 px-md-3 py-md-0 text-center ${
              !isModal ? 'col-md-8 offset-md-2' : String.empty
            }`}
          >
            <h2>
              <strong>
                {hideStepCount ? title : `Step ${currentStep} of ${totalSteps}: ${title}`}
              </strong>
            </h2>
            {children && <p className="mt-3 mb-0 my-md-4 text-muted">{children}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantInstructions;
