import React from 'react';
import { Link } from 'react-router-dom';

export default ({ title, link, description }) => (
  <div className="mb-4">
    <div className="header d-flex">
      <h5>
        <strong>{title}</strong>
      </h5>
      <Link to={link} className="ml-2">
        <strong>See all</strong>
      </Link>
    </div>
    <span className="d-block" style={{ color: '#4B5563', fontSize: '15px' }}>
      {description}
    </span>
  </div>
);
