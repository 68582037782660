import React, { useContext, useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { AuthenticationContext } from 'auth/AuthProvider';
import { ApiContext } from 'components/ApiProvider';
import ReactTooltip from 'react-tooltip';

const resolver = yupResolver(
  yup.object().shape({
    currentPassword: yup.string().trim().required('Current password is required'),
    newPassword: yup
      .string()
      .trim()
      .required('New password is required')
      .test(
        'isValidLength',
        'Password must have at least 8 characters',
        (value) => value?.length >= 8
      )
      .test('hasLoverCaseLetter', 'Password must have at least one lower case letter', (value) =>
        /[a-z]/.test(value)
      )
      .test('hasUpperCaseLetter', 'Password must have at least one upper case letter', (value) =>
        /[A-Z]/.test(value)
      )
      .test('hasNumber', 'Password must have at least one number', (value) => /[0-9]/.test(value)),
    newPasswordConfirmation: yup
      .string()
      .trim()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm new password is required'),
  })
);

const PasswordForm = () => {
  const { user } = useContext(AuthenticationContext);
  const { updatePassword } = useContext(ApiContext);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isNewPasswordConfirmationVisible, setIsNewPasswordConfirmationVisible] = useState(false);
  const [isInstructionTooltip, setIsInstructionTooltip] = useState(true);

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ criteriaMode: 'all', resolver });

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const toggleNewPasswordConfirmationVisibility = () => {
    setIsNewPasswordConfirmationVisible(!isNewPasswordConfirmationVisible);
  };

  useEffect(() => {
    if (errors.newPassword) {
      setIsInstructionTooltip(false);
    }
  }, [errors.newPassword]);

  const changePassword = async (values) => {
    const { currentPassword, newPassword } = values;
    try {
      await updatePassword(user.id, currentPassword, newPassword);
      toast.success('Your password has been successfully changed!', {
        toastId: 'successToast',
      });
      setIsInstructionTooltip(true);
      reset();
    } catch (e) {
      reset();
      if (e.response?.data === 'Previous password used') {
        toast.error(
          'The password you entered does not comply with the password policy. Please note that previously used passwords cannot be used.',
          {
            toastId: 'errorToast',
          }
        );
      } else {
        setError('currentPassword', { type: 'custom', message: 'Incorrect password' });
      }
    }
  };

  return (
    <Form
      className="form mt-4 mb-0 my-md-4 password-form"
      onSubmit={handleSubmit((values) => changePassword(values))}
    >
      <Form.Group sm={12} md={4} controlId="currentPassword">
        <Form.Label>Current Password:</Form.Label>
        <InputGroup>
          <Form.Control
            type={isCurrentPasswordVisible ? 'text' : 'password'}
            {...register('currentPassword')}
            isInvalid={errors.currentPassword}
            className="password-input"
          />
          <button
            type="button"
            className="button visibility"
            onClick={() => toggleCurrentPasswordVisibility()}
            tabIndex={-1}
          >
            <i className={`bi ${isCurrentPasswordVisible ? 'bi-eye' : 'bi-eye-slash'}`} />
          </button>
          <Form.Control.Feedback type="invalid">
            {errors.currentPassword?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group sm={12} md={4} controlId="newPassword">
        <Form.Label>New Password:</Form.Label>
        <InputGroup>
          <Form.Control
            type={isNewPasswordVisible ? 'text' : 'password'}
            {...register('newPassword')}
            isInvalid={errors.newPassword}
            data-tip
            className="password-input"
          />
          <ReactTooltip
            place="right"
            className="tooltip tooltip-password"
            effect="solid"
            events={['hover', 'focus']}
          >
            {isInstructionTooltip && (
              <>
                <div>
                  <strong>Password Tips:</strong>
                  <ul>
                    <li>Use 8-14 characters.</li>
                    <li>Don&apos;t use your full name, account name or email.</li>
                    <li>Use mix of uppercase and lowercase letters, numbers and symbols</li>
                  </ul>
                </div>
              </>
            )}
            {!isInstructionTooltip && (
              <>
                <div>
                  <strong>Password must:</strong>
                  <ul>
                    <li className={errors.newPassword?.types?.isValidLength ? 'invalid' : 'valid'}>
                      Have at least 8 characters
                    </li>
                    <li
                      className={
                        errors.newPassword?.types?.hasLoverCaseLetter ? 'invalid' : 'valid'
                      }
                    >
                      Have at least one lower case letter
                    </li>
                    <li
                      className={
                        errors.newPassword?.types?.hasUpperCaseLetter ? 'invalid' : 'valid'
                      }
                    >
                      Have at least one upper case letter
                    </li>
                    <li className={errors.newPassword?.types?.hasNumber ? 'invalid' : 'valid'}>
                      Have at least one number
                    </li>
                  </ul>
                </div>
              </>
            )}
          </ReactTooltip>
          <button
            type="button"
            className="button visibility"
            onClick={() => toggleNewPasswordVisibility()}
            tabIndex={-1}
          >
            <i className={`bi ${isNewPasswordVisible ? 'bi-eye' : 'bi-eye-slash'}`} />
          </button>
          <Form.Control.Feedback type="invalid">
            {errors.newPassword?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group sm={12} md={4} controlId="newPasswordConfirmation">
        <Form.Label>Confirm New Password:</Form.Label>
        <InputGroup>
          <Form.Control
            type={isNewPasswordConfirmationVisible ? 'text' : 'password'}
            {...register('newPasswordConfirmation')}
            isInvalid={errors.newPasswordConfirmation}
            className="password-input"
          />
          <button
            type="button"
            className="button visibility"
            onClick={() => toggleNewPasswordConfirmationVisibility()}
            tabIndex={-1}
          >
            <i className={`bi ${isNewPasswordConfirmationVisible ? 'bi-eye' : 'bi-eye-slash'}`} />
          </button>
          <Form.Control.Feedback type="invalid">
            {errors.newPasswordConfirmation?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <button
          className="button button--dark-blue col col-md-auto px-3 py-3 px-md-5"
          type="submit"
        >
          Change Password
        </button>
      </Form.Group>
    </Form>
  );
};

export default PasswordForm;
