import React, { useState, useContext, useEffect } from 'react';
import { GrantsContext } from 'Providers/Grants';
import GrantTable from 'components/Main/Grant/Table';
import Instructions from '../Instructions';
import SubStepModal from './SubStep/indexModal';

export const AddGrantInfo = (props) => {
  const { currentStep, totalSteps, subStep, setSubStep, firstStep } = props;

  const { grants, removeGrant, updateGrant } = useContext(GrantsContext);
  const [grant, setGrant] = useState(null);
  const [showSubstepModal, setShowSubstepModal] = useState(false);
  const [prevGrantInfo, setPrevGrantInfo] = useState(null);

  const switchToSubstep = (subStepName, grantObj) => {
    setPrevGrantInfo({
      substep: subStepName,
      designation: grantObj.designation,
      acknowledgement: grantObj.acknowledgement,
      timing: grantObj.timing,
      comments: grantObj.comments,
      requestWireTransfer: grantObj.requestWireTransfer,
    });
    if (grantObj.timing.includes('On a recurring basis:')) {
      setPrevGrantInfo((prev) => ({
        ...prev,
        recurring: true,
        frequency: grantObj.frequency,
        startDate: grantObj.startDate,
        endDate: grantObj.endDate,
      }));
    }
    setSubStep(subStepName);
    setGrant(grantObj);
    setShowSubstepModal(true);
  };

  useEffect(() => {
    setShowSubstepModal(showSubstepModal);
  }, [showSubstepModal]);

  if (grants.length === 0) {
    firstStep();
  }

  return (
    <>
      <SubStepModal
        showModal={showSubstepModal}
        closeModal={() => setShowSubstepModal(false)}
        currentStep={currentStep}
        substep={subStep}
        setSubStep={setSubStep}
        grant={grant}
        prevGrantInfo={prevGrantInfo}
      />
      <Instructions title="Add Grant Info" currentStep={currentStep} totalSteps={totalSteps}>
        <div className="d-none d-md-block">
          Default information is provided for each grant. Click on any cell to edit the grant&apos;s{' '}
          <span className="font-weight-bold">Designation</span>,{' '}
          <span className="font-weight-bold">Acknowledgement</span>, or{' '}
          <span className="font-weight-bold">Timing</span> descriptions.
        </div>
        <div className="d-block d-md-none">
          Default information is provided for each grant. Click on the edit icon to start changing{' '}
          <span className="font-weight-bold">Designation</span>,{' '}
          <span className="font-weight-bold">Acknowledgement</span>, and{' '}
          <span className="font-weight-bold">Timing</span> descriptions at once.
        </div>
      </Instructions>
      <div className="container add-grant-info">
        <div className="row">
          <div className="col">
            <GrantTable
              grants={grants}
              removeGrant={removeGrant}
              onGrantClick={switchToSubstep}
              onWireRequest={(granteeId, checked) =>
                updateGrant(granteeId, 'requestWireTransfer', checked)
              }
              onComment={(granteeId, value) => updateGrant(granteeId, 'comments', value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
