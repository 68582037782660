import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ApiContext } from 'components/ApiProvider';
import { toast } from 'react-toastify';

const resolver = yupResolver(
  yup.object().shape({
    username: yup.string().required('Username required'),
  })
);

const ResetPasswordForm = () => {
  const { resetPassword } = useContext(ApiContext);
  const resetUserPassword = async (values) => {
    try {
      await resetPassword(values.username).then(() => {
        toast.success('The password reset link has been sent. Please check your email.', {
          toastId: 'successToast',
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver });

  return (
    <Form className="form" onSubmit={handleSubmit((values) => resetUserPassword(values))}>
      <div className="reset-password-form">
        <Form.Label align="right" className="subtitle">
          Username:
        </Form.Label>
        <Form.Control {...register('username')} isInvalid={errors.username} type="text" />
        <Form.Control.Feedback type="invalid">{errors.username?.message}</Form.Control.Feedback>
        <button className="button button--dark-blue mt-3 px-4 py-3 py-md-2 w-100" type="submit">
          Reset Password
        </button>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
