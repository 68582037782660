import React from 'react';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';

const SubmittedDonationBox = ({
  supportText,
  granteeName,
  contributionAmount,
  firstName,
  lastName,
  frequency,
  email,
  paymentLastDigits,
}) => {
  return (
    <>
      <div className="ach-donations px-0 col-md-6 pb-2 scrollable-grid">
        <table className="table table-borderless ">
          <tbody>
            <tr className="row">
              <td className="text-right col-5 ">{supportText}</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{granteeName}</td>
            </tr>
            <tr className="row">
              <td className="text-right col-5">Donation</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{`$${displayCurrency(
                contributionAmount
              )}`}</td>
            </tr>
            <tr className="row">
              <td className="text-right col-5">Frequency</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{frequency}</td>
            </tr>
            <tr className="row">
              <td className="text-right col-5">User&apos;s email</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{email}</td>
            </tr>
            <tr className="row">
              <td className="text-right col-5">User&apos;s full name</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{`${firstName} ${lastName}`}</td>
            </tr>
            <tr className="row">
              <td className="text-right  col-5">Payment details</td>
              <td className="text-left col-7 pl-5 font-weight-normal">****{paymentLastDigits}</td>
            </tr>
            <tr className="row">
              <td className="text-right col-5">Submitted date</td>
              <td className="text-left col-7 pl-5 font-weight-normal">{displayDate(new Date())}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubmittedDonationBox;
