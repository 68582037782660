import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import NewGranteeForm from 'components/Main/NewGrantee/Form';

const NewGranteeModal = ({ showModal, granteeName, onAdd, onClose }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>New Grantee Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewGranteeForm name={granteeName} onSaveSuccess={onAdd} onClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

export default NewGranteeModal;
