import React from 'react';
import { useLocation } from 'react-router-dom';
import SubmittedDonationBox from 'components/Common/SubmittedDonationBox/SubmittedDonationBox';
import { DownloadPdf } from './Pdf/index';

const AchDonationsSubmitted = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const granteeName = urlParams.get('donors_trust_grantee_name');
  const contributionAmount = urlParams.get('amount') / 100;
  const firstName = urlParams.get('first_name');
  const lastName = urlParams.get('last_name');
  const isRecurring = urlParams.get('recurring') === 'true';
  const frequency = urlParams.get('frequency');
  const email = urlParams.get('email');
  const paymentLastDigits = urlParams.get('last_digits');

  return (
    <>
      <div className="text-center mt-3 col-md-8 offset-md-2">
        <h2>
          <strong> Your donation has been submitted</strong>
        </h2>
        <p className="my-4 text-left text-md-center">
          Congratulations! Your donation should occur within 3-7 business days after Donors Trust
          receives the funds.
          <br /> Please, check your email for confirmation of your donation.
        </p>
      </div>
      <div className="container col-md-8 pt-3  offset-md-4">
        <SubmittedDonationBox
          supportText="Donors Trust to support"
          granteeName={granteeName}
          contributionAmount={contributionAmount}
          firstName={firstName}
          lastName={lastName}
          frequency={isRecurring ? frequency : 'once'}
          email={email}
          paymentLastDigits={paymentLastDigits}
        />
        <div>
          <div className="mt-4  d-flex d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <p className="mb-0">To save the donation, click the icon</p>
            <button
              type="button"
              className="border-0 mx-0 mx-md-3 mt-2 mt-md-1 pool-choice__download-pdf"
            >
              <DownloadPdf
                granteeName={granteeName}
                amount={contributionAmount}
                frequency={isRecurring ? frequency : 'once'}
                email={email}
                fullName={`${firstName} ${lastName}`}
                paymentLastDigits={paymentLastDigits}
              />
            </button>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={() => {
                window.location.href = 'https://www.donorstrust.org/';
              }}
              className="button button--gray d-block  col-12 col-md-auto p-3 py-md-3 px-md-5 mr-2 my-2"
            >
              Return to Website
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchDonationsSubmitted;
