import jwtDecode from 'jwt-decode';

export const getAuthToken = () => {
  return localStorage.getItem('dt-token');
};

export const setAuthToken = (token) => {
  localStorage.setItem('dt-token', token);
};

export const getUser = (token) => {
  try {
    const { sub: id, name, isDafFunctionalityAvailable } = jwtDecode(token);
    return {
      id,
      name,
      isDafFunctionalityAvailable,
    };
  } catch {
    return {};
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem('dt-token');
};
