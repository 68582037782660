import React from 'react';
import { NavLink } from 'react-router-dom';
import Logout from 'components/Logout';

export const BurgerMenu = ({ isActive, setInactive }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setInactive();
  };

  return (
    <div
      className={isActive ? 'burger-menu active' : 'burger-menu'}
      onClick={setInactive}
      onKeyDown={handleKeyDown}
      role="menu"
      tabIndex={0}
    >
      <div
        className={isActive ? 'burger-menu__content active' : 'burger-menu__content'}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
        role="menu"
        tabIndex={0}
      >
        <div className="">
          <button className="button-close" onClick={setInactive} type="button">
            <span />
          </button>
          <div className="burger-menu__list">
            <NavLink exact to="/" title="Your Funds" onClick={setInactive}>
              Your Funds
            </NavLink>
            <NavLink exact to="/resources" title="Forms & Resources" onClick={setInactive}>
              Forms & Resources
            </NavLink>
            <NavLink exact to="/profile" title="Manage Profile" onClick={setInactive}>
              Manage Profile
            </NavLink>
            <NavLink exact to="/contact" title="Contact Us" onClick={setInactive}>
              Contact Us
            </NavLink>
            <button type="button" className="button-logout" onClick={setInactive}>
              <Logout />
            </button>
          </div>
        </div>
        <ul className="list" />
      </div>
    </div>
  );
};
