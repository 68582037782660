import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      margin: '10px',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const GranteeHistoryPdf = (props) => {
  const { granteeHistory, filter, isSecondPeriod = true } = props;

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Table>
            <TableRow>
              <TableCell textAlign="center" />
              <TableCell textAlign="center">Grantee history</TableCell>
              <TableCell textAlign="center" />
            </TableRow>
            <TableRow>
              <TableCell textAlign="center" />
              <TableCell textAlign="center" />
              <TableCell textAlign="center" />
            </TableRow>
            <TableRow>
              <TableCell textAlign="center" />
              <TableCell textAlign="center">Period 1</TableCell>
              {isSecondPeriod && <TableCell textAlign="center">Period 2</TableCell>}
            </TableRow>
            <TableRow>
              <TableCell textAlign="center">Grantee name</TableCell>
              <TableCell textAlign="center">
                {`${displayDate(filter.firstPeriodStartDate)} - ${
                  filter.firstPeriodEndDate == null
                    ? 'Today'
                    : displayDate(filter.firstPeriodEndDate)
                }`}
              </TableCell>
              {isSecondPeriod && (
                <>
                  <TableCell textAlign="center">
                    {`${displayDate(filter.secondPeriodStartDate)} - ${
                      filter.secondPeriodEndDate == null
                        ? 'Today'
                        : displayDate(filter.secondPeriodEndDate)
                    }`}
                  </TableCell>
                  <TableCell>Difference</TableCell>
                </>
              )}
            </TableRow>
            {granteeHistory.map((gh) => (
              <TableRow>
                <TableCell textAlign="center">{gh.grantee}</TableCell>
                <TableCell textAlign="center">{`$${displayCurrency(
                  gh.firstPeriodAmount
                )}`}</TableCell>
                {isSecondPeriod && (
                  <>
                    <TableCell textAlign="center">{`$${displayCurrency(
                      gh.secondPeriodAmount
                    )}`}</TableCell>
                    <TableCell textAlign="center">{`$${displayCurrency(
                      gh.firstPeriodAmount - gh.secondPeriodAmount
                    )}`}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </Table>
        </View>
      </Page>
    </Document>
  );
};
