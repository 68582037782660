import React, { useState, useEffect, useContext, useMemo, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Dropdown } from 'react-bootstrap';
import { ApiContext } from 'components/ApiProvider';
import { useForm } from 'react-hook-form';
import GrantHistoryTable from 'components/Main/ReviewFund/components/GrantHistoryTable';
import useCurrentFund from 'Hooks/CurrentFund';
import ReactTooltip from 'react-tooltip';
import { useInView } from 'react-intersection-observer';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import { ButtonToBottomGranteeHistory } from './Buttons/ButtonToBottomGranteeHistory';
import { ButtonToTopGranteeHistory } from './Buttons/ButtonToTopGranteeHistory';
import GrantProgressModal from './Modals/GrantProgress';
import GranteeHistoryModal from './Modals/GranteeHistory';

const GrantHistoryPage = memo(() => {
  const [pendingGrants, setPendingGrants] = useState([]);
  const [issuedGrants, setIssuedGrants] = useState([]);
  const [grantStatusFilter, setGrantStatusFilter] = useState('all');
  const [isRecent, setIsRecent] = useState(false);
  const [grantProgress, setGrantProgress] = useState(null);
  const [grants, setGrants] = useState([]);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isFilterApplied, setisFilterApplied] = useState(false);
  const [isGrantProgresModalOpened, setIsGrantProgresModalOpened] = useState(false);
  const [isGranteeHistoryModalOpened, setIsGranteeHistoryModalOpened] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const fund = useCurrentFund();
  const limit = 100;
  const [ref, inView, entry] = useInView({
    rootMargin: '150px 0px',
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  if (!fund.id) {
    return <Redirect to="/" />;
  }

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const { getGrantRequests, getGrants, getGrantProgress } = useContext(ApiContext);

  const getModalData = async (id, isRequest) => {
    const progress = await getGrantProgress(id, isRequest);
    setGrantProgress(progress);
  };

  const openGrantProgressModal = (id, isRequest) => {
    getModalData(id, isRequest);
    setIsGrantProgresModalOpened(true);
  };

  useEffect(() => {
    async function pGrants() {
      const g = await getGrantRequests(fund.id);
      setPendingGrants(g);
    }

    pGrants();
  }, []);

  useEffect(() => {
    async function iGrants() {
      const g = await getGrants(fund.id, limit, true);
      setIssuedGrants(g);
    }

    iGrants();
  }, []);

  const allGrants = () => {
    const allArr = issuedGrants.concat(pendingGrants);

    return allArr.sort(
      (a, b) => new Date(b.checkDate || b.submittedDate) - new Date(b.checkDate || a.submittedDate)
    );
  };

  const filteredGrants = (filterOptions) => {
    if (issuedGrants.length + pendingGrants.length > 0) {
      let fGrants = [];

      switch (grantStatusFilter) {
        case 'pending':
          fGrants = pendingGrants;
          break;
        case 'issued':
          fGrants = issuedGrants;
          break;
        default:
          fGrants = allGrants();
      }

      if (filterOptions) {
        fGrants = fGrants.filter((g) =>
          filterOptions.endDate
            ? new Date(filterOptions.startDate) <= new Date(g.submittedDate) &&
              new Date(filterOptions.endDate) >= new Date(g.submittedDate.substring(0, 10))
            : new Date(filterOptions.startDate) <= new Date(g.submittedDate)
        );
        setisFilterApplied(true);
        setIsDropdownOpened(false);
      } else {
        reset({
          startDate: '',
          endDate: '',
        });
        setStartDate('');
        setEndDate('');

        if (isFilterApplied) setisFilterApplied(false);
        if (isDropdownOpened) setIsDropdownOpened(false);
      }

      if (isRecent) {
        fGrants = fGrants.filter((g) => new Date(g.checkDate || g.submittedDate) > thirtyDaysAgo);
      }
      setGrants(fGrants);
    }
  };

  const sortedGrants = (key, order) => {
    let sortedGrantsList = [];

    if (order === 'asc') {
      sortedGrantsList = [...grants].sort((a, b) => {
        if (typeof a[key] === 'string') {
          return key.includes('Date')
            ? new Date(a[key]) - new Date(b[key])
            : a[key].localeCompare(b[key]);
        }
        return a[key] - b[key];
      });
    } else {
      sortedGrantsList = [...grants].sort((a, b) => {
        if (typeof a[key] === 'string') {
          return key.includes('Date')
            ? new Date(b[key] || null) - new Date(a[key] || null)
            : b[key].localeCompare(a[key]);
        }
        return b[key] - a[key];
      });
    }

    setGrants(sortedGrantsList);
  };

  useMemo(() => filteredGrants(), [grantStatusFilter, isRecent, issuedGrants, pendingGrants]);

  return (
    <>
      <GrantProgressModal
        showModal={isGrantProgresModalOpened}
        progressInfo={grantProgress}
        closeModal={() => setIsGrantProgresModalOpened(false)}
      />
      <GranteeHistoryModal
        isGranteeHistoryModalOpened={isGranteeHistoryModalOpened}
        closeModal={() => setIsGranteeHistoryModalOpened(false)}
        fundId={fund.id}
      />
      <div className="container review-grants">
        <div className="row">
          <div className="col">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-end mb-md-5">
              <h1>Review your grants below</h1>
              <h2 className="text-blue">{fund.name}</h2>
            </div>
            <h4 className="mt-3 mt-md-5 mb-3 d-md-none">Grants</h4>
            <div className="d-flex mb-4 flex-column flex-md-row justify-content-between review-grants__header pt-1 pb-3 py-md-3 ">
              <div className="d-flex flex-column">
                <div className="row d-flex flex-column flex-md-row">
                  <small className="font-weight-bold col-2">Show</small>
                  <div className="col-10 d-flex mt-1 mt-md-0">
                    <Form.Check
                      defaultChecked
                      type="radio"
                      label="Last 100"
                      name="isRecent"
                      id="grantee-history-last-100"
                      htmlFor="grantee-history-last-100"
                      value={isRecent}
                      onChange={() => setIsRecent(false)}
                    />
                    <Form.Check
                      type="radio"
                      label="Recent (last 30 days)"
                      className="ml-3 mx-md-2"
                      id="grantee-history-is-recent"
                      htmlFor="grantee-history-is-recent"
                      name="isRecent"
                      value={isRecent}
                      onChange={() => setIsRecent(true)}
                    />
                  </div>
                </div>
                <div className="row mt-4 mt-md-3 d-flex flex-column flex-md-row">
                  <small className="font-weight-bold col-2">Status</small>
                  <div className="col-10 d-flex mt-1 mt-md-0">
                    <Form.Check
                      defaultChecked
                      type="radio"
                      label="All"
                      id="grantee-history-all"
                      htmlFor="grantee-history-all"
                      name="grantStatusFilter"
                      value={grantStatusFilter === 'all'}
                      onChange={() => setGrantStatusFilter('all')}
                    />
                    <Form.Check
                      type="radio"
                      className="ml-3 mx-md-2"
                      label="Issued"
                      id="grantee-history-issued"
                      htmlFor="grantee-history-issued"
                      name="grantStatusFilter"
                      value={grantStatusFilter === 'issued'}
                      onChange={() => setGrantStatusFilter('issued')}
                    />
                    <Form.Check
                      type="radio"
                      className="ml-3 mx-md-2"
                      label="Pending"
                      id="grantee-history-pending"
                      htmlFor="grantee-history-pending"
                      name="grantStatusFilter"
                      value={grantStatusFilter === 'pending'}
                      onChange={() => setGrantStatusFilter('pending')}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mt-4 mt-md-0">
                <div className="mr-4 mt-md-0 d-flex flex-column align-items-start align-items-md-center">
                  <small className="font-weight-bold">Filter option</small>
                  <div className="d-flex mt-2 dropdown-filter">
                    <Dropdown show={isDropdownOpened}>
                      <div className="position-relative">
                        <Dropdown.Toggle
                          className="button--dark-blue filter__dropdown-toggle"
                          onClick={() => setIsDropdownOpened(!isDropdownOpened)}
                        >
                          <i className="bi bi-funnel-fill" />
                        </Dropdown.Toggle>
                        {isFilterApplied && (
                          <button
                            type="button"
                            className="button button-reset-filter"
                            onClick={() => filteredGrants()}
                          >
                            <i className="bi bi-arrow-clockwise mr-1" /> <div>Reset filter</div>
                          </button>
                        )}
                      </div>
                      <Dropdown.Menu>
                        <div className="p-3 p-md-0 mx-md-4">
                          <div className="px-1 py-1">
                            <div className="text-black dropdown-filter__title">Filter Options</div>
                          </div>
                          <div className="px-1 py-0 py-md-2 ">
                            <div className="mb-2">
                              <div className="form-label dropdown-filter__label mt-3 mt-md-0">
                                Submitted Date
                              </div>
                              <DatePickerInput
                                {...register('startDate', { required: true })}
                                className="dropdown-filter__input"
                                onChange={(value) => {
                                  setValue('startDate', value);
                                  setStartDate(value);
                                  if (value) {
                                    clearErrors('startDate');
                                  } else {
                                    setError('startDate', {
                                      type: 'required',
                                    });
                                  }
                                }}
                                selected={startDate}
                              />
                              {errors.startDate && (
                                <div className="validation-alert"> Required field </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <div className="form-label dropdown-filter__label mt-3 mt-md-0">
                                Issued Date
                              </div>
                              <DatePickerInput
                                {...register('endDate')}
                                className="dropdown-filter__input"
                                onChange={(value) => {
                                  setValue('endDate', value);
                                  setEndDate(value);
                                }}
                                selected={endDate}
                              />
                            </div>
                            <div className="d-flex justify-content-between">
                              <button
                                type="button"
                                className="button button--gray py-3 w-100 p-md-1 px-2 mr-0 mr-md-2"
                                onClick={() => filteredGrants()}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                onClick={handleSubmit(filteredGrants)}
                                className="button button--dark-blue w-100 ml-4 ml-md-0 p-1 px-2"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0 pl-3 pl-md-0 mt-md-0 d-flex flex-column align-items-start align-items-md-center">
                  <div className="d-flex">
                    <small className="font-weight-bold">Grantee History</small>
                    <i
                      data-tip
                      data-for="granteeHistory"
                      className="bi bi-question-circle ml-1 icon__question"
                    />
                    <ReactTooltip
                      id="granteeHistory"
                      type="dark"
                      place="top"
                      className="tooltip col-md-3 opaque"
                      event="click"
                      effect="solid"
                      globalEventOff="click"
                    >
                      <div className="h6">
                        <div>
                          <strong>Grantee History</strong> is a filter that allows you to compare
                          the total value of grants made to a grantee(s) during two time periods
                          that are specified by the user.
                        </div>
                      </div>
                    </ReactTooltip>
                  </div>
                  <div className="d-flex mt-2">
                    <button
                      type="button"
                      className="button--dark-blue btn btn-primary"
                      onClick={() => setIsGranteeHistoryModalOpened(true)}
                    >
                      <i className="bi bi-clock" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <GrantHistoryTable
              grants={grants}
              grantProgress={openGrantProgressModal}
              sortedGrants={sortedGrants}
            />
          </div>
        </div>
      </div>
      <ButtonToTopGranteeHistory inView />
      <ButtonToBottomGranteeHistory isBottomInView={inView} bottomElement={entry} />
      <div ref={ref} />
    </>
  );
});

export default GrantHistoryPage;
