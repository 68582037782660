import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AllocationInfo } from './Common/AllocationInfo';
import { DownloadPdf } from './Pdf/index';

const AchContributionAllocationSubmitted = () => {
  const location = useLocation();
  const contributionAmount = location.state?.contributionAmount;
  const fee = location.state?.fee;
  const allocations = location.state?.allocations;
  const fund = location.state?.fund;
  const fullName = location.state?.accountName;
  const paymentLastDigits = location.state?.paymentLastDigits;

  return (
    <>
      <div className="container allocation-submitted">
        <div className="row">
          <div className="col">
            <div className="col-md-8 offset-md-2 allocation-submitted__wrapper">
              <h1 className="text-center">Investment for your donation has been submitted.</h1>
              <p className="my-3 my-md-5 text-left text-md-center">
                Congratulations! Investment on your contribution should occur within 3-7 business
                days after Donors Trust receives the funds.
                <br />
                Please, check your email for confirmation of your investment choice.
              </p>
            </div>
            <AllocationInfo
              fund={fund}
              contributionAmount={contributionAmount}
              fee={fee}
              allocations={allocations}
              fullName={fullName}
              paymentLastDigits={paymentLastDigits}
            />
            <div className="col col-md-8 offset-md-2 allocation-submitted__wrapper">
              <div className="my-3 my-md-4 my-md-5 d-flex flex-column flex-md-row align-items-start align-items-md-center">
                <p className="mb-0 col-8 col-md-auto px-0">
                  To save contribution information, click the icon
                </p>
                <button
                  type="button"
                  className="mx-md-3 mt-2 mb-3 my-md-3 border-0 pool-choice__download-pdf"
                >
                  <DownloadPdf
                    fund={fund}
                    amount={contributionAmount}
                    fee={fee}
                    allocations={allocations}
                    fullName={fullName}
                    paymentLastDigits={paymentLastDigits}
                  />
                </button>
              </div>
              <div className="my-0 my-md-5">
                <Link
                  to={{ pathname: '/fund/overview', state: { fund } }}
                  className="button button--gray d-block d-md-inline text-center px-5 py-3 mr-0 mr-md-3"
                >
                  Return to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchContributionAllocationSubmitted;
