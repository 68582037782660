import React, { useState, memo } from 'react';
import { displayDate } from 'util/dates';
import { displayCurrency } from 'util/money';
import { useLocation } from 'react-router-dom';

const GrantHistoryTable = memo(({ grants, grantProgress, sortedGrants }) => {
  if (!grants?.length) return null;
  const [sortConfig, setSortConfig] = useState({ key: 'checkDate', direction: 'desc' });

  const requestSort = (key) => {
    if (sortedGrants) {
      const direction =
        sortConfig && sortConfig.key === key && sortConfig.direction === 'desc' ? 'asc' : 'desc';
      setSortConfig({ key, direction });
      sortedGrants(key, direction);
    }
  };

  const SortArrow = ({ field }) => {
    return (
      <>
        {sortedGrants ? (
          <>
            {sortConfig?.key === field ? (
              <i
                className={`bi text-blue ${
                  sortConfig?.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'
                } ml-2`}
                role="button"
                aria-label="order"
                tabIndex={0}
              />
            ) : (
              <i className="bi bi-caret-down-fill ml-2" />
            )}
          </>
        ) : null}
      </>
    );
  };

  const { pathname } = useLocation();
  return (
    <div className="funds-table-container">
      <table className="table funds-table">
        <thead>
          <tr>
            <th className="funds-table__name" onClick={() => requestSort('grantOrganization')}>
              Name
              <SortArrow field="grantOrganization" />
            </th>
            <th className="text-right funds-table__grant-id">Grant Id</th>
            <th
              className="text-right funds-table__grant-value"
              onClick={() => requestSort('grantValue')}
            >
              Value ($)
              <SortArrow field="grantValue" />
            </th>
            <th
              className="text-right funds-table__submitted-date"
              onClick={() => requestSort('submittedDate')}
            >
              Submitted Date
              <SortArrow field="submittedDate" />
            </th>
            <th
              className="text-right funds-table__issued-date"
              onClick={() => requestSort('checkDate')}
            >
              Issued Date
              <SortArrow field="checkDate" />
            </th>
            {pathname === '/fund/grants' && (
              <th className="text-center funds-table__grant-progress">Grant Progress</th>
            )}
          </tr>
        </thead>
        <tbody>
          {grants.map((g) => (
            <tr key={g.grantId || g.grantRequestId}>
              <td>{g.grantOrganization}</td>
              <td className="text-right">{g.grantId || ''}</td>
              <td className="text-right">{displayCurrency(g.grantValue)}</td>
              <td align="right">{displayDate(g.submittedDate)}</td>
              <td align="right">{displayDate(g.checkDate)}</td>
              {pathname === '/fund/grants' && (
                <td align="center">
                  {g.withGrantProgress && (
                    <button
                      type="button"
                      className="button mx-3 border-0 rounded icon__reception"
                      onClick={() => {
                        const isRequest = !!g.grantRequestId;
                        grantProgress(isRequest ? g.grantRequestId : g.grantId, isRequest);
                      }}
                    >
                      <i className="bi bi-reception-4" />
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default GrantHistoryTable;
