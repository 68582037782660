import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import RecurringGrantsTable from 'components/Main/ReviewFund/components/RecurringGrantsTable';
import useCurrentFund from 'Hooks/CurrentFund';
import RecurringGrantUpdatedModal from './RecurringGrantUpdatedModal';

const RecurringGrant = () => {
  const fund = useCurrentFund();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  if (!fund.id) {
    return <Redirect to="/" />;
  }

  const [grants, setGrants] = useState([]);
  const { getRecurringGrants } = useContext(ApiContext);

  const getGrants = async () => {
    const grantsList = await getRecurringGrants(fund.id);
    setGrants(grantsList);
  };

  useEffect(() => {
    getGrants();
  }, []);

  const configureModalData = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  return (
    <>
      <RecurringGrantUpdatedModal
        showModal={showModal}
        modalData={modalData}
        closeModal={() => {
          setShowModal(false);
          window.location.reload();
        }}
      />
      <div className="container review-recurring-grants">
        <div className="row">
          <div className="col col-md-15">
            <h1>Review your recurring grants below</h1>
            <h2 className="text-secondary">{fund.name}</h2>
            <h4 className="my-4 my-md-5">Recurring Grants</h4>
            <RecurringGrantsTable
              editAbility
              grants={grants}
              updateGrants={getGrants}
              configureModalData={configureModalData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecurringGrant;
