import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import RequestGrantLink from 'components/Main/Grant/Link';
import FundTable from './FundTable';

const FundLink = ({ fund, className, children }) => {
  return (
    <Link to={{ pathname: '/fund/overview', state: { fund } }} className={className}>
      {children}
    </Link>
  );
};

const FundCard = ({ fund }) => {
  return (
    <Card className="p-3 p-md-4 fund-card shadow-sm">
      <Card.Body className="p-0">
        <div className="d-flex row w-100 no-gutters justify-content-between mb-4">
          <div className="col-md-auto">
            <FundLink fund={fund} className="fund-card__title col-12 pl-0">
              <strong>{fund.name}</strong>
            </FundLink>
            <div className="fund-card__id text-muted">ID: {fund.id}</div>
          </div>

          <div>
            <p className="fund-card__date col-12 col-md-auto">
              as of {displayDate(fund.dateLastChecked)}
            </p>
            <p className="fund-card__value col-12 col-md-auto">
              ${displayCurrency(fund.totalValue - fund.pendingGrantAmount)}
            </p>
          </div>
        </div>
        <FundTable
          fundTotal={fund.totalValue - fund.pendingGrantAmount}
          pools={fund.poolFundUnits}
        />
      </Card.Body>
      <Card.Footer className="px-0 bg-white pt-3 pb-0 py-md-4 border-0">
        <div className="d-flex flex-wrap">
          <FundLink fund={fund}>Review Account</FundLink>
          <span className="mx-2">|</span>
          <RequestGrantLink fund={fund}>Request a Grant</RequestGrantLink>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default FundCard;
