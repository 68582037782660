import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBar from 'components/NavBar';
import Main from 'components/Main';
import Footer from 'components/Footer';
import { AuthProvider } from 'auth/AuthProvider';
import { ApiProvider, ApiContext } from 'components/ApiProvider';
import { ScrollToTop } from 'util/ScrollToTop';
import { AlertProvider } from 'Providers/Alert';
import LoadingSpinner from 'components/LoadingSpinner';
import env from './environment';

const WrappedApp = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Client Portal | DonorsTrust</title>
        <link rel="icon" href="/dt-favicon2.png" sizes="32x32" />
        <link rel="icon" href="/dt-favicon2.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="dt-favicon2.png" />
      </Helmet>
      <AlertProvider>
        <AuthProvider>
          <ApiProvider>
            <ApiContext.Consumer>
              {(ctx) => {
                if (ctx.isLoading) {
                  return (
                    <div className="loader">
                      <LoadingSpinner />
                    </div>
                  );
                }

                return null;
              }}
            </ApiContext.Consumer>
            <NavBar />
            <Main />
            <Footer />
          </ApiProvider>
        </AuthProvider>
      </AlertProvider>
    </div>
  );
};

const App = () => (
  <Router basename={env.ROUTER_BASE_NAME}>
    <ScrollToTop />
    <Switch>
      <Route path="/" component={WrappedApp} />
    </Switch>
  </Router>
);

export default App;
