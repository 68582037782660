import React, { useEffect, useState, createContext } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

export const AlertContext = createContext();

const AlertProviderComponent = ({ children }) => {
  const [type, setType] = useState(null);
  const [msg, setMsg] = useState(null);

  const location = useLocation();

  const addAlert = (alertType, alertMsg) => {
    const at = alertType === 'error' ? 'danger' : alertType;

    setType(at);
    setMsg(alertMsg);
  };

  const removeAlert = () => {
    setType(null);
    setMsg(null);
  };

  useEffect(() => {
    removeAlert();
  }, [location]);

  return (
    <AlertContext.Provider value={{ addAlert, removeAlert, alertType: type, alertMsg: msg }}>
      {children}
    </AlertContext.Provider>
  );
};

export const AlertProvider = withRouter(AlertProviderComponent);
