import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      margin: '10px',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = (props) => {
  const { granteeName, amount, fullName, frequency, paymentLastDigits, email } = props;

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text>
            Congratulations! Your donation should occur within 3-7 business days after Donors Trust
            receives the funds.
          </Text>
          <Text>Please, check your email for confirmation of your donation.</Text>
          <Table>
            <TableRow>
              <TableCell textAlign="right">Donors Trust to support:</TableCell>
              <TableCell textAlign="left">{granteeName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Donation:</TableCell>
              <TableCell textAlign="left">{`$${displayCurrency(amount)}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Frequency:</TableCell>
              <TableCell textAlign="left">{frequency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">User&apos;s email:</TableCell>
              <TableCell textAlign="left">{email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">User&apos;s full name:</TableCell>
              <TableCell textAlign="left">{fullName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Payment details:</TableCell>
              <TableCell textAlign="left">{`****${paymentLastDigits}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Submitted Date:</TableCell>
              <TableCell textAlign="left">{displayDate(new Date())}</TableCell>
            </TableRow>
          </Table>
        </View>
      </Page>
    </Document>
  );
};
