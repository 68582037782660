export const displayCurrency = (float = 0) => {
  return Number(float)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  // return formatter.format(float);
};

export const roundToCents = (float) => Number(float.toFixed(2));
