import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import Instructions from 'components/Main/Grant/Request/Instructions';
import { pdf } from '@react-pdf/renderer';
import { PRIVACY_LEVELS } from '../common/constants';
import { findChangedValues } from '../common/helpers';
import { Pdf } from './Pdf/Pdf';

const FundNameElection = () => {
  const { getFundNameAndPrivacyElections, updateFundNameAndPrivacyElections, getFund } =
    useContext(ApiContext);
  const history = useHistory();
  const { fundId } = useParams();

  const [fund, setFund] = useState();
  const [privacyOption, setPrivacyOption] = useState();
  const [previousSettings, setPreviousSettings] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    async function getPrivacyChoice() {
      const data = await getFundNameAndPrivacyElections(fundId);
      const fundData = await getFund(fundId);
      setFund(fundData);
      setPrivacyOption(data?.privacyOption || 1);
      setPreviousSettings({ privacyOption: data?.privacyOption || 1, fundName: data?.fundName });
      reset(data);
    }
    getPrivacyChoice();
  }, []);

  const validateFundName = (value) => {
    return !value.toLowerCase().includes('trust') && !value.toLowerCase().includes('foundation');
  };

  const submit = async ({ fundName: newFundName }) => {
    const newSettings = { privacyOption, fundName: newFundName.trim() };

    const changedOptions = findChangedValues(previousSettings, newSettings);

    if (Object.keys(changedOptions).length) {
      const blobPdf = await pdf(Pdf(changedOptions)).toBlob();
      const formData = new FormData();
      formData.append(
        'updatesFile',
        blobPdf,
        'Fund name and default primary elections changes.pdf'
      );
      formData.append('fundId', fundId);
      formData.append('fundName', newFundName.trim());
      formData.append('privacyOption', privacyOption);
      await updateFundNameAndPrivacyElections(formData)
        .then(() => {
          history.push({
            pathname: '/daf/submitted',
            state: { startingPage: 'fundNameElection', blobPdf, fund },
          });
        })
        .catch(console.log);
    } else {
      history.push({ pathname: '/fund/overview', state: { fund } });
    }
  };

  return (
    <>
      <Instructions hideStepCount title="Fund name and default privacy elections">
        <div>
          On this page you are able to change your fund name and privacy choice. If you want to make
          changes, please follow instructions below and click <strong>‘Submit’</strong> to confirm
          changes or hit <strong>‘Cancel’</strong> to return to the landing page.
        </div>
      </Instructions>
      <div className="container daf-container">
        <div>
          <strong>Fund name.</strong> You have a choice to change the fund name below. Please do not
          include terms such as “Trust” or “Foundation” as part of your fund name.
        </div>
        <div className="my-4 row">
          <label htmlFor="daf-fund-name" className="col-12 no-gutters d-flex align-items-start">
            <strong className="col-auto mt-1">Fund name:</strong>
            <div className="ml-4 col-4">
              <Form.Control
                id="daf-fund-name"
                className="p-1 w-100"
                {...register('fundName', {
                  required: 'Required field',
                  validate: (value) => validateFundName(value) || 'Incorrect input',
                })}
                isInvalid={errors.fundName}
              />
              {errors.fundName && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.fundName.message}
                </div>
              )}
            </div>
          </label>
        </div>
        <div>
          <strong>Privacy choice.</strong> DonorsTrust is committed to the American tradition of
          private giving. Accordingly, we provide fund advisors a variety of disclosure choices.
          Although, legally, any grant made upon acceptance of your advice by DonorsTrust is from
          DonorsTrust, as the advisor who requested a grant be made, we credit your advice in all
          correspondence accompanying a grant check as the reason the grant was issued.
        </div>
        <div className="mt-4">
          Please choose a default privacy choice in the space provided below. You always have the
          option to choose a different level of privacy with each grant request.
        </div>
        <ul className="mt-4 daf-fund-name-list">
          {PRIVACY_LEVELS.map(({ label, id }) => (
            <li key={id}>
              <Form.Check
                type="radio"
                label={label}
                name="daf-private-level"
                id={`daf-fund-name-radio-${label}`}
                htmlFor={`daf-fund-name-radio-${label}`}
                className="mr-3 mb-3"
                value={id}
                checked={id === Number(privacyOption)}
                onChange={(e) => setPrivacyOption(Number(e.target.value))}
              />
            </li>
          ))}
        </ul>
        <div className="d-flex mt-5 justify-content-end">
          <button
            type="button"
            className="button button--gray py-3 px-5"
            onClick={() => history.push({ pathname: '/fund/overview', state: { fund } })}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button button--dark-blue py-3 px-5 ml-3"
            onClick={handleSubmit(submit)}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default FundNameElection;
