import { PooChoicesProvider } from 'Providers/PoolChoices';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import Navigation from './Navigation/Navigation';
import { ChangeInvestments } from './Steps/ChangeInvestments';
import { ChooseTypeOfChange } from './Steps/ChooseTypeOfChange';
import { FinishChange } from './Steps/FinishChange';

const PoolChoiceMain = ({ location }) => {
  const [fund] = useState(location.state?.fund);

  if (!fund) {
    return <Redirect to="/" />;
  }

  return (
    <PooChoicesProvider fund={fund}>
      <StepWizard
        nav={<Navigation fund={fund} />}
        transitions={{}}
        className="grant-request pool-choice mt-n3 mt-md-0"
        isHashEnabled
      >
        <ChooseTypeOfChange fund={fund} hashKey="choose" />
        <ChangeInvestments fund={fund} hashKey="change" />
        <FinishChange fund={fund} hasnKey="finish" />
      </StepWizard>
    </PooChoicesProvider>
  );
};

export default PoolChoiceMain;
