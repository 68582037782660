import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import { DownloadPdf } from './Pdf/index';

const CompleteChange = () => {
  const location = useLocation();
  const history = useHistory();
  const fund = location.state?.fund;
  const header = location.state?.header;
  const sourcePool = location.state?.sourcePool;
  const amountToExchange = location.state?.amountToExchange;
  const allocations = location.state?.allocations;

  const type = !header ? 'change' : header;
  const isExchange = location.state?.header === 'exchange';

  return (
    <div className="container pool-choice">
      <div className="row">
        <div className="col col-md-8 offset-md-2 mb-3 mb-md-0">
          <h1 className="text-center">{`Your ${type} has been submitted.`}</h1>
          <p className="my-3 my-md-5 text-center text-md-left">
            {`Congratulations! Your ${type} has been successfully submitted. Please, check your
            email to see details of the ${type}. Please allow 3 to 7 business days for the ${type} to occur.`}
          </p>
        </div>
        {isExchange ? (
          <>
            <div className="col-12 col-sm-8 text-center mx-auto allocation-info">
              <table className="d-flex flex-column d-md-table general-info">
                <tr className="d-flex flex-row flex-wrap d-md-table-row">
                  <td className="text-left text-md-right col-sm-3 finish-change-wrapper">
                    <div className="pb-3 pb-md-0">
                      <strong>Exchange from:</strong>
                    </div>
                  </td>
                  <td className="col-9 text-left col-sm-3 border-bottom finish-change-wrapper">
                    {sourcePool}
                  </td>
                  <td className="col-3 text-right text-md-left col-sm-3 border-bottom finish-change-wrapper">{`$${displayCurrency(
                    amountToExchange
                  )}`}</td>
                </tr>
              </table>
            </div>
            <div className="col-12 col-sm-8 text-center mx-auto allocation-info my-2">
              <table className="w-100 d-flex flex-column d-md-table pool-info">
                {allocations?.map((pool, i) => (
                  <tr
                    key={pool.poolId}
                    className="py-1 py-md-5 d-flex flex-row flex-wrap d-md-table-row"
                  >
                    <td className="col-sm-3 text-left text-md-right finish-change-wrapper">
                      {i === 0 && (
                        <div className="py-3 py-md-0">
                          <strong>To:</strong>
                        </div>
                      )}
                    </td>
                    <td className="col-9 text-left col-sm-3 border-bottom finish-change-wrapper">
                      {pool.name}
                    </td>
                    <td className="col-3 text-right text-md-left col-sm-3 border-bottom finish-change-wrapper">{`$${displayCurrency(
                      pool.amount
                    )}`}</td>
                  </tr>
                ))}
                <tr className="py-1 mb-3 mb-md-0 py-md-5">
                  <td className="col-sm-3 d-none d-md-table-cell text-right" />
                  <td className="text-left col-sm-3 finish-change-wrapper ">
                    <strong>Total:</strong>
                  </td>
                  <td className="text-left col-sm-3 finish-change-wrapper">
                    <strong>{`$${displayCurrency(amountToExchange)}`}</strong>
                  </td>
                </tr>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 col-sm-8 text-center  mx-auto allocation-info">
              <table className="d-flex flex-column d-md-table pool-info">
                {fund?.poolFundUnits?.map((pool, i) => (
                  <tr
                    key={pool.poolId}
                    className="py-1 py-md-5 d-flex flex-row flex-wrap d-md-table-row"
                  >
                    <td className="col-md-3 text-left text-md-right finish-change-wrapper">
                      {i === 0 && (
                        <div className="pb-3 pb-md-0">
                          <strong>Rebalancing from:</strong>
                        </div>
                      )}
                    </td>
                    <td className="col-9 text-left col-sm-3 border-bottom finish-change-wrapper">
                      {pool.name}
                    </td>
                    <td className="col-3 text-right text-md-left col-sm-3 border-bottom finish-change-wrapper">
                      {(
                        ((pool.poolFundValue - pool.pendingAmount) /
                          (fund.totalValue - fund.pendingGrantAmount)) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                  </tr>
                ))}
                <tr className="py-1 py-md-5 ">
                  <td className="col-sm-3 text-right d-none d-md-table-cell" />
                  <td className="text-left col-sm-3 finish-change-wrapper">
                    <strong>Total:</strong>
                  </td>
                  <td className="text-left col-sm-3 finish-change-wrapper">
                    <strong>{`${displayCurrency(100)}%`}</strong>
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-sm-8 text-center d-flex mx-auto allocation-info mt-2">
              <table className="w-100 pool-info d-flex flex-column d-md-table">
                {allocations?.map((pool, i) => (
                  <tr
                    key={pool.poolId}
                    className="py-1 py-md-5 d-flex flex-row flex-wrap d-md-table-row"
                  >
                    <td className="col-sm-3 text-left text-md-right finish-change-wrapper">
                      {i === 0 && (
                        <div className="pb-3 pb-md-0">
                          <strong>To:</strong>
                        </div>
                      )}
                    </td>
                    <td className="col-9 text-left col-sm-3 border-bottom finish-change-wrapper">
                      {pool.name}
                    </td>
                    <td className="col-3 col-sm-3 text-right text-md-left border-bottom finish-change-wrapper">{`${displayCurrency(
                      pool.amount
                    )}%`}</td>
                  </tr>
                ))}
                <tr className="py-1 mb-3 mb-md-0 py-md-5">
                  <td className="col-sm-3 d-none d-md-table-cell text-right" />
                  <td className="text-left col-sm-3 finish-change-wrapper">
                    <strong>Total:</strong>
                  </td>
                  <td className="text-left col-sm-3 finish-change-wrapper">
                    <strong>{`${displayCurrency(100)}%`}</strong>
                  </td>
                </tr>
              </table>
            </div>
          </>
        )}
        <div className="col-sm-8 text-center d-flex mx-auto allocation-info my-2">
          <table className="w-100 general-info">
            <tr>
              <td className="col-1 col-md-3 text-left text-md-right finish-change-wrapper">
                <strong>Submitted&nbsp;Date:</strong>
              </td>
              <td className="text-left col-sm-3">{displayDate(new Date())}</td>
              <td className="text-left col-sm-3" />
            </tr>
          </table>
        </div>
        <div className="col col-md-8 offset-md-2">
          <div className="my-3 my-md-4 my-md-5 d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <p className="mb-0 col-8 col-md-auto px-0">{`To save the ${type} choice, click the icon`}</p>
            <button
              type="button"
              className="mx-md-3 mt-2 my-md-3 border-0 pool-choice__download-pdf"
            >
              <DownloadPdf
                amount={amountToExchange}
                allocations={allocations}
                type={type}
                sourcePool={sourcePool}
                fund={fund}
              />
            </button>
          </div>
          <div className="mt-5 mb-3 my-md-5 w-100">
            <button
              type="button"
              className="button button--gray px-4 px-md-5 py-3 mr-0 text-center mr-md-3 col-12 col-md-auto"
              onClick={() => history.push({ pathname: '/fund/overview', state: { fund } })}
            >
              Return to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteChange;
