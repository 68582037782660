import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PHONE_VALIDATION_REGEX } from 'components/Main/constants';

export const PRIVACY_LEVELS = [
  { label: 'Account name only', id: 1 },
  { label: 'Account name & full name of primary advisor', id: 2 },
  { label: 'Account name and full name & address of primary advisor', id: 3 },
  {
    label:
      'Private (correspondence with the grantee will state that the person who recommended the grant be made has elected to remain anonymous)',
    id: 4,
  },
];

export const greyColor = '#e7e6e6';

export const regExp = {
  IS_PREFIX_OTHER: /^(Dr\.?)|(Mr\.?)|(Mrs\.?)|(Ms\.?)$/i,
  IS_PREFIX_MR: /^Mr\.?$/i,
  IS_PREFIX_MS: /^Ms\.?$/i,
  IS_PREFIX_MRS: /^Mrs\.?$/i,
  IS_PREFIX_DR: /^Dr\.?$/i,
  EIN_VALIDATION: /^\d{2}-\d{7}$/,
};

export const PHONE_TYPES = [
  { label: '', id: '' },
  { label: 'Mobile', id: 1 },
  { label: 'Home', id: 2 },
  { label: 'Work', id: 3 },
  { label: 'Other', id: 4 },
];

export const resolver = yupResolver(
  yup.object().shape({
    firstName: yup.string().required('Required field').nullable(true),
    lastName: yup.string().required('Required field').nullable(true),
    donorsTrustSalutation: yup.string().required('Required field').nullable(true),
    granteeSalutation: yup.string().required('Required field').nullable(true),
    mailingAddress: yup.string().required('Required field').nullable(true),
    email: yup
      .string()
      .email('Invalid email address ')
      .required('Required field')
      .max(60, 'Incorrect input')
      .nullable(true),
    preferredPhone: yup
      .string()
      .required('Required field')
      .matches(PHONE_VALIDATION_REGEX, {
        message: 'Please enter 10 digits phone number',
        excludeEmptyString: true,
      })
      .nullable(true),
    preferredPhoneType: yup.string().required('Required field').nullable(true),
    secondaryPhone: yup
      .string()
      .matches(PHONE_VALIDATION_REGEX, {
        message: 'Please enter 10 digits phone number',
        excludeEmptyString: true,
      })
      .nullable(true),
    secondaryPhoneType: yup
      .string()
      .when('secondaryPhone', {
        is: (secondaryPhone) => {
          return Boolean(secondaryPhone);
        },
        then: yup.string().required('Required field').nullable(true),
      })
      .nullable(true),
    prefix: yup.string().nullable(true),
    prefixOther: yup
      .string()
      .when('prefix', {
        is: (prefix) => {
          return !regExp.IS_PREFIX_OTHER.test(prefix);
        },
        then: yup.string().required('Required field'),
      })
      .nullable(true),
    city: yup.string().required('Required field').nullable(true),
    state: yup.string().required('Required field').nullable(true),
    zip: yup.string().required('Required field').max(10, 'Incorrect input').nullable(true),
  })
);

export const documentTypes = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel',
};
