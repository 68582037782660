import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PoolChoicesContext } from 'Providers/PoolChoices';

const Navigation = (props) => {
  const { currentStep, totalSteps, nextStep, previousStep, fund } = props;
  const [isSubmitting, setisSubmitting] = useState(false);
  const { configuration, isValid, submit } = useContext(PoolChoicesContext);
  const selectedPool = fund?.poolFundUnits.find((g) => g.poolId === configuration.poolId) || null;
  const history = useHistory();
  const stepBack = () => {
    if (currentStep === 1) {
      history.push({ pathname: '/fund/overview', state: { fund } });
    } else {
      previousStep();
      window.scroll(0, 0);
    }
  };
  const validNextStep = async () => {
    const valid = await isValid(currentStep);
    if (valid) {
      if (currentStep === totalSteps) {
        if (!isSubmitting) {
          setisSubmitting(true);
          const submitGood = await submit();
          setisSubmitting(false);

          if (submitGood) {
            history.push({
              pathname: '/fund/grants/rebalancing/complete',
              state: {
                fund,
                header: configuration.type.toLowerCase(),
                sourcePool: selectedPool?.name,
                amountToExchange: configuration.amountToExchange,
                allocations: configuration.allocations,
              },
            });
          }
        }
      } else {
        nextStep();
        window.scroll(0, 0);
      }
    }
  };

  return (
    <div className="grant-request__navigation">
      <div className="container">
        <div className="row">
          <div className="col col-md-8 offset-md-2">
            <div className="d-flex justify-content-end">
              <div className="grant-request__navigation-buttons">
                {configuration.type && (
                  <>
                    <button
                      className="button button--gray px-5 py-3 mr-3"
                      onClick={stepBack}
                      type="button"
                    >
                      {currentStep === 1 ? 'Cancel' : 'Back'}
                    </button>
                    <button
                      className="button button--dark-blue px-5 py-3"
                      onClick={validNextStep}
                      type="button"
                      disabled={isSubmitting}
                    >
                      {currentStep === totalSteps ? 'Submit' : 'Continue'}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
