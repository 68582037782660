import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { DownloadPdfFundElection } from '../FundNameElection/Pdf';
import { DownloadPdfPrimaryAdvisors } from '../PrimaryAdvisorsElection/Pdf';
import { DownloadPdfSunsettingDaf } from '../SunsettingDaf/Pdf';
import { DownloadPdfSuccessorAdvisors } from '../SuccessorAdvisors/Pdf';
import { DownloadPdfDonorIntent } from '../DafDonorIntent/Pdf';

const DafSubmitted = () => {
  const location = useLocation();
  const history = useHistory();
  const startingPage = location.state?.startingPage;
  const blobPdf = location.state?.blobPdf;
  const fund = location.state?.fund;

  if (!fund || !blobPdf || !startingPage) return <Redirect to="/" />;

  const pdfs = {
    fundNameElection: DownloadPdfFundElection,
    primaryAdvisors: DownloadPdfPrimaryAdvisors,
    sunsettingDaf: DownloadPdfSunsettingDaf,
    successorAdvisors: DownloadPdfSuccessorAdvisors,
    dafDonorIntent: DownloadPdfDonorIntent,
  };

  const DownloadPdf = pdfs[startingPage];

  return (
    <div>
      <div className="text-center mt-3 col-md-8 offset-md-2">
        <h2>
          <strong>Your donor advised fund changes have been submitted.</strong>
        </h2>
      </div>
      <div className="container col-md-4 pt-3  offset-md-4">
        <div className="text-left ">
          <p className="my-4 ">
            All changes have been recorded. Please, check your account to see them and your email
            with all details.
          </p>
        </div>
        <div className="mt-5  d-flex d-flex flex-column flex-md-row align-items-start align-items-md-center">
          <p className="mb-0">To save the donor advised fund changes, click the icon</p>
          <button
            type="button"
            className="border-0 mx-0 mx-md-3 mt-2 mt-md-1 pool-choice__download-pdf"
          >
            <DownloadPdf blobPdf={blobPdf} />
          </button>
        </div>
        <button
          type="button"
          className="button button--gray mt-5 py-3 px-5"
          onClick={() => history.push({ pathname: '/fund/overview', state: { fund } })}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default DafSubmitted;
