import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import marked from 'marked';
import useLocalStorage from 'Hooks/LocalStorage';

const MessageModal = ({ popupId }) => {
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({});
  const [showPopup, setShowPopup] = useLocalStorage('showPopup');

  const onHide = () => {
    setShowModal(false);
  };

  useEffect(() => {
    async function getPopup() {
      const res = await fetch(`https://donorstrust-cms.herokuapp.com/popups/${popupId}`);

      if (res.status === 200) {
        const json = await res.json();
        const { display, title, message } = json;

        setModal({ title, message });
        setShowModal(display);
      }
    }
    if (showPopup) {
      setShowPopup(false);
      getPopup();
    }
  }, []);

  return (
    <Modal show={showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="banner__message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: marked(modal.message || '') }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
