import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { GranteeHistoryPdf } from './GranteeHistoryPdf';

export const DownloadPdf = (props) => {
  const { granteeHistory, filter, isSecondPeriod = true } = props;

  return (
    <PDFDownloadLink
      document={
        <GranteeHistoryPdf
          granteeHistory={granteeHistory}
          filter={filter}
          isSecondPeriod={isSecondPeriod}
        />
      }
      fileName="GranteeHistory.pdf"
    >
      <i className="bi bi-filetype-pdf text-danger" />
    </PDFDownloadLink>
  );
};
