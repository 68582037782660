import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { PRIVACY_LEVELS, greyColor } from '../../common/constants';
import { getLabelById } from '../../common/helpers';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = ({ privacyOption, fundName }) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>
            Fund name and default primary elections
          </Text>
          {fundName && (
            <>
              <Text>
                Fund name. You have a choice to change the fund name below. Please do not include
                terms such as “Trust” or “Foundation” as part of your fund name.
              </Text>
              <Table>
                <TableRow>
                  <TableCell color={greyColor}>Old</TableCell>
                  <TableCell color={greyColor}>New</TableCell>
                </TableRow>
              </Table>
              <Table>
                <TableRow>
                  <TableCell>Fund Name</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{fundName.old}</TableCell>
                  <TableCell>{fundName.new}</TableCell>
                </TableRow>
              </Table>
            </>
          )}
          {privacyOption && (
            <>
              <Text style={{ marginTop: 15 }}>
                Privacy choice. DonorsTrust is committed to the American tradition of private
                giving. Accordingly, we provide fund advisors a variety of disclosure choices.
                Although, legally, any grant made upon acceptance of your advice by DonorsTrust is
                from DonorsTrust, as the advisor who requested a grant be made, we credit your
                advice in all correspondence accompanying a grant check as the reason the grant was
                issued.
              </Text>
              <Text style={{ marginTop: 15 }}>
                Please choose a default privacy choice in the space provided below. You always have
                the option to choose a different level of privacy with each grant request.
              </Text>

              <Table>
                <TableRow>
                  <TableCell color={greyColor}>Old</TableCell>
                  <TableCell color={greyColor}>New</TableCell>
                </TableRow>
              </Table>

              <Table style={{ marginTop: 15 }}>
                <TableRow>
                  <TableCell>Privacy Choice</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{getLabelById(privacyOption.old, PRIVACY_LEVELS)}</TableCell>
                  <TableCell>{getLabelById(privacyOption.new, PRIVACY_LEVELS)}</TableCell>
                </TableRow>
              </Table>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};
