import React from 'react';

export const SuccessorAdvisorsInfo = ({
  prefix,
  firstName,
  lastName,
  mailingAddress,
  city,
  state,
  zip,
  preferredPhone,
  email,
  suffix,
  secondaryPhone,
  deleteAdvisorById,
  relationshipToPrimaryAdvisor,
  editAdvisor,
  advisor,
  idFront,
  jointlyHeldPercentage,
  continueHoldingAccount,
}) => {
  return (
    <div className="row mb-4">
      <div className="col-8 ml-4 pl-4">
        <div>
          {prefix} {firstName} {lastName}
          {suffix && <span> {suffix}</span>},
        </div>
        <div>{mailingAddress},</div>
        <div>
          {city}, {state}, {zip},
        </div>
        <div>{preferredPhone}</div>
        {secondaryPhone && <div>{secondaryPhone},</div>}
        <div>{email},</div>
        <div>{relationshipToPrimaryAdvisor},</div>
        {jointlyHeldPercentage ? (
          <div>Jointly held {jointlyHeldPercentage} % of account</div>
        ) : (
          <div>Is not bound by Donor Intent Statement</div>
        )}
        <div>{continueHoldingAccount && <div>Continue holding an account</div>}</div>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="border-0 onGrantClick mr-4"
            onClick={() => editAdvisor(advisor)}
          >
            <i className="bi bi-pencil-fill" />
          </button>
          <button
            type="button"
            className="button button--red button--small px-2 py-1 "
            onClick={() => deleteAdvisorById(idFront)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessorAdvisorsInfo;
