import React, { useState, useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import { displayDate } from 'util/dates';
import ReactTooltip from 'react-tooltip';

const yearsArr = (currentYear, fundYearEstablished) => {
  const arr = [];

  for (let i = currentYear; i >= fundYearEstablished; i -= 1) {
    arr.push(i);
  }

  return arr;
};

const Statements = () => {
  const location = useLocation();
  const { fund } = location.state;

  if (!fund.id) {
    return <Redirect to="/" />;
  }

  const date = new Date();
  const currentYear = date.getFullYear();

  const [asc, setAsc] = useState(false);
  const [statements, setStatements] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const { getStatements, getStatement } = useContext(ApiContext);

  const handleChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
  };

  const orderStatements = (notOredredStatements) => {
    if (asc) {
      notOredredStatements.sort(
        (item1, item2) => new Date(item1.statementDate) - new Date(item2.statementDate)
      );
    } else {
      notOredredStatements.sort(
        (item1, item2) => new Date(item2.statementDate) - new Date(item1.statementDate)
      );
    }

    setStatements([...notOredredStatements]);
  };

  useEffect(() => {
    orderStatements(statements);
  }, [asc]);

  useEffect(() => {
    async function getAllStatements() {
      const statementsResponse = await getStatements(fund.id, selectedYear);
      orderStatements(statementsResponse);
    }

    getAllStatements();
  }, [selectedYear]);

  const downloadStatement = async (statementId) => {
    try {
      const url = await getStatement(statementId);
      const link = document.createElement('a');

      link.href = url;
      link.target = '_blank';
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container statements">
      <div className="row">
        <div className="col">
          <h1>Click on the links below to download PDF forms</h1>
          <h2 className="text-secondary">{fund.name}</h2>
          <select className="my-3 py-md-3 px-md-4 statements__select" onChange={handleChange}>
            <option disabled="true">Select year for statements</option>
            {yearsArr(currentYear, fund.yearEstablished).map((y) => {
              return (
                <option key={y} value={y}>
                  {y} Statements
                </option>
              );
            })}
          </select>
          <h4 className="my-2 my-md-3">
            Download Statements
            <i
              className={`bi ${asc ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} ml-2`}
              onClick={() => setAsc(!asc)}
              onKeyDown={() => setAsc(!asc)}
              role="button"
              aria-label="order"
              tabIndex={0}
              data-tip="<i class='bi-caret-up-fill mr-2'></i>Sort Ascending<br /><i class='bi-caret-down-fill mr-2'></i>Sort Descending"
            />
            <ReactTooltip
              type="light"
              place="right"
              html
              border
              arrowColor="transparent"
              offset={{ right: 10 }}
            />
          </h4>
          {statements.length > 0 ? (
            <table>
              {statements.map((s) => {
                return (
                  <tr className="row mt-3 mt-md-0" key={s.id}>
                    <td className="col font-weight-bold pr-5 py-1">
                      {displayDate(s.statementDate)}
                    </td>
                    <td className="col-12 pt-md-1 col-md-auto">
                      <button
                        className="button button--link"
                        type="button"
                        onClick={() => downloadStatement(s.id)}
                      >
                        {s.name}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            <p>No statements found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statements;
