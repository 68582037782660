import React, { useEffect, useContext, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { State, City } from 'country-state-city';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import _ from 'lodash';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { ApiContext } from 'components/ApiProvider';
import { AuthenticationContext } from 'auth/AuthProvider';
import { trimObjectProperties } from 'util/trimObjectProperties';
import { PHONE_VALIDATION_REGEX } from 'components/Main/constants';
import NumberFormat from 'react-number-format';

const resolver = yupResolver(
  yup.object().shape({
    prefix: yup.string(),
    firstName: yup.string().trim().required('First Name required'),
    middleInitital: yup.string(),
    lastName: yup.string().trim().required('Last Name required'),
    suffix: yup.string(),
    email: yup
      .string()
      .trim()
      .required('Email required')
      .email('Please enter a valid email')
      .max(60, 'Only 60 characters are allowed'),
    phone: yup.string().trim().required('Phone required').matches(PHONE_VALIDATION_REGEX, {
      message: 'Please enter 10 digits phone number',
      excludeEmptyString: true,
    }),
    address1: yup.string().trim().nullable().required('Address  required'),
    address2: yup.string().nullable(),
    city: yup.string().trim().nullable().required('City  required'),
    state: yup
      .string()
      .nullable()
      .trim()
      .matches(/^[a-zA-Z]*$/, 'Only alphabetic characters are allowed')
      .required('State required'),
    zipCode: yup
      .string()
      .nullable()
      .matches(/^[0-9 ]*$/, 'Only digits are allowed')
      .required('Zip Code  required')
      .max(10, 'Only 10 digits are allowed'),
  })
);

const PROFILE_FIELDS = [
  {
    label: 'Prefix',
    name: 'prefix',
  },
  {
    label: 'First Name',
    name: 'firstName',
  },
  {
    label: 'Middle Initial',
    name: 'middleInitial',
  },
  {
    label: 'Last Name',
    name: 'lastName',
  },
  {
    label: 'Suffix',
    name: 'suffix',
  },
  {
    label: 'Email Address',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Phone',
    name: 'phone',
    type: 'tel',
  },
  {
    label: 'Address 1',
    name: 'address1',
  },
  {
    label: 'Address 2',
    name: 'address2',
  },
  {
    label: 'City',
    name: 'city',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
  },
];

const ProfileForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ resolver });
  const { getUser, updateUser } = useContext(ApiContext);
  const { user } = useContext(AuthenticationContext);

  const [previousSettings, serPreviousSettings] = useState(null);
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);

  useEffect(() => {
    async function getProfileFields() {
      const data = await getUser(user.id);
      serPreviousSettings(data);
      const keys = Object.keys(data);

      keys.forEach((key) => {
        setValue(key, data[key]);
      });
      setStates(
        State.getStatesOfCountry('US')
          .filter((state) => state.isoCode.length === 2)
          .map((state) => state.isoCode)
      );
      setCities([...new Set(City.getCitiesOfCountry('US').map((city) => city.name))]);
    }
    getProfileFields();
  }, []);

  const submit = async (values) => {
    const trimmedValues = trimObjectProperties(values);
    if (_.isEqual(previousSettings, trimmedValues)) return;
    try {
      await updateUser(user.id, trimmedValues);
      serPreviousSettings(trimmedValues);
      toast.success('Your profile has been updated successfully!.', {
        toastId: 'successToast',
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form className="form my-4" onSubmit={handleSubmit((values) => submit(values))}>
      <Form.Row>
        {PROFILE_FIELDS.map((f) => {
          if (f.name === 'city') {
            return (
              <Form.Group key={f.name} as={Col} md={4} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value: cityValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={cityValue}
                          {...inputProps}
                          isInvalid={!!errors.city}
                          id="manage-profile-city-input"
                        />
                      )}
                      minLength={3}
                      options={cities}
                      id={`${f.name}`}
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-city"
                      onChange={([city]) => {
                        onChange(city);
                      }}
                    />
                  )}
                />
                <div className="text-red text-left" style={{ fontSize: '80%' }}>
                  {errors[f.name]?.message}
                </div>
              </Form.Group>
            );
          }
          if (f.name === 'state') {
            return (
              <Form.Group key={f.name} as={Col} md={4} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { value: stateValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={stateValue}
                          {...inputProps}
                          isInvalid={!!errors.state}
                          maxLength="2"
                          id="manage-profile-state-input"
                        />
                      )}
                      options={states}
                      id={`${f.name}`}
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-state"
                      onChange={([state]) => {
                        onChange(state);
                      }}
                    />
                  )}
                />
                <div className="text-red text-left" style={{ fontSize: '80%' }}>
                  {errors[f.name]?.message}
                </div>
              </Form.Group>
            );
          }
          if (f.name === 'phone') {
            return (
              <Form.Group key={f.name} as={Col} md={4} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { value: phoneValue, onChange, ...field } }) => (
                    <NumberFormat
                      {...field}
                      value={phoneValue}
                      type="tel"
                      format="### ### ####"
                      onValueChange={(value) => onChange(value.formattedValue)}
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      id={`${f.name}`}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[f.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            );
          }
          return (
            <Form.Group key={f.name} as={Col} md={4} controlId={`${f.name}`}>
              <Form.Label>{f.label}:</Form.Label>
              <Form.Control
                type={f.type || 'text'}
                {...register(f.name)}
                isInvalid={errors[f.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[f.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          );
        })}
        <Form.Group as={Col} sm={12} md={4}>
          <button
            className="button button--dark-blue col col-md-auto px-3 py-3 px-md-5"
            type="submit"
          >
            Save Changes
          </button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default ProfileForm;
