import React from 'react';

const SunsettingDafInstruction = () => {
  return (
    <div className="grant-instructions mb-3 mb-md-4 p-md-5 mt-md-n4">
      <div className="container">
        <div className="row">
          <div className="col py-md-0 text-center col-md-10 offset-md-1">
            <h2>
              <strong>
                Sunsetting of donor-advised fund account and final distribution elections
              </strong>
            </h2>
            <p className="mt-3 mb-0 mt-md-4 text-muted">
              On this page you are able to change a sunsetting of donors-advised fund account and
              select final distributions after the death. If you want to make changes, please follow
              instructions below and click <strong>‘Submit’</strong> to confirm changes or hit{' '}
              <strong>‘Cancel’</strong> to return to the landing page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SunsettingDafInstruction;
