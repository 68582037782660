import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import EmptyEmailOrPhoneAlertModal from 'components/Main/NewGrantee/Modal/EmptyEmailOrPhoneAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PHONE_VALIDATION_REGEX, EIN_REGEX } from 'components/Main/constants';
import NumberFormat from 'react-number-format';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { State, City } from 'country-state-city';

const resolver = yupResolver(
  yup.object().shape({
    name: yup.string().required('Organization is required'),
    ein: yup.string().matches(EIN_REGEX, {
      message: 'EIN must include exactly 9 digits',
      excludeEmptyString: true,
    }),
    firstName: yup.string().required('Contact first name is required'),
    lastName: yup.string().required('Contact last name is required'),
    email: yup.string().email(),
    phone: yup.string().matches(PHONE_VALIDATION_REGEX, {
      message: 'Please enter 10 digits phone number',
      excludeEmptyString: true,
    }),
    address1: yup.string(),
    address2: yup.string(),
    city: yup.string(),
    state: yup
      .string()
      .trim()
      .matches(
        /^[a-zA-Z]*$/,
        'The STATE field may only include a two-letter Postal Code abbreviation'
      )
      .test(
        'state',
        'The STATE field may only include a two-letter Postal Code abbreviation',
        (value) => value == null || value.trim() === '' || value.length === 2
      ),
    zipcode: yup.string(),
  })
);

const PROFILE_FIELDS = [
  {
    label: 'Organization',
    name: 'name',
  },
  {
    label: 'EIN #',
    name: 'ein',
  },
  {
    label: 'Contact First Name',
    name: 'firstName',
  },
  {
    label: 'Contact Last Name',
    name: 'lastName',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Phone',
    name: 'phone',
    type: 'tel',
  },
  {
    label: 'Address 1',
    name: 'address1',
  },
  {
    label: 'Address 2',
    name: 'address2',
  },
  {
    label: 'City',
    name: 'city',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Zip Code',
    name: 'zipcode',
  },
];

const NewGranteeForm = ({ name, onSaveSuccess, onClose }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);

  useEffect(() => {
    setStates(
      State.getStatesOfCountry('US')
        .filter((state) => state.isoCode.length === 2)
        .map((state) => state.isoCode)
    );
    setCities([...new Set(City.getCitiesOfCountry('US').map((city) => city.name))]);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm({ resolver });

  useEffect(() => {
    setValue('name', name);
  }, []);

  const submit = (values) => {
    const newGranteeModel = values;
    newGranteeModel.phone = newGranteeModel.phone === '' ? null : newGranteeModel.phone;
    newGranteeModel.email = newGranteeModel.email === '' ? null : newGranteeModel.email;
    onSaveSuccess(newGranteeModel);
    reset();
  };

  const addGrantee = (values) => {
    const isAlertSouldBeShown = values.phone === '' || values.email === '';
    if (isAlertSouldBeShown) {
      setShowAlert(true);
    } else {
      submit(values);
    }
  };

  return (
    <Form
      className="form my-4 new-grantee-modal"
      onSubmit={handleSubmit((values) => addGrantee(values))}
    >
      <Form.Row>
        {PROFILE_FIELDS.map((f) => {
          if (f.name === 'phone') {
            return (
              <Form.Group key={f.name} as={Col} md={6} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { value: phoneValue, onChange, ...field } }) => (
                    <NumberFormat
                      {...field}
                      value={phoneValue}
                      type="tel"
                      format="### ### ####"
                      onValueChange={(value) => onChange(value.formattedValue)}
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      id={`${f.name}`}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[f.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            );
          }

          if (f.name === 'ein') {
            return (
              <Form.Group key={f.name} as={Col} md={6} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="ein"
                  control={control}
                  render={({ field: { value: einValue, onChange, ...field } }) => (
                    <NumberFormat
                      {...field}
                      value={einValue}
                      format="##-#######"
                      onValueChange={(value) => onChange(value.formattedValue)}
                      className={`form-control ${errors.ein ? 'is-invalid' : ''}`}
                      id={`${f.name}`}
                      placeholder="XX-XXXXXXX"
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[f.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            );
          }

          if (f.name === 'city') {
            return (
              <Form.Group key={f.name} as={Col} md={6} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value: cityValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={cityValue}
                          {...inputProps}
                          isInvalid={!!errors.city}
                          id="new-grantee-city-input"
                        />
                      )}
                      minLength={3}
                      options={cities}
                      id={`${f.name}`}
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-city"
                      onChange={([city]) => {
                        onChange(city);
                      }}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[f.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            );
          }

          if (f.name === 'state') {
            return (
              <Form.Group key={f.name} as={Col} md={6} controlId={`${f.name}`}>
                <Form.Label>{f.label}:</Form.Label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { value: stateValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={stateValue}
                          {...inputProps}
                          isInvalid={!!errors.state}
                          maxLength="2"
                          id="manage-profile-state-input"
                        />
                      )}
                      options={states}
                      id={`${f.name}`}
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-state"
                      onChange={([state]) => {
                        onChange(state);
                      }}
                    />
                  )}
                />
                <div className="text-red text-left" style={{ fontSize: '80%' }}>
                  {errors[f.name]?.message}
                </div>
              </Form.Group>
            );
          }

          return (
            <Form.Group key={f.name} as={Col} md={6} controlId={`${f.name}`}>
              <Form.Label>{f.label}:</Form.Label>
              <Form.Control
                type={f.type || 'text'}
                {...register(f.name)}
                isInvalid={errors[f.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[f.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          );
        })}
        <Form.Group as={Col} xs={12} className="text-center">
          <div className="button-container d-inline-block">
            <button className="button button--gray px-3 py-2 mt-3" type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
          <div className="button-container d-inline-block">
            <button className="button button--dark-blue px-3 py-2 mt-3" type="submit">
              Add Grantee
            </button>
          </div>
        </Form.Group>
      </Form.Row>
      <EmptyEmailOrPhoneAlertModal
        showModal={showAlert}
        onClose={() => setShowAlert(false)}
        submit={handleSubmit((values) => submit(values))}
      />
    </Form>
  );
};

export default NewGranteeForm;
