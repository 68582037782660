import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AlertContext } from 'Providers/Alert';
import AlertComponent from 'components/Alert';
import Login from 'components/Main/Login';
import YourFunds from 'components/Main/YourFunds';
import ContactUs from 'components/Main/ContactUs';
import ReviewFund from 'components/Main/ReviewFund';
import Resources from 'components/Main/Resources';
import GrantHistory from 'components/Main/GrantHistory';
import PoolChoiceMain from 'components/Main/Grant/PoolChoice/PoolChoiceMain';
import Contributions from 'components/Main/Contributions';
import Statements from 'components/Main/Statements';
import AddContribution from 'components/Main/AddContribution';
import OauthPlaidPage from 'components/Main/Oauth/Plaid';
import ManageProfile from 'components/Main/ManageProfile';
import PasswordReset from 'components/Main/Password/Reset';
import PasswordChange from 'components/Main/Password/Change';
import Test from 'components/Main/test';
import GrantRequest from 'components/Main/Grant/Request';
import GrantRequestComplete from 'components/Main/Grant/Request/Complete';
import RecurringGrant from 'components/Main/RecurringGrants/RecurringGrant.component';
import PrivateRoute from 'PrivateRoute';
import AchContributionAllocation from 'components/Main/AchContibutionAllocation/AchContributionAllocationMain';
import AchContributionChooseAmount from 'components/Main/AchContibutionAllocation/AchContributionChooseAmount';
import AchContributionAllocationSubmitted from 'components/Main/AchContibutionAllocation/AchContributionAllocationSubmitted';
import ToastNotify from 'components/ToastNotify';
import CompleteChange from './Grant/PoolChoice/CompleteChange';
import AchDonationsChooseGrantee from './AchDonations/AchDonationsChooseGrantee';
import AchDonationsSubmitted from './AchDonations/AchDonationsSubmitted';
import AchDonationsChooseAmount from './AchDonations/AchDonationsChooseAmount';
import CapitalFundDonationsChooseGrantee from './CapitalFundDonations/CapitalFundDonationsChooseGrantee';
import CapitalFundDonationsSubmitted from './CapitalFundDonations/CapitalFundDonationsSubmitted';
import CapitalFundDonationsChooseAmount from './CapitalFundDonations/CapitalFundDonationsChooseAmount';
import FundNameElection from './DafFunctionalities/FundNameElection/FundNameElection';
import DafSubmitted from './DafFunctionalities/DafSubmitted/DafSubmitted';
import PrimaryAdvisorsElection from './DafFunctionalities/PrimaryAdvisorsElection/PrimaryAdvisorsElection';
import SunsettingDaf from './DafFunctionalities/SunsettingDaf/SunsettingDaf';
import SuccessorAdvisors from './DafFunctionalities/SuccessorAdvisors/SuccessorAdvisors';
import DafDonorIntent from './DafFunctionalities/DafDonorIntent/DafDonorIntent';
import FundTransactionsHistory from './ReviewFund/components/FundTransactionsHistory/FundTransactionsHistory';

export default () => {
  const { alertType, alertMsg, removeAlert } = useContext(AlertContext);

  return (
    <div className="main-wrapper">
      {/* <MainHeader /> */}
      <div className="main-content pt-3 pb-0 py-md-4 position-relative">
        <AlertComponent type={alertType} msg={alertMsg} removeCallback={removeAlert} />
        <ToastNotify />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/password/reset" component={PasswordReset} />
          <Route exact path="/password/change" component={PasswordChange} />
          <PrivateRoute exact path="/" component={YourFunds} />
          <PrivateRoute exact path="/fund/overview" component={ReviewFund} />
          <PrivateRoute exact path="/contact" component={ContactUs} />
          <PrivateRoute exact path="/resources" component={Resources} />
          <PrivateRoute exact path="/fund/grants" component={GrantHistory} />
          <PrivateRoute exact path="/fund/contributions" component={Contributions} />
          <PrivateRoute exact path="/fund/recurrence" component={RecurringGrant} />
          <PrivateRoute exact path="/fund/statements" component={Statements} />
          <PrivateRoute exact path="/fund/contributions/add" component={AddContribution} />
          <PrivateRoute exact path="/oauth/plaid" component={OauthPlaidPage} />
          <PrivateRoute exact path="/profile" component={ManageProfile} />
          <PrivateRoute exact path="/test" component={Test} />
          <PrivateRoute exact path="/fund/grants/rebalancing" component={PoolChoiceMain} />
          <PrivateRoute exact path="/fund/grants/request" component={GrantRequest} />
          <PrivateRoute
            exact
            path="/fund/grants/request/complete"
            component={GrantRequestComplete}
          />
          <PrivateRoute exact path="/fund/grants/rebalancing/complete" component={CompleteChange} />
          <PrivateRoute
            exact
            path="/fund/achcontributions/allocation"
            component={AchContributionAllocation}
          />
          <PrivateRoute
            exact
            path="/fund/achcontributions/amount"
            component={AchContributionChooseAmount}
          />
          <PrivateRoute
            exact
            path="/fund/transactions/:fundId/:poolId"
            component={FundTransactionsHistory}
          />
          <PrivateRoute
            exact
            path="/fund/achcontributions/allocation/submitted"
            component={AchContributionAllocationSubmitted}
          />
          <Route
            exact
            path="/grantee/achcontribution/choose"
            component={AchDonationsChooseGrantee}
          />
          <Route
            exact
            path="/grantee/achcontribution/choose/amount"
            component={AchDonationsChooseAmount}
          />
          <Route
            exact
            path="/grantee/achcontribution/submitted"
            component={AchDonationsSubmitted}
          />
          <Route
            exact
            path="/capital-fund-grantee/achcontribution/choose"
            component={CapitalFundDonationsChooseGrantee}
          />
          <Route
            exact
            path="/capital-fund-grantee/achcontribution/choose/amount"
            component={CapitalFundDonationsChooseAmount}
          />
          <Route
            exact
            path="/capital-fund-grantee/achcontribution/submitted"
            component={CapitalFundDonationsSubmitted}
          />
          <PrivateRoute exact path="/daf/fundname/:fundId" component={FundNameElection} />
          <PrivateRoute exact path="/daf/advisors/:fundId" component={PrimaryAdvisorsElection} />
          <PrivateRoute exact path="/daf/submitted" component={DafSubmitted} />
          <PrivateRoute exact path="/daf/sunsetting/:fundId" component={SunsettingDaf} />
          <PrivateRoute exact path="/daf/successor/:fundId" component={SuccessorAdvisors} />
          <PrivateRoute exact path="/daf/donor/:fundId" component={DafDonorIntent} />
        </Switch>
      </div>
    </div>
  );
};
