import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { greyColor } from '../../common/constants';

const statementFilesToText = (files) => {
  let result = '';
  files.forEach(({ fileName, name }) => {
    result += `${fileName || name} \n`;
  });
  return result;
};

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
      padding: '0 10',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = ({ newStatementFiles, message }) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>
            Donor intent/mission statement
          </Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>
            Donor Intent/Mission Statement. You are encouraged to describe your charitable
            principles, interests, and goals so that DonorsTrust may better preserve your donor
            intent. Your intent must fall within (or not contradict) the purposes and mission of
            DonorsTrust. To further protect your intent, DonorsTrust strongly encourages you to
            limit the life of your donor-advised fund to a term of twenty-five years or less after
            your death.
          </Text>
          <Table>
            <TableRow>
              <TableCell color={greyColor}>Old</TableCell>
              <TableCell color={greyColor}>New</TableCell>
            </TableRow>
          </Table>
          {newStatementFiles && (
            <>
              <Table>
                <TableRow>
                  <TableCell>
                    Upload your document if you have any changes to your donor intent
                  </TableCell>
                </TableRow>
                <TableRow>
                  {newStatementFiles?.old ? (
                    <TableCell textAlign="left">
                      {statementFilesToText(newStatementFiles.old)}
                    </TableCell>
                  ) : (
                    <TableCell textAlign="center">N/A</TableCell>
                  )}
                  {newStatementFiles?.new ? (
                    <TableCell textAlign="left">
                      {statementFilesToText(newStatementFiles.new)}
                    </TableCell>
                  ) : (
                    <TableCell textAlign="center">N/A</TableCell>
                  )}
                </TableRow>
              </Table>
              <Text style={{ textAlign: 'left', marginTop: 10, marginBottom: 10 }}>
                Note: Attached document is sent to your and Donors Trust emails.
              </Text>
            </>
          )}
          {message && (
            <Table>
              <TableRow>
                <TableCell>Leave your text message to your donor intent</TableCell>
              </TableRow>
              <TableRow>
                {message.old ? (
                  <TableCell textAlign="left">{message?.old}</TableCell>
                ) : (
                  <TableCell textAlign="center">N/A</TableCell>
                )}
                {message?.new ? (
                  <TableCell textAlign="left">{message?.new}</TableCell>
                ) : (
                  <TableCell textAlign="center">N/A</TableCell>
                )}
              </TableRow>
            </Table>
          )}
        </View>
      </Page>
    </Document>
  );
};
