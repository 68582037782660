import React, { useEffect, useContext, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AsyncTypeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import _ from 'lodash';
import { ApiContext } from 'components/ApiProvider';
import { NavigateButtons } from '../../common/NavigateButtons/NavigateButtons';
import { addHyphen, handleInputNumberValue } from '../../common/helpers';
import { regExp } from '../../common/constants';

export const PublicCharityModal = ({
  isPublicCharityModalOpen,
  closeModal,
  unallocatedFunds,
  checkedPublicCharity,
  saveCharity,
}) => {
  const { searchPublicCharities } = useContext(ApiContext);
  const resolver = yupResolver(
    yup.object().shape({
      charityName: yup.string().required('Required input').nullable(true),
      ein: yup
        .string()
        .required('Required input')
        .matches(regExp.EIN_VALIDATION, {
          message: 'Incorrect input',
          excludeEmptyString: true,
        })
        .nullable(true),
      distributionPercentage: yup
        .number()
        .min(1, 'Incorrect input')
        .required('Required field')
        .nullable(true)
        .test('max', 'Incorrect input', function validate(value) {
          return value <= unallocatedFunds + (checkedPublicCharity?.distributionPercentage || 0);
        }),
    })
  );

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    resolver,
  });

  const [charityNames, setCharityNames] = useState([]);
  const [charityEins, setCharityEins] = useState([]);

  const submit = (data) => {
    saveCharity({
      ...data,
      idFront: checkedPublicCharity?.idFront || _.uniqueId(),
      id: checkedPublicCharity?.id || 0,
    });
    closeModal();
  };

  useEffect(() => {
    reset(checkedPublicCharity);
  }, []);

  const handleSearchName = async (query) => {
    const data = await searchPublicCharities(query);
    setCharityNames(data);
  };
  const handleSearchEin = async (query) => {
    const data = await searchPublicCharities(query);
    setCharityEins(data);
  };

  return (
    <Modal show={isPublicCharityModalOpen} className="grantee-history-modal" centered size="md">
      <Modal.Body className="daf-container">
        <div className="daf-container">
          <div className="grant-instructions mb-3 mb-md-4 p-md-5 ">
            <div className="col py-md-0 text-center col-md-10 offset-md-1">
              <h2>
                <strong>Public Charity</strong>
              </h2>
            </div>
          </div>
        </div>
        <div className="my-3">
          <Form.Label
            htmlFor="daf-charity-name"
            className="col-12 no-gutters d-flex flex-column align-items-start"
          >
            <strong className="col-auto mt-1">Charity name</strong>
            <div className="mt-2 col-12">
              <Controller
                name="charityName"
                control={control}
                render={({ field: { value: nameValue, onChange, ...field } }) => (
                  <AsyncTypeahead
                    {...field}
                    renderInput={({ value, ...inputProps }) => (
                      <TypeaheadInputSingle
                        value={nameValue}
                        {...inputProps}
                        id="daf-charity-name"
                      />
                    )}
                    minLength={3}
                    options={charityNames}
                    id="daf-charity-name"
                    filterBy={(option, props) =>
                      option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                    }
                    onInputChange={(text) => {
                      if (!text) {
                        setValue('ein', '');
                        if (isSubmitted) trigger('ein');
                      }
                      onChange(text);
                    }}
                    className="typeahead-city w-100"
                    onChange={([charityName]) => {
                      if (charityName) {
                        setValue('ein', charityName?.ein.trim());
                        if (isSubmitted) trigger('ein');
                        onChange(charityName?.name);
                      }
                    }}
                    onSearch={handleSearchName}
                    labelKey="name"
                  />
                )}
              />
              {errors.charityName && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.charityName.message}
                </div>
              )}
            </div>
          </Form.Label>
        </div>
        <div className="my-3">
          <Form.Label
            htmlFor="daf-charity-ein"
            className="col-12 no-gutters d-flex flex-column align-items-start"
          >
            <strong className="col-auto mt-1">EIN</strong>
            <div className="mt-2 col-12">
              <Controller
                name="ein"
                control={control}
                render={({ field: { value: einValue, onChange, ...field } }) => (
                  <AsyncTypeahead
                    {...field}
                    renderInput={({ value, ...inputProps }) => (
                      <TypeaheadInputSingle value={einValue} {...inputProps} id="daf-charity-ein" />
                    )}
                    minLength={3}
                    options={charityEins}
                    value={einValue}
                    id="daf-charity-ein"
                    filterBy={(option, props) =>
                      option.ein?.toLowerCase().indexOf(props.text?.toLowerCase()) !== -1
                    }
                    onInputChange={(text) => {
                      if (!text) {
                        setValue('charityName', '');
                        if (isSubmitted) trigger('charityName');
                      }
                      onChange(addHyphen(text, einValue));
                    }}
                    className="typeahead-city w-100"
                    onChange={([charity]) => {
                      if (charity) {
                        setValue('charityName', charity?.name);
                        if (isSubmitted) trigger('charityName');
                        onChange(charity?.ein);
                      }
                    }}
                    onSearch={handleSearchEin}
                    labelKey="ein"
                  />
                )}
              />
              {errors.ein && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.ein.message}
                </div>
              )}
            </div>
          </Form.Label>
        </div>
        <div className="my-4">
          <Form.Label
            htmlFor="charity-distribution-percentage"
            className="mb-0 d-flex align-items-start"
          >
            <div className="col-3">
              <Controller
                name="distributionPercentage"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    type="number"
                    value={value ?? ''}
                    inputValue={value}
                    id="charity-distribution-percentage"
                    className="px-2 py-0 w-100 daf-number-input"
                    onChange={(e) => {
                      onChange(handleInputNumberValue(e));
                    }}
                  />
                )}
              />
              {errors.distributionPercentage && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.distributionPercentage.message}
                </div>
              )}
            </div>
            <div className="sunsetting-daf-years__text">% of account</div>
          </Form.Label>
        </div>
        <div className="mt-4">
          <NavigateButtons
            onClickCancel={() => {
              closeModal();
            }}
            onClickSubmit={handleSubmit(submit)}
            submitButtonText="Save"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
