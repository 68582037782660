import React, { useEffect, useRef, memo, useCallback, useState } from 'react';
import _ from 'lodash';

export const ButtonToBottomGranteeHistory = memo(({ isBottomInView, bottomElement }) => {
  const [showGoBottomButton, setShowGoBottomButton] = useState(false);
  const coordinateY = useRef(0);
  const isBottomInViewRef = useRef(isBottomInView);

  const debouncedHandler = useCallback(
    _.debounce(() => setShowGoBottomButton(false), 2000),
    []
  );

  useEffect(() => {
    setShowGoBottomButton(
      window.scrollY > document.documentElement.clientHeight && !isBottomInView
    );
    isBottomInViewRef.current = isBottomInView;
  }, [isBottomInView]);

  const handleVisibleButton = () => {
    if (
      coordinateY.current !== window.scrollY &&
      window.scrollY > document.documentElement.clientHeight &&
      !isBottomInViewRef.current
    ) {
      setShowGoBottomButton(true);
      debouncedHandler();
    }
    if (window.scrollY < document.documentElement.clientHeight) {
      setShowGoBottomButton(false);
    }
    coordinateY.current = window.scrollY;
  };

  const handleScrollBottom = () => {
    bottomElement.target.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
  }, []);
  return (
    <>
      <button
        type="button"
        className={
          showGoBottomButton
            ? 'button button-goTo button-goTo__bottom button-goTo__show'
            : 'button button-goTo button-goTo__bottom button-goTo__hidden'
        }
        onClick={handleScrollBottom}
      >
        <i className="bi bi-chevron-down " />
      </button>
    </>
  );
});
