import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AchContributionAllocationContext } from 'Providers/AchContributionAllocation';

const Navigation = (props) => {
  const {
    currentStep,
    totalSteps,
    nextStep,
    previousStep,
    fund,
    contributionAmount,
    fee,
    accountName,
    paymentLastDigits,
  } = props;
  const history = useHistory();
  const [isSubmitting, setisSubmitting] = useState(false);
  const { configuration, isValid, submit } = useContext(AchContributionAllocationContext);

  const stepBack = () => {
    if (currentStep === 1) {
      history.push({ pathname: '/fund/overview', state: { fund } });
    } else {
      previousStep();
      window.scroll(0, 0);
    }
  };

  const validNextStep = async () => {
    const valid = await isValid();
    if (valid) {
      if (currentStep === totalSteps) {
        if (!isSubmitting) {
          setisSubmitting(true);
          const submitGood = await submit();
          setisSubmitting(false);

          if (submitGood) {
            history.push({
              pathname: '/fund/achcontributions/allocation/submitted',
              state: {
                contributionAmount,
                allocations: configuration.allocations,
                fund,
                fee,
                accountName,
                paymentLastDigits,
              },
            });
          }
        }
      } else {
        nextStep();
        window.scroll(0, 0);
      }
    }
  };

  return (
    <div className="grant-request__navigation">
      <div className="container">
        <div className="row">
          <div className="col col-md-8 offset-md-2">
            <div className="d-flex justify-content-between justify-content-md-end">
              <button
                className="button button--gray px-5 py-3 mr-4 mr-md-3 ach-contribution-allocation__navigation-button"
                onClick={stepBack}
                type="button"
              >
                {currentStep === 1 ? 'Cancel' : 'Back'}
              </button>
              <button
                className="button button--dark-blue px-5 py-3 ach-contribution-allocation__navigation-button"
                onClick={validNextStep}
                type="button"
                disabled={isSubmitting}
              >
                {currentStep === totalSteps ? 'Submit' : 'Continue'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
