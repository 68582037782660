import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import useCurrentFund from 'Hooks/CurrentFund';
import { displayDate } from 'util/dates';
import Instructions from '../../Grant/Request/Instructions';

const GrantProgressModal = ({ showModal, closeModal, progressInfo }) => {
  const [show, setShow] = useState(showModal);
  const fund = useCurrentFund();

  const handleClose = () => {
    setShow(false);
    closeModal();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const TableRow = ({ status, date, comment }) => {
    return (
      date && (
        <>
          <tr>
            <td>{status}</td>
            <td className="text-right text-md-right">{displayDate(date) || '-'}</td>
          </tr>
          {comment && (
            <tr>
              <td className="comment-block" colSpan={2}>
                Comment: {comment}
              </td>
            </tr>
          )}
        </>
      )
    );
  };

  return (
    <Modal show={show} centered>
      <Modal.Body>
        <div className="grant-progress-modal">
          <Instructions isModal hideStepCount title={`Grant Progress: ${fund.name}`}>
            <div>View and track your grant status below</div>
          </Instructions>
          <table className="table">
            <thead>
              <tr>
                <th>Status</th>
                <th className="text-left text-md-right">Date</th>
              </tr>
            </thead>
            {progressInfo && (
              <tbody>
                <TableRow status="Received" date={progressInfo.dateReceived} />
                <TableRow
                  status="Start Process"
                  date={progressInfo.startProcessDate}
                  comment={progressInfo.processComment}
                />
                <TableRow
                  status="In Review"
                  date={progressInfo.dateInReview}
                  comment={progressInfo.reviewComment}
                />
                <TableRow status="To Committee" date={progressInfo.toCommitteeDate} />
                <TableRow status="Expected Issue" date={progressInfo.expectedIssueDate} />
                <TableRow status="Issued" date={progressInfo.issueDate} />
              </tbody>
            )}
          </table>
          <div className="text-center col-12 p-0 col-md-auto text-md-right mt-3">
            <button
              className="button col-12 col-md-auto button--dark-blue py-3  py-md-2 px-0 px-md-5"
              type="button"
              onClick={handleClose}
            >
              Quit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GrantProgressModal;
