import React from 'react';
import { getLabelById } from '../../common/helpers';
import { PHONE_TYPES } from '../../common/constants';

const CoPrimaryAdvisorInfo = ({
  prefix,
  firstName,
  lastName,
  donorsTrustSalutation,
  granteeSalutation,
  mailingAddress,
  city,
  state,
  zip,
  preferredPhone,
  email,
  suffix,
  secondaryPhone,
  secondaryPhoneType,
  preferredPhoneType,
  setCoprimaryAdvisor,
  openPopup,
}) => {
  return (
    <div className="row">
      <div className="col-8 ml-4 pl-4">
        <div>
          {prefix} {firstName} {lastName}
          {suffix && <span> {suffix}</span>},
        </div>
        <div>Preferred DonorTrust salutation: {donorsTrustSalutation}</div>
        <div>Preferred Grantee salutation: {granteeSalutation}</div>
        <div>{mailingAddress},</div>
        <div>
          {city}, {state}, {zip},
        </div>
        <div>
          {preferredPhone}, {getLabelById(Number(preferredPhoneType), PHONE_TYPES)},
        </div>
        {secondaryPhone && (
          <div>
            {secondaryPhone}
            {secondaryPhoneType ? (
              <span>, {getLabelById(Number(secondaryPhoneType), PHONE_TYPES)},</span>
            ) : (
              ','
            )}
          </div>
        )}
        <div>{email}</div>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center">
          <button type="button" className="border-0 onGrantClick mr-4" onClick={openPopup}>
            <i className="bi bi-pencil-fill" />
          </button>
          <button
            type="button"
            className="button button--red button--small px-2 py-1 "
            onClick={() => setCoprimaryAdvisor(null)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoPrimaryAdvisorInfo;
