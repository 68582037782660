import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Pdf } from './Pdf';

export const DownloadPdf = (props) => {
  const { granteeName, amount, fullName, frequency, email, paymentLastDigits } = props;

  return (
    <PDFDownloadLink
      document={
        <Pdf
          granteeName={granteeName}
          amount={amount}
          frequency={frequency}
          fullName={fullName}
          email={email}
          paymentLastDigits={paymentLastDigits}
        />
      }
      fileName="Donors Capital Fund Donation.pdf"
    >
      <i className="bi bi-cloud-arrow-down-fill text-dark" />
    </PDFDownloadLink>
  );
};
