import React, { useState, createContext, useContext } from 'react';
import * as yup from 'yup';
import { formatErrors } from 'util/errors';
import { ApiContext } from 'components/ApiProvider';

export const PoolChoicesContext = createContext();

const schema = yup.object().shape({
  type: yup.string().nullable().required(),
  fundId: yup.number().required(),
  exchangeMode: yup.string().nullable().when('type', {
    is: 'Exchange',
    then: yup.string().nullable().required(),
  }),
  amountToExchange: yup
    .number()
    .nullable()
    .when('exchangeMode', {
      is: (exchangeMode) => exchangeMode,
      then: yup.number().nullable().required(),
    })
    .when('$remainingAmount', (remainingAmount, state) => {
      return remainingAmount < 0 ? state.oneOf(['error'], 'amountToExchange too large') : state;
    }),
  approve: yup.string().when('$step', (step, state) => {
    return step > 1 ? state.required().oneOf(['Yes']) : state;
  }),
  investedAmount: yup
    .number()
    .nullable()
    .when('$step', (step, state) => {
      return step > 1
        ? state.required().when('$needToinvest', (needToinvest, amount) => {
            return amount.min(needToinvest).max(needToinvest);
          })
        : state;
    }),
});

export const PooChoicesProvider = ({ fund, children }) => {
  const [configuration, setconfiguration] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const { exchangeInvestments, rebalanceInvestments } = useContext(ApiContext);

  const updateConfiguration = (key, value) => {
    if (value || value === 0) {
      configuration[key] = value;
    } else {
      delete configuration[key];
    }

    setconfiguration({ ...configuration });
  };

  const isValid = async (step) => {
    const selectedPool = fund?.poolFundUnits.find((g) => g.poolId === configuration?.poolId);
    const remainingAmount = selectedPool
      ? selectedPool.poolFundValue - selectedPool.pendingAmount - configuration.amountToExchange
      : null;
    const investedAmount =
      (configuration?.allocations?.length &&
        configuration?.allocations?.reduce((a, b) => a + b.amount, 0)) ||
      null;
    const needToinvest =
      configuration?.type === 'Exchange' ? configuration?.amountToExchange?.toFixed(2) : 100;

    try {
      const data = await schema.validate(
        { ...configuration, investedAmount },
        {
          context: { step, remainingAmount, investedAmount, needToinvest },
        }
      );

      setErrorMessage(null);
      return data;
    } catch (e) {
      setErrorMessage(e.errors && e.errors[0]);
      return false;
    }
  };

  const addconfiguration = (type) => {
    const newconfiguration = {
      fundId: fund.id,
      type: type || null,
      poolId: undefined,
      exchangeMode: null,
      allocations: [],
    };

    setconfiguration({ ...newconfiguration });
  };

  const submit = async () => {
    const errors = [];
    const { type, fundId, poolId, allocations } = configuration;
    const isExchange = type === 'Exchange';
    const newAllocations = allocations.map((pool) =>
      isExchange
        ? {
            targetPoolId: pool.poolId,
            amount: pool.amount,
          }
        : {
            poolId: pool.poolId,
            allocationPercentage: pool.amount,
          }
    );
    try {
      // eslint-disable-next-line no-unused-expressions
      isExchange
        ? await exchangeInvestments({ fundId, SourcePoolId: poolId, exchanges: newAllocations })
        : await rebalanceInvestments({ fundId, allocations: newAllocations });
    } catch (e) {
      const msg = formatErrors(e.response.data);
      errors.push({ errorMsg: msg });
    }

    if (errors.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <PoolChoicesContext.Provider
      value={{
        configuration,
        updateConfiguration,
        addconfiguration,
        isValid,
        submit,
        errorMessage,
      }}
    >
      {children}
    </PoolChoicesContext.Provider>
  );
};
