// common environment for all stages dev host_url defaults to staging to allow access of other apps in dev mode
const common = {};

const env = {
  dev: {
    API_BASE_URL: 'https://localhost:44362/api/v1',
    ROUTER_BASE_NAME: '/',
    ACH_CONTRIBUTION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/a09bdbf2-85f6-4542-a292-1ff3a18c9f6f',
    ACH_CONTRIBUTION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/e1f0967e-2f3b-4068-bf44-baef408c2d96',
    ACH_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/a7a42ec9-3c3c-440f-8a77-d77402b1583b',
    ACH_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/cef5ccdf-53ab-4d4f-bf05-5570896818ef',
    ACH_GRANTEE_DONATION_MORE_10K_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/31ec233f-31fd-4915-bc48-25a4a766b48a',
    ACH_GRANTEE_DONATION_MORE_1K_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/4289a17e-495a-4c0d-b03e-c8dca7d14bca',
    CAPITAL_FUND_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/c89dfccf-15ee-4288-a875-9749e43b0166',
    CAPITAL_FUND_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/5d891ee5-4921-4b5c-ae8e-889f2edef196',
    IS_SHOW_BANNER: false,
  },
  test: {
    API_BASE_URL: 'https://dtstagingapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    ACH_CONTRIBUTION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/a09bdbf2-85f6-4542-a292-1ff3a18c9f6f',
    IS_SHOW_BANNER: false,
  },
  staging: {
    API_BASE_URL: 'https://dtstagingapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    ACH_CONTRIBUTION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/cc8001b7-8ab1-44df-a19d-91fb9cb84c73',
    ACH_CONTRIBUTION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/fcdf93bd-fc98-448b-8ced-c6d9d91a3494',
    ACH_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/0a155740-dd86-4d54-8234-83224291a447',
    ACH_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/5b17a777-34d5-451e-b1f4-503d4e4b4bf7',
    ACH_GRANTEE_DONATION_MORE_10K_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/424531c5-2252-4875-b339-d2424f81add9',
    ACH_GRANTEE_DONATION_MORE_1K_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/22b8f7e6-d174-4a9b-96bc-32d4d4d6e07d',
    CAPITAL_FUND_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/6f09ef3c-f8f3-4d62-a53c-68088a7fd18c',
    CAPITAL_FUND_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot-sandbox.com/a4798aaa-98d2-42b4-a4be-fc7d9164500e/3a3f5ace-3b0f-4622-a732-c8ef9fe9d9df',
    IS_SHOW_BANNER: false,
  },
  production: {
    API_BASE_URL: 'https://donorstrustapi.azurewebsites.net/api/v1',
    ROUTER_BASE_NAME: '/',
    ACH_CONTRIBUTION_BOTTOM_QUANTITY_URL: 'https://secure.anedot.com/donorstrust/donate',
    ACH_CONTRIBUTION_UPPER_QUANTITY_URL: 'https://secure.anedot.com/donorstrust/hd',
    ACH_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot.com/donorstrust/2d8f398c-2f82-48bb-874a-519adae32b59',
    ACH_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot.com/donorstrust/0d890c3e-e619-4d40-95d5-267992002522',
    ACH_GRANTEE_DONATION_MORE_10K_URL:
      'https://secure.anedot.com/donorstrust/a31b5f82-7d13-4e05-9a36-42d59707f4f6',
    ACH_GRANTEE_DONATION_MORE_1K_URL:
      'https://secure.anedot.com/donorstrust/015ebf58-1edc-4b00-aa49-c70dde6e849a',
    CAPITAL_FUND_GRANTEE_DONATION_BOTTOM_QUANTITY_URL:
      'https://secure.anedot.com/donorstrust/ff3d1ad3-55c6-4669-9d9e-1ce9fa2dfae0',
    CAPITAL_FUND_GRANTEE_DONATION_UPPER_QUANTITY_URL:
      'https://secure.anedot.com/donorstrust/233be3fa-cf5c-4030-87fd-cf73d20a9943',
    IS_SHOW_BANNER: true,
  },
};

export default {
  ...common,
  ...env[process.env.REACT_APP_STAGE],
};
