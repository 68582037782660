/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import { FundTransactionsHistoryHeader } from './FundTransactionsHistoryHeader';
import {
  FundTransactionsHistoryFilter,
  substractDaysFromToday,
  subtractMonths,
} from './FundTransactionsHistoryFilter';
import {
  FundTransactionsHistoryNoResults,
  FundTransactionsHistoryNoDate,
} from './FundTransactionsHistoryNoResults';
import { FundTransactionsHistoryTable } from './FundTransactionsHistoryTable';
import { FundTransactionsHistoryPagination } from './FundTransactionsHistoryPagination';

const sortTransactions = (key, order, transactions) => {
  let sortedTransactions = [];

  if (order === 'asc') {
    sortedTransactions = [...transactions].sort((a, b) => {
      if (typeof a[key] === 'string') {
        return key.includes('date')
          ? new Date(a[key]) - new Date(b[key])
          : a[key].localeCompare(b[key]);
      }
      return Math.abs(a[key]) - Math.abs(b[key]);
    });
  } else {
    sortedTransactions = [...transactions].sort((a, b) => {
      if (typeof a[key] === 'string') {
        return key.includes('date')
          ? new Date(b[key] || null) - new Date(a[key] || null)
          : b[key].localeCompare(a[key]);
      }
      return Math.abs(b[key]) - Math.abs(a[key]);
    });
  }
  return sortedTransactions;
};

const FundTransactionsHistory = () => {
  const { poolId, fundId } = useParams();
  const { getFund } = useContext(ApiContext);

  const [fund, setFund] = useState();
  const [pool, setPool] = useState();
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionsForCurrentPage, setTransactionsForCurrentPage] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [dateFrom, setDateFrom] = useState(subtractMonths(1));
  const [dateTo, setDateTo] = useState(substractDaysFromToday(1));

  useEffect(() => {
    const getData = async () => {
      const fundData = await getFund(fundId);
      setPool(...fundData.poolFundUnits.filter((p) => p.poolId === Number(poolId)));
      setFund(fundData);
    };
    getData();
  }, []);

  useEffect(() => {
    setFilteredTransactions((prev) => sortTransactions(sortConfig.key, sortConfig.direction, prev));
  }, [sortConfig]);

  return (
    <div className="transactions">
      <FundTransactionsHistoryHeader
        fund={fund}
        pool={pool}
        dateFrom={dateFrom}
        dateTo={dateTo}
        filteredTransactions={filteredTransactions}
      />
      <FundTransactionsHistoryFilter
        fundId={fundId}
        poolId={poolId}
        poolName={pool?.name}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={(date) => setDateFrom(date)}
        setDateTo={(date) => setDateTo(date)}
        filteredTransactions={filteredTransactions}
        setFilteredTransactions={(transactions) => {
          setFilteredTransactions(transactions);
        }}
        setSortConfig={() => setSortConfig({ key: 'date', direction: 'desc' })}
      />
      {filteredTransactions.length ? (
        <FundTransactionsHistoryTable
          transactionsForCurrentPage={transactionsForCurrentPage}
          sortConfig={sortConfig}
          setSortConfig={(config) => setSortConfig(config)}
        />
      ) : dateTo && dateFrom ? (
        <FundTransactionsHistoryNoResults />
      ) : (
        <FundTransactionsHistoryNoDate />
      )}
      {filteredTransactions.length ? (
        <FundTransactionsHistoryPagination
          filteredTransactions={filteredTransactions}
          setTransactionsForCurrentPage={(transactions) =>
            setTransactionsForCurrentPage(transactions)
          }
        />
      ) : null}
    </div>
  );
};

export default FundTransactionsHistory;
