import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      margin: '10px',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = (props) => {
  const { fund, amount, fee, allocations, fullName, frequency, paymentLastDigits } = props;

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text>
            Congratulations! Investment on your contribution should occur within 3-7 business days
            after Donors Trust receives the funds.
          </Text>
          <Text>Please, check your email for confirmation of your investment choice.</Text>
          <Table>
            <TableRow>
              <TableCell textAlign="right">Fund name:</TableCell>
              <TableCell textAlign="left" />
              <TableCell textAlign="right">{fund.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Fund number:</TableCell>
              <TableCell textAlign="left" />
              <TableCell textAlign="right">{fund.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Contribution:</TableCell>
              <TableCell textAlign="left" />
              <TableCell textAlign="right">{`$${displayCurrency(amount)}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Processing fee:</TableCell>
              <TableCell textAlign="left" />
              <TableCell textAlign="right">{`$${displayCurrency(fee)}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="right">Investible amount:</TableCell>
              <TableCell textAlign="left" />
              <TableCell textAlign="right">{`$${displayCurrency(amount - fee)}`}</TableCell>
            </TableRow>
            {allocations?.map((pool) => (
              <TableRow>
                <TableCell textAlign="right" />
                <TableCell textAlign="left">{pool.name}</TableCell>
                <TableCell textAlign="right">{`${displayCurrency(pool.amount)}%`}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell textAlign="right" />
              <TableCell textAlign="left">Total:</TableCell>
              <TableCell textAlign="right">{`${displayCurrency(100)}%`}</TableCell>
            </TableRow>
            {frequency && (
              <TableRow>
                <TableCell textAlign="right">Frequency:</TableCell>
                <TableCell textAlign="left">{frequency}</TableCell>
                <TableCell textAlign="right" />
              </TableRow>
            )}
            {fullName && (
              <TableRow>
                <TableCell textAlign="right">Account name:</TableCell>
                <TableCell textAlign="left">{fullName}</TableCell>
                <TableCell textAlign="right" />
              </TableRow>
            )}
            {paymentLastDigits && (
              <TableRow>
                <TableCell textAlign="right">Payment details:</TableCell>
                <TableCell textAlign="left">{`****${paymentLastDigits}`}</TableCell>
                <TableCell textAlign="right" />
              </TableRow>
            )}
            <TableRow>
              <TableCell textAlign="right">Submitted Date:</TableCell>
              <TableCell textAlign="left">{displayDate(new Date())}</TableCell>
              <TableCell textAlign="right" />
            </TableRow>
          </Table>
        </View>
      </Page>
    </Document>
  );
};
