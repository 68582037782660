import React, { useState, useContext } from 'react';
import Typeahead from 'components/Typeahead';
import { ApiContext } from 'components/ApiProvider';
import NewGranteeModal from 'components/Main/NewGrantee/Modal';

const Test = () => {
  const [showModal, setShowModal] = useState(false);
  const { getGrantees } = useContext(ApiContext);

  const handleSelect = (selection) => {
    if (selection && selection.customOption) {
      setShowModal(true);
      return true;
    }

    return selection;
  };

  console.log('setShowModal', showModal);

  return (
    <div className="container">
      <NewGranteeModal showModal={showModal} />
      <Typeahead
        asyncSearchFunc={getGrantees}
        placeholder="Search for an organization"
        newSelectionLabel="+ Add new grantee: "
        labelKey="name"
        id="searchGranteeTypeahead"
        onSelect={handleSelect}
      />
    </div>
  );
};

export default Test;
