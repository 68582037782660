import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import env from 'environment';
import { AuthenticationContext } from 'auth/AuthProvider';
import ChooseAmountCard from 'components/Common/ChooseAmountCard/ChooseAmountCard';
import { ContributionWarningModal } from 'components/Common/ChooseAmountCard/ContributionWarningModal';

const AchContributionChooseAmount = () => {
  const { user } = useContext(AuthenticationContext);
  const location = useLocation();
  const history = useHistory();
  const fund = location.state?.fund;
  const [isShowModal, setIsShowModal] = useState(false);
  const [chosenContributionInfo, setChosenContributionInfo] = useState(null);
  const achContributionBottomQuantityUrl = `${env.ACH_CONTRIBUTION_BOTTOM_QUANTITY_URL}?donors_trust_fund_id=${fund.id}&donors_trust_contributor_id=${user.id}`;
  const achContributionUpperQuantityUrl = `${env.ACH_CONTRIBUTION_UPPER_QUANTITY_URL}?donors_trust_fund_id=${fund.id}&donors_trust_contributor_id=${user.id}`;

  return (
    <>
      <div className="grant-request mt-n3 mt-md-n4">
        <div className="grant-instructions text-center p-3 p-md-5">
          <h2>
            <strong>Choose amount of contribution</strong>
          </h2>
        </div>
      </div>
      <div className="container mt-5">
        <div className="d-flex justify-content-between flex-column flex-md-row ach-contribution-cards-wrapper">
          <ChooseAmountCard
            text={
              <div>
                To request a contribution amount less than $8,500.00, click on the link below.
                <br />
                <br />
                Once you click, you will be redirected to the appropriate payment form.
              </div>
            }
            navigation={
              <button
                type="button"
                className="button text-link"
                onClick={() => {
                  setChosenContributionInfo({
                    url: achContributionBottomQuantityUrl,
                    isGreater8500: false,
                  });
                  setIsShowModal(true);
                }}
              >
                <strong>Contribute &lt; $8,500.00</strong>
              </button>
            }
          />
          <ChooseAmountCard
            text={
              <div>
                To request a contribution amount greater or equal to $8,500.00, click on the link
                below.
                <br />
                <br />
                Once you click, you will be redirected to the appropriate payment form.
              </div>
            }
            navigation={
              <button
                type="button"
                className="button text-link"
                onClick={() => {
                  setChosenContributionInfo({
                    url: achContributionUpperQuantityUrl,
                    isGreater8500: true,
                  });
                  setIsShowModal(true);
                }}
              >
                <strong>Contribute &ge; $8,500.00</strong>
              </button>
            }
          />
        </div>
        <div className="mt-5 d-flex justify-content-end">
          <button
            type="button"
            className="button button--dark-blue px-4 px-md-5 py-3 mr-0 text-center col-12 col-md-auto"
            onClick={() => history.push({ pathname: '/fund/overview', state: { fund } })}
          >
            Back
          </button>
        </div>
      </div>
      {isShowModal && (
        <ContributionWarningModal
          isShowModal={isShowModal}
          setIsShowModal={(value) => setIsShowModal(value)}
          chosenContributionInfo={chosenContributionInfo}
        />
      )}
    </>
  );
};
export default AchContributionChooseAmount;
