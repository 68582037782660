import React from 'react';
import { useLocation } from 'react-router-dom';
import { CAPITAL_FUND_ROUTE } from 'components/Main/constants';

export default () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const location = useLocation();
  const isCapitalFundPath = location.pathname.includes(CAPITAL_FUND_ROUTE);
  const termsOfUsageUrl =
    'https://app.termly.io/document/terms-of-service/269eba76-3b4b-4433-bb86-bbe3c7ddde9a';
  const capitalFundTermsOfUsageUrl =
    'https://app.termly.io/document/terms-of-service/f69399e0-2905-422c-a039-b1ab2101f745';
  const privacyPolicy =
    'https://app.termly.io/document/privacy-policy/914a8bda-0b39-42bc-8a8a-72713cee2d0b';
  const capitalFundPrivacyPolicy =
    'https://app.termly.io/document/privacy-policy/7cc0ff3b-74a1-4001-bf7b-e067a9a9480e';

  return (
    <div className="footer row no-gutters text-center">
      {isCapitalFundPath ? (
        <span className="mr-md-2 col-md-auto"> Copyright {year}, Donors Capital Fund, Inc.</span>
      ) : (
        <span className="mr-md-2 col-md-auto"> Copyright {year}, Donorstrust, Inc.</span>
      )}

      <div className="col-md-auto pt-3 pt-md-0">
        <a
          className="ml-md-1 mr-2 mr-md-1"
          href={isCapitalFundPath ? capitalFundTermsOfUsageUrl : termsOfUsageUrl}
        >
          Terms Of Use
        </a>
        <span>|</span>
        <a
          className="ml-2 ml-md-1 mr-1"
          href={isCapitalFundPath ? capitalFundPrivacyPolicy : privacyPolicy}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
