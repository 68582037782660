import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { pdf } from '@react-pdf/renderer';
import { ApiContext } from 'components/ApiProvider';
import { Form } from 'react-bootstrap';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import moment from 'moment';
import SunsettingDafInstruction from './Instruction/SunsettingDafInstruction';
import { Pdf } from './Pdf/Pdf';
import {
  handleLoadedDataSunsettingDaf,
  handleSubmittedDataSunsettingDaf,
  findChangedValuesSunsetting,
  handleInputNumberValue,
  findDeletedIds,
} from '../common/helpers';
import { NavigateButtons } from '../common/NavigateButtons/NavigateButtons';
import { PublicCharityModal } from './PublicCharityModal/PublicCharityModal';
import { PublicCharityInfo } from './PublicCharityInfo/PublicCharityInfo';

const SunsettingDaf = () => {
  const {
    getDafSunsettingAndFinalDistributionElections,
    updateDafSunsettingAndFinalDistributionElections,
    sendNotificationAboutDonorAdvisedFundChanges,
    getFund,
  } = useContext(ApiContext);
  const history = useHistory();
  const { fundId } = useParams();

  const [fund, setFund] = useState();
  const [unallocatedFunds, setUnallocatedFunds] = useState(100);
  const [dafSunsetting, setDafSunsetting] = useState(null);
  const [checkedPublicCharity, setCheckedPublicCharity] = useState();
  const [publicCharities, setPublicCharities] = useState([]);
  const [isPublicCharityModalOpen, setIsPublicCharityModalOpen] = useState(false);
  const [previousSettings, setPreviousSettings] = useState({});
  const [publicCharitiesDistribution, setPublicCharitiesDistribution] = useState(false);

  const resolver = yupResolver(
    yup.object().shape({
      dafTermLimitYearsAfterDeath: yup
        .number()
        .when('dafTermLimitOption', {
          is: (dafTermLimitOption) => dafTermLimitOption === 1,
          then: yup
            .number()
            .min(1, 'Incorrect input')
            .max(25, 'Incorrect input')
            .required('Required field')
            .nullable(true),
        })
        .nullable(true),
      dafTermLimitSpecificDate: yup
        .string()
        .when('dafTermLimitOption', {
          is: (dafTermLimitOption) => dafTermLimitOption === 3,
          then: yup.string().required('Required field').nullable(true),
        })
        .nullable(true),
      finalDestributionToWitneyBallFundPercentage: yup
        .number()
        .when('whitneyBallDistribution', {
          is: (whitneyBallDistribution) => whitneyBallDistribution,
          then: yup
            .number()
            .min(1, 'Incorrect input')
            .required('Required field')
            .nullable(true)
            .test('max', 'Incorrect input', function validate() {
              return unallocatedFunds >= 0;
            }),
        })
        .nullable(true),
      averageOfPastGivingPercentage: yup
        .number()
        .when('weightedAverageDistribution', {
          is: (weightedAverageDistribution) => weightedAverageDistribution,
          then: yup
            .number()
            .min(1, 'Incorrect input')
            .required('Required field')
            .nullable(true)
            .test('max', 'Incorrect input', function validate() {
              return unallocatedFunds >= 0;
            }),
        })
        .nullable(true),
      publicCharities: yup.array().when('publicCharitiesDistribution', {
        is: () => publicCharitiesDistribution,
        then: yup.array().test('required', function validate() {
          return publicCharities?.length;
        }),
      }),
      unallocatedFunds: yup.number().test('max', 'Incorrect input', function validate() {
        return unallocatedFunds === 0;
      }),
      dafTermLimitOption: yup
        .number()
        .when('isCustomPlan', {
          is: (isCustomPlan) => {
            return !isCustomPlan;
          },
          then: yup.number().required('Select one of the options').nullable(true),
        })
        .nullable(true),
    })
  );
  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    resolver,
  });

  const [
    whitneyBallDistribution,
    weightedAverageDistribution,
    dafTermLimitOption,
    isCustomPlan,
    finalDestributionToWitneyBallFundPercentage,
    averageOfPastGivingPercentage,
  ] = useWatch({
    control,
    name: [
      'whitneyBallDistribution',
      'weightedAverageDistribution',
      'dafTermLimitOption',
      'isCustomPlan',
      'finalDestributionToWitneyBallFundPercentage',
      'averageOfPastGivingPercentage',
      'dafTermLimitSpecificDate',
    ],
  });

  const savePublicCharity = (publicCharity) => {
    if (!publicCharities?.some((p) => p?.idFront === publicCharity.idFront)) {
      setPublicCharities((prev) => prev.concat(publicCharity));
    } else {
      setPublicCharities((prev) =>
        prev.map((x) => (x.idFront === publicCharity.idFront ? publicCharity : x))
      );
    }
  };

  const deletePublicCharityById = (idFront) => {
    setPublicCharities((prev) => prev.filter((charity) => charity.idFront !== idFront));
  };

  useEffect(() => {
    const getDafSunsettings = async () => {
      const data = await getDafSunsettingAndFinalDistributionElections(fundId);
      const fundData = await getFund(fundId);
      setFund(fundData);
      const handledData = handleLoadedDataSunsettingDaf(data);
      setPublicCharitiesDistribution(handledData.publicCharitiesDistribution);
      setPreviousSettings(
        handleSubmittedDataSunsettingDaf(handledData, handledData.publicCharityDistributions)
      );
      setPublicCharities(handledData.publicCharityDistributions);
      setDafSunsetting(handledData);
    };
    getDafSunsettings();
  }, []);

  useEffect(() => {
    reset(dafSunsetting);
  }, [dafSunsetting]);

  useEffect(() => {
    setValue('publicCharities', publicCharities);
    if (isSubmitted) trigger('publicCharities');
  }, [publicCharities, publicCharitiesDistribution]);

  const submit = async (data) => {
    const newSettings = handleSubmittedDataSunsettingDaf(data, publicCharities);
    const changedOptions = findChangedValuesSunsetting(previousSettings, newSettings);

    const deletedPublicCharityIds = findDeletedIds(
      previousSettings.publicCharityDistributions,
      publicCharities
    );

    if (Object.keys(changedOptions).length) {
      const blobPdf = await pdf(
        Pdf({
          ...changedOptions,
        })
      ).toBlob();

      const formData = new FormData();
      formData.append(
        'updatesFile',
        blobPdf,
        'Sunsetting of donor-advised fund account and final distribution elections.pdf'
      );

      await updateDafSunsettingAndFinalDistributionElections({
        fundId,
        ...newSettings,
        deletedPublicCharityIds,
      })
        .then(async () => {
          await sendNotificationAboutDonorAdvisedFundChanges(formData);
        })
        .then(() => {
          history.push({
            pathname: '/daf/submitted',
            state: { changedOptions, startingPage: 'sunsettingDaf', fund, blobPdf },
          });
        })
        .catch(console.log);
    } else {
      history.push({ pathname: '/fund/overview', state: { fund } });
    }
  };

  useEffect(() => {
    if (unallocatedFunds <= 0) {
      if (!weightedAverageDistribution || !averageOfPastGivingPercentage) {
        setValue('weightedAverageDistribution', false);
      }
      if (!whitneyBallDistribution || !finalDestributionToWitneyBallFundPercentage) {
        setValue('whitneyBallDistribution', false);
      }
      if (!publicCharities.length) {
        setValue('publicCharitiesDistribution', false);
        setPublicCharitiesDistribution(false);
      }
    }
    if (isSubmitted) trigger('unallocatedFunds');
  }, [unallocatedFunds]);

  useEffect(() => {
    if (isSubmitted) trigger('dafTermLimitYearsAfterDeath');
    if (isSubmitted) trigger('dafTermLimitSpecificDate');
    if (dafTermLimitOption !== 1) setValue('dafTermLimitYearsAfterDeath', null);
    if (dafTermLimitOption !== 3) setValue('dafTermLimitSpecificDate', '');
  }, [dafTermLimitOption]);

  useEffect(() => {
    let unallocatedPercentages = 100;
    if (whitneyBallDistribution)
      unallocatedPercentages -= finalDestributionToWitneyBallFundPercentage || 0;
    if (weightedAverageDistribution) unallocatedPercentages -= averageOfPastGivingPercentage || 0;
    if (publicCharities?.length)
      publicCharities.forEach((charity) => {
        unallocatedPercentages -= charity.distributionPercentage || 0;
      });
    setUnallocatedFunds(unallocatedPercentages);
    setValue('unallocatedFunds', unallocatedPercentages);
    if (isSubmitted) {
      trigger('averageOfPastGivingPercentage');
      trigger('finalDestributionToWitneyBallFundPercentage');
    }
  }, [
    whitneyBallDistribution,
    weightedAverageDistribution,
    publicCharities,
    finalDestributionToWitneyBallFundPercentage,
    averageOfPastGivingPercentage,
  ]);

  useEffect(() => {
    if (!whitneyBallDistribution) setValue('finalDestributionToWitneyBallFundPercentage', null);
  }, [whitneyBallDistribution]);

  useEffect(() => {
    if (!weightedAverageDistribution) setValue('averageOfPastGivingPercentage', null);
  }, [weightedAverageDistribution]);

  useEffect(() => {
    if (isSubmitted) trigger('dafTermLimitOption');
    if (isCustomPlan) {
      setValue('dafTermLimitOption', null);
      setValue('dafTermLimitYearsAfterDeath', null);
      setValue('dafTermLimitSpecificDate', null);
      return;
    }
    setValue('dafTermLimitOption', previousSettings.dafTermLimitOption);
    setValue('dafTermLimitYearsAfterDeath', previousSettings.dafTermLimitYearsAfterDeath);
    setValue('dafTermLimitSpecificDate', previousSettings.dafTermLimitSpecificDate);
  }, [isCustomPlan]);

  return (
    <>
      <SunsettingDafInstruction />
      <div className="container daf-container">
        <div className="my-4">
          <strong>Account Sunsetting.</strong> Accounting sunsetting at DonorsTrust requires two
          decisions: when would you like your account to end and how would you like the remaining
          balance in the account distributed. Either contact us to construct a customized memo of
          understanding (MoU) that addresses these two decisions, or provide the information
          requested in the spaces below.
        </div>
        <div className="my-3">
          <Controller
            name="isCustomPlan"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <>
                <div className="d-flex align-items-start">
                  <input
                    {...field}
                    type="radio"
                    id="sunsetting-custom-plan-true"
                    className="mt-1"
                    value
                    checked={value}
                    onChange={() => {
                      onChange(true);
                    }}
                  />
                  <Form.Label htmlFor="sunsetting-custom-plan-true" className="ml-3 mb-0">
                    Custom Plan. I will contact DonorsTrust to discuss a custom sunset plan.
                  </Form.Label>
                </div>
                <div className="my-3">
                  <div className="d-flex align-items-start">
                    <input
                      {...field}
                      type="radio"
                      id="sunsetting-custom-plan-false"
                      className="mt-1"
                      checked={!value}
                      onChange={() => {
                        onChange(false);
                      }}
                    />
                    <Form.Label htmlFor="sunsetting-custom-plan-false" className="ml-3 mb-0">
                      I have indicated my sunsetting advice, below.
                    </Form.Label>
                  </div>
                </div>
              </>
            )}
          />
        </div>
        {!isCustomPlan && (
          <>
            <div className="my-3">
              <strong>Term Limit of Donor-Advised Fund.</strong> How long should your fund continue
              to make grants after the death of all original account holders (up to 25 years)?
            </div>
            <div className="my-3">
              <Controller
                name="dafTermLimitOption"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <>
                    <div className="d-flex align-items-start ">
                      <input
                        {...field}
                        type="radio"
                        className="sunsetting-daf-years__radio"
                        id="daf-term-limit-years-after-death"
                        checked={value === 1}
                        onChange={() => {
                          onChange(1);
                        }}
                      />
                      <Form.Label
                        htmlFor="daf-term-limit-years-after-death"
                        className="ml-3 mb-0  d-flex align-items-start"
                      >
                        <div className="flex-shrink-0 sunsetting-daf-years__text">
                          I would like my account to close
                        </div>
                        <div className="col-2">
                          <Controller
                            name="dafTermLimitYearsAfterDeath"
                            control={control}
                            render={({
                              field: { value: valueInput, onChange: onChangeYears, ...fieldYears },
                            }) => (
                              <Form.Control
                                {...fieldYears}
                                type="number"
                                id="daf-term-limit-years-after-death"
                                className="p-1 w-100 daf-number-input"
                                value={valueInput ?? ''}
                                onChange={(e) => {
                                  onChangeYears(handleInputNumberValue(e));
                                }}
                                disabled={dafTermLimitOption !== 1}
                              />
                            )}
                          />
                          {errors.dafTermLimitYearsAfterDeath && (
                            <div className="validation-alert text-left " style={{ width: 120 }}>
                              {errors.dafTermLimitYearsAfterDeath.message}
                            </div>
                          )}
                        </div>
                        <div className="flex-shrink-0 sunsetting-daf-years__text">
                          years after the date of my death.
                        </div>
                      </Form.Label>
                    </div>
                    <div className="my-3">
                      <div className="d-flex align-items-center">
                        <input
                          {...field}
                          type="radio"
                          id="daf-term-limit-last-successor"
                          checked={value === 2}
                          onChange={() => {
                            onChange(2);
                          }}
                        />
                        <Form.Label
                          htmlFor="daf-term-limit-last-successor"
                          className="ml-3 mb-0 d-flex align-items-center"
                        >
                          I would like my account to close with the death of my last successor
                          advisor.
                        </Form.Label>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex align-items-start">
                        <input
                          {...field}
                          type="radio"
                          id="daf-term-limit-specific-date"
                          className="sunsetting-daf-years__radio"
                          checked={value === 3}
                          onChange={() => {
                            onChange(3);
                          }}
                        />
                        <Form.Label
                          htmlFor="daf-term-limit-specific-date"
                          className="ml-3 mb-0 d-flex align-items-start"
                        >
                          <div className="flex-shrink-0 sunsetting-daf-years__text">
                            I would like my account to close on a specific date (MM/DD/YYYY)
                          </div>
                          <div className="col-4">
                            <Controller
                              name="dafTermLimitSpecificDate"
                              control={control}
                              render={({
                                field: { value: valueInput, onChange: onChangeDate, ...fieldYears },
                              }) => (
                                <DatePickerInput
                                  {...fieldYears}
                                  className={`p-1 w-100 date-input daf-datepicker ${
                                    errors.dafTermLimitSpecificDate ? 'input-error ' : null
                                  }`}
                                  minDate={new Date()}
                                  maxDate={moment().add(25, 'years').toDate()}
                                  disabled={dafTermLimitOption !== 3}
                                  selected={valueInput}
                                  onChange={(e) => {
                                    onChangeDate(e);
                                  }}
                                />
                              )}
                            />
                            {errors.dafTermLimitSpecificDate && (
                              <div className="validation-alert text-left " style={{ width: 120 }}>
                                {errors.dafTermLimitSpecificDate.message}
                              </div>
                            )}
                          </div>
                        </Form.Label>
                      </div>
                    </div>
                  </>
                )}
              />
              {errors.dafTermLimitOption && (
                <div className="validation-alert text-left">
                  <i className="bi bi-exclamation-circle-fill" />{' '}
                  {errors.dafTermLimitOption.message}
                </div>
              )}
            </div>
          </>
        )}
        <div className="my-3">
          <strong>Final distributions.</strong> Upon your death, disbursements will continue from
          your account until the end of the defined term limit of your account as stated above.
          Disbursements will follow your stated donor intent or, if that is undefined, will follow a
          24-month weighted average of past giving from your account. At the end of the account’s
          term, you may indicate that any remaining balance be transferred to one or more qualified
          public charities or to the Whitney Ball Memorial Fund at DonorsTrust, which supports
          continued growth of DonorsTrust and the broader liberty movement. The DonorsTrust Board of
          Directors will allocate resources only to those charities that are in keeping with your
          principles and DonorsTrust’s purposes. If you do not specify a beneficiary by the date of
          your fund’s termination, the remainder of your donor-advised fund will go to the Whitney
          Ball Memorial Fund when your account reaches its term limit.{' '}
          <strong>The sum of the percentages must total 100%.</strong>
        </div>
        <div className="my-3">
          <div className="d-flex align-items-start">
            <Controller
              name="whitneyBallDistribution"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <input
                  {...field}
                  type="checkbox"
                  className="sunsetting-daf-years__radio"
                  checked={value}
                  id="whitney-ball-distribution"
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  disabled={
                    unallocatedFunds <= 0 &&
                    (!whitneyBallDistribution || finalDestributionToWitneyBallFundPercentage === 0)
                  }
                />
              )}
            />
            <Form.Label
              htmlFor="whitney-ball-distribution"
              className="ml-3 mb-0 d-flex align-items-start"
            >
              <div className="sunsetting-daf-years__text">Whitney Ball Memorial Fund:</div>
              <div className="col-2">
                <Controller
                  name="finalDestributionToWitneyBallFundPercentage"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      value={value ?? ''}
                      inputValue={value}
                      id="final-destribution-to-witney-ball-fund-percentage"
                      className="px-2 py-0 w-100 daf-number-input"
                      onChange={(e) => {
                        onChange(handleInputNumberValue(e));
                        if (isSubmitted) trigger('averageOfPastGivingPercentage');
                      }}
                      disabled={!whitneyBallDistribution}
                    />
                  )}
                />
                {errors.finalDestributionToWitneyBallFundPercentage && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.finalDestributionToWitneyBallFundPercentage.message}
                  </div>
                )}
              </div>
              <div className="sunsetting-daf-years__text">% of account remainder.</div>
            </Form.Label>
          </div>
        </div>
        <div className="my-3">
          <div className="d-flex align-items-start">
            <Controller
              name="weightedAverageDistribution"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <input
                  {...field}
                  type="checkbox"
                  className="sunsetting-daf-years__radio"
                  checked={value}
                  id="weighted-average-distribution"
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  disabled={
                    unallocatedFunds <= 0 &&
                    (!weightedAverageDistribution || !averageOfPastGivingPercentage)
                  }
                />
              )}
            />
            <Form.Label
              htmlFor="weighted-average-distribution"
              className="ml-3 mb-0 d-flex align-items-start"
            >
              <div className="sunsetting-daf-years__text">
                Follow weighted average of past giving:
              </div>
              <div className="col-2">
                <Controller
                  name="averageOfPastGivingPercentage"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      id="average-of-past-giving-percentage"
                      className="px-2 py-0 w-100 daf-number-input"
                      onChange={(e) => {
                        onChange(handleInputNumberValue(e));
                        if (isSubmitted) trigger('finalDestributionToWitneyBallFundPercentage');
                      }}
                      value={value ?? ''}
                      disabled={!weightedAverageDistribution}
                    />
                  )}
                />
                {errors.averageOfPastGivingPercentage && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.averageOfPastGivingPercentage.message}
                  </div>
                )}
              </div>
              <div className="sunsetting-daf-years__text">% of account remainder.</div>
            </Form.Label>
          </div>
        </div>
        <div className="my-3">
          <Controller
            name="publicCharitiesDistribution"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <div className="d-flex align-items-start">
                <input
                  {...field}
                  type="checkbox"
                  className="mt-1"
                  checked={value}
                  id="public-charities-distribution"
                  onChange={(e) => {
                    if (publicCharities?.length > 0) return;
                    onChange(e.target.checked);
                    setPublicCharitiesDistribution(e.target.checked);
                  }}
                  disabled={unallocatedFunds <= 0 && publicCharities?.length === 0}
                />
                <Form.Label
                  htmlFor="public-charities-distribution"
                  className="ml-3 mb-0 d-flex align-items-start"
                >
                  Distributed to the following public charities (all we need is a name and an EIN):
                </Form.Label>
              </div>
            )}
          />
        </div>
        {publicCharitiesDistribution && (
          <>
            <div className="my-3">
              <button
                type="button"
                className="button p-0 d-flex align-items-center"
                disabled={unallocatedFunds <= 0}
                onClick={() => setIsPublicCharityModalOpen(true)}
              >
                <i className="bi bi-plus-circle daf-plus-icon" />
                <strong className="ml-2">Add Public Charity:</strong>
              </button>
            </div>
            <div className="my-3">
              {publicCharities &&
                publicCharities.map((publicCharity) => (
                  <PublicCharityInfo
                    key={publicCharity.id}
                    publicCharity={publicCharity}
                    {...publicCharity}
                    deletePublicCharityById={(id) => deletePublicCharityById(id)}
                    editPublicCharity={(charity) => {
                      setCheckedPublicCharity(charity);
                      setIsPublicCharityModalOpen(true);
                    }}
                  />
                ))}
            </div>
          </>
        )}

        {errors.publicCharities && (
          <div className="d-flex justify-content-end mt-4 mt-md-0">
            <div className="grant-instructions px-4 py-2 text-center daf-error-popup">
              <small>Please, add at least one public charity</small>
            </div>
          </div>
        )}
        {errors.unallocatedFunds && (
          <div className="d-flex justify-content-end mt-4 mt-md-0">
            <div className="grant-instructions mt-2 px-4 py-2 text-center daf-error-popup">
              <small>The sum of the percentages must total 100%.</small>
            </div>
          </div>
        )}
        <NavigateButtons
          onClickCancel={() => history.push({ pathname: '/fund/overview', state: { fund } })}
          onClickSubmit={handleSubmit(submit)}
          submitButtonText="Submit"
        />
      </div>
      {isPublicCharityModalOpen && (
        <PublicCharityModal
          isPublicCharityModalOpen={isPublicCharityModalOpen}
          checkedPublicCharity={checkedPublicCharity}
          unallocatedFunds={unallocatedFunds}
          saveCharity={(charity) => savePublicCharity(charity)}
          closeModal={() => {
            setIsPublicCharityModalOpen(false);
            setCheckedPublicCharity(null);
          }}
        />
      )}
    </>
  );
};

export default SunsettingDaf;
