import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Instructions from 'components/Main/Grant/Request/Instructions';
import ResetPasswordForm from './components/Form';

const ResetPassword = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isTokenExpired = urlParams.get('tokenExpired');

  return (
    <>
      {isTokenExpired !== 'true' && (
        <>
          <Instructions title="Reset Password" hideStepCount className="reset-password-instruction">
            <div>
              <div className="first-sentence">
                If you have forgotten your password, please enter your username and we will send you
                a password reset link to your email address from dtgrants@donorstrust.org.
              </div>
              Click on the link in the email and you will be taken to a page where you can create a
              new password.
            </div>
          </Instructions>
        </>
      )}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row">
              {isTokenExpired === 'true' && (
                <>
                  <div className="expired-instruction mx-auto mb-4 px-3">
                    <div className="first-sentence">
                      <span className="subtitle " style={{ maxWidth: '500px' }}>
                        You have selected a link that is no longer valid.
                      </span>
                    </div>
                    <span className="subtitle" style={{ maxWidth: '500px' }}>
                      Enter your username and we will email you a new link to reset your password
                      from dtgrants@donorstrust.org
                    </span>
                  </div>
                </>
              )}
              <div className="col col-md-6 offset-md-3">
                <ResetPasswordForm />
                {isTokenExpired !== 'true' && (
                  <div className="d-block text-black w-100 text-center mt-3">
                    Back to&nbsp;
                    <Link to="/login">
                      <u>Login</u>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
