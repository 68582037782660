import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBoxWithLabel = ({ register, name, id, label }) => {
  return (
    <div className="d-flex align-items-start">
      <input type="checkbox" className="mt-1" id={id} {...register(name)} />
      <Form.Label htmlFor={id} className="ml-3 mb-0">
        {label}
      </Form.Label>
    </div>
  );
};

export default CheckBoxWithLabel;
