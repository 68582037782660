import React from 'react';

export const FundTransactionsHistoryNoResults = () => {
  return (
    <div className="container my-5">
      <div className="col-md-4 offset-md-4 text-center">
        <div className="no-results-icon text-muted my-md-4">
          <i className="bi bi-search" />
        </div>
        <div>
          <strong>Sorry, we can&apos;t find records on your request</strong>
        </div>
        <div className="text-muted my-3">
          <strong>Try to change filter criteria</strong>
        </div>
      </div>
    </div>
  );
};

export const FundTransactionsHistoryNoDate = () => {
  return (
    <div className="container my-5">
      <div className="col-md-8 offset-md-2 text-center">
        <div className="no-results-icon text-muted my-md-4">
          <i className="bi bi-search" />
        </div>
        <div>
          <strong>There are no records to display.</strong>
        </div>
        <div className="text-muted my-3">
          <strong>
            Please select a date range from the drop-down list or enter a custom period.
          </strong>
        </div>
      </div>
    </div>
  );
};
