import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CurrencyInput from 'react-currency-input-field';
import { ApiContext } from 'components/ApiProvider';
import { AcknowlegementOptions, FrequencyOptions } from 'types/grants';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import moment from 'moment';
import Instructions from 'components/Main/Grant/Request/Instructions';

export const EditRecurringGrantTableModal = ({
  isEditRecurringGrantTableModalOpened,
  closeModal,
  updateGrants,
  configureModalData,
  item,
}) => {
  const { updateRecurringGrant } = useContext(ApiContext);
  const [endDate, setEndDate] = useState(item.endDate ? new Date(item.endDate) : '');
  const DesignationValues = [
    'General operations',
    'In support of a specific project ',
    'In memory of ... ',
    'In honor of ... ',
    'Other ',
  ];

  const valueValidationMessage = 'The grant request minimum is $100 per grant request';

  const ValidationSchema = yup.object().shape({
    frequency: yup.string().required(),
    value: yup
      .number()
      .transform((curr, orig) => parseFloat(orig.replace(/,/g, '')))
      .typeError(valueValidationMessage)
      .min(100, valueValidationMessage),
    acknowledgment: yup.string().required(),
    endDate: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(),
    designation: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
    mode: 'onSubmit',
    defaultValues: {
      endDate: item.endDate ? moment(item.endDate).toDate().toLocaleDateString('en-CA') : null,
    },
  });

  const ResetEdited = () => {
    reset();
    setEndDate('');
  };

  const onSumbit = async (data) => {
    if (
      data.value !== item.value ||
      data?.endDate?.substr(0, 10) !== item.endDate?.substr(0, 10) ||
      data.frequency !== item.frequency ||
      data.acknowledgment + data.acknowledgmentInput !== item.acknowledgement ||
      data.designation + data.designationInput !== item.designation
    ) {
      await updateRecurringGrant({
        RecurringGrantId: item.recurringGrantId,
        frequency: {
          key: FrequencyOptions.find((f) => data.frequency?.includes(f.label)).value,
          value: data.frequency,
        },
        value: data.value,
        endDate: data.endDate,
        designation:
          data.designationInput && data.designation !== 'General operations'
            ? data.designation + data.designationInput
            : data.designation,
        acknowledgement: {
          key: AcknowlegementOptions.find((f) => data.acknowledgment?.includes(f.label)).value,
          value:
            data?.acknowledgmentInput && data.acknowledgment === 'Recognize Someone Else '
              ? data.acknowledgment + data?.acknowledgmentInput
              : data.acknowledgment,
        },
      })
        .then(() => {
          updateGrants();
          configureModalData({
            name: item.name,
            oldFrequency: item.frequency,
            oldValue: displayCurrency(item.value),
            oldEndDate: displayDate(item.endDate),
            oldDesignation: item.designation,
            oldAcknowledgement: item.acknowledgement,
            newFrequency: data.frequency,
            newValue: displayCurrency(data.value),
            newEndDate: displayDate(data.endDate),
            newDesignation:
              data.designation === 'General operations'
                ? data.designation
                : data.designation + data.designationInput,
            newAcknowledgement:
              data.acknowledgment === 'Recognize Someone Else '
                ? data.acknowledgment + data.acknowledgmentInput
                : data.acknowledgment,
          });
        })
        .catch(console.log);
    }
    ResetEdited();
    closeModal();
  };

  const parseValue = (value, inputName) => {
    let arrayValue;
    if (inputName === 'designation') {
      arrayValue = DesignationValues.find((f) => value?.includes(f));
    } else {
      arrayValue = AcknowlegementOptions.find((f) => value?.includes(f.label))?.label;
    }
    const inputValue = value?.replace(arrayValue, '');
    return inputValue;
  };

  return (
    <Modal
      show={isEditRecurringGrantTableModalOpened}
      className="fade edit-recurring-grant-table-modal"
      centered
      size="xl"
    >
      <Modal.Body>
        <Instructions isModal hideStepCount title="Edit Recurring Grantee" />

        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Name:</strong>
          </div>
          <div className="col-12 mt-2">
            <input
              {...register('name', { required: true })}
              type="text"
              className="edit-recurring-grant-table-modal__input"
              value={item.name}
              disabled
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Frequency</strong>
          </div>
          <div className="col-12">
            <select
              className="edit-recurring-grant-table-modal__select"
              name="frequency"
              defaultValue={item.frequency}
              {...register('frequency')}
            >
              {FrequencyOptions.map((frequency) => (
                <option key={frequency.value} value={frequency.label}>
                  {frequency.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-lef edit-recurring-grant-table-modal__label">
            <strong>Value ($)</strong>
          </div>
          <div className="col-12">
            <CurrencyInput
              className="edit-recurring-grant-table-modal__input"
              allowNegativeValue={false}
              decimalScale={2}
              decimalSeparator="."
              defaultValue={item.value}
              {...register('value')}
            />
            {errors.value && <div className="validation-alert">{errors.value?.message}</div>}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Start Date: </strong>
          </div>
          <div className="col-12">
            <input
              type="date"
              className="edit-recurring-grant-table-modal__input"
              disabled
              defaultValue={item.startDate?.substr(0, 10)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>End Date: </strong>
          </div>
          <div className="col-12">
            <DatePickerInput
              {...register('endDate')}
              minDate={
                new Date() > new Date(item?.startDate) ? new Date() : new Date(item?.startDate)
              }
              className="edit-recurring-grant-table-modal__input"
              onChange={(value) => {
                setValue(
                  'endDate',
                  value ? moment(value).toDate().toLocaleDateString('en-CA') : null
                );
                setEndDate(value);
              }}
              selected={endDate}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Created Date: </strong>
          </div>
          <div className="col-12">
            <input
              type="date"
              className="edit-recurring-grant-table-modal__input"
              disabled
              defaultValue={item.dateCreated?.substr(0, 10)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Designation: </strong>
          </div>
          <div className="col-12">
            <select
              className="edit-recurring-grant-table-modal__select"
              defaultValue={DesignationValues.find((f) => item.designation?.includes(f))}
              {...register('designation')}
            >
              {DesignationValues.map((designation) => (
                <option key={designation} value={designation}>
                  {designation}
                </option>
              ))}
            </select>
            <div className="row mt-2">
              <div className="col-12">
                <input
                  className="edit-recurring-grant-table-modal__input"
                  defaultValue={parseValue(item?.designation, 'designation')}
                  hidden={
                    watch('designation')
                      ? watch('designation').includes('General operations')
                      : item.designation?.includes('General operations')
                  }
                  type="textarea"
                  {...register('designationInput')}
                />
                {errors.designationInput && (
                  <div className="validation-alert">{errors.designationInput?.message}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left edit-recurring-grant-table-modal__label">
            <strong>Acknowledgement: </strong>
          </div>
          <div className="col-12">
            <select
              className="edit-recurring-grant-table-modal__select"
              defaultValue={
                AcknowlegementOptions.find((f) => item.acknowledgement?.includes(f.label))?.label
              }
              {...register('acknowledgment')}
            >
              {AcknowlegementOptions.map((acknowledgement) => (
                <option key={acknowledgement.value} value={acknowledgement.label}>
                  {acknowledgement.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 mt-2">
            <input
              className="edit-recurring-grant-table-modal__input"
              defaultValue={parseValue(item?.acknowledgement)}
              hidden={
                watch('acknowledgment')
                  ? !watch('acknowledgment')?.includes('Recognize Someone Else')
                  : !item.acknowledgement?.includes('Recognize Someone Else')
              }
              type="textarea"
              {...register('acknowledgmentInput')}
            />
            {errors.acknowledgmentInput && (
              <div className="validation-alert">{errors.acknowledgmentInput?.message}</div>
            )}
          </div>
        </div>
        <div className="grant-request__navigation-buttons mt-4">
          <button
            className="button button--gray px-5 py-3 mr-4 mr-md-3"
            onClick={() => {
              ResetEdited();
              closeModal();
            }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="button button--dark-blue  px-5 py-3"
            onClick={handleSubmit(onSumbit)}
            type="button"
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
