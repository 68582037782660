import React from 'react';
import { Form } from 'react-bootstrap';
import { regExp } from '../../../common/constants';

export const PrefixRadiosModal = ({ prefix, setPrefix, setValue }) => {
  return (
    <>
      <div className="mt-1">
        <strong>Prefix:</strong>
      </div>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-mister-modal"
          name="daf-prefix-mister-modal"
          value="Mr."
          checked={regExp.IS_PREFIX_MR.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-mister-modal">
          <strong>Mr.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-miss-modal"
          name="daf-prefix-miss-modal"
          value="Ms."
          checked={regExp.IS_PREFIX_MS.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-miss-modal">
          <strong>Ms.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-missis-modal"
          name="daf-prefix-missis-modal"
          value="Mrs."
          checked={regExp.IS_PREFIX_MRS.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-missis-modal">
          <strong>Mrs.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-doctor-modal"
          name="daf-prefix-doctor-modal"
          value="Dr."
          checked={regExp.IS_PREFIX_DR.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-doctor-modal">
          <strong>Dr.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-other-modal"
          name="daf-prefix-other-modal"
          value="Other"
          checked={!regExp.IS_PREFIX_OTHER.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-other-modal">
          <strong>Other:</strong>
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};
