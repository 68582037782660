/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthenticationContext } from 'auth/AuthProvider';

const SecuredRoute = (props) => {
  const { isLoggedIn } = useContext(AuthenticationContext);
  const {
    user: { isDafFunctionalityAvailable },
  } = useContext(AuthenticationContext);

  const hiddenRoutes = [
    '/daf/fundname',
    '/daf/advisors',
    '/daf/submitted',
    '/daf/sunsetting',
    '/daf/successor',
    '/daf/donor',
  ];

  const isShowDaf = isDafFunctionalityAvailable === 'True';
  const isRouteHidden = hiddenRoutes.some((route) => props.path.includes(route)) && !isShowDaf;
  if (!isLoggedIn || isRouteHidden) return <Redirect to="/login" />;

  return <Route {...props} />;
};

export default SecuredRoute;
