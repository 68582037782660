import { useEffect } from 'react';
import useLocalStorage from 'Hooks/LocalStorage';

const useCurrentFund = (fundId, fundName) => {
  const initVal =
    fundId && fundName
      ? { id: fundId, name: fundName }
      : JSON.parse(localStorage.getItem('dt-fund')) || {};
  const [currentFund, setCurrentFund] = useLocalStorage('dt-fund', initVal);

  useEffect(() => {
    if (fundId && fundName) {
      setCurrentFund({ id: fundId, name: fundName });
    }
  }, []);

  return currentFund;
};

export default useCurrentFund;
