import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { greyColor } from '../../common/constants';

const isCustomPlanText = (isCustomPlan) => {
  return isCustomPlan
    ? 'Custom Plan. I will contact DonorsTrust to discuss a custom sunset plan.'
    : 'I have indicated my sunsetting advice, below.';
};

const dafTermLimitText = (
  dafTermLimitOption,
  dafTermLimitYearsAfterDeath = '',
  dafTermLimitSpecificDate = ''
) => {
  let result = '';
  if (dafTermLimitOption === 1 || dafTermLimitYearsAfterDeath) {
    result += `I would like my account to close ${dafTermLimitYearsAfterDeath} years after the date of my death.`;
  }
  if (dafTermLimitOption === 2) {
    result += 'I would like my account to close with the death of my last successor advisor.';
  }
  if (dafTermLimitOption === 3 || dafTermLimitSpecificDate) {
    result += `I would like my account to close on a specific date (MM/DD/YYYY) ${dafTermLimitSpecificDate}`;
  }
  return result;
};

const finalDistributionText = (
  finalDestributionToWitneyBallFundPercentage,
  averageOfPastGivingPercentage,
  publicCharityDistributions
) => {
  let result = '';
  if (finalDestributionToWitneyBallFundPercentage)
    result += `Whitney Ball Memorial Fund: ${finalDestributionToWitneyBallFundPercentage}% of account remainder. \n`;

  if (averageOfPastGivingPercentage)
    result += `Follow weighted average of past giving: ${averageOfPastGivingPercentage}% of account remainder. \n`;
  if (publicCharityDistributions.length) {
    result +=
      'Distributed to the following public charities (all we need is a name and an EIN): \n';
    publicCharityDistributions.forEach(({ charityName, ein, distributionPercentage }) => {
      result += '\n';
      result += `${charityName} \n`;
      result += `${ein} \n`;
      result += `${distributionPercentage}% of account \n`;
    });
  }
  return result;
};

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
      padding: '0 10',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = ({
  isCustomPlan,
  dafTermLimitOption,
  dafTermLimitYearsAfterDeath,
  dafTermLimitSpecificDate,
  publicCharityDistributions,
  percentages,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>
            Sunsetting of donor-advised fund account and final distribution elections
          </Text>
          {isCustomPlan && (
            <>
              <Text style={{ textAlign: 'left', marginBottom: 5 }}>
                Account Sunsetting. Accounting sunsetting at DonorsTrust requires two decisions:
                when would you like your account to end and how would you like the remaining balance
                in the account distributed. Either contact us to construct a customized memo of
                understanding (MoU) that addresses these two decisions, or provide the information
                requested in the spaces below.
              </Text>
              <Table>
                <TableRow>
                  <TableCell color={greyColor}>Old</TableCell>
                  <TableCell color={greyColor}>New</TableCell>
                </TableRow>
              </Table>
              <Table>
                <TableRow>
                  <TableCell>Account sunsetting plan</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{isCustomPlanText(isCustomPlan.old)}</TableCell>
                  <TableCell>{isCustomPlanText(isCustomPlan.new)}</TableCell>
                </TableRow>
              </Table>
            </>
          )}
          {!isCustomPlan && (
            <Table>
              <TableRow>
                <TableCell color={greyColor}>Old</TableCell>
                <TableCell color={greyColor}>New</TableCell>
              </TableRow>
            </Table>
          )}
          {(dafTermLimitOption || dafTermLimitSpecificDate || dafTermLimitYearsAfterDeath) && (
            <>
              <Text style={{ textAlign: 'left', marginBottom: 5 }}>
                Term Limit of Donor-Advised Fund. How long should your fund continue to make grants
                after the death of all original account holders (up to 25 years)?
              </Text>
              <Table>
                <TableRow>
                  <TableCell>Term Limit of Donor-Advised Fund</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="left">
                    {dafTermLimitText(
                      dafTermLimitOption?.old,
                      dafTermLimitYearsAfterDeath?.old || '',
                      dafTermLimitSpecificDate?.old || ''
                    )}
                  </TableCell>
                  <TableCell textAlign="left">
                    {dafTermLimitText(
                      dafTermLimitOption?.new,
                      dafTermLimitYearsAfterDeath?.new || '',
                      dafTermLimitSpecificDate?.new || ''
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </>
          )}
          {(percentages || publicCharityDistributions) && (
            <>
              <Text style={{ textAlign: 'left', marginBottom: 5 }}>
                Final distributions. Upon your death, disbursements will continue from your account
                until the end of the defined term limit of your account as stated above.
                Disbursements will follow your stated donor intent or, if that is undefined, will
                follow a 24-month weighted average of past giving from your account. At the end of
                the account’s term, you may indicate that any remaining balance be transferred to
                one or more qualified public charities or to the Whitney Ball Memorial Fund at
                DonorsTrust, which supports continued growth of DonorsTrust and the broader liberty
                movement. The DonorsTrust Board of Directors will allocate resources only to those
                charities that are in keeping with your principles and DonorsTrust’s purposes. If
                you do not specify a beneficiary by the date of your fund’s termination, the
                remainder of your donor-advised fund will go to the Whitney Ball Memorial Fund when
                your account reaches its term limit. The sum of the percentages must total 100%
              </Text>
              <Table>
                <TableRow>
                  <TableCell>Final distributions</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="left">
                    {finalDistributionText(
                      percentages?.old?.finalDestributionToWitneyBallFundPercentage || '',
                      percentages?.old?.averageOfPastGivingPercentage || '',
                      percentages?.old?.publicCharityDistributions || []
                    )}
                  </TableCell>
                  <TableCell textAlign="left">
                    {finalDistributionText(
                      percentages?.new?.finalDestributionToWitneyBallFundPercentage || '',
                      percentages?.new?.averageOfPastGivingPercentage || '',
                      percentages?.new?.publicCharityDistributions || []
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};
