import { AchContributionAllocationProvider } from 'Providers/AchContributionAllocation';
import { useLocation } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { ApiContext } from 'components/ApiProvider';
import React, { useEffect, useContext, useState } from 'react';
import Navigation from './Navigation/Navigation';
import { InvestContribution } from './Steps/InvestContribution';
import { FinishInvestment } from './Steps/FinishInvestment';
import RecurringAchContributionAllocationSubmitted from './RecurringAchContributionAllocationSubmitted';

const AchContributionAllocationMain = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const fundId = urlParams.get('fundId');
  const userId = urlParams.get('userId');
  const contributionAmount = urlParams.get('amount') / 100;
  const fees = urlParams.get('fees') / 100;
  const contributionId = urlParams.get('donationId');
  const firstName = urlParams.get('first_name');
  const lastName = urlParams.get('last_name');
  const isRecurring = urlParams.get('recurring');
  const frequency = urlParams.get('frequency');
  const paymentLastDigits = urlParams.get('last_digits');
  const { getFund } = useContext(ApiContext);
  const [fund, setFund] = useState(null);
  const isValidData = fund && contributionAmount;

  useEffect(() => {
    async function getFundInfo() {
      setFund(await getFund(fundId));
    }

    getFundInfo();
  }, [fundId]);

  if (isRecurring === 'true' && isValidData) {
    return (
      <RecurringAchContributionAllocationSubmitted
        contributionAmount={contributionAmount}
        fee={fees}
        fullName={`${firstName} ${lastName}`}
        frequency={frequency}
        paymentLastDigits={paymentLastDigits}
        fund={fund}
      />
    );
  }

  if (isValidData) {
    return (
      <AchContributionAllocationProvider
        fund={fund}
        contributorId={userId}
        achContributionId={contributionId}
        contributionAmount={contributionAmount}
        fee={fees}
        accountName={`${firstName} ${lastName}`}
      >
        <StepWizard
          nav={
            <Navigation
              fund={fund}
              contributionAmount={contributionAmount}
              fee={fees}
              accountName={`${firstName} ${lastName}`}
              paymentLastDigits={paymentLastDigits}
            />
          }
          transitions={{}}
          className="grant-request mt-n3 mt-md-0"
          isHashEnabled
        >
          <InvestContribution fund={fund} contributionAmount={contributionAmount} fee={fees} />
          <FinishInvestment
            fund={fund}
            contributionAmount={contributionAmount}
            fee={fees}
            paymentLastDigits={paymentLastDigits}
            fullName={`${firstName} ${lastName}`}
          />
        </StepWizard>
      </AchContributionAllocationProvider>
    );
  }

  return null;
};

export default AchContributionAllocationMain;
