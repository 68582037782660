import React, { useState, useRef } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

const Typeahead = ({ asyncSearchFunc, onSelect, placeholder, newSelectionLabel, labelKey, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  // const [selected, setSelected] = useState([]);
  const inputRef = useRef();

  const search = async (query) => {
    try {
      setIsLoading(true);
      const data = await asyncSearchFunc(query);

      setResults(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const selectHandler = (items) => {
    onSelect(items[0]);
    // const removed = selected.filter((s) => !items.includes(s));
    // const added = items.filter((i) => !selected.includes(i));
    // onRemove(removed[0]?.id);
    // onSelect(added[0]);
    inputRef.current.clear();
  };

  return (
    <AsyncTypeahead
      allowNew
      filterBy={() => true}
      isLoading={isLoading}
      id={id}
      labelKey={labelKey}
      minLength={3}
      onSearch={search}
      options={results}
      newSelectionPrefix={newSelectionLabel}
      placeholder={placeholder}
      onChange={selectHandler}
      ref={inputRef}
      className="typeahead"
      renderMenuItemChildren={(option, props) => (
        <div className="typeahead__options">
          <div className="typeahead__options-name">
            <Highlighter search={props.text}>{option.name}</Highlighter>
          </div>
          <div className="text-muted">{option.ein}</div>
        </div>
      )}
    />
  );
};

export default Typeahead;
