import React, { useState, useContext } from 'react';
import { displayDate } from 'util/dates';
import { ApiContext } from 'components/ApiProvider';
import { displayCurrency } from 'util/money';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AcknowlegementOptions, FrequencyOptions } from 'types/grants';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import moment from 'moment';
import { EditRecurringGrantTableModal } from './EditRecurringGrantTableModal';

const RecurringGrantsTable = ({ grants, updateGrants, configureModalData, short, editAbility }) => {
  if (!grants.length) return null;
  const ascSorting = 'asc';
  const descSorting = 'desc';
  const { updateRecurringGrant } = useContext(ApiContext);
  const [isEditRecurringGrantTableModalOpened, setIsModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [sortedGrants, setSortedGrants] = useState(grants);
  const [sortingConfig, setSortingConfig] = useState({ key: 'name', direction: ascSorting });

  let isEditable = true;
  const DesignationValues = [
    'General operations',
    'In support of a specific project ',
    'In memory of ... ',
    'In honor of ... ',
    'Other ',
  ];
  const valueValidationMessage = 'The grant request minimum is $100 per grant request';

  const ValidationSchema = yup.object().shape({
    frequency: yup.string().required(),
    value: yup
      .number()
      .transform((curr, orig) => parseFloat(orig.replace(/,/g, '')))
      .typeError(valueValidationMessage)
      .min(100, valueValidationMessage),
    acknowledgment: yup.string().required(),
    endDate: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(),
    acknowledgmentInput: yup
      .string()
      .nullable()
      .when('acknowledgment', {
        is: 'Recognize Someone Else ',
        then: yup.string().required('Required field'),
      })
      .when('acknowledgment', {
        is: (acknowledgment) =>
          acknowledgment && !acknowledgment.includes('Recognize Someone Else'),
        then: yup
          .string()
          .nullable()
          .transform(() => null),
      }),
    designation: yup.string().required(),
    designationInput: yup
      .string()
      .when('designation', {
        is: (designation) => designation && !designation.includes('General operations'),
        then: yup.string().required('Required field'),
      })
      .when('designation', {
        is: (designation) => designation && designation.includes('General operations'),
        then: yup
          .string()
          .nullable()
          .transform(() => null),
      }),
  });

  const configureSorting = (key) => {
    const direction =
      sortingConfig && sortingConfig.key === key && sortingConfig.direction === descSorting
        ? ascSorting
        : descSorting;
    setSortingConfig({ key, direction });

    return direction;
  };

  const sort = (sortingField) => {
    if (sortedGrants && !short) {
      const sortingDirection = configureSorting(sortingField);
      let newSortedGrantsArray = [];
      if (sortingDirection === ascSorting) {
        newSortedGrantsArray = [...sortedGrants].sort((a, b) => {
          return a[sortingField].localeCompare(b[sortingField]);
        });
      } else {
        newSortedGrantsArray = [...sortedGrants].sort((a, b) => {
          return b[sortingField].localeCompare(a[sortingField]);
        });
      }

      setSortedGrants(newSortedGrantsArray);
    }
  };

  const TableRow = ({ item }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [endDate, setEndDate] = useState(item.endDate ? new Date(item.endDate) : '');

    const {
      register,
      handleSubmit,
      reset,
      watch,
      setValue,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(ValidationSchema),
      mode: 'onSubmit',
      defaultValues: {
        endDate: item.endDate ? moment(item.endDate).toDate().toLocaleDateString('en-CA') : null,
      },
    });

    const ResetEdited = () => {
      setIsEdit(false);
      setEndDate('');
      isEditable = true;
      reset();
    };

    const onSumbit = async (data) => {
      if (
        data.value !== item.value ||
        data.endDate?.substr(0, 10) !== item.endDate?.substr(0, 10) ||
        data.frequency !== item.frequency ||
        data.acknowledgment + data.acknowledgmentInput !== item.acknowledgement ||
        data.designation + data.designationInput !== item.designation
      ) {
        await updateRecurringGrant({
          RecurringGrantId: item.recurringGrantId,
          frequency: {
            key: FrequencyOptions.find((f) => data.frequency?.includes(f.label)).value,
            value: data.frequency,
          },
          value: data.value,
          endDate: data.endDate,
          designation:
            data.designationInput && data.designation !== 'General operations'
              ? data.designation + data.designationInput
              : data.designation,
          acknowledgement: {
            key: AcknowlegementOptions.find((f) => data.acknowledgment?.includes(f.label)).value,
            value:
              data?.acknowledgmentInput && data.acknowledgment === 'Recognize Someone Else '
                ? data.acknowledgment + data?.acknowledgmentInput
                : data.acknowledgment,
          },
        })
          .then(() => {
            updateGrants();
            configureModalData({
              name: item.name,
              oldFrequency: item.frequency,
              oldValue: displayCurrency(item.value),
              oldEndDate: displayDate(item.endDate),
              oldDesignation: item.designation,
              oldAcknowledgement: item.acknowledgement,
              newFrequency: data.frequency,
              newValue: displayCurrency(data.value),
              newEndDate: displayDate(data.endDate),
              newDesignation:
                data.designation === 'General operations'
                  ? data.designation
                  : data.designation + data.designationInput,
              newAcknowledgement:
                data.acknowledgment === 'Recognize Someone Else '
                  ? data.acknowledgment + data.acknowledgmentInput
                  : data.acknowledgment,
            });
          })
          .catch(console.log);
      }
      ResetEdited();
    };

    const parseValue = (value, inputName) => {
      let arrayValue;
      if (inputName === 'designation') {
        arrayValue = DesignationValues.find((f) => value?.includes(f));
      } else {
        arrayValue = AcknowlegementOptions.find((f) => value?.includes(f.label))?.label;
      }
      const inputValue = value?.replace(arrayValue, '');
      return inputValue;
    };

    return (
      <>
        {isEdit === false ? (
          <tr>
            <td className="review-recurring-grants__table-name">{item.name}</td>
            <td className="review-recurring-grants__table-frequency text-md-left">
              {item.frequency}
            </td>
            <td className="review-recurring-grants__table-value text-right text-md-right">
              {displayCurrency(item.value)}
            </td>
            <td className="review-recurring-grants__table-start text-right">
              {displayDate(item.startDate)}
            </td>
            <td className="review-recurring-grants__table-end text-right">
              {displayDate(item.endDate)}
            </td>
            {!short && (
              <>
                <td className="review-recurring-grants__table-created text-right pr-3">
                  {displayDate(item.dateCreated)}
                </td>
                <td className="review-recurring-grants__table-designation">{item.designation}</td>
                <td className="review-recurring-grants__table-acknowledgement">
                  {item.acknowledgement}
                </td>
              </>
            )}
            {editAbility && (
              <td>
                <button
                  type="button"
                  className="mx-3 border-0 recurring-grants-icon_desktop"
                  onClick={() => {
                    if (isEditable) {
                      setIsEdit(true);
                      isEditable = false;
                    }
                  }}
                >
                  <i className="bi bi-pencil-fill" />
                </button>
                <button
                  type="button"
                  className="mx-3 border-0 recurring-grants-icon_mobile"
                  onClick={() => {
                    setIsModal(true);
                    setModalData(item);
                  }}
                >
                  <i className="bi bi-pencil-fill" />
                </button>
              </td>
            )}
          </tr>
        ) : (
          <tr>
            <td>{item.name}</td>
            <td>
              <select
                className="form-select recurring-grants-input"
                name="frequency"
                defaultValue={item.frequency}
                {...register('frequency')}
              >
                {FrequencyOptions.map((frequency) => (
                  <option key={frequency.value} value={frequency.label}>
                    {frequency.label}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <CurrencyInput
                className="mx-1 recurring-grants-input w-75"
                allowNegativeValue={false}
                decimalScale={2}
                decimalSeparator="."
                defaultValue={item.value}
                {...register('value')}
              />
              {errors.value && <div className="validation-alert">{errors.value?.message}</div>}
            </td>
            <td className="text-center">{displayDate(item.startDate)}</td>
            <td>
              <DatePickerInput
                {...register('endDate')}
                minDate={
                  new Date() > new Date(item?.startDate) ? new Date() : new Date(item?.startDate)
                }
                className="recurring-grants-input"
                onChange={(value) => {
                  setValue(
                    'endDate',
                    value ? moment(value).toDate().toLocaleDateString('en-CA') : null
                  );
                  setEndDate(value);
                }}
                selected={endDate}
              />
            </td>
            <td className="text-center">{displayDate(item.dateCreated)}</td>
            <td>
              <div>
                <select
                  className="form-select recurring-grants-input w-75"
                  defaultValue={DesignationValues.find((f) => item.designation?.includes(f))}
                  {...register('designation')}
                >
                  {DesignationValues.map((designation) => (
                    <option key={designation} value={designation}>
                      {designation}
                    </option>
                  ))}
                </select>
                <input
                  className="mt-1 recurring-grants-input w-75"
                  defaultValue={parseValue(item?.designation, 'designation')}
                  hidden={
                    watch('designation')
                      ? watch('designation').includes('General operations')
                      : item.designation?.includes('General operations')
                  }
                  type="textarea"
                  {...register('designationInput')}
                />
                {errors.designationInput && (
                  <div className="validation-alert">{errors.designationInput?.message}</div>
                )}
              </div>
            </td>
            <td>
              <div>
                <select
                  className="form-select recurring-grants-input w-75"
                  defaultValue={
                    AcknowlegementOptions.find((f) => item.acknowledgement?.includes(f.label))
                      ?.label
                  }
                  {...register('acknowledgment')}
                >
                  {AcknowlegementOptions.map((acknowledgement) => (
                    <option key={acknowledgement.value} value={acknowledgement.label}>
                      {acknowledgement.label}
                    </option>
                  ))}
                </select>
                <input
                  className="mt-1 recurring-grants-input w-75"
                  defaultValue={parseValue(item?.acknowledgement)}
                  hidden={
                    watch('acknowledgment')
                      ? !watch('acknowledgment')?.includes('Recognize Someone Else')
                      : !item.acknowledgement?.includes('Recognize Someone Else')
                  }
                  type="textarea"
                  {...register('acknowledgmentInput')}
                />
                {errors.acknowledgmentInput && (
                  <div className="validation-alert">{errors.acknowledgmentInput?.message}</div>
                )}
              </div>
            </td>
            <td className="review-recurring-grants__extended-edit">
              <button
                type="button"
                className="mx-md-3 my-md-3 border-0"
                onClick={handleSubmit(onSumbit)}
              >
                <i className="bi bi-device-ssd-fill" />
              </button>
              <button
                type="button"
                className="mx-md-3 my-md-3 recurring-cancel border-0"
                onClick={ResetEdited}
              >
                Cancel
              </button>
            </td>
          </tr>
        )}
      </>
    );
  };

  const SortArrow = ({ field }) => {
    return (
      <>
        {sortedGrants ? (
          <>
            {sortingConfig?.key === field ? (
              <i
                className={`bi text-blue ${
                  sortingConfig?.direction === ascSorting
                    ? 'bi-caret-up-fill'
                    : 'bi-caret-down-fill'
                } ml-2`}
                role="button"
                aria-label="order"
                tabIndex={0}
              />
            ) : (
              <i className="bi bi-caret-down-fill ml-2" />
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isEditRecurringGrantTableModalOpened && (
        <EditRecurringGrantTableModal
          isEditRecurringGrantTableModalOpened={isEditRecurringGrantTableModalOpened}
          closeModal={() => setIsModal(false)}
          item={modalData}
          updateGrants={updateGrants}
          configureModalData={configureModalData}
        />
      )}
      <div className="recurring-grants-table">
        <table className="table funds-table">
          <thead>
            <tr>
              <th className="width-15" onClick={() => sort('name')}>
                Name
                {!short && <SortArrow field="name" />}
              </th>
              <th className="width-10 text-md-left" onClick={() => sort('frequency')}>
                Frequency
                {!short && <SortArrow field="frequency" />}
              </th>
              <th className="width-15 text-md-right">Value ($)</th>
              <th className="width-15 text-right">Start Date</th>
              <th className="width-15 text-right">End Date</th>
              {!short && (
                <>
                  <th className="width-15 text-right pr-3">Created Date</th>
                  <th className="width-18">Designation</th>
                  <th className="width-18">Acknowledgement</th>
                  <th> </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedGrants.map((item) => (
              <TableRow key={item.recurringGrantId} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecurringGrantsTable;
