import { ApiContext } from 'components/ApiProvider';
import React, { useContext, useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-maskedinput';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Dropdown } from 'react-bootstrap';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { SelectWithSearchInDropdown } from 'components/Common/SelectWithSearchInDropdown';
import { DownloadPdf } from './TransactionsHistoryPdf';

export const substractDaysFromToday = (daysQuantity) => {
  const date = new Date();
  date.setDate(date.getDate() - daysQuantity);

  return date;
};

export const subtractMonths = (monthsQuantity) => {
  const date = new Date();
  date.setDate(date.getDate());
  date.setMonth(date.getMonth() - monthsQuantity);

  return date;
};

const currentDateMinusMonth = subtractMonths(1);

const TRANSACTIONS_PERIODS = [
  { label: '10 days', value: substractDaysFromToday(10) },
  { label: '1 month', value: subtractMonths(1) },
  { label: '3 months', value: subtractMonths(3) },
  { label: '6 months', value: subtractMonths(6) },
  { label: '12 months', value: subtractMonths(12) },
  { label: 'Custom period', value: 'custom' },
];

export const FundTransactionsHistoryFilter = ({
  fundId,
  poolId,
  poolName,
  filteredTransactions,
  setFilteredTransactions,
  setSortConfig,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
}) => {
  const [isDownloadDropdownOpened, setIsDownloadDropdownOpened] = useState(false);
  const [subtypes, setSubtypes] = useState([]);
  const [transactionsHistory, setTransactionsHistory] = useState([]);
  const [selectedSubtypes, setSelectedSubtypes] = useState([]);
  const [dateFromPlaceholder, setDateFromPlaceholder] = useState('mm/dd/yyyy');
  const [dateToPlaceholder, setDateToPlaceholder] = useState('mm/dd/yyyy');
  const minFilterDate = new Date(2020, 0, 1);
  const maxFilterDate = substractDaysFromToday(1);

  const { getTransactionsHistory, getTransactionHistoryXlsx, getTransactionSubtypes } =
    useContext(ApiContext);
  const modalRef = useRef(null);
  const selectRef = useRef(null);

  useOnClickOutside(modalRef, () => setIsDownloadDropdownOpened(false));

  useEffect(() => {
    const getSubTypes = async () => {
      const subtypesList = await getTransactionSubtypes();
      setSubtypes(subtypesList);
    };
    getSubTypes();
  }, []);

  useEffect(() => {
    if (dateFrom || dateTo) {
      setSortConfig();
      const getTransactions = async () => {
        const data = await getTransactionsHistory({
          fundId,
          poolId,
          dateFrom,
          dateTo,
          transactionSubtypes: [],
        });
        setTransactionsHistory(data);
      };
      getTransactions();
      if (!dateFrom) {
        setDateFromPlaceholder('01/01/2020');
      }

      if (!dateTo) {
        setDateToPlaceholder(
          maxFilterDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          })
        );
      }
    } else {
      setDateFromPlaceholder('mm/dd/yyyy');
      setDateToPlaceholder('mm/dd/yyyy');
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (selectedSubtypes.length) {
      const filteredData = transactionsHistory.filter((t) => {
        return selectedSubtypes.map((subtype) => subtype.value).includes(t.subtype);
      });
      setFilteredTransactions(filteredData);
    } else {
      setFilteredTransactions(transactionsHistory);
    }
  }, [transactionsHistory, selectedSubtypes]);

  const buildGranteeHistoryExcelAsync = async () => {
    try {
      const data = await getTransactionHistoryXlsx({
        fundId,
        poolId,
        dateFrom,
        dateTo,
        transactionSubtypes: selectedSubtypes.map((subtype) => subtype.key),
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data.data);
      link.download = data.filename;
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container transactions-filter">
      <div className="px-4 px-md-2 p-3 p-md-2 d-flex flex-column flex-md-row justify-content-between align-items-md-center filter-wrapper">
        <Form.Label
          htmlFor="fund-transactions-history-select-period"
          className="d-flex flex-column align-items-start mb-0"
        >
          <div>
            <strong>Select period</strong>
          </div>
          <Form.Control
            as="select"
            className="mt-1 transactions-select"
            id="fund-transactions-history-select-period"
            onChange={(v) => {
              if (v.target.value === 'custom') {
                setDateFrom(null);
                setDateTo(null);
                setTransactionsHistory([]);
              } else {
                setDateFrom(new Date(v.target.value));
                setDateTo(substractDaysFromToday(1));
              }
            }}
            defaultValue={currentDateMinusMonth}
            ref={selectRef}
          >
            {TRANSACTIONS_PERIODS.map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Label>
        <Form.Label
          htmlFor="fund-transactions-subtypes-filter"
          className="d-flex flex-column align-items-start mb-0 mt-3 mt-md-0"
        >
          <div>
            <strong>Filter by Subtype</strong>
          </div>
          <SelectWithSearchInDropdown
            className="mt-1"
            defaultItems={subtypes}
            setSelectedSubtypes={(transactionSubtypes) => setSelectedSubtypes(transactionSubtypes)}
          />
        </Form.Label>
        <div className="d-flex flex-column flex-md-row">
          <Form.Label
            htmlFor="fund-transactions-history-start-date"
            className="d-flex flex-column align-items-start mb-0 mt-3 mt-md-0"
            onClick={(e) => e.preventDefault()}
          >
            <div>
              <strong>From</strong>
            </div>
            <DatePicker
              selected={dateFrom}
              onChange={(e) => {
                if (selectRef.current.value !== 'custom') {
                  setDateTo(null);
                }

                setDateFrom(e);
                selectRef.current.value = 'custom';
              }}
              onSelect={(e) => {
                if (selectRef.current.value !== 'custom') {
                  setDateTo(null);
                }

                setDateFrom(e);
                selectRef.current.value = 'custom';
              }}
              minDate={minFilterDate}
              maxDate={maxFilterDate && (dateTo || maxFilterDate)}
              id="fund-transactions-history-start-date"
              className="mt-1 transactions-datepicker datepicker"
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={30}
              placeholderText={dateFromPlaceholder}
              customInput={<MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />}
              autoComplete="off"
            />
          </Form.Label>
          <Form.Label
            htmlFor="fund-transactions-history-end-date"
            className="d-flex flex-column align-items-start mt-3 mt-md-0 ml-md-5 mb-0"
            onClick={(e) => e.preventDefault()}
          >
            <div>
              <strong>To</strong>
            </div>
            <DatePicker
              selected={dateTo}
              onChange={(e) => {
                if (selectRef.current.value !== 'custom') {
                  setDateFrom(null);
                }

                setDateTo(e);
                selectRef.current.value = 'custom';
              }}
              onSelect={(e) => {
                if (selectRef.current.value !== 'custom') {
                  setDateFrom(null);
                }

                setDateTo(e);
                selectRef.current.value = 'custom';
              }}
              minDate={minFilterDate && (dateFrom || minFilterDate)}
              maxDate={maxFilterDate}
              id="fund-transactions-history-start-date"
              className="mt-1 transactions-datepicker datepicker"
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={30}
              placeholderText={dateToPlaceholder}
              customInput={<MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" />}
              autoComplete="off"
            />
          </Form.Label>
        </div>
        <Dropdown ref={modalRef} show={isDownloadDropdownOpened} className="d-none d-md-block">
          <button
            type="button"
            className="button transaction-dropdown"
            onClick={() => setIsDownloadDropdownOpened(!isDownloadDropdownOpened)}
            disabled={filteredTransactions.length === 0}
          >
            <i className=" bi bi-download download-icon" />
          </button>
          {isDownloadDropdownOpened && (
            <div
              className={
                isDownloadDropdownOpened
                  ? 'dropdown-menu dropdown-menu__active'
                  : 'dropdown-menu dropdown-menu__inactive'
              }
            >
              <div className="row mx-1">
                <small className="col text-center px-0">Choose the format type</small>
              </div>
              <div className="row m-1 mh-100">
                <div className="col text-center">
                  <button type="button" className="m-0 border-0 btn btn-link btn-lg p-0">
                    <DownloadPdf
                      transactionsHistory={filteredTransactions}
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                      poolName={poolName}
                    />
                  </button>
                </div>
                <div className="col text-center">
                  <button
                    type="button"
                    className="m-0 border-0 btn btn-link btn-lg p-0"
                    onClick={() => buildGranteeHistoryExcelAsync()}
                  >
                    <i className="bi bi-filetype-xls text-success" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </Dropdown>
      </div>
    </div>
  );
};
