import React from 'react';

export const NavigateButtons = ({
  onClickCancel,
  onClickSubmit,
  submitButtonText,
  disabledSubmit = false,
}) => {
  return (
    <div className="d-flex mt-5 justify-content-end">
      <button type="button" className="button button--gray py-3 px-5" onClick={onClickCancel}>
        Cancel
      </button>
      <button
        type="button"
        className="button button--dark-blue py-3 px-5 ml-3"
        onClick={onClickSubmit}
        disabled={disabledSubmit}
      >
        {submitButtonText}
      </button>
    </div>
  );
};
