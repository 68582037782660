import React, { useState, useContext, useEffect, useRef } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { ApiContext } from 'components/ApiProvider';
import { displayDate } from 'util/dates';
import { displayCurrency } from 'util/money';
import { useForm } from 'react-hook-form';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import Instructions from '../../Grant/Request/Instructions';
import { DownloadPdf } from '../GranteeHistoryPdf';

const GranteeHistory = ({ isGranteeHistoryModalOpened, closeModal, fundId }) => {
  const { getGranteesByFundId, getGranteeHistory, buildGranteeHistoryExcel } =
    useContext(ApiContext);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [grantees, setGrantees] = useState([]);
  const [isSecondPeriod, setIsSecondPeriod] = useState(true);
  const [selectedGrantees, setSelectedGrantees] = useState([]);
  const minFilterDate = new Date(2001, 0, 1);
  const [maxFilterDate, setMaxFilterDate] = useState(null);
  const [firstPeriodStartDate, setFirstPeriodStartDate] = useState('');
  const [firstPeriodEndDate, setFirstPeriodEndDate] = useState('');
  const [secondPeriodStartDate, setSecondPeriodStartDate] = useState('');
  const [secondPeriodEndDate, setSecondPeriodEndDate] = useState('');
  const [filterModel, setFilterModel] = useState(null);
  const [isFilterSubmitted, setIsFilterSubmitted] = useState(false);
  const [granteeHitoryResponse, setGranteeHistoryResponse] = useState([]);
  const [isDownloadDropdownOpened, setIsDownloadDropdownOpened] = useState(false);
  const pdfModalRef = useRef(null);
  useOnClickOutside(pdfModalRef, () => setIsDownloadDropdownOpened(false));

  const substractYearsFromYesterday = (yearsNumber) => {
    const oneDay = 1;
    const date = new Date();
    date.setDate(date.getDate() - oneDay);
    date.setFullYear(date.getFullYear() - yearsNumber);

    return date;
  };

  const substractYearsFromToday = (yearsNumber) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - yearsNumber);

    return date;
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstPeriodStartDate: substractYearsFromToday(1),
    },
  });

  const loadGranteesAsync = async () => {
    const responseData = await getGranteesByFundId(fundId);
    setGrantees(
      responseData.map((g) => {
        return { label: g.value, value: g.key };
      })
    );

    return responseData;
  };

  const clearFilters = () => {
    setSelectedGrantees([]);
    setFirstPeriodStartDate(substractYearsFromToday(1));
    setFirstPeriodEndDate(substractYearsFromYesterday(0));
    setSecondPeriodStartDate(substractYearsFromToday(2));
    setSecondPeriodEndDate(substractYearsFromYesterday(1));
    setIsFilterSubmitted(false);
    reset();
  };

  const getGranteeHistoryAsync = async () => {
    const responseData = await getGranteeHistory(filterModel);
    setGranteeHistoryResponse(responseData);
  };

  const buildGranteeHistoryExcelAsync = async () => {
    try {
      const data = await buildGranteeHistoryExcel(filterModel);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data.data);
      link.download = data.filename;
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

  const submit = () => {
    setIsSecondPeriod(Boolean(secondPeriodStartDate));
    setFilterModel({
      granteeIds: selectedGrantees.map((g) => g.value),
      firstPeriodStartDate,
      firstPeriodEndDate: firstPeriodEndDate === '' ? null : firstPeriodEndDate,
      secondPeriodStartDate: secondPeriodStartDate === '' ? null : secondPeriodStartDate,
      secondPeriodEndDate: secondPeriodEndDate === '' ? null : secondPeriodEndDate,
      fundId,
    });
    setIsFilterSubmitted(true);
  };

  useEffect(() => {
    setIsModalOpened(isGranteeHistoryModalOpened);
  }, [isGranteeHistoryModalOpened]);

  useEffect(() => {
    if (isModalOpened) {
      loadGranteesAsync();
      setMaxFilterDate(substractYearsFromYesterday(0));
      setFirstPeriodStartDate(substractYearsFromToday(1));
      setFirstPeriodEndDate(substractYearsFromYesterday(0));
      setSecondPeriodStartDate(substractYearsFromToday(2));
      setSecondPeriodEndDate(substractYearsFromYesterday(1));
    }
    return () => {
      if (isModalOpened) {
        clearFilters();
      }
    };
  }, [isModalOpened]);

  useEffect(() => {
    if (filterModel) {
      getGranteeHistoryAsync();
    }
  }, [filterModel]);

  return (
    <Modal show={isGranteeHistoryModalOpened} className="grantee-history-modal" centered size="xl">
      <Modal.Body>
        <div className="container border-bottom mb-4">
          <Instructions isModal hideStepCount title="Grantee History">
            <div>
              Set filters below to see an amount of grants to the selected grantees during the
              selected periods.
            </div>
          </Instructions>
          <div className="row no-gutters mx-1 mb-4">
            <strong className="d-inline-flex col-12 col-md-auto align-items-center mt-2 mt-md-0 mr-4 grantee-history-modal_label">
              Select Grantee
            </strong>
            <div className="col-12 col-md-4 mt-2 mt-md-0 ">
              <MultiSelect
                {...register('selectedGrantees', { required: true })}
                options={grantees}
                value={selectedGrantees}
                labelledBy={(o) => o.value}
                className="grantee-history-modal__multiselect"
                onChange={(e) => {
                  setSelectedGrantees(e);
                  setValue('selectedGrantees', e);
                  if (e.length) {
                    clearErrors('selectedGrantees');
                  } else {
                    setError('selectedGrantees', {
                      type: 'required',
                    });
                  }
                }}
              />
              {errors.selectedGrantees && <div className="validation-alert"> Required field </div>}
            </div>
          </div>
          <div className=" row mb-4 justify-content-center">
            <div className="col-12 col-md-4  no-gutters text-center">
              <div className="mb-4 text-left text-md-center grantee-history-modal__subtitle">
                <strong>Period 1</strong>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md  text-left text-md-right grantee-history-modal_label">
                  <strong>Select Start Date</strong>
                </div>
                <div className="col-12 col-md">
                  <DatePickerInput
                    {...register('firstPeriodStartDate', { required: true })}
                    className="grantee-history-modal__input"
                    minDate={minFilterDate}
                    maxDate={maxFilterDate && (firstPeriodEndDate || maxFilterDate)}
                    onChange={(d) => {
                      setFirstPeriodStartDate(d);
                      if (d) {
                        clearErrors('firstPeriodStartDate');
                      } else {
                        setError('firstPeriodStartDate', {
                          type: 'required',
                        });
                      }
                    }}
                    selected={firstPeriodStartDate}
                  />
                  {errors.firstPeriodStartDate && (
                    <div className="validation-alert text-left mx-auto " style={{ width: 120 }}>
                      Required field
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md text-left text-md-right grantee-history-modal_label">
                  <strong>Select End Date</strong>
                </div>
                <div className="col-12 col-md">
                  <DatePickerInput
                    className="grantee-history-modal__input"
                    minDate={minFilterDate && (firstPeriodStartDate || minFilterDate)}
                    maxDate={maxFilterDate}
                    onSelect={(d) => {
                      setFirstPeriodEndDate(d);
                    }}
                    selected={firstPeriodEndDate}
                    defaultValue={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 row align-items-center">
              <div className="col-md-12 text-left text-md-center my-4 my-md-0 px-0">
                Compares with
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="mb-4 text-left text-md-center grantee-history-modal__subtitle">
                <strong>Period 2</strong>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md text-left text-md-right grantee-history-modal_label">
                  <strong>Select Start Date</strong>
                </div>
                <div className="col-12 col-md">
                  <DatePickerInput
                    type="date"
                    className="grantee-history-modal__input"
                    minDate={minFilterDate}
                    maxDate={maxFilterDate && (secondPeriodEndDate || maxFilterDate)}
                    onChange={(d) => setSecondPeriodStartDate(d)}
                    selected={secondPeriodStartDate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md text-left text-md-right grantee-history-modal_label">
                  <strong>Select End Date</strong>
                </div>
                <div className="col-12 col-md">
                  <DatePickerInput
                    className="grantee-history-modal__input"
                    minDate={minFilterDate && (secondPeriodStartDate || minFilterDate)}
                    maxDate={maxFilterDate}
                    onChange={(d) => setSecondPeriodEndDate(d)}
                    selected={secondPeriodEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters mb-4 justify-content-between flex-nowrap justify-content-md-end">
            <button
              type="button"
              className="grantee-history-modal__reset-button button button--gray py-3  py-sm-1 px-md-4 m-md-2"
              onClick={() => clearFilters()}
            >
              Reset
            </button>
            <button
              type="button"
              className="grantee-history-modal__submit-button button button--dark-blue py-3 py-md-1 px-sm-4 ml-4 m-md-2"
              onClick={handleSubmit(submit)}
              disabled={errors.selectedGrantees || errors.firstPeriodStartDate}
            >
              Submit
            </button>
          </div>
        </div>
        {isFilterSubmitted && (
          <div className="container mb-4 mb-md-0">
            <div className="row no-gutters mb-4">
              <Dropdown
                className="order-last order-md-first"
                show={isDownloadDropdownOpened}
                ref={pdfModalRef}
              >
                <Dropdown.Toggle
                  className="d-inline-flex align-items-center history-dropdown"
                  onClick={() => setIsDownloadDropdownOpened(!isDownloadDropdownOpened)}
                >
                  <i className="bi bi-cloud-arrow-down-fill" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="row mx-1">
                    <small className="col text-center px-0">Choose the format type</small>
                  </div>
                  <div className="row m-1 mh-100">
                    <div className="col text-center">
                      <button type="button" className="m-0 border-0 btn btn-link btn-lg p-0">
                        <DownloadPdf
                          granteeHistory={granteeHitoryResponse}
                          filter={filterModel}
                          isSecondPeriod={isSecondPeriod}
                        />
                      </button>
                    </div>
                    <div className="col text-center">
                      <button
                        type="button"
                        className="m-0 border-0 btn btn-link btn-lg p-0"
                        onClick={() => buildGranteeHistoryExcelAsync()}
                      >
                        <i className="bi bi-filetype-xls text-success" />
                      </button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="col text-left text-md-center">
                <h2 className="grantee-history-modal__title">Total amount</h2>
              </div>
            </div>
            <div className="row no-gutters mb-2 mb-md-4 grant-instructions p-2 p-md-3">
              <div className="col text-left text-md-left">
                <h4>Grantee name</h4>
              </div>
              <div className="col text-left text-md-center">
                <h4>Period 1</h4>
                {`${displayDate(filterModel.firstPeriodStartDate)} - ${
                  filterModel.firstPeriodEndDate == null
                    ? 'Today'
                    : displayDate(filterModel.firstPeriodEndDate)
                }`}
              </div>
              {isSecondPeriod && (
                <>
                  <div className="col text-left text-md-center">
                    <h4>Period 2</h4>
                    {`${displayDate(filterModel.secondPeriodStartDate)} - ${
                      filterModel.secondPeriodEndDate == null
                        ? 'Today'
                        : displayDate(filterModel.secondPeriodEndDate)
                    }`}
                  </div>
                  <div className="col text-right text-md-right">
                    <h4>Difference</h4>
                  </div>
                </>
              )}
            </div>
            {granteeHitoryResponse.map((gh) => (
              <div className="row no-gutters mb-2 mb-md-4 grant-instructions p-2 p-md-3">
                <div className="col text-left text-md-left">
                  <span>{gh.grantee}</span>
                </div>
                <div className="col text-left pl-2 pl-md-0 text-md-center">
                  <span>{`$${displayCurrency(gh.firstPeriodAmount)}`}</span>
                </div>
                {isSecondPeriod && (
                  <>
                    <div className="col text-left text-md-center">
                      <span>{`$${displayCurrency(gh.secondPeriodAmount)}`}</span>
                    </div>
                    <div className="col text-right text-md-right">{`$${displayCurrency(
                      gh.firstPeriodAmount - gh.secondPeriodAmount
                    )}`}</div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="container row no-gutters justify-content-end">
          <button
            type="button"
            className="button button--dark-blue col-12 col-md-auto py-3 px-4 px-md-5"
            onClick={closeModal}
          >
            Quit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GranteeHistory;
