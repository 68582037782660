import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export const InputWithValidation = ({
  name,
  register,
  errors,
  id,
  label,
  labelWidth,
  inputWidth,
  isPhoneInput,
  control,
}) => {
  return (
    <Form.Label htmlFor={id} className={`col-${labelWidth} no-gutters d-flex align-items-start`}>
      <strong className="col-auto mt-1">{label}</strong>
      <div className={`ml-3 col-${inputWidth}`}>
        {!isPhoneInput && (
          <Form.Control
            id={id}
            className="p-1 w-100"
            {...register(name)}
            isInvalid={errors[name]}
          />
        )}
        {isPhoneInput && (
          <Controller
            name={name}
            control={control}
            className="p-1 w-100"
            render={({ field: { value: phoneValue, onChange, ...field } }) => (
              <NumberFormat
                {...field}
                value={phoneValue}
                type="tel"
                format="### ### ####"
                onValueChange={(value) => {
                  onChange(value.formattedValue);
                }}
                className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
                id={id}
              />
            )}
          />
        )}
        {errors[name] && <div className="validation-alert text-left ">{errors[name].message}</div>}
      </div>
    </Form.Label>
  );
};
