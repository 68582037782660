/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { displayCurrency, roundToCents } from 'util/money';
import { displayDate } from 'util/dates';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
      padding: '5 5',
      justifyContent: 'center',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const TransactionsHistoryPdf = ({ transactionsHistory, dateFrom, dateTo, poolName }) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>Transactions History</Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>Pool: {poolName}</Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>From: {displayDate(dateFrom)}</Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>To: {displayDate(dateTo)}</Text>
          {transactionsHistory.length ? (
            <Table>
              <TableRow>
                <TableCell textAlign="right">Date</TableCell>
                <TableCell textAlign="left">Transaction type</TableCell>
                <TableCell textAlign="left">Transaction subtype</TableCell>
                <TableCell textAlign="right">Units</TableCell>
                <TableCell textAlign="right">Amount ($)</TableCell>
              </TableRow>
              {transactionsHistory.map((transaction, i) => (
                <TableRow key={i}>
                  <TableCell textAlign="right">{displayDate(transaction.date)}</TableCell>
                  <TableCell textAlign="left">{transaction.type}</TableCell>
                  <TableCell textAlign="left">{transaction.subtype}</TableCell>
                  <TableCell textAlign="right">{roundToCents(transaction.numberOfUnits)}</TableCell>
                  <TableCell textAlign="right">
                    {displayCurrency(transaction.dollarAmount)}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          ) : (
            <Text style={{ textAlign: 'center', marginBottom: 10 }}>No records</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};
