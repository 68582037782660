import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';
import { ApiContext } from 'components/ApiProvider';
import DafDonorIntentInstruction from './Instruction/DafDonorIntentInstruction';
import { DafIntentFilesList } from './DafIntentFilesList/DafIntentFilesList';
import { Pdf } from './Pdf/Pdf';
import { NavigateButtons } from '../common/NavigateButtons/NavigateButtons';
import { DafIntentUploadFiles } from './DafIntentUploadFiles/DafIntentUploadFiles';
import { findChangedValuesDafDonorIntent, handleDataDonorIntent } from '../common/helpers';

const DafDonorIntent = () => {
  const history = useHistory();
  const { fundId } = useParams();
  const { getDafStatement, updateDafStatement, getFund } = useContext(ApiContext);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const message = useWatch({ control, name: 'message' });
  const messageMaxLength = 10000;
  const oneThousandSymbols = 1000;
  const messageTextareaEnlarged = 12;
  const messageTextareaDefault = 8;

  const [fund, setFund] = useState();
  const [files, setFiles] = useState([]);
  const [previousSettings, setPreviousSettings] = useState(null);
  const [statementFiles, setStatementFiles] = useState([]);
  const [deletedStatementFileIds, setDeletedStatementFileIds] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const data = handleDataDonorIntent(await getDafStatement(fundId));
      const fundData = await getFund(fundId);
      setFund(fundData);
      setPreviousSettings(data);
      setStatementFiles(data?.statementFiles || []);
      reset(data);
    };
    getData();
  }, []);

  const deleteStatementFile = (id) => {
    setStatementFiles((prev) => prev.filter((file) => file.id !== id));
    setDeletedStatementFileIds((prev) => prev.concat(id));
  };

  const submit = async () => {
    const deletedFiles = [...deletedStatementFileIds];
    let statementFilesSubmit = [...statementFiles];
    statementFiles.forEach((oldFile) => {
      if (files.some((newFile) => newFile.name === oldFile.fileName)) {
        statementFilesSubmit = statementFilesSubmit.filter((file) => file.id !== oldFile.id);
        deletedFiles.push(oldFile.id);
      }
    });

    const changedOptions = findChangedValuesDafDonorIntent(
      previousSettings,
      statementFilesSubmit,
      files,
      message
    );

    if (Object.keys(changedOptions).length) {
      const blobPdf = await pdf(Pdf(changedOptions)).toBlob();

      const formData = new FormData();
      formData.append('fundId', fundId);
      formData.append('message', message);
      formData.append('updatesFile', blobPdf, 'Donors intent. Mission statement.pdf');
      files.forEach((file) => {
        formData.append('newStatementFiles', file);
      });
      deletedFiles.forEach((deletedId) => {
        formData.append('deletedStatementFileIds', deletedId);
      });

      await updateDafStatement(formData)
        .then(() => {
          history.push({
            pathname: '/daf/submitted',
            state: { startingPage: 'dafDonorIntent', fund, blobPdf },
          });
        })
        .catch(console.log);
    } else {
      history.push({ pathname: '/fund/overview', state: { fund } });
    }
  };

  return (
    <>
      <DafDonorIntentInstruction />
      <div className="container daf-container">
        <div className="my-4">
          <strong>Donor Intent/Mission Statement.</strong> You are encouraged to describe your
          charitable principles, interests, and goals so that DonorsTrust may better preserve your
          donor intent. Your intent must fall within (or not contradict) the purposes and mission of
          DonorsTrust. To further protect your intent, DonorsTrust strongly encourages you to limit
          the life of your donor-advised fund to a term of twenty-five years or less after your
          death.
        </div>
        <div className="my-3">
          <DafIntentFilesList
            removeFile={(id) => deleteStatementFile(id)}
            fileLinks={statementFiles}
          />
        </div>
        <div className="mt-3 mb-5">
          <DafIntentUploadFiles
            updateFiles={(uploadedFiles) => setFiles(uploadedFiles)}
            setSubmitDisabled={(isDisabled) => {
              setSubmitDisabled(isDisabled);
            }}
          />
        </div>
        <div className="my-5">
          <div className="d-flex justify-content-between">
            <div>
              <strong>Leave your donor intent text message</strong>
            </div>
            <div className="text-right">
              <strong>
                {message?.length || 0}/{messageMaxLength}
              </strong>
            </div>
          </div>
          <Controller
            name="message"
            control={control}
            rules={{ maxLength: { value: messageMaxLength, message: 'Incorrect input' } }}
            render={({ field: { value, onChange, ...field } }) => (
              <Form.Control
                {...field}
                as="textarea"
                value={value || ''}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                maxlength={messageMaxLength}
                className="mt-2 daf-textarea"
                rows={
                  message?.length > oneThousandSymbols
                    ? messageTextareaEnlarged
                    : messageTextareaDefault
                }
                isInvalid={errors.message}
              />
            )}
          />
          {errors.message && (
            <div className="validation-alert text-left " style={{ width: 120 }}>
              {errors.message.message}
            </div>
          )}
        </div>
        <div className="my-4">
          <NavigateButtons
            onClickCancel={() => history.push({ pathname: '/fund/overview', state: { fund } })}
            onClickSubmit={handleSubmit(submit)}
            submitButtonText="Submit"
            disabledSubmit={isSubmitDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default DafDonorIntent;
