import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Instructions from '../Grant/Request/Instructions';

const RecurringGrantUpdatedModal = ({ showModal, modalData, closeModal }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => {
    setShow(false);
    closeModal();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} centered size="lg">
      <Modal.Body className="recurring-grant-updated-modal">
        <Instructions isModal hideStepCount title={`Recurring Grant Update: ${modalData?.name}`}>
          View changes made to selected recurring grant
        </Instructions>
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Frequency</th>
                <th className="table-value">Value ($)</th>
                <th>End Date</th>
                <th className="table-designation">Designation</th>
                <th>Acknowledgement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{modalData?.oldFrequency}</td>
                <td>{modalData?.oldValue}</td>
                <td>{modalData?.oldEndDate}</td>
                <td>{modalData?.oldDesignation}</td>
                <td>{modalData?.oldAcknowledgement}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-2 my-md-3">
          <strong>Changed to:</strong>
        </div>
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Frequency</th>
                <th className="table-value">Value ($)</th>
                <th>End Date</th>
                <th className="table-designation">Designation</th>
                <th>Acknowledgement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{modalData?.newFrequency}</td>
                <td>{modalData?.newValue}</td>
                <td>{modalData?.newEndDate}</td>
                <td>{modalData?.newDesignation}</td>
                <td>{modalData?.newAcknowledgement}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-12 text-center text-md-right mt-4 mt-md-5">
            <button
              className="button button--dark-blue py-3 py-md-2 px-3 mr-5"
              type="button"
              onClick={handleClose}
            >
              Quit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecurringGrantUpdatedModal;
