import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { AuthenticationContext } from 'auth/AuthProvider';
import Logout from 'components/Logout';
import DonorsTrustLogo from 'assets/images/DON_logo.png';
import CapitalFundLogo from 'assets/images/CapitalFundLogo.png';
import { CAPITAL_FUND_ROUTE } from 'components/Main/constants';
import { BurgerMenu } from './BurgerMenu';
import { CaretSVG } from './CaretSVG';

export const NavItem = ({ path, title }) => {
  return (
    <div className="d-flex flex-column align-items-lg-center position-relative">
      <NavLink exact to={path} title={title}>
        {title}
      </NavLink>
      <CaretSVG style={{ position: 'absolute', bottom: '-7px' }} />
    </div>
  );
};

export default () => {
  const location = useLocation();
  const isCapitalFundPath = location.pathname.includes(CAPITAL_FUND_ROUTE);

  return isCapitalFundPath ? <CapitalFundHeader /> : <DefaultHeader />;
};

const DefaultHeader = () => {
  const { isLoggedIn } = useContext(AuthenticationContext);
  const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false);
  return (
    <div className="navbar-container">
      <Navbar className="navbar navbar-light bg-white">
        <NavLink to="/" title="Donors Trust">
          <img src={DonorsTrustLogo} className="d-inline-block" alt="DonorsTrust Logo" />
        </NavLink>
        {isLoggedIn && (
          <button
            className="burger-toggler"
            type="button"
            onClick={() => setIsShowBurgerMenu(true)}
          >
            <span />
          </button>
        )}
        <div className="d-none d-md-flex w-100 justify-content-end my-3">
          {isLoggedIn && (
            <Nav className="mx-auto">
              <NavItem path="/" title="Your Funds" />
              <NavItem path="/resources" title="Forms & Resources" />
              <NavItem path="/profile" title="Manage Profile" />
              <NavItem path="/contact" title="Contact Us" />
            </Nav>
          )}
          {isLoggedIn ? (
            <Logout />
          ) : (
            <Nav className="ml-auto">
              <NavItem path="/login" title="Login" />
            </Nav>
          )}
        </div>
      </Navbar>
      <BurgerMenu isActive={isShowBurgerMenu} setInactive={() => setIsShowBurgerMenu(false)} />
    </div>
  );
};

const CapitalFundHeader = () => {
  return (
    <div className="navbar-container">
      <Navbar className="navbar navbar-light bg-white">
        <button
          className="btn navbar-logo-button"
          type="button"
          onClick={() => {
            window.location.href = 'http://donorscapitalfund.org/';
          }}
        >
          <img src={CapitalFundLogo} className="d-inline-block" alt="DonorsTrust Logo" />
        </button>
      </Navbar>
    </div>
  );
};
