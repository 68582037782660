import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { GrantsContext } from 'Providers/Grants';
import AddDesignation from './AddDesignation';
import AddAcknowledgement from './AddAcknowledgement';
import AddTiming from './AddTiming';
import { EditGrantMobile } from './EditGrantMobile';

const SubStepModal = ({
  showModal,
  closeModal,
  currentStep,
  substep,
  grant,
  goToStep,
  prevGrantInfo,
}) => {
  const [show, setShow] = useState(showModal);
  const { updateGrant, isValid, clearValidationErrors } = useContext(GrantsContext);
  const [redirectStep, setRedirectStep] = useState(null);

  const addGrantInfo = (key, value, custom) => {
    if (key === 'editMobile') {
      updateGrant(grant.granteeId, 'designation', value.designation);
      updateGrant(grant.granteeId, 'acknowledgement', value.acknowledgement);
      updateGrant(grant.granteeId, 'timing', value.timing);
      updateGrant(grant.granteeId, 'comments', value.comments);
      updateGrant(grant.granteeId, 'requestWireTransfer', value.requestWireTransfer);
      updateGrant(grant.granteeId, 'recurring', value.recurring);
      updateGrant(grant.granteeId, 'frequency', value.frequency);
      updateGrant(grant.granteeId, 'startDate', value.startDate);
      updateGrant(grant.granteeId, 'endDate', value.endDate);
      return;
    }
    if (key === 'timingModal') {
      updateGrant(grant.granteeId, 'timing', value.timing);
      updateGrant(grant.granteeId, 'recurring', value.recurring);
      updateGrant(grant.granteeId, 'frequency', value.frequency);
      updateGrant(grant.granteeId, 'startDate', value.startDate);
      updateGrant(grant.granteeId, 'endDate', value.endDate);
      return;
    }

    let v = value;
    if (custom) {
      updateGrant(grant.granteeId, `${key}CustomInput`, custom);
      v += ` ${custom}`;
    }

    updateGrant(grant.granteeId, key, v);
  };

  const handleClose = () => {
    setShow(false);
    closeModal();
  };

  const clearChanges = () => {
    let grantInfo;
    switch (prevGrantInfo.substep) {
      case 'designation':
        grantInfo = prevGrantInfo.designation;
        break;
      case 'acknowledgement':
        grantInfo = prevGrantInfo.acknowledgement;
        break;
      case 'timing':
        grantInfo = prevGrantInfo;
        grantInfo.substep = 'timingModal';
        break;
      case 'editMobile':
        grantInfo = prevGrantInfo;
        break;
      default:
        console.log('Invalid substep type');
        break;
    }

    addGrantInfo(prevGrantInfo.substep, grantInfo);
    clearValidationErrors();
    handleClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const validate = async () => {
    const valid = await isValid(currentStep);
    if (valid) {
      handleClose();
      setRedirectStep(null);
    } else if (redirectStep) {
      goToStep(redirectStep);
      setShow(false);
      setRedirectStep(null);
    }
  };

  const substeps = {
    designation: AddDesignation,
    acknowledgement: AddAcknowledgement,
    timing: AddTiming,
    editMobile: EditGrantMobile,
  };
  const SubStepComponent = substeps[substep];

  return (
    <Modal className="edit-grant-mobile-modal" show={show} onHide={clearChanges} centered>
      <Modal.Body>
        <div>
          <SubStepComponent
            grant={grant}
            validate={validate}
            updateGrant={addGrantInfo}
            onRedirectStep={(step) => setRedirectStep(step)}
          />
          <div className="container mt-3">
            <div className="text-left d-inline-block modal-button">
              <button
                className="button button--gray py-3 px-3 px-md-5 mr-3"
                type="button"
                onClick={clearChanges}
              >
                Back
              </button>
            </div>
            <div className="text-right d-inline-block modal-button">
              <button
                className="button button--dark-blue py-3 px-3 px-md-5"
                type="button"
                onClick={validate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubStepModal;
