import React, { useEffect, useContext } from 'react';
import Instructions from 'components/Main/Grant/Request/Instructions';
import { ApiContext } from 'components/ApiProvider';
import { displayCurrency } from 'util/money';
import { AchContributionAllocationContext } from 'Providers/AchContributionAllocation';
import NumberFormat from 'react-number-format';
import { Form } from 'react-bootstrap';

export const InvestContribution = (props) => {
  const { currentStep, fund, contributionAmount, fee } = props;
  const { configuration, updateConfiguration, errorMessage } = useContext(
    AchContributionAllocationContext
  );
  const investedAmount =
    (configuration?.allocations?.length &&
      configuration?.allocations?.reduce((a, b) => a + b.amount, 0)) ||
    null;
  const { getPoolsForReallocation } = useContext(ApiContext);
  const isValid = configuration?.approve === 'Yes' && investedAmount === 100;

  useEffect(() => {
    async function getPools() {
      const pools = await getPoolsForReallocation(fund.id);
      updateConfiguration('pools', pools);
    }

    if (!configuration.pools) {
      getPools();
    }
  }, currentStep);

  const updateAllocations = (poolId, name, amount) => {
    const allocationsArray = configuration.allocations;
    const pool = allocationsArray?.find((p) => p.poolId === poolId) || null;
    if (pool) {
      const index = allocationsArray?.findIndex((i) => i.poolId === poolId);
      if (amount > 0) allocationsArray.splice(index, 1, { poolId, name, amount });
      else allocationsArray.splice(index, 1);
    } else {
      allocationsArray.push({ poolId, name, amount });
    }

    updateConfiguration('allocations', allocationsArray);
  };

  return (
    <>
      <div className="ach-contribution-allocation">
        <Instructions hideStepCount title="Invest your current contribution">
          <>
            On this page you can invest your pending contribution. Once money is received, it will
            be invested between selected pools. If you have finished your investment choise, click{' '}
            <strong>Continue</strong>. Clicking <strong>Cancel</strong> will default to 100% Money
            Market investment
          </>
        </Instructions>
        <div className="col pt-2 pt-md-0 text-center col-md-4 offset-md-4">
          <h2>Contribution you can invest</h2>
          <div className="contribution-info">
            <table>
              <tr>
                <td className="text-left">Contribution</td>
                <td className="text-right text-secondary">
                  {`$${displayCurrency(contributionAmount)}`}
                </td>
              </tr>
              <tr>
                <td className="text-left">ACH/CC fee</td>
                <td className="text-right text-secondary">{`$${displayCurrency(fee)}`}</td>
              </tr>
              <tr>
                <td className="text-left">Investable amount</td>
                <td className="text-right">{`$${displayCurrency(contributionAmount - fee)}`}</td>
              </tr>
              <tr>
                <td className="text-left">Status</td>
                <td className="text-right text-secondary">Pending</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column flex-md-row justify-content-between mb-4 mb-md-5">
                <div className="d-flex flex-column flex-md-row">
                  <span>Fund Name</span> <strong className="ml-0 ml-md-5">{fund.name}</strong>
                </div>
                <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row">
                  <strong className="mr-5">
                    You need to invest:
                    <div className="text-danger">{`${displayCurrency(100 - investedAmount)}%`}</div>
                  </strong>
                  <strong className="mt-3 mt-md-0">
                    You invested:
                    <div className="text-success">{`${displayCurrency(investedAmount || 0)}%`}</div>
                  </strong>
                </div>
              </div>
              <div className="mb-4 mb-md-2">
                Total fund amount:
                <strong> ${displayCurrency(fund.totalValue - fund.pendingGrantAmount)}</strong>
              </div>
              <div>
                <div className="funds-table-container">
                  <table className="table funds-table">
                    <thead className="text-secondary">
                      <tr>
                        <th className="funds-table__investment-pool">Investment Pool</th>
                        <th className="funds-table__current-balance text-right">Current Balance</th>
                        <th className="funds-table__current-units text-right">Current Units</th>
                        <th className="funds-table__current-allocation text-right text-md-right">
                          Current Allocations
                        </th>
                        <th className="funds-table__investment-allocation text-right pr-md-3">
                          New Investment Allocations <span className="d-inline d-md-none">(%)</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {configuration.pools
                        ?.sort((a, b) => b.value - a.value)
                        ?.map(
                          (pool) =>
                            pool.poolId !== configuration.poolId && (
                              <tr key={pool.poolId}>
                                <td className="font-weight-bold">{pool.poolName}</td>
                                <td className="text-right">${displayCurrency(pool.value)}</td>
                                <td className="text-right">
                                  {displayCurrency(pool.numberOfUnits)}
                                </td>
                                <td className="text-right text-md-right">
                                  {(
                                    (pool.value / (fund.totalValue - fund.pendingGrantAmount)) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td className="text-right">
                                  <NumberFormat
                                    className="mt-1 mr-2 text-right number-format"
                                    thousandSeparator
                                    allowNegative={false}
                                    value={
                                      configuration.allocations?.find(
                                        (val) => val.poolId === pool.poolId
                                      )?.amount || ''
                                    }
                                    onValueChange={(values) => {
                                      const val = values.floatValue || 0;
                                      updateAllocations(pool.poolId, pool.poolName, val);
                                    }}
                                  />
                                  <span className="d-none d-md-inline">%</span>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-column flex-md-row mt-4 mt-md-0">
                <div>
                  <div>Apply the investment selection?</div>
                  <div className="d-flex mt-2 mt-md-1">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="approve"
                      id="approve-yes"
                      htmlFor="approve-yes"
                      className="mr-3 ach-contribution-allocation__checkbox"
                      onChange={() => updateConfiguration('approve', 'Yes')}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      id="approve-no"
                      htmlFor="approve-no"
                      name="approve"
                      className="ach-contribution-allocation__checkbox"
                      onChange={() => updateConfiguration('approve', 'No')}
                    />
                  </div>
                </div>
                {errorMessage && !isValid && (
                  <div className="col-12 col-md-3 mt-4 mt-md-0 grant-instructions p-2">
                    <small>
                      Please, ensure you entered correct percentage and confirmed the changes
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
