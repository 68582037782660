import React, { useContext, useState, useEffect } from 'react';
import NewGranteeModal from 'components/Main/NewGrantee/Modal';
import { ApiContext } from 'components/ApiProvider';
import Typeahead from 'components/Typeahead';
import { GrantsContext } from 'Providers/Grants';
import Instructions from '../Instructions';

export const AddGrantees = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const [showModal, setShowModal] = useState(false);
  const [recentOrgs, setRecentOrgs] = useState([]);
  const [newGrantee, setNewGrantee] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const { grants, addGrant, removeGrant, validationErrors } = useContext(GrantsContext);
  const { getGrantees, getGrants } = useContext(ApiContext);

  useEffect(() => {
    setErrorMessage(validationErrors?.find((e) => e.name === 'grantee')?.message);
  }, [validationErrors]);

  useEffect(() => {
    async function getRecentGrants() {
      const data = await getGrants(fund.id, 4);
      const recentGrants = data.map((d) => {
        return {
          id: d.granteeId,
          name: d.grantOrganization,
          acknowledgment: d.acknowledgment,
          designation: d.designation,
          ein: d.granteeEin,
          address1: d.granteeAddress1,
          city: d.granteeCity,
          state: d.granteeState,
          zip: d.granteeZip,
        };
      });
      setRecentOrgs(recentGrants);
    }

    getRecentGrants();
  }, []);

  const handleSelectGrantee = (selection) => {
    if (selection) {
      if (selection.customOption) {
        setNewGrantee(selection);
        setShowModal(true);
      } else {
        addGrant(
          selection.id,
          selection.name,
          null,
          selection.acknowledgment,
          selection.designation,
          selection.ein || '',
          selection.address1 || '',
          selection.city || '',
          selection.state || '',
          selection.zip || ''
        );
      }
    }

    return true;
  };

  const addNewGrantee = (values) => {
    addGrant(newGrantee.id, values.name, values);
    setNewGrantee({});
    setShowModal(false);
  };

  return (
    <>
      <NewGranteeModal
        showModal={showModal}
        granteeName={newGrantee?.name}
        onAdd={addNewGrantee}
        onClose={() => setShowModal(false)}
      />
      <Instructions title="Grant Recipient" currentStep={currentStep} totalSteps={totalSteps}>
        Begin typing the name of the organization to receive this grant, and when it appears{' '}
        <strong>click the name</strong> to add it to the list. If it is a new grantee, type the full
        name of the organization and select “Click here to add new grantee”. You may add multiple
        grantees. When you’ve selected all your grantees, click <strong>Next Step</strong>.
      </Instructions>
      <div className="container">
        <div className="row">
          <div className="col no-gutters">
            <div className="col col-md-10 offset-md-1">
              <div className={`${errorMessage == null ? String.empty : 'border-danger'}`}>
                <Typeahead
                  asyncSearchFunc={getGrantees}
                  placeholder="Search for an organization either by name or EIN"
                  newSelectionLabel="+ Click here to add new grantee: "
                  labelKey="name"
                  id="searchGranteeTypeahead"
                  onSelect={handleSelectGrantee}
                />
              </div>
              <div className="validation-alert">{errorMessage}</div>
              <div className="mt-3 mt-md-2 mb-4">
                <small className="grant-request__label">
                  <strong>SELECT RECENTLY USED</strong>
                </small>
                <ul className="grant-request__grantees-list mt-1 text-left">
                  {recentOrgs.map((org) => (
                    <li key={org.id}>
                      <button
                        type="button"
                        className="p-0 button button--link"
                        style={{ color: 'black' }}
                        onClick={() =>
                          addGrant(
                            org.id,
                            org.name,
                            null,
                            org.acknowledgment,
                            org.designation,
                            org.ein || '',
                            org.address1 || '',
                            org.city || '',
                            org.state || '',
                            org.zip || ''
                          )
                        }
                      >
                        <u>{org.name}</u>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-md-5">
              <h4 className="mb-0 mb-md-4 text-center pt-md-4">Organizations Chosen</h4>

              {grants?.length ? (
                <table className="d-none d-md-table grant-request__chosen-organizations mx-auto mt-3 mt-md-0">
                  <thead>
                    <tr>
                      <th className="chosen-organizations__header text-muted pb-3">Name</th>
                      <th className="chosen-organizations__header text-center text-muted pb-3">
                        EIN
                      </th>
                      <th className="chosen-organizations__header text-center text-muted pb-3">
                        Mailing Address
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {grants.map((grant) => (
                      <tr key={grant.granteeId} className="organizations">
                        <td
                          align="left"
                          className="py-2 grant-request__chosen-organizations-name organization-name"
                        >
                          {grant.granteeName}
                        </td>
                        <td
                          align="center"
                          className="py-2 grant-request__chosen-organizations-name organization-ein"
                        >
                          {grant.ein}
                        </td>
                        <td
                          align="center"
                          className="py-2 grant-request__chosen-organizations-name organization-address"
                        >
                          {grant.address1}
                          <br />
                          {grant.city ? `${grant.city}` : ''}
                          {grant.state ? `, ${grant.state}` : ''}
                          {grant.zip ? `, ${grant.zip}` : ''}
                        </td>
                        <td className="pl-5">
                          <button
                            type="button"
                            className="button button--red button--small px-2 py-1"
                            onClick={() => removeGrant(grant.granteeId)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
              <div className="d-block d-md-none">
                {grants.map((grant) => (
                  <div
                    key={grant.granteeId}
                    className="d-flex align-items-start justify-content-between mt-3"
                  >
                    <div>
                      <div className="grant-request__chosen-organizations-name">
                        {grant.granteeName}
                      </div>
                      {grant.address1 && (
                        <div className="mt-2 text-muted">
                          {grant.address1}
                          <br />
                          {grant.city ? `${grant.city}` : ''}
                          {grant.state ? `, ${grant.state}` : ''}
                          {grant.zip ? `, ${grant.zip}` : ''}
                        </div>
                      )}
                      {grant.ein && (
                        <div className="mt-2 text-muted">
                          {grant.ein ? `EIN: ${grant.ein}` : ''}
                        </div>
                      )}
                    </div>
                    <div className="pl-5">
                      <button
                        type="button"
                        className="button button--red button--small px-2 py-1"
                        onClick={() => removeGrant(grant.granteeId)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
