import React from 'react';
import { Form } from 'react-bootstrap';
import { regExp } from '../../common/constants';

export const PrefixRadios = ({ prefix, setPrefix, setValue }) => {
  return (
    <>
      <div className="mt-1">
        <strong>Prefix:</strong>
      </div>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-mister"
          name="daf-prefix"
          value="Mr."
          checked={regExp.IS_PREFIX_MR.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-mister">
          <strong>Mr.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-miss"
          name="daf-prefix"
          value="Ms."
          checked={regExp.IS_PREFIX_MS.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-miss">
          <strong>Ms.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-missis"
          name="daf-prefix"
          value="Mrs."
          checked={regExp.IS_PREFIX_MRS.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-missis">
          <strong>Mrs.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-doctor"
          name="daf-prefix"
          value="Dr."
          checked={regExp.IS_PREFIX_DR.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-doctor">
          <strong>Dr.</strong>
        </Form.Check.Label>
      </Form.Check>
      <Form.Check className="mt-1">
        <Form.Check.Input
          type="radio"
          id="daf-prefix-other"
          name="daf-prefix"
          value="Other"
          checked={!regExp.IS_PREFIX_OTHER.test(prefix)}
          onChange={(e) => {
            setPrefix(e.target.value);
            setValue('prefix', e.target.value);
          }}
        />
        <Form.Check.Label htmlFor="daf-prefix-other">
          <strong>Other:</strong>
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};
