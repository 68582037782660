import React, { useContext, useState, useEffect } from 'react';
import { GrantsContext } from 'Providers/Grants';
import { Form } from 'react-bootstrap';
import Instructions from 'components/Main/Grant/Request/Instructions';
import RadioInputToggle from 'components/Form/Input/RadioInputToggle';

export const CUSTOM_INPUTS = [
  {
    label: 'In support of a specific project*',
    inputType: 'text',
  },
  {
    label: 'In memory of*',
    inputType: 'text',
  },
  {
    label: 'In honor of*',
    inputType: 'text',
  },
  {
    label: 'Other*',
    inputType: 'textarea',
    placeholder: 'Additional Comments',
  },
];

export const DesignationForm = ({ grant, updateGrant, editGrantValidationErrors }) => {
  const { validationErrors } = useContext(GrantsContext);
  const [editGrantErrors, setEditGrantErrors] = useState(editGrantValidationErrors);

  useEffect(() => {
    setEditGrantErrors(editGrantValidationErrors);
  }, [editGrantValidationErrors]);

  let designationInputDefaultValue = '';

  CUSTOM_INPUTS.forEach(({ label }) => {
    if (grant.designation.includes(label.replace('*', ''))) {
      designationInputDefaultValue = grant.designation.replace(label.replace('*', ''), '').trim();
    }
  });

  return (
    <Form className="text-left">
      <Form.Group>
        <Form.Label as="legend" className="mt-3 mb-2 subtitle">
          Select your preferred designation:
        </Form.Label>
        <Form.Check
          checked={grant.designation === 'General operations'}
          onChange={(e) => updateGrant('designation', e.target.value)}
          type="radio"
          value="General operations"
          name="designation"
          id="general-operations"
          htmlFor="general-operations"
          label="General operations"
          className="my-2"
        />
        {CUSTOM_INPUTS.map((i) => {
          return (
            <RadioInputToggle
              key={i.label}
              name="designation"
              label={i.label}
              onChange={(value, custom) =>
                updateGrant('designation', value.replace('*', ''), custom)
              }
              isActive={grant.designation.includes(i.label.replace('*', ''))}
              inputPlaceholder={i.placeholder}
              type={i.inputType}
              inputValue={designationInputDefaultValue || grant.designationCustomInput}
              validationErrors={
                validationErrors?.some((x) => x) ? validationErrors : editGrantErrors
              }
            />
          );
        })}
      </Form.Group>
    </Form>
  );
};

const AddDesignation = ({ grant, updateGrant }) => {
  return (
    <>
      <div className="designation-popup">
        <Instructions hideStepCount isModal title={`Designation: ${grant.granteeName} `}>
          <div className="text-left px-4 description">
            The designation you recommend will display on the letter to the organization. You must
            choose a designation.
          </div>
        </Instructions>
        <div className="container">
          <div className="row">
            <div className="col">
              <DesignationForm grant={grant} updateGrant={updateGrant} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesignation;
