import React from 'react';

export const PublicCharityInfo = ({
  charityName,
  ein,
  distributionPercentage,
  editPublicCharity,
  deletePublicCharityById,
  publicCharity,
}) => {
  return (
    <div className="row mb-4">
      <div className="col-8 ml-4 pl-4">
        <div>
          <strong>{charityName}</strong>
        </div>
        <div>{ein}</div>
        <div>{distributionPercentage}% of account</div>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="border-0 onGrantClick mr-4"
            onClick={() => editPublicCharity(publicCharity)}
          >
            <i className="bi bi-pencil-fill" />
          </button>
          <button
            type="button"
            className="button button--red button--small px-2 py-1 "
            onClick={() => deletePublicCharityById(publicCharity?.idFront)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
