import React, { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { displayCurrency, roundToCents } from 'util/money';
import { displayDate } from 'util/dates';
import { ButtonToTop } from 'components/Common/ButtonToTop';

export const FundTransactionsHistoryTable = memo(
  ({ transactionsForCurrentPage, sortConfig, setSortConfig }) => {
    const { ref, inView } = useInView({
      rootMargin: '50px 0px',
    });

    const requestSort = (key) => {
      const direction =
        sortConfig && sortConfig.key === key && sortConfig.direction === 'desc' ? 'asc' : 'desc';
      setSortConfig({ key, direction });
    };

    const SortArrow = ({ field }) => {
      return (
        <>
          {sortConfig?.key === field ? (
            <i
              className={`bi text-blue ${
                sortConfig?.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'
              } ml-2`}
              role="button"
              aria-label="order"
              tabIndex={0}
            />
          ) : (
            <i
              role="button"
              aria-label="order"
              tabIndex={0}
              className="bi bi-caret-down-fill ml-2"
            />
          )}
        </>
      );
    };

    return (
      <div>
        <div className="container transactions-table mt-2 mt-md-4">
          <table className="table">
            <thead className="text-muted">
              <tr>
                <th className="text-left sortable w-20" onClick={() => requestSort('date')}>
                  Date <SortArrow field="date" />
                </th>
                <th className="text-left sortable w-25" onClick={() => requestSort('type')}>
                  Transaction type <SortArrow field="type" />
                </th>
                <th className="text-left sortable w-25" onClick={() => requestSort('subtype')}>
                  Transaction subtype <SortArrow field="subtype" />
                </th>
                <th
                  className="text-right sortable w-15"
                  onClick={() => requestSort('numberOfUnits')}
                >
                  Units <SortArrow field="numberOfUnits" />
                </th>
                <th
                  className="text-right sortable w-15"
                  onClick={() => requestSort('dollarAmount')}
                >
                  Amount ($) <SortArrow field="dollarAmount" />
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionsForCurrentPage.map(
                ({ id, date, type, subtype, numberOfUnits, dollarAmount }) => (
                  <tr key={id}>
                    <td className="text-left w-20">{displayDate(date)}</td>
                    <td className="text-left w-25">{type}</td>
                    <td className="text-left w-25">{subtype}</td>
                    <td className="text-right w-15">{roundToCents(numberOfUnits)}</td>
                    <td className="text-right w-15">{displayCurrency(dollarAmount)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div ref={ref} />
        <ButtonToTop inView={inView} />
      </div>
    );
  }
);
