import React from 'react';
import FormBox from '../FormBox';

export default ({ info }) => {
  return (
    <div className="row mb-0 mt-4 my-md-4">
      {info.map((f) => (
        <div key={f.name} className="col-12 col-md-6 ">
          <FormBox name={f.name} src={f.src} />
        </div>
      ))}
    </div>
  );
};
