import React from 'react';
import { displayDate } from 'util/dates';
import { displayCurrency } from 'util/money';

const ContributionsHistoryTable = ({ history }) => {
  if (!history.length) return null;

  return (
    <div className="funds-table-container">
      <table className="table funds-table">
        <thead>
          <tr>
            <th className="funds-table__date">Date</th>
            <th className="funds-table__description">Description</th>
            <th className="text-right funds-table__contribution-value">Contribution Value ($)</th>
          </tr>
        </thead>
        <tbody>
          {history.map((h) => (
            <tr key={h.id}>
              <td>{displayDate(h.date)}</td>
              <td>{h.description}</td>
              <td className="text-right">{displayCurrency(h.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContributionsHistoryTable;
