import React, { useState, useContext } from 'react';
import { GrantsContext } from 'Providers/Grants';
import GrantTable from 'components/Main/Grant/Table';
import Instructions from '../Instructions';
import { EditGrantModal } from './SubStep/EditGrantModal';

export const ReviewGrants = (props) => {
  const { currentStep, totalSteps, goToStep, firstStep, fund } = props;
  const [grant, setGrant] = useState(null);
  const [isEditGrantModalShow, setIsEditGrantModalShow] = useState(false);
  const { grants, removeGrant, updateGrant } = useContext(GrantsContext);

  const handleGrantEdit = (grantObj) => {
    setGrant(grantObj);
    setIsEditGrantModalShow(true);
  };

  if (grants.length === 0) {
    firstStep();
  }

  const addGrantInfo = (key, value, custom) => {
    if (key === 'timingModal') {
      updateGrant(grant.granteeId, 'timing', value.timing);
      updateGrant(grant.granteeId, 'recurring', value.recurring);
      updateGrant(grant.granteeId, 'frequency', value.frequency);
      updateGrant(grant.granteeId, 'startDate', value.startDate);
      updateGrant(grant.granteeId, 'endDate', value.endDate);
      return;
    }

    let inputValue = value;
    if (custom) {
      updateGrant(grant.granteeId, `${key}CustomInput`, custom);
      inputValue += ` ${custom}`;
    }

    updateGrant(grant.granteeId, key, inputValue);
  };

  return (
    <>
      <Instructions title="Review Grants" currentStep={currentStep} totalSteps={totalSteps} />
      <div className="container add-grant-info">
        <div className="row">
          <div className="col">
            <GrantTable grants={grants} removeGrant={removeGrant} onEdit={handleGrantEdit} />
          </div>
        </div>
        {isEditGrantModalShow && (
          <EditGrantModal
            grants={grants}
            grant={grant}
            fund={fund}
            updateGrant={addGrantInfo}
            isEditGrantModalShow={isEditGrantModalShow}
            closeModal={() => setIsEditGrantModalShow(false)}
            currentStep={currentStep}
            goToStep={goToStep}
          />
        )}
      </div>
    </>
  );
};
