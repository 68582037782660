import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ApiContext } from 'components/ApiProvider';
import { resolver } from './constants';

export default () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ resolver });
  const { contact } = useContext(ApiContext);

  const submit = async (values) => {
    const { email, firstName, lastName, message } = values;

    try {
      await contact(email, firstName, lastName, message);
      toast.success(
        'Your message has been successfully sent. We will get in touch soon as possible.',
        {
          toastId: 'successToast',
        }
      );
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="contact-us-form">
      <Form className="form" onSubmit={handleSubmit((values) => submit(values))}>
        <Form.Label>
          Email<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control {...register('email')} isInvalid={errors.email} type="email" />
        {errors.email && <div className="validation-alert"> Required field </div>}
        <Form.Label className="mt-3 mt-md-2">
          First Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control {...register('firstName')} isInvalid={errors.firstName} type="text" />
        {errors.firstName && <div className="validation-alert"> Required field </div>}
        <Form.Label className="mt-3 mt-md-2">
          Last Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control {...register('lastName')} isInvalid={errors.lastName} type="text" />
        {errors.lastName && <div className="validation-alert"> Required field </div>}
        <Form.Label className="mt-3 mt-md-2">
          Message<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          className="contact-us-form__textarea"
          as="textarea"
          rows="4"
          {...register('message')}
          isInvalid={errors.message}
        />
        {errors.message && <div className="validation-alert"> Required field </div>}
        <button
          className="button button--dark-blue px-3 py-3 px-md-5 mt-3 col col-md-auto mb-3 mb-md-0"
          type="submit"
        >
          Submit
        </button>
      </Form>
    </div>
  );
};
