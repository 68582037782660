import React, { useEffect, useState, memo } from 'react';

const calculateCurrentItems = (currentPage, itemsPerPage, itemsQuantity) => {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const lastItem =
    currentPage * itemsPerPage > itemsQuantity ? itemsQuantity : currentPage * itemsPerPage;
  return `${startItem} - ${lastItem}`;
};

export const FundTransactionsHistoryPagination = memo(
  ({ filteredTransactions, setTransactionsForCurrentPage }) => {
    const itemsPerPage = 20;
    const defaultTransactionsQuantityForView = 50;
    const [currentPage, setCurrentPage] = useState(1);
    const breakpointSm = '576px';
    const [transactionsQuantity, setTransactionsQuantity] = useState(
      defaultTransactionsQuantityForView
    );

    useEffect(() => {
      setCurrentPage(1);
      setTransactionsQuantity(defaultTransactionsQuantityForView);
    }, [filteredTransactions]);

    useEffect(() => {
      if (window.matchMedia(`(max-width: ${breakpointSm})`).matches) {
        if (filteredTransactions.length > transactionsQuantity) {
          const transactionsForCurrentPage = filteredTransactions.slice(0, transactionsQuantity);
          setTransactionsForCurrentPage(transactionsForCurrentPage);
        } else {
          setTransactionsForCurrentPage(filteredTransactions);
        }
      } else if (filteredTransactions.length > itemsPerPage) {
        const transactionsForCurrentPage = filteredTransactions.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );
        setTransactionsForCurrentPage(transactionsForCurrentPage);
      } else {
        setTransactionsForCurrentPage(filteredTransactions);
      }
    }, [currentPage, filteredTransactions, transactionsQuantity]);

    const handleClickCurrentPage = (page) => setCurrentPage(page);
    const handleClickPlusOne = () => setCurrentPage((prev) => prev + 1);
    const handleClickMinusOne = () => setCurrentPage((prev) => prev - 1);

    const pages = [];
    for (let i = 1; i <= Math.ceil(filteredTransactions.length / itemsPerPage); i += 1) {
      pages.push(i);
    }

    if (filteredTransactions.length < itemsPerPage) return null;

    return (
      <>
        <div className="container mt-4 d-none d-md-block">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-blue">
              <strong>
                {calculateCurrentItems(currentPage, itemsPerPage, filteredTransactions.length)} of{' '}
                {filteredTransactions.length} records
              </strong>
            </div>
            <div className="pagination">
              <div className="pagination-wrapper">
                <button
                  type="button"
                  disabled={currentPage === 1}
                  className="button control control-icon back"
                  onClick={handleClickMinusOne}
                >
                  <i className="bi bi-chevron-compact-left" />
                </button>
                {pages.map((page) => {
                  if (pages.length < 7) {
                    return (
                      <button
                        type="button"
                        onClick={() => handleClickCurrentPage(page)}
                        className={
                          page === currentPage
                            ? 'button control page active-page'
                            : 'button control page'
                        }
                        key={page}
                      >
                        {page}
                      </button>
                    );
                  }
                  if (page === 1) {
                    return (
                      <button
                        type="button"
                        onClick={() => handleClickCurrentPage(page)}
                        className={
                          page === currentPage
                            ? 'button control page active-page'
                            : 'button control page'
                        }
                        key={page}
                      >
                        {page}
                      </button>
                    );
                  }
                  if (
                    (page > 1 && page < currentPage - 1 && page < 3) ||
                    (page === pages.length - 1 && currentPage < pages.length - 2)
                  ) {
                    return (
                      <span className="control page page-ellipsis disabled" key={page}>
                        ...
                      </span>
                    );
                  }
                  if (
                    (page <= 5 && currentPage < 5) ||
                    (page >= currentPage - 1 && page <= currentPage + 1) ||
                    (page >= pages.length - 4 && currentPage > pages.length - 4)
                  ) {
                    return (
                      <button
                        type="button"
                        onClick={() => handleClickCurrentPage(page)}
                        className={
                          page === currentPage
                            ? 'button control page active-page'
                            : 'button control page'
                        }
                        key={page}
                      >
                        {page}
                      </button>
                    );
                  }
                  if (page === pages.length) {
                    return (
                      <button
                        type="button"
                        onClick={() => handleClickCurrentPage(page)}
                        className={
                          page === currentPage
                            ? 'button control page active-page'
                            : 'button control page'
                        }
                        key={page}
                      >
                        {page}
                      </button>
                    );
                  }
                  return null;
                })}

                <button
                  type="button"
                  className="button control control-icon forward"
                  disabled={currentPage === pages.length}
                  onClick={handleClickPlusOne}
                >
                  <i className="bi bi-chevron-compact-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {filteredTransactions.length >= transactionsQuantity ? (
          <div className="d-block d-md-none container my-3">
            <button
              type="button"
              className="transactions-showmore-button"
              onClick={() => {
                setTransactionsQuantity((prev) => prev + defaultTransactionsQuantityForView);
              }}
            >
              Show more results
            </button>
          </div>
        ) : null}
      </>
    );
  }
);
