import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DafLinksComponent = ({ fund }) => {
  const [areLinksShow, setLinksShow] = useState(false);

  const toggleDafLinksShow = () => {
    setLinksShow(!areLinksShow);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') setLinksShow(false);
  };

  return (
    <div
      className="my-2 my-md-1 ml-0 pl-0 d-flex flex-column align-items-start no-gutters"
      type="button"
      onClick={toggleDafLinksShow}
      onKeyDown={handleKeyDown}
      role="menu"
      tabIndex={0}
    >
      <div className="button button--link ml-0 pl-0">
        Update Intent Statement, Advisors,
        <span className={`daf-links-accordion__${areLinksShow} d-inline-block ml-2`}>
          <i className="bi bi-arrow-up" />
        </span>
        <br />
        Sunset Info, etc.
      </div>
      <ul className="daf-links pt-2 ml-0 pl-0">
        <div className={`daf-links-content__${areLinksShow}`}>
          <li className="daf-links-content__link">
            <Link to={{ pathname: `/daf/fundname/${fund.id}` }} className="my-2 my-md-1">
              Fund name and default grant privacy elections
            </Link>
          </li>
          <li className="daf-links-content__link">
            <Link to={{ pathname: `/daf/advisors/${fund.id}` }} className="my-2 my-md-1k">
              Primary advisors and default communication elections
            </Link>
          </li>
          <li className="daf-links-content__link">
            <Link to={{ pathname: `/daf/donor/${fund.id}` }} className="my-2 my-md-1">
              Donor intent/mission statement
            </Link>
          </li>
          <li className="daf-links-content__link">
            <Link to={{ pathname: `/daf/sunsetting/${fund.id}` }} className="my-2 my-md-1">
              Sunsetting of donor-advised fund account and final distribution elections
            </Link>
          </li>
          <li className="daf-links-content__link">
            <Link to={{ pathname: `/daf/successor/${fund.id}` }} className="my-2 my-md-1">
              Successor advisors
            </Link>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default DafLinksComponent;
