import React, { useState, useContext, useEffect, useRef } from 'react';
import { GrantsContext } from 'Providers/Grants';
import RadioInputToggle from 'components/Form/Input/RadioInputToggle';
import { Form, Col, InputGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import { displayDate } from 'util/dates';
import Instructions from '../../Instructions';
import { CUSTOM_INPUTS } from './AddDesignation';

export const EditGrantMobile = ({ grant, updateGrant }) => {
  const { validationErrors } = useContext(GrantsContext);
  const [isValidFrequency, setIsValidFrequency] = useState(true);
  const [startDateValidationError, setStartDateValidationError] = useState(null);
  const [withEndDate, setWithEndDate] = useState(grant.endDate && grant.endDate !== '');
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [minEndDate, setMinEndDate] = useState(null);
  const designationRef = useRef(null);
  const acknowledgementRef = useRef(null);
  const timingRef = useRef(null);
  let designationInputDefaultValue = '';
  let acknowledgementInputDefaultValue = '';

  CUSTOM_INPUTS.forEach(({ label }) => {
    if (grant.designation.includes(label.replace('*', ''))) {
      designationInputDefaultValue = grant.designation.replace(label.replace('*', ''), '').trim();
    }
  });

  if (grant.acknowledgement.includes('Recognize Someone Else')) {
    acknowledgementInputDefaultValue = grant.acknowledgement
      .replace('Recognize Someone Else', '')
      .trim();
  }

  useEffect(() => {
    if (validationErrors?.find((e) => e.name === 'designation')?.message) {
      designationRef.current.scrollIntoView();
    } else if (validationErrors?.find((e) => e.name === 'acknowledgement')?.message) {
      acknowledgementRef.current.scrollIntoView();
    } else if (
      validationErrors?.find((e) => e.name === 'timing')?.message ||
      validationErrors?.find((e) => e.name === 'startDate')?.message
    ) {
      timingRef.current.scrollIntoView();
    }

    if (validationErrors?.find((e) => e.name === 'startDate')?.message) {
      setIsValidFrequency(false);
    }

    setStartDateValidationError(validationErrors?.find((e) => e.name === 'startDate')?.message);
  }, [validationErrors]);

  const RADIO_OPTIONS = [
    'Anonymous',
    'Account Name Only',
    'Account Name and Full Name',
    'Account Name, Full Name, and Address',
  ];

  const [isRecurring, setIsRecurring] = useState(grant.recurring);

  const handleCheck = (key, value, custom) => {
    setIsRecurring(false);
    updateGrant('recurring', false);
    updateGrant('frequency');
    updateGrant('startDate');
    updateGrant('endDate');
    updateGrant(key, value, custom);
  };

  const handleRecurring = () => {
    setIsRecurring(true);
    updateGrant('recurring', true);
    updateGrant('timing', 'On a recurring basis:');
  };

  return (
    <div className="edit-grant-mobile">
      <Instructions isModal hideStepCount title="Edit Grant Info" />
      <div className="container">
        <div className="row mt-4">
          <div className="col-12 text-left">
            <strong>Name:</strong>
          </div>
          <div className="col-12 mt-2">
            <input
              type="text"
              className="edit-recurring-grant-table-modal__input"
              value={grant.granteeName}
              disabled
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-left">
            <strong>Amount ($):</strong>
          </div>
          <div className="col-12 mt-2">
            <input
              type="number"
              className="edit-recurring-grant-table-modal__input"
              value={grant.totalAmount}
              disabled
            />
          </div>
        </div>
        <Form className="mt-4 text-left">
          <Form.Group>
            <Form.Label as="legend" className="mb-2" ref={designationRef}>
              Select your preferred designation:
            </Form.Label>
            <div className="row">
              <div className="col-12 text-muted">
                The designation you recommend will display on the letter to the organization. You
                must choose a designation.
              </div>
            </div>
            <Form.Check
              checked={grant.designation === 'General operations'}
              onChange={(e) => updateGrant('designation', e.target.value)}
              type="radio"
              value="General operations"
              name="designation"
              id="general-operations-mobile"
              htmlFor="general-operations-mobile"
              label="General operations"
              className="my-2"
            />
            {CUSTOM_INPUTS.map((i) => {
              return (
                <RadioInputToggle
                  key={i.label}
                  name="designation"
                  label={i.label}
                  onChange={(value, custom) =>
                    updateGrant('designation', value.replace('*', ''), custom)
                  }
                  isActive={grant.designation.includes(i.label.replace('*', ''))}
                  inputPlaceholder={i.placeholder}
                  type={i.inputType}
                  inputValue={designationInputDefaultValue || grant.designationCustomInput}
                  validationErrors={validationErrors}
                />
              );
            })}
          </Form.Group>
        </Form>
        <Form className="text-left mt-4">
          <Form.Group>
            <Form.Label as="legend" className="mb-2" ref={acknowledgementRef}>
              Select your preferred style of acknowledgement:
            </Form.Label>
            <div className="row">
              <div className="col-12 text-muted">
                Grants to organizations are accompanied by a letter from DonorsTrust requesting the
                organization acknowledge the person who requested the grant. Choose below how you
                would like this grant acknowledged.
              </div>
            </div>
            {RADIO_OPTIONS.map((o) => {
              return (
                <Form.Check
                  key={o}
                  checked={grant.acknowledgement === o}
                  onChange={(e) => updateGrant('acknowledgement', e.target.value)}
                  type="radio"
                  value={o}
                  id={`acknowledgement-mobile-${o}`}
                  htmlFor={`acknowledgement-mobile-${o}`}
                  name="acknowledgement"
                  label={o}
                  className="my-2"
                />
              );
            })}

            <RadioInputToggle
              name="acknowledgement"
              label="Recognize Someone Else*"
              onChange={(value, custom) =>
                updateGrant('acknowledgement', 'Recognize Someone Else', custom)
              }
              isActive={grant.acknowledgement.includes('Recognize Someone Else')}
              type="textarea"
              inputPlaceholder="Enter Name (Address is optional)"
              inputValue={acknowledgementInputDefaultValue || grant.acknowledgementCustomInput}
              validationErrors={validationErrors}
            />
          </Form.Group>
        </Form>
        <div className="row mt-4">
          <Form className="form col-12 text-left timing">
            <Form.Group>
              <Form.Label as="legend" className="mb-2" ref={timingRef}>
                Select your preferred timing:
              </Form.Label>
              <div className="row">
                <div className="col-12 text-muted">
                  DonorsTrust will review your request and, if approved, will distribute the grant
                  as soon as possible, unless you request otherwise. Future and recurring grants are
                  reviewed again prior to distribution.
                </div>
              </div>
              <Form.Check
                checked={grant.timing === 'As soon as possible'}
                onChange={(e) => handleCheck('timing', e.target.value)}
                type="radio"
                value="As soon as possible"
                id="edit-grant-mobile-as-soon-as-possible"
                htmlFor="edit-grant-mobile-as-soon-as-possible"
                name="timing"
                label="As soon as possible"
                className="my-2 ml-0"
              />
              <RadioInputToggle
                name="timing"
                label="On a specific future date*"
                onChange={(value, custom) =>
                  handleCheck('timing', 'On a specific future date:', custom)
                }
                isActive={grant.timing.includes('On a specific future date:')}
                inputPlaceholder="MM/DD/YYYY"
                type="date"
                inputValue={grant.timingCustomInput}
                validationErrors={validationErrors}
                min={moment(displayDate(new Date())).add(4, 'days').format('YYYY-MM-DD')}
                withoutInput
              />
              {grant.timing.includes('On a specific future date:') && (
                <div className="mb-2">
                  <DatePickerInput
                    id="grant-specific-future-date"
                    selected={grant.timingCustomInput}
                    className={`mt-1 transactions-datepicker ${
                      validationErrors?.find((e) => e.name === 'timing')?.message ? 'invalid' : ''
                    }`}
                    onSelect={(e) => {
                      const formattedDate = moment(e).toDate().toLocaleDateString('en-CA');
                      updateGrant('timingCustomInput', e);
                      updateGrant('timing', `On a specific future date: ${formattedDate}`);
                    }}
                    minDate={moment().add(4, 'days').toDate()}
                  />
                  <Form.Text class className="validation-message">
                    {validationErrors?.find((e) => e.name === 'timing')?.message}
                  </Form.Text>
                </div>
              )}
              <InputGroup>
                <Form.Check
                  checked={isRecurring}
                  onChange={handleRecurring}
                  type="radio"
                  value="recurring"
                  name="timing"
                  id="edit-grant-mobile-on-a-recurring-basis"
                  htmlFor="edit-grant-mobile-on-a-recurring-basis"
                  label="On a recurring basis"
                  className="my-2 ml-0"
                />
                {isRecurring && (
                  <>
                    <i className="bi bi-question-circle my-auto ml-2" data-tip />
                    <ReactTooltip
                      place="top"
                      className="tooltip"
                      effect="solid"
                      event="click"
                      globalEventOff="click"
                      isCapture="true"
                    >
                      <div className="subtitle text-center py-2">How does recurrence work?</div>
                      <ul>
                        <li>
                          <span className="recurrence-type">Choosing Monthly</span> recurrence, you
                          will be charged a specified amount every month. Money charge is performed
                          on the first day of recurrence for the selected month automatically.
                        </li>
                        <li className="mt-3">
                          <span className="recurrence-type">Choosing Quarterly</span> recurrence,
                          you will be charged a specified amount one time per quarter. Money charge
                          is performed on the first day of recurrence for the selected quarter
                          automatically.
                        </li>
                        <li className="mt-3">
                          <span className="recurrence-type">Choosing Yearly</span> recurrence, you
                          will be charged a specified amount one time per year. Money charge is
                          performed on the first day of recurrence for the selected year
                          automatically.
                        </li>
                      </ul>
                    </ReactTooltip>
                  </>
                )}
              </InputGroup>

              {isRecurring && (
                <div className="text-left mt-1">
                  <table>
                    <tbody>
                      <tr>
                        <div className="row">
                          <td className="pr-3 col-12 d-flex" style={{ verticalAlign: 'top' }}>
                            <strong>Frequency*</strong>
                          </td>
                          <td className="col-12">
                            <Form.Group className="d-flex frequency">
                              {['Monthly', 'Quarterly', 'Yearly'].map((t) => {
                                return (
                                  <Form.Check
                                    key={t}
                                    checked={grant.frequency === t}
                                    type="radio"
                                    className={`mr-3 ml-0 ${
                                      isValidFrequency ? '' : 'frequency-required'
                                    }`}
                                    value={t}
                                    name="frequency"
                                    id={`frequency${t}`}
                                    htmlFor={`frequency${t}`}
                                    label={t}
                                    onChange={(e) => {
                                      updateGrant('frequency', e.target.value);
                                      setIsValidFrequency(true);
                                    }}
                                  />
                                );
                              })}
                            </Form.Group>
                            {!isValidFrequency && (
                              <Form.Text class className="validation-message__frequency">
                                Frequency required
                              </Form.Text>
                            )}
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                  <Form.Row>
                    <Form.Group as={Col} md={5} className="start-date">
                      <Form.Label className="d-block">Starting On*</Form.Label>
                      <DatePickerInput
                        id="grant-starting-on"
                        selected={grant.startDate}
                        className={`mt-1 transactions-datepicker ${
                          startDateValidationError ? 'invalid' : ''
                        }`}
                        onSelect={(e) => {
                          updateGrant('startDate', e);
                          setMinEndDate(e);
                        }}
                        minDate={moment().add(4, 'days').toDate()}
                        maxDate={maxStartDate}
                      />
                    </Form.Group>
                    <Form.Text class className="validation-message__start-date">
                      {startDateValidationError}
                    </Form.Text>
                  </Form.Row>
                  <Form.Row className="mb-2 ml-1">
                    <Form.Check
                      checked={grant.endDate && grant.endDate !== ''}
                      onChange={(e) => {
                        setWithEndDate(e.target.checked);
                        if (!e.target.checked) {
                          updateGrant('endDate', null);
                        }
                      }}
                      type="checkbox"
                      label="Select a date to stop recurring grant (optional)"
                    />
                  </Form.Row>
                  {withEndDate && (
                    <Form.Row className="mt-2">
                      <Form.Group as={Col} md={5}>
                        <Form.Label className="d-block">Ending On</Form.Label>
                        <DatePickerInput
                          id="grant-ending-on"
                          selected={grant.endDate}
                          className="mt-1 transactions-datepicker"
                          onSelect={(e) => {
                            updateGrant('endDate', e);
                            setMaxStartDate(e);
                          }}
                          minDate={minEndDate}
                        />
                      </Form.Group>
                    </Form.Row>
                  )}
                </div>
              )}
            </Form.Group>
          </Form>
        </div>
        <div className="row mt-2">
          <div className="col-12 text-left">
            <strong>Comments:</strong>
          </div>
          <div className="col-12 mt-2">
            <Form.Control
              className="edit-grant-mobile__textarea"
              onChange={(e) => updateGrant('comments', e.target.value)}
              as="textarea"
              value={grant.comments}
              rows="4"
            />
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          <input
            className="edit-grant-mobile__checkbox"
            type="checkbox"
            id="edit-grant-request-wire-transfer"
            checked={grant.requestWireTransfer}
            onChange={(e) => updateGrant('requestWireTransfer', e.target.checked)}
          />
          <Form.Label
            htmlFor="edit-grant-request-wire-transfer"
            className="ml-2 mb-0 font-weight-normal"
          >
            Request Wire Grant
          </Form.Label>
        </div>
      </div>
    </div>
  );
};
