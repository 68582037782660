import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import { AuthenticationContext } from 'auth/AuthProvider';
import ChangePasswordForm from './components/Form';

const ResetPassword = () => {
  const { isChangePasswordLinkExpired } = useContext(ApiContext);
  const { removeToken } = useContext(AuthenticationContext);
  const location = useLocation();
  const { search } = location;
  let passwordResetToken = '';
  const match = search.match(/^\?t=(.+)$/);
  const history = useHistory();

  if (match) {
    // eslint-disable-next-line prefer-destructuring
    passwordResetToken = match[1];
  }

  useEffect(() => {
    async function isChangePasswordLinkExpiredAsync() {
      const data = await isChangePasswordLinkExpired(passwordResetToken);
      removeToken();
      if (data) {
        history.push('/password/reset?tokenExpired=true');
      }
    }

    isChangePasswordLinkExpiredAsync();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="text-center">Create New Password</h1>
          <div className="row">
            <div className="col col-md-6 offset-md-3">
              <ChangePasswordForm resetPasswordToken={passwordResetToken} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
