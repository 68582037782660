import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GrantsContext } from 'Providers/Grants';

const Navigation = (props) => {
  const { currentStep, totalSteps, nextStep, previousStep, fund, setSubStep } = props;
  const [isSubmitting, setisSubmitting] = useState(false);
  const { isValid, submit, grantsTotal } = useContext(GrantsContext);
  const history = useHistory();

  const validNextStep = async () => {
    const valid = await isValid(currentStep);

    if (valid) {
      if (currentStep === totalSteps) {
        if (!isSubmitting) {
          setisSubmitting(true);
          const submitGood = await submit();
          setisSubmitting(false);

          if (submitGood) {
            fund.pendingGrantAmount += grantsTotal();
            history.push({
              pathname: '/fund/grants/request/complete',
              state: { fund },
            });
          }
        }
      } else {
        setSubStep(null);
        nextStep();
      }
    }
  };

  const handlePreviousStep = () => {
    previousStep();
  };

  return (
    <div className="grant-request__navigation">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="grant-request__navigation-buttons">
                {currentStep > 1 && (
                  <button
                    className="button button--gray px-5 py-3 mr-4 mr-md-3"
                    onClick={handlePreviousStep}
                    type="button"
                  >
                    Back
                  </button>
                )}
                <button
                  className="button button--dark-blue  px-5 py-3"
                  onClick={validNextStep}
                  type="button"
                  disabled={isSubmitting}
                >
                  {currentStep === totalSteps ? 'Submit' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
