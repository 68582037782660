import React, { useEffect, useRef, memo, useCallback, useState } from 'react';
import _ from 'lodash';

export const ButtonToTop = memo(({ inView }) => {
  const [showGoTopButton, setShowGoTopButton] = useState(false);
  const coordinateY = useRef(0);
  const debouncedHandler = useCallback(
    _.debounce(() => setShowGoTopButton(false), 2000),
    []
  );

  useEffect(() => {
    setShowGoTopButton(window.scrollY > document.documentElement.clientHeight && inView);
  }, [inView]);

  const handleVisibleButton = () => {
    if (
      coordinateY.current > window.scrollY &&
      window.scrollY > document.documentElement.clientHeight
    ) {
      setShowGoTopButton(true);
      debouncedHandler();
    }
    if (window.scrollY < document.documentElement.clientHeight) {
      setShowGoTopButton(false);
    }
    coordinateY.current = window.scrollY;
  };

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
  }, []);
  return (
    <>
      <button
        type="button"
        className={
          showGoTopButton
            ? 'button button-goTo button-goTo__top button-goTo__show d-md-none'
            : 'button button-goTo button-goTo__top button-goTo__hidden d-md-none'
        }
        onClick={handleScrollUp}
      >
        <i className="bi bi-chevron-up" />
      </button>
    </>
  );
});
