import React from 'react';
import FormBoxes from './components/FormBoxes';
import { FORMS, RESOURCES } from './constants';

export default () => {
  return (
    <div className="container common-forms">
      <div className="row">
        <div className="col">
          <h2 className="text-center">Common Forms</h2>
          <p className="text-center">
            Below are forms for many actions you may need to take with your account, from making
            grants to account changes. Also, please find our client guidebook, the Donor’s Guide to
            DonorsTrust. To request a grant online, return to the Your Funds tab above and click on
            your fund name.
          </p>
          <FormBoxes info={FORMS} />
          <h2 className="text-center mt-4 mt-md-0">Resources</h2>
          <p className="text-center mt-3 mt-md-0">
            These resources can assist you in your giving and your use of the donor-advised fund.
          </p>
          <FormBoxes info={RESOURCES} />
        </div>
      </div>
    </div>
  );
};
