/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { displayCurrency } from 'util/money';
import moment from 'moment';

const timingDescription = (grant) => {
  let description = grant.timing;

  if (grant.recurring) {
    description += ` ${grant.frequency} ${moment(grant.startDate)
      .toDate()
      .toLocaleDateString('en-CA')}`;

    if (grant.endDate) {
      description += ` to ${moment(grant.endDate).toDate().toLocaleDateString('en-CA')}`;
    }
  }

  return description;
};

const renderDescription = (grant, category) => {
  if (category === 'timing') {
    return timingDescription(grant);
  }

  return grant[category];
};

const GrantTable = ({ grants, removeGrant, onGrantClick, onWireRequest, onComment, onEdit }) => {
  const totalGrantsValue = grants.reduce((acc, grant) => {
    return acc + (Number(grant.totalAmount) || 0);
  }, 0);

  return (
    <div className="grant-table-container">
      <table className="grant-table table borderless">
        <thead className="text-center">
          <tr>
            <th className="align-top text-left table-name">Name</th>
            <th className="align-top text-left text-md-right table-amount">Amount</th>
            <th className="align-top text-left text-md-left table-designation">Designation</th>
            <th className="align-top text-left text-md-left table-acknowledgement">
              Acknowledgement
            </th>
            <th className="align-top text-left text-md-left table-timing">Timing</th>
            <th className="align-top text-left text-md-center table-wire-grant">
              Request Wire Grant?
            </th>
            <th className="align-top text-left text-md-center table-comments">Comments</th>
            <th className="d-table-cell d-md-none"> </th>
            {removeGrant && <th className="d-none d-md-table-cell"> </th>}
            {onEdit && <th className="d-none d-md-table-cell"> </th>}
          </tr>
        </thead>
        <tbody>
          {grants.map((g) => {
            return (
              <tr key={g.granteeId}>
                <td className="text-left">{g.granteeName}</td>
                <td className="text-left text-md-right" style={{ whiteSpace: 'nowrap' }}>
                  ${displayCurrency(g.totalAmount)}
                </td>
                {['designation', 'acknowledgement', 'timing'].map((c) => {
                  return (
                    <td key={c} className="text-left text-md-left">
                      {onGrantClick ? (
                        <div>
                          <span>{renderDescription(g, c)}</span>
                          <button
                            type="button"
                            className={`button text-left text-md-center button--edit ml-1 ${
                              g[c] ? 'button--link' : 'button--dark-blue'
                            }`}
                            onClick={() => onGrantClick(c, g)}
                            aria-label="edit"
                          />
                        </div>
                      ) : (
                        <span>{renderDescription(g, c)}</span>
                      )}
                    </td>
                  );
                })}
                <td className="py-1 px-0 text-center">
                  {onWireRequest ? (
                    <div className=" form-check pl-0 d-flex flex-column align-items-center flex-wrap justify-content-start ">
                      <input
                        className="form-check-input ml-0 position-relative"
                        type="checkbox"
                        checked={g.requestWireTransfer}
                        onChange={(e) => onWireRequest(g.granteeId, e.target.checked)}
                      />
                      {g.requestWireTransfer && (
                        <div className="grant-request__popup  mt-2 border border-dark px-1 mb-0 ">
                          A $150 fee is
                          <br className="d-none d-md-block" /> assessed for each
                          <br className="d-none d-md-block" /> wired grant
                        </div>
                      )}
                    </div>
                  ) : (
                    <span>{g.requestWireTransfer ? 'Yes' : 'No'}</span>
                  )}
                </td>
                <td align="center">
                  {onComment ? (
                    <>
                      <textarea
                        className="form-control table-comment_desktop"
                        rows="3"
                        value={g.comments}
                        onChange={(e) => onComment(g.granteeId, e.target.value)}
                      />
                      <span className="table-comment_mobile d-block d-md-none">{g.comments}</span>
                    </>
                  ) : (
                    <div className="text-left text-md-center">{g.comments}</div>
                  )}
                </td>
                <td className="d-table-cell d-md-none">
                  <div className="table-controls">
                    {onGrantClick ? (
                      <button
                        type="button"
                        className="border-0 onGrantClick"
                        onClick={() => {
                          onGrantClick('editMobile', g);
                        }}
                      >
                        <i className="bi bi-pencil-fill" />
                      </button>
                    ) : (
                      <button type="button" className="border-0 onEdit" onClick={() => onEdit(g)}>
                        <i className="bi bi-pencil-fill" />
                      </button>
                    )}
                    <button
                      type="button"
                      className="border-0 "
                      onClick={() => removeGrant(g.granteeId)}
                    >
                      <i className="bi bi-trash-fill" />
                    </button>
                  </div>
                </td>
                {removeGrant && (
                  <td className="d-none d-md-table-cell text-left align-middle">
                    <button
                      type="button"
                      className="button button--bin px-2 py-1"
                      onClick={() => removeGrant(g.granteeId)}
                      aria-label="edit"
                    />
                  </td>
                )}
                {onEdit && (
                  <td className="d-none d-md-table-cell text-left align-middle">
                    <button
                      type="button"
                      className="button button--edit px-2 py-1"
                      onClick={() => onEdit(g)}
                      aria-label="edit"
                    />
                  </td>
                )}
              </tr>
            );
          })}
          {!onGrantClick && (
            <tr className="font-weight-bold">
              <td align="left">Total</td>
              <td align="right">${displayCurrency(totalGrantsValue)}</td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td className="d-none d-md-table-cell" />
              <td className="d-none d-md-table-cell" />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GrantTable;
