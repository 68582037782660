import React from 'react';

export const DownloadPdfPrimaryAdvisors = ({ blobPdf }) => {
  const fileURL = window.URL.createObjectURL(blobPdf);

  return (
    <a download="Primary advisors and default communication elections.pdf" href={fileURL}>
      <i className="bi bi-cloud-arrow-down-fill text-dark" />
    </a>
  );
};
