/* eslint-disable react/destructuring-assignment */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerInput = (props) => {
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className={`${props?.className || ''} datepicker`}
      onClick={onClick}
      onChange={onChange}
      disabled={props.disabled}
      ref={ref}
      placeholder="mm/dd/yyyy"
    />
  ));
  return (
    <DatePicker
      customInput={<CustomInput />}
      dateFormat="MM/dd/yyyy"
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={30}
      {...props}
    />
  );
};
