const AcknowlegementOptions = [
  { value: 4, label: 'Anonymous' },
  { value: 2, label: 'Account Name Only' },
  { value: 3, label: 'Account Name and Full Name' },
  { value: 1, label: 'Account Name, Full Name, and Address' },
  { value: 5, label: 'Recognize Someone Else ' },
];

const FrequencyOptions = [
  { value: 1, label: 'Monthly' },
  { value: 2, label: 'Quarterly' },
  { value: 4, label: 'Yearly' },
];

export { FrequencyOptions, AcknowlegementOptions };
