import React from 'react';
import { Form } from 'react-bootstrap';

export const InputWithoutValidation = ({ name, register, id, label, labelWidth, inputWidth }) => {
  return (
    <Form.Label htmlFor={id} className={`col-${labelWidth} no-gutters d-flex align-items-start`}>
      <strong className="col-auto mt-1">{label}</strong>
      <div className={`ml-3 col-${inputWidth}`}>
        <Form.Control id={id} className="p-1 w-100" {...register(name)} />
      </div>
    </Form.Label>
  );
};
