import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { GrantsProvider } from 'Providers/Grants';
import Navigation from './Navigation';
import { AddGrantees, AddAmount, AddGrantInfo, AllocatePool, ReviewGrants } from './Steps';

const GrantRequest = ({ location }) => {
  const [fund] = useState(location.state?.fund);
  const [subStep, setSubStep] = useState(null);

  if (!fund) {
    return <Redirect to="/" />;
  }

  return (
    <GrantsProvider fund={fund}>
      <StepWizard
        nav={<Navigation subStep={subStep} setSubStep={setSubStep} fund={fund} />}
        transitions={{}}
        className="grant-request mt-n3 mt-md-0"
        isHashEnabled
        isLazyMount
      >
        <AddGrantees fund={fund} hashKey="grantees" />
        <AddAmount fund={fund} hashKey="amount" />
        <AddGrantInfo subStep={subStep} setSubStep={setSubStep} hashKey="info" />
        <AllocatePool fund={fund} hashKey="allocate" />
        <ReviewGrants hashKey="review" setSubStep={setSubStep} subStep={subStep} fund={fund} />
      </StepWizard>
    </GrantsProvider>
  );
};

export default GrantRequest;
