import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      margin: '10px',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = (props) => {
  const { amount, allocations, type, sourcePool, fund } = props;
  const isExchange = type === 'exchange';

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text>
            {`Congratulations! Your ${type} has been successfully submitted. Please, check your
            email to see details of the ${type}. Please allow 3 to 7 business days for the ${type} to occur.`}
          </Text>
          <Table>
            {isExchange ? (
              <>
                <TableRow>
                  <TableCell textAlign="right">{`${type.charAt(0).toUpperCase()}${type.slice(
                    1
                  )} from:`}</TableCell>
                  <TableCell textAlign="left">{sourcePool}</TableCell>
                  <TableCell textAlign="right">{`$${displayCurrency(amount)}`}</TableCell>
                </TableRow>
                {allocations?.map((pool, i) => (
                  <TableRow>
                    <TableCell textAlign="right">{i === 0 && 'To:'}</TableCell>
                    <TableCell textAlign="left">{pool.name}</TableCell>
                    <TableCell textAlign="right">{`$${displayCurrency(pool.amount)}`}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell textAlign="right" />
                  <TableCell textAlign="left">Total:</TableCell>
                  <TableCell textAlign="right">{`$${displayCurrency(amount)}`}</TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {fund?.poolFundUnits?.map((pool, i) => (
                  <TableRow>
                    <TableCell textAlign="right">{i === 0 && 'From:'}</TableCell>
                    <TableCell textAlign="left">{pool.name}</TableCell>
                    <TableCell textAlign="right">
                      {(
                        ((pool.poolFundValue - pool.pendingAmount) /
                          (fund.totalValue - fund.pendingGrantAmount)) *
                        100
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell textAlign="right" />
                  <TableCell textAlign="left">Total:</TableCell>
                  <TableCell textAlign="right">{`${displayCurrency(100)}%`}</TableCell>
                </TableRow>
                {allocations?.map((pool, i) => (
                  <TableRow>
                    <TableCell textAlign="right">{i === 0 && 'To:'}</TableCell>
                    <TableCell textAlign="left">{pool.name}</TableCell>
                    <TableCell textAlign="right">{`${displayCurrency(pool.amount)}%`}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell textAlign="right" />
                  <TableCell textAlign="left">Total:</TableCell>
                  <TableCell textAlign="right">{`${displayCurrency(100)}%`}</TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell textAlign="right">Submitted Date:</TableCell>
              <TableCell textAlign="left">{displayDate(new Date())}</TableCell>
              <TableCell textAlign="right" />
            </TableRow>
          </Table>
        </View>
      </Page>
    </Document>
  );
};
