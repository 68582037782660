import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { PHONE_VALIDATION_REGEX } from 'components/Main/constants';
import { regExp } from '../../common/constants';
import SuccessorModalInstruction from './SuccessorModalInstruction/SuccessorModalInstruction';
import { InputWithValidation } from '../../PrimaryAdvisorsElection/InputWithValidation/InputWithValidation';
import { InputWithoutValidation } from '../../PrimaryAdvisorsElection/InputWithoutValidation/InputWithoutValidation';
import { PrefixRadiosModal } from '../../PrimaryAdvisorsElection/CoPrimaryAdvisorModal/PrefixRadiosModal/PrefixRadiosModal';
import { NavigateButtons } from '../../common/NavigateButtons/NavigateButtons';
import { handleInputNumberValue } from '../../common/helpers';

export const AdvisorsModal = ({
  successorAdvisor,
  cities,
  states,
  isAdvisorsModalOpen,
  closeModal,
  saveAdvisor,
  unallocatedFunds,
}) => {
  const resolver = yupResolver(
    yup.object().shape({
      firstName: yup.string().required('Required field').nullable(true),
      lastName: yup.string().required('Required field').nullable(true),
      mailingAddress: yup.string().required('Required field').nullable(true),
      email: yup
        .string()
        .email('Invalid email address ')
        .required('Required field')
        .max(60, 'Incorrect input')
        .nullable(true),
      preferredPhone: yup
        .string()
        .required('Required field')
        .matches(PHONE_VALIDATION_REGEX, {
          message: 'Please enter 10 digits phone number',
          excludeEmptyString: true,
        })
        .nullable(true),
      secondaryPhone: yup
        .string()
        .matches(PHONE_VALIDATION_REGEX, {
          message: 'Please enter 10 digits phone number',
          excludeEmptyString: true,
        })
        .nullable(true),
      prefix: yup.string().nullable(true),
      prefixOther: yup
        .string()
        .when('prefix', {
          is: (prefix) => {
            return !regExp.IS_PREFIX_OTHER.test(prefix);
          },
          then: yup.string().required('Required field'),
        })
        .nullable(true),
      city: yup.string().required('Required field').nullable(true),
      state: yup.string().required('Required field').nullable(true),
      zip: yup.string().required('Required field').max(10, 'Incorrect input').nullable(true),
      relationshipToPrimaryAdvisor: yup.string().required('Required field').nullable(true),
      jointlyHeldPercentage: yup
        .number()
        .when('coOwnershipChoice', {
          is: (coOwnershipChoice) => coOwnershipChoice === '1',
          then: yup
            .number()
            .min(1, 'Incorrect input')
            .required('Required field')
            .test('max', 'Incorrect input', function validate(value) {
              return value <= unallocatedFunds + (successorAdvisor?.jointlyHeldPercentage || 0);
            })
            .nullable(true),
        })
        .nullable(true),
      coOwnershipChoice: yup.string().required(),
    })
  );

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    trigger,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onChange ',
    resolver,
  });

  const [prefix, setPrefix] = useState();
  const [successorAdvisorSettings, setSuccessorAdvisorSettings] = useState(null);
  const [coOwnershipChoice, setCoOwnershipChoice] = useState(successorAdvisor?.coOwnershipChoice);
  const [jointlyHeldPercentage, setJointlyHeldPercentage] = useState(
    successorAdvisor?.jointlyHeldPercentage
  );

  useEffect(() => {
    setPrefix(successorAdvisor?.prefix);
    setSuccessorAdvisorSettings({
      ...successorAdvisor,
      prefixOther: regExp.IS_PREFIX_OTHER.test(successorAdvisor?.prefix)
        ? ''
        : successorAdvisor?.prefix,
    });
  }, []);

  useEffect(() => {
    reset(successorAdvisorSettings);
  }, [successorAdvisorSettings]);

  const submit = (data) => {
    saveAdvisor({
      ...data,
      idFront: successorAdvisor?.idFront || _.uniqueId(),
      id: successorAdvisor?.id || 0,
      jointlyHeldPercentage: coOwnershipChoice === '1' ? data.jointlyHeldPercentage : null,
    });
    closeModal();
  };

  useEffect(() => {
    if (isSubmitted) {
      trigger('jointlyHeldPercentage');
      trigger('coOwnershipChoice');
    }
  }, [coOwnershipChoice, jointlyHeldPercentage]);

  useEffect(() => {
    if (coOwnershipChoice === '2') setValue('continueHoldingAccount', false);
  }, [coOwnershipChoice]);

  useEffect(() => {
    if (regExp.IS_PREFIX_OTHER.test(prefix)) {
      setValue('prefixOther', '');
      clearErrors('prefixOther');
    }
  }, [prefix]);

  return (
    <Modal show={isAdvisorsModalOpen} className="grantee-history-modal" centered size="xl">
      <Modal.Body>
        <div className="container daf-container">
          <SuccessorModalInstruction />
          <div className="my-4">
            <strong>Successor Advisor:</strong>
          </div>
          <div className="row">
            <InputWithValidation
              register={register}
              name="firstName"
              errors={errors}
              id="daf-first-name-modal"
              label="First name: "
              labelWidth={4}
              inputWidth="auto"
            />
            <InputWithValidation
              register={register}
              name="lastName"
              errors={errors}
              id="daf-last-name-modal"
              label="Last name:"
              labelWidth={4}
              inputWidth="auto"
            />
            <InputWithoutValidation
              register={register}
              name="suffix"
              id="daf-suffix-modal"
              label="Suffix:"
              labelWidth={4}
              inputWidth="auto"
            />
          </div>
          <div className="my-3 d-flex daf-prefixes-container">
            <PrefixRadiosModal prefix={prefix} setPrefix={setPrefix} setValue={setValue} />
            <div className="w-25">
              <Controller
                name="prefixOther"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    className="p-1 w-100"
                    value={value}
                    disabled={regExp.IS_PREFIX_OTHER.test(prefix)}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setPrefix(e.target.value);
                      setValue('prefix', e.target.value);
                    }}
                    isInvalid={errors.prefixOther}
                  />
                )}
              />
              {errors.prefixOther && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.prefixOther.message}
                </div>
              )}
            </div>
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="mailingAddress"
              errors={errors}
              id="daf-mailing-address-modal"
              label="Mailing address:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="row my-3">
            <label
              htmlFor="daf-city-modal-input"
              className="col-4 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">City:</strong>
              <div className="ml-3 col-9">
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value: cityValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={cityValue}
                          {...inputProps}
                          isInvalid={!!errors.city}
                          id="daf-city-modal-input"
                        />
                      )}
                      minLength={3}
                      options={cities}
                      id="daf-city-modal"
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-city w-100"
                      onChange={([city]) => {
                        onChange(city);
                      }}
                    />
                  )}
                />
                {errors.city && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.city.message}
                  </div>
                )}
              </div>
            </label>
            <label
              htmlFor="daf-state-modal-input"
              className="col-2 mr-3 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">State:</strong>
              <div className="ml-3 col-7">
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { value: stateValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={stateValue}
                          {...inputProps}
                          isInvalid={!!errors.state}
                          maxLength="2"
                          id="daf-state-modal-input"
                        />
                      )}
                      options={states}
                      id="daf-state-modal"
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-state w-100"
                      onChange={([state]) => {
                        onChange(state);
                      }}
                    />
                  )}
                />
                {errors.state && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.state.message}
                  </div>
                )}
              </div>
            </label>
            <InputWithValidation
              register={register}
              name="zip"
              errors={errors}
              id="daf-zip-modal"
              label="Zip:"
              labelWidth={2}
              inputWidth={8}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="preferredPhone"
              errors={errors}
              id="daf-preferred-phone-modal"
              label="Preferred phone:"
              labelWidth={6}
              inputWidth={6}
              isPhoneInput
              control={control}
            />
          </div>
          <div className="row my-3">
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Form.Label
                  htmlFor="daf-secondary-phone-modal"
                  className="col-6 no-gutters d-flex align-items-start"
                >
                  <strong className="col-auto mt-1">Secondary phone:</strong>
                  <div className="ml-3 col-6">
                    <NumberFormat
                      {...field}
                      value={value}
                      type="tel"
                      format="### ### ####"
                      onValueChange={(v) => {
                        onChange(v.formattedValue);
                      }}
                      className={`form-control p-1 w-100 ${
                        errors.secondaryPhone ? 'is-invalid' : ''
                      }`}
                      id="daf-secondary-phone"
                    />
                    {errors.secondaryPhone && (
                      <div className="validation-alert text-left ">
                        {errors.secondaryPhone.message}
                      </div>
                    )}
                  </div>
                </Form.Label>
              )}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="email"
              errors={errors}
              id="daf-email-modal"
              label="Email address:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="relationshipToPrimaryAdvisor"
              errors={errors}
              id="relationship-to-primary-advisor"
              label="Relationship to primary advisor:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="my-3">
            <Controller
              name="coOwnershipChoice"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <>
                  <div className="d-flex align-items-start ">
                    <input
                      {...field}
                      type="radio"
                      className="sunsetting-daf-years__radio"
                      id="co-ownership-choice-jointly-held"
                      value="1"
                      checked={value === '1'}
                      onChange={() => {
                        onChange('1');
                        setCoOwnershipChoice('1');
                      }}
                    />
                    <Form.Label
                      htmlFor="co-ownership-choice-jointly-held"
                      className="ml-3 mb-0  d-flex align-items-start"
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <div className="flex-shrink-0 sunsetting-daf-years__text">
                            Jointly held
                          </div>
                          <div className="col-3">
                            <Controller
                              name="jointlyHeldPercentage"
                              control={control}
                              render={({
                                field: {
                                  value: valueInput,
                                  onChange: onChangeYears,
                                  ...fieldYears
                                },
                              }) => (
                                <Form.Control
                                  {...fieldYears}
                                  type="number"
                                  id="jointly-held-percentage-input"
                                  className="p-1 w-100 daf-number-input"
                                  value={valueInput ?? ''}
                                  onChange={(e) => {
                                    onChangeYears(handleInputNumberValue(e));
                                    setJointlyHeldPercentage(handleInputNumberValue(e));
                                  }}
                                  disabled={coOwnershipChoice !== '1'}
                                />
                              )}
                            />
                            {errors.jointlyHeldPercentage && (
                              <div className="validation-alert text-left " style={{ width: 120 }}>
                                {errors.jointlyHeldPercentage.message}
                              </div>
                            )}
                          </div>
                          <div className="flex-shrink-0 sunsetting-daf-years__text">
                            % of account
                          </div>
                        </div>
                        {coOwnershipChoice === '1' && (
                          <div className="d-flex align-items-start mt-2">
                            <input
                              type="checkbox"
                              className="mt-1"
                              id="continue-holding-an-account"
                              {...register('continueHoldingAccount')}
                            />
                            <Form.Label
                              htmlFor="continue-holding-an-account"
                              className="ml-3 mb-0 d-flex align-items-start"
                            >
                              Continue holding an account
                            </Form.Label>
                          </div>
                        )}
                      </div>
                    </Form.Label>
                  </div>
                  <div className="my-3">
                    <div className="d-flex align-items-center">
                      <input
                        {...field}
                        type="radio"
                        id="co-ownership-choice-not-bound"
                        value="2"
                        checked={value === '2'}
                        onChange={() => {
                          onChange('2');
                          setCoOwnershipChoice('2');
                        }}
                      />
                      <Form.Label
                        htmlFor="co-ownership-choice-not-bound"
                        className="ml-3 mb-0 d-flex align-items-center"
                      >
                        Is not bound by Donor Intent Statement.
                      </Form.Label>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
          {errors.coOwnershipChoice && (
            <div className="d-flex justify-content-end mt-4 mt-md-0">
              <div className="grant-instructions px-4 py-2 text-center daf-error-popup">
                <small>Please, make your co-ownership choice</small>
              </div>
            </div>
          )}
          <div className="my-4">
            <NavigateButtons
              onClickCancel={() => {
                setSuccessorAdvisorSettings(null);
                reset();
                closeModal();
              }}
              onClickSubmit={handleSubmit(submit)}
              submitButtonText="Save"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
