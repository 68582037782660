import React from 'react';
import ProfileForm from './components/ProfileForm';
import PasswordForm from './components/PasswordForm';

const ManageProfile = () => {
  return (
    <div className="container contact-information">
      <div className="row">
        <div className="col">
          <h2>Contact Information</h2>
          <ProfileForm />
          <hr className="mt-4 mb-4 my-md-3" />
          <h2 className="mb-0 mb-md-2">Account Access</h2>
          <div className="row">
            <div className="col-12 col-md-4">
              <PasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageProfile;
