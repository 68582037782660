import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { State, City } from 'country-state-city';
import { pdf } from '@react-pdf/renderer';
import { ApiContext } from 'components/ApiProvider';
import SuccessorAdvisorsInstruction from './Instruction/SuccessorAdvisorsInstruction';
import { NavigateButtons } from '../common/NavigateButtons/NavigateButtons';
import { AdvisorsModal } from './AdvisorsModal/AdvisorsModal';
import { Pdf } from './Pdf/Pdf';
import {
  handleDataSuccessorAdvisors,
  findDeletedIds,
  findChangedValuesSuccessorAdvisors,
} from '../common/helpers';
import { SuccessorAdvisorsInfo } from './SuccessorAdvisorsInfo/SuccessorAdvisorsInfo';

const SuccessorAdvisors = () => {
  const history = useHistory();
  const { fundId } = useParams();
  const {
    getSuccessorAdvisorsInfo,
    updateSuccessorAdvisorElection,
    sendNotificationAboutDonorAdvisedFundChanges,
    getFund,
  } = useContext(ApiContext);

  const [fund, setFund] = useState();
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);
  const [unallocatedFunds, setUnallocatedFunds] = useState(100);
  const [isAdvisorsModalOpen, setIsAdvisorsModalOpen] = useState(false);
  const [previousSettings, setPreviousSettings] = useState(null);
  const [checkedAdvisor, setCheckedAdvisor] = useState(null);
  const [successorAdvisorsData, setSuccessorAdvisorsData] = useState(null);
  const [successorAdvisors, setSuccessorAdvisors] = useState([]);
  const [successorAdvisorOption, setSuccessorAdvisorOption] = useState(null);

  const resolver = yupResolver(
    yup.object().shape({
      successorAdvisors: yup.array().when('successorAdvisorOption', {
        is: () => successorAdvisorOption === '2',
        then: yup.array().test('required', function validate() {
          return successorAdvisors?.length;
        }),
      }),
    })
  );

  const {
    handleSubmit,
    trigger,
    control,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    resolver,
  });

  useEffect(() => {
    const getData = async () => {
      const data = handleDataSuccessorAdvisors(await getSuccessorAdvisorsInfo(fundId));
      const fundData = await getFund(fundId);
      setFund(fundData);
      setSuccessorAdvisors(data.successorAdvisors);
      setSuccessorAdvisorOption(data.successorAdvisorOption);
      setPreviousSettings(data);
      setSuccessorAdvisorsData(data);
    };
    getData();
    setStates(
      State.getStatesOfCountry('US')
        .filter((state) => state.isoCode.length === 2)
        .map((state) => state.isoCode)
    );
    setCities([...new Set(City.getCitiesOfCountry('US').map((city) => city.name))]);
  }, []);

  useEffect(() => {
    reset(successorAdvisorsData);
  }, [successorAdvisorsData]);

  useEffect(() => {
    let unallocatedPercentages = 100;
    if (successorAdvisors?.length)
      successorAdvisors.forEach((advisor) => {
        unallocatedPercentages -= advisor.jointlyHeldPercentage || 0;
      });
    setUnallocatedFunds(unallocatedPercentages);
  }, [JSON.stringify(successorAdvisors)]);

  const saveAdvisor = (advisor) => {
    if (!successorAdvisors?.some((p) => p?.idFront === advisor.idFront)) {
      setSuccessorAdvisors((prev) => prev.concat(advisor));
    } else {
      setSuccessorAdvisors((prev) =>
        prev.map((x) => (x.idFront === advisor.idFront ? advisor : x))
      );
    }
  };

  const deleteAdvisorById = (idFront) => {
    setSuccessorAdvisors((prev) => prev.filter((charity) => charity.idFront !== idFront));
  };

  useEffect(() => {
    if (isSubmitted) trigger('successorAdvisors');
  }, [JSON.stringify(successorAdvisors), successorAdvisorOption]);

  const submit = async (data) => {
    const newSettings = handleDataSuccessorAdvisors({ ...data, successorAdvisors });
    const changedOptions = findChangedValuesSuccessorAdvisors(previousSettings, newSettings);
    const deletedSuccesorAdvisorIds = findDeletedIds(
      previousSettings.successorAdvisors,
      successorAdvisors
    );

    if (Object.keys(changedOptions).length) {
      const blobPdf = await pdf(Pdf(changedOptions)).toBlob();

      const formData = new FormData();
      formData.append('updatesFile', blobPdf, 'Successor advisors.pdf');

      await updateSuccessorAdvisorElection({
        ...newSettings,
        successorAdvisorOption: Number(successorAdvisorOption),
        fundId,
        deletedSuccesorAdvisorIds,
      })
        .then(async () => {
          await sendNotificationAboutDonorAdvisedFundChanges(formData);
        })
        .then(() => {
          history.push({
            pathname: '/daf/submitted',
            state: { changedOptions, startingPage: 'successorAdvisors', fund, blobPdf },
          });
        })
        .catch(console.log);
    } else {
      history.push({ pathname: '/fund/overview', state: { fund } });
    }
  };

  return (
    <>
      <SuccessorAdvisorsInstruction />
      <div className="container daf-container">
        <div className="my-4">
          <strong>Successor Advisors.</strong> You may appoint successor advisors for an account. If
          you appoint successor advisors, you can request that we only allow successor advisors to
          make distributions to charities that fall within your mission statement, or you may give
          the successor advisor(s) free reign over the account (although, like all advisors, only
          advice for a grant that falls within DonorsTrust’s mission would be accepted). The
          account(s) must still sunset within the time limits. If you name more than one successor
          advisor, the account can be split, provided each advisor’s account will be funded with an
          amount of at least $10,000.00. If you don’t split the account, successor advisors must
          unanimously agree to any advice they provide. Please complete the following if you would
          like to name a successor advisor(s):
        </div>
        <div className="my-4">
          <Controller
            name="successorAdvisorOption"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <>
                <div className="d-flex align-items-start">
                  <input
                    {...field}
                    type="radio"
                    id="successor-advisors-custom"
                    className="mt-1"
                    value="1"
                    checked={value === '1'}
                    onChange={() => {
                      onChange('1');
                      setSuccessorAdvisorOption('1');
                    }}
                    disabled={successorAdvisors?.length > 0}
                  />
                  <Form.Label htmlFor="successor-advisors-custom" className="ml-3 mb-0">
                    Custom Plan. I have or am in the process of submitting a custom sunsetting plan
                    with DonorsTrust. That plan deals with the issue of successor advisors or
                    instructions to DonorsTrust on how to steward the account.
                  </Form.Label>
                </div>
                <div className="my-4">
                  <div className="d-flex align-items-start">
                    <input
                      {...field}
                      type="radio"
                      id="successor-advisors-individuals"
                      value="2"
                      checked={value === '2'}
                      className="mt-1"
                      onChange={() => {
                        onChange('2');
                        setSuccessorAdvisorOption('2');
                      }}
                    />
                    <Form.Label htmlFor="successor-advisors-individuals" className="ml-3 mb-0">
                      I would like to name the following individuals as successor advisors:
                    </Form.Label>
                  </div>
                </div>
                {successorAdvisorOption === '2' && (
                  <div className="my-4">
                    <button
                      type="button"
                      className="button p-0 d-flex align-items-center"
                      disabled={successorAdvisors?.length === 2}
                      onClick={() => setIsAdvisorsModalOpen(true)}
                    >
                      <i className="bi bi-plus-circle daf-plus-icon" />
                      <strong className="ml-2">Add successor advisor:</strong>
                    </button>
                    <div className="my-3">
                      {successorAdvisors &&
                        successorAdvisors.map((advisor) => (
                          <SuccessorAdvisorsInfo
                            key={advisor.frontId}
                            advisor={advisor}
                            {...advisor}
                            deleteAdvisorById={(id) => deleteAdvisorById(id)}
                            editAdvisor={(advisorData) => {
                              setCheckedAdvisor(advisorData);
                              setIsAdvisorsModalOpen(true);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                )}
                <div className="my-4">
                  <div className="d-flex align-items-start">
                    <input
                      {...field}
                      type="radio"
                      id="successor-advisors-without"
                      value="3"
                      className="mt-1"
                      checked={value === '3'}
                      onChange={() => {
                        setSuccessorAdvisorOption('3');
                        onChange('3');
                      }}
                      disabled={successorAdvisors?.length > 0}
                    />
                    <Form.Label htmlFor="successor-advisors-without" className="ml-3 mb-0">
                      I do not want to have a successor advisor.
                    </Form.Label>
                  </div>
                </div>
              </>
            )}
          />
        </div>
        <div className="my-3">
          {errors.successorAdvisors && (
            <div className="d-flex justify-content-end mt-4 mt-md-0">
              <div className="grant-instructions px-4 py-2 text-center daf-error-popup">
                <small>Please, add at least one successor advisor</small>
              </div>
            </div>
          )}
        </div>
        <div className="my-4">
          <NavigateButtons
            onClickCancel={() => history.push({ pathname: '/fund/overview', state: { fund } })}
            onClickSubmit={handleSubmit(submit)}
            submitButtonText="Submit"
          />
        </div>
      </div>
      {isAdvisorsModalOpen && (
        <AdvisorsModal
          isAdvisorsModalOpen={isAdvisorsModalOpen}
          successorAdvisor={checkedAdvisor}
          saveAdvisor={(advisor) => saveAdvisor(advisor)}
          cities={cities}
          states={states}
          unallocatedFunds={unallocatedFunds}
          closeModal={() => {
            setIsAdvisorsModalOpen(false);
            setCheckedAdvisor(null);
          }}
        />
      )}
    </>
  );
};

export default SuccessorAdvisors;
