import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const EmptyEmailOrPhoneAlertModal = ({ showModal, onClose, submit }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal show={show} onHide={handleClose} className="new-grantee-alert">
      <Modal.Body>
        <div>
          Approving the New Grantee may take longer then 10 business days if you submit this form
          without a Phone and/or an Email
        </div>
        <div className="text-center mt-5">
          <button
            className="button button--dark-blue py-2 px-3 mr-5"
            type="button"
            onClick={submit}
          >
            Submit
          </button>
          <button className="button button--gray py-2 px-3" type="button" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmptyEmailOrPhoneAlertModal;
