import React, { useState, useCallback, useEffect, useContext } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { ApiContext } from 'components/ApiProvider/';
import useLocalStorage from 'Hooks/LocalStorage';
import useCurrentFund from 'Hooks/CurrentFund';
import { AuthenticationContext } from 'auth/AuthProvider';

const PlaidLink = ({ successCallback = () => {}, errorCallback = () => {} }) => {
  const [linkToken, setLinkToken] = useLocalStorage('dt-link-token', '');
  const [loading, setLoading] = useState(false);
  let isOauth = false;
  const fund = useCurrentFund();
  const { user } = useContext(AuthenticationContext);
  const { initBankAuth, exchangeBankToken } = useContext(ApiContext);

  const onSuccess = useCallback(async (token) => {
    try {
      await exchangeBankToken(token, user.id, fund.id);
      successCallback();
    } catch (e) {
      console.log(e);
      errorCallback();
    } finally {
      setLinkToken('');
    }
  }, []);

  const onExit = (err) => {
    if (err) {
      setLinkToken('');
      errorCallback();
    }

    return null;
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  if (window.location.href.includes('?oauth_state_id=')) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  const initPlaidLink = async () => {
    if (linkToken === '') {
      try {
        setLoading(true);

        const data = await initBankAuth(user.id, fund.id);
        const { linkToken: l } = data;

        setLinkToken(l);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      open();
    }
  };

  useEffect(() => {
    if (!isOauth && linkToken !== '' && ready) {
      open();
    }

    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  if (isOauth) {
    return <p>Authorizing...</p>;
  }

  return (
    <button
      className="button button--dark-blue px-3 py-2"
      type="button"
      onClick={initPlaidLink}
      disabled={loading || !ready}
    >
      Connect your bank account
    </button>
  );
};

export default PlaidLink;
