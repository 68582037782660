import React, { useContext, useState, useEffect } from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { displayCurrency } from 'util/money';
import { GrantsContext } from 'Providers/Grants';
import Instructions from '../Instructions';

export const AmountForm = ({ grant, updateGrant, editGrantValidationErrors, isLastStep }) => {
  const { validationErrors } = useContext(GrantsContext);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setErrorMessage(
      validationErrors?.find((e) => e.name === 'amount')?.message ||
        editGrantValidationErrors?.find((e) => e.name === 'amount')?.message
    );
  }, [editGrantValidationErrors, validationErrors]);

  return (
    <Form.Row key={grant.granteeId} className="justify-content-center mb-2 mb-md-4 amount">
      <Col xs={12} md={4} className="mr-0 mr-md-4 text-left">
        <Form.Label className="text-left font-weight-bold m-0 py-2 subtitle">
          {isLastStep ? 'Grant Amount:' : grant.granteeName}
        </Form.Label>
      </Col>
      <Col xs={12} md={4} className="amount-input">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup.Prepend>
          <CurrencyInput
            required
            customInput={Form.Control}
            allowNegativeValue={false}
            decimalsLimit={2}
            onValueChange={(value) => updateGrant('totalAmount', value)}
            decimalSeparator="."
            decimalScale={2}
            value={grant.totalAmount}
            className={`text-right ${errorMessage ? 'border-danger' : String.empty}`}
          />
        </InputGroup>
        <div className="validation-alert">{errorMessage}</div>
      </Col>
    </Form.Row>
  );
};

export const AddAmount = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const { grants, updateGrant } = useContext(GrantsContext);

  const setGrantAmount = (granteeId, value) => {
    updateGrant(granteeId, 'totalAmount', value);
  };

  const totalValue = grants.reduce((acc, grant) => {
    return acc + (Number(grant.totalAmount) || 0);
  }, 0);
  const remainingFundValue = fund.totalValue - totalValue;
  const availableGrantAmount = (remainingFundValue - fund.pendingGrantAmount).toFixed(2);

  return (
    <>
      <Instructions title="Add Amount" currentStep={currentStep} totalSteps={totalSteps}>
        Please enter the grant amount you would like to give for each chosen grantee.
      </Instructions>
      <div className="grant-request__available-funds text-center container">
        <h2 className="mb-3 font-weight-normal">
          <u>
            <span className="grant-request__sum">&#8721;</span> Total Funds:{' '}
            <span className="font-weight-bold">${displayCurrency(remainingFundValue)}</span>
          </u>
        </h2>
        <h2 className="mb-3 font-weight-normal">
          Pending (<i>grants in the process</i>): ${displayCurrency(fund.pendingGrantAmount)}
        </h2>
        <h2 className="mb-4 mb-md-5 font-weight-normal">
          Uncommitted funds: ${displayCurrency(availableGrantAmount)}
        </h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            {grants.map((grant) => (
              <AmountForm
                grant={grant}
                key={grant.granteeId}
                updateGrant={(key, value) => setGrantAmount(grant.granteeId, value)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
