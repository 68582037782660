import { TokenExpiredIcon } from 'components/ToastNotify/TokenExpiredIcon';
import { toast } from 'react-toastify';

export const showErrorToast = (url = '') => {
  const urlsWithoutErrorHandling = ['/update-password', '/auth/login', '/auth/change-password'];
  if (!urlsWithoutErrorHandling.some((skippedUrl) => url.includes(skippedUrl))) {
    toast.error(
      'Sorry, there were some technical issues while processing your request. Please try again later.',
      {
        toastId: 'errorToast',
      }
    );
  }
};

export const showExpireTokenToast = () => {
  toast.info('Your session has expired due to inactivity. Please log in again.', {
    toastId: 'errorToast',
    icon: TokenExpiredIcon,
  });
};
