import React from 'react';
import ContactUsForm from 'components/Main/ContactUs/form';

export default () => {
  return (
    <div className="container contact-us">
      <div className="row">
        <div className="col">
          <h1 className="contact-us__title mb-3">Contact Us</h1>
          <div className="mb-4 mb-md-5">
            <p>Have a Question?</p>
            <p style={{ maxWidth: '800px' }}>
              The DonorsTrust team is available from 9:00 am – 5:00 pm ET to answer questions and
              discuss your giving. You are also welcome to submit a question or request directly to
              our grants team using the form below.
            </p>
            <p>Email: dtgrants@donorstrust.org</p>
            <p>Phone: (703) 535-3563</p>
            <p>Fax: (703) 535-3564</p>
            <p>
              Mailing Address:
              <br />
              1800 Diagonal Rd
              <br />
              Suite 280
              <br />
              Alexandria, VA 22314
            </p>
          </div>
          <div className="row">
            <div className="col col-md-8">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
