import React, { useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ResetPasswordModal } from 'components/Main/Login/ResetPasswordModal';
import { AuthenticationContext } from 'auth/AuthProvider';
import { useLogin } from './hooks';
import { resolver } from './constants';

export default () => {
  const [showModal, setShow] = useState(false);
  const { isLoggedIn } = useContext(AuthenticationContext);
  const { login } = useLogin();

  const toggleModal = () => setShow((prev) => !prev);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver });

  if (isLoggedIn) return <Redirect to="/" />;

  return (
    <div className="login-wrapper">
      <div className="login-banner px-3 px-md-0" />
      <div className="container my-md-3">
        <h3 className="login-header mb-0 mb-md-3 text-center">
          Welcome to Your DonorsTrust Dashboard
        </h3>
        <div className="row">
          <div className="col col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <Alert variant="info" className="alert">
              Welcome to the new and improved client portal! We recently upgraded our servers, so if
              you have not already seen this message and done so, please initiate a password reset
              by clicking the <strong>Reset Password</strong> link below. For assistance, contact
              DonorsTrust at dtgrants@donorstrust.org or call 703 535 3563.
            </Alert>
            <Form
              className="form my-4"
              onSubmit={handleSubmit((values) => login(values))}
              autoComplete="off"
            >
              <Form.Row className="align-items-center mx-0">
                <Form.Label className="pr-3 pl-0 col-auto col-md-4" align="right">
                  Username:
                </Form.Label>
                <Form.Control
                  className="col-12 col-md-8"
                  {...register('username')}
                  isInvalid={errors.username}
                  type="text"
                />
              </Form.Row>
              <Form.Row className="mt-3 align-items-center mx-0">
                <Form.Label className="pr-3 pl-0 col-auto col-md-4" align="right">
                  Password:
                </Form.Label>
                <Form.Control
                  className="col-12 col-md-8"
                  type="password"
                  {...register('password')}
                  isInvalid={errors.password}
                  autoComplete="new-password"
                />
              </Form.Row>
              <Form.Row className="mx-0">
                <div className="offset-md-4 w-100">
                  <button
                    className="button button--dark-blue mt-3 px-4 py-3 py-md-2 w-100"
                    type="submit"
                  >
                    Login
                  </button>
                  <Link to="/password/reset" className="d-block text-black w-100 text-center mt-3">
                    <u>Reset Password</u>
                  </Link>
                </div>
              </Form.Row>
            </Form>
          </div>
        </div>
      </div>
      <ResetPasswordModal show={showModal} onHide={toggleModal} />

      <div className="bottom-banner" />
    </div>
  );
};
