import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { ApiContext } from 'components/ApiProvider';
import FundTable from 'components/Main/YourFunds/components/FundTable';
import { displayCurrency } from 'util/money';
import { displayDate } from 'util/dates';
import useCurrentFund from 'Hooks/CurrentFund';
import { AuthenticationContext } from 'auth/AuthProvider';
import { showErrorToast } from 'util/showToast';
import GrantHistoryTable from './components/GrantHistoryTable';
import ContributionsHistoryTable from './components/ContributionsHistoryTable';
import Header from './components/Header';
import RecurringGrantsTable from './components/RecurringGrantsTable';
import DafLinksComponent from './components/DafLinksComponent';

const RequestGrantLink = ({ fund, className, children }) => {
  return (
    <Link to={{ pathname: '/fund/grants/request', state: { fund } }} className={className}>
      {children}
    </Link>
  );
};

export default ({ location }) => {
  const [grants, setGrants] = useState([]);
  const [contributions, setContributions] = useState([]);
  const [recurringGrants, setRecurringGrants] = useState([]);
  const fund = location.state?.fund;
  const {
    user: { isDafFunctionalityAvailable },
  } = useContext(AuthenticationContext);

  if (!fund) {
    return <Redirect to="/" />;
  }

  useCurrentFund(fund.id, fund.name);
  const { getGrants, getContributions, getFundHistory, getRecurringGrants } =
    useContext(ApiContext);
  const limit = 2;

  const downloadFundHistory = async () => {
    try {
      const data = await getFundHistory(fund.id);
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(data.data);
      link.download = data.filename;
      link.click();
    } catch {
      showErrorToast();
    }
  };

  useEffect(() => {
    async function getFundGrants() {
      const g = await getGrants(fund.id, limit, true);
      setGrants(g);
    }

    getFundGrants();
  }, []);

  useEffect(() => {
    async function getFundRecurringGrants() {
      const grantsList = await getRecurringGrants(fund.id, limit);
      setRecurringGrants(grantsList);
    }

    getFundRecurringGrants();
  }, []);

  useEffect(() => {
    async function getFundContributions() {
      const c = await getContributions(fund.id, limit);
      setContributions(c);
    }

    getFundContributions();
  }, []);

  return (
    <div>
      <div className="sub-nav mt-n4">
        <div className="back-btn container">
          <Link to="/">
            <strong>← Back to All Funds</strong>
          </Link>
        </div>
      </div>
      <div className="review-fund-wrapper container pt-4 pt-md-5">
        <div className="top-content row">
          <div className="left-panel col-xs-12 col-md-4">
            <div className="fund-info mb-2 mb-md-4">
              <div className="mb-2 mb-md-4">
                <h2>
                  <strong>{fund.name}</strong>
                </h2>
                <span className="text-muted">ID: {fund.id}</span>
              </div>
              <div className="asof mb-0 mb-md-2">
                <small>as of {displayDate(fund.dateLastChecked)}</small>
              </div>
              <h5>
                <strong>${displayCurrency(fund.totalValue - fund.pendingGrantAmount)}</strong>
              </h5>
            </div>
            <div className="d-flex flex-column mb-4 align-items-start">
              <RequestGrantLink className="my-2 my-md-1" fund={fund}>
                Request a Grant →
              </RequestGrantLink>
              <Link className="my-2 my-md-1" to="/fund/grants">
                Grant History →
              </Link>
              <Link className="my-2 my-md-1" to="/fund/recurrence">
                View Recurring Grants →
              </Link>
              <Link to={{ pathname: '/fund/statements', state: { fund } }} className="my-2 my-md-1">
                Download Statements →
              </Link>
              <button
                className="my-2 my-md-1 button button--link"
                type="button"
                onClick={downloadFundHistory}
              >
                Download Fund History (Excel) →
              </button>
              <Link
                to={{ pathname: '/fund/achcontributions/amount', state: { fund } }}
                className="my-2 my-md-1"
              >
                Make ACH/СС Contribution →
              </Link>
              {fund.poolFundUnits.length > 0 && fund?.totalValue - fund?.pendingGrantAmount > 0 && (
                <Link
                  className="my-2 my-md-1"
                  to={{ pathname: '/fund/grants/rebalancing', state: { fund } }}
                >
                  Reallocate Your Investments →
                </Link>
              )}
              {isDafFunctionalityAvailable === 'True' && <DafLinksComponent fund={fund} />}
            </div>
          </div>
          <div className="col-xs-12 col-md-8">
            <div>
              <FundTable fundTotal={fund.totalValue} fund={fund} pools={fund.poolFundUnits} />
            </div>
            <div className="mt-4 mt-md-5">
              <Header
                title="Grants"
                link="/fund/grants"
                description="View any past or pending grants"
              />
              <GrantHistoryTable grants={grants} />
            </div>
            <div className="mt-4 mt-md-5">
              <Header
                title="Recurring Grants"
                link="/fund/recurrence"
                description="View and manage your recurrence"
              />
              <RecurringGrantsTable short grants={recurringGrants} />
            </div>
            <div className="mt-4 mt-md-5">
              <Header
                title="Contribution History"
                link="/fund/contributions"
                description="View past contributions"
              />
              <ContributionsHistoryTable history={contributions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
