import React from 'react';
import { displayDate } from 'util/dates';
import { displayCurrency } from 'util/money';

// const INTERVAL_MAP = {
//   0: 'Weekly',
//   1: 'Monthly',
//   2: 'Yearly',
// };

const RecurringContributions = ({ contributions, removeCallback }) => {
  if (contributions.length === 0) {
    return (
      <div>
        <p>You do not have any recurring contributions.</p>
      </div>
    );
  }

  const cancelContribution = (id) => {
    const msg = 'Are you sure you want to cancel this recurring contribution?';

    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      removeCallback(id);
    }
  };

  return (
    <div className="funds-table-container">
      <table className="table funds-table">
        <thead>
          <tr>
            <th>Interval</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th className="text-right">Amount ($)</th>

            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {contributions.map((c) => (
            <tr key={c.id}>
              <td>
                <strong>{`${c.interval}ly`.toUpperCase()}</strong>
              </td>
              <td>{displayDate(c.startDate)}</td>
              <td>{displayDate(c.endDate)}</td>
              <td align="right">{displayCurrency(c.amount)}</td>
              <td align="right">
                <button
                  className="button button--dark-blue px-3 py-1"
                  type="button"
                  onClick={() => cancelContribution(c.id)}
                >
                  Cancel
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecurringContributions;
