import React from 'react';

export default ({ name, src }) => {
  const isPDF = /[^/]*\.(pdf)$/.test(src);

  return (
    <div className="form-box ">
      <h4 className="form-box__title">{name}</h4>
      <a className="form-box__src" href={src}>
        {isPDF ? '↓ Download pdf' : 'View page'}
      </a>
    </div>
  );
};
