import React, { useState, useContext, useEffect } from 'react';
import { GrantsContext } from 'Providers/Grants';
import { Form, InputGroup } from 'react-bootstrap';
import Instructions from 'components/Main/Grant/Request/Instructions';
import RadioInputToggle from 'components/Form/Input/RadioInputToggle';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { displayDate } from 'util/dates';

export const TimingForm = ({ grant, updateGrant, editGrantValidationErrors }) => {
  const { validationErrors } = useContext(GrantsContext);
  const [isRecurring, setIsRecurring] = useState(grant.recurring);
  const [withEndDate, setWithEndDate] = useState(grant.endDate && grant.endDate !== '');
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [minEndDate, setMinEndDate] = useState(null);
  const [isValidFrequency, setIsValidFrequency] = useState(true);
  const [startDateValidationError, setStartDateValidationError] = useState(null);
  const [editGrantErrors, setEditGrantErrors] = useState(editGrantValidationErrors);

  const handleCheck = (key, value, custom) => {
    setIsRecurring(false);
    updateGrant('recurring', false);
    updateGrant('frequency');
    updateGrant('startDate');
    updateGrant('endDate');
    updateGrant(key, value, custom);
  };

  const handleRecurring = () => {
    setIsRecurring(true);
    updateGrant('recurring', true);
    updateGrant('timing', 'On a recurring basis:');
  };

  useEffect(() => {
    setEditGrantErrors(editGrantValidationErrors);
  }, [editGrantValidationErrors]);

  useEffect(() => {
    if (
      validationErrors?.find((e) => e.name === 'frequency')?.message ||
      editGrantErrors?.find((e) => e.name === 'frequency')?.message
    ) {
      setIsValidFrequency(false);
    }

    if (validationErrors?.find((e) => e.name === 'startDate')?.message) {
      setStartDateValidationError(validationErrors?.find((e) => e.name === 'startDate')?.message);
    } else if (editGrantErrors?.find((e) => e.name === 'startDate')?.message) {
      setStartDateValidationError(editGrantErrors?.find((e) => e.name === 'startDate')?.message);
    }
  }, [validationErrors, editGrantErrors]);

  return (
    <Form className="form text-left timing-component">
      <Form.Group>
        <Form.Label as="legend" className="mt-3 mb-2 subtitle">
          Select your preferred timing:
        </Form.Label>
        <Form.Check
          checked={grant.timing === 'As soon as possible'}
          onChange={(e) => handleCheck('timing', e.target.value)}
          type="radio"
          value="As soon as possible"
          id="grant-request-as-soon-as-possible"
          htmlFor="grant-request-as-soon-as-possible"
          name="timing"
          label="As soon as possible"
          className="my-2"
        />
        <RadioInputToggle
          name="timing"
          label="On a specific future date*"
          onChange={(value, custom) => handleCheck('timing', 'On a specific future date:', custom)}
          isActive={grant.timing.includes('On a specific future date:')}
          inputPlaceholder="MM/DD/YYYY"
          type="date"
          inputValue={grant.timingCustomInput}
          validationErrors={validationErrors?.some((x) => x) ? validationErrors : editGrantErrors}
          min={moment(displayDate(new Date())).add(4, 'days').format('YYYY-MM-DD')}
          withoutInput
        />
        {grant.timing.includes('On a specific future date:') && (
          <div className="mb-2">
            <DatePickerInput
              id="grant-specific-future-date"
              selected={grant.timingCustomInput}
              className={`mt-1 transactions-datepicker ${
                validationErrors?.find((e) => e.name === 'timing')?.message ? 'invalid' : ''
              }`}
              onSelect={(e) => {
                const formattedDate = moment(e).toDate().toLocaleDateString('en-CA');
                updateGrant('timingCustomInput', e);
                updateGrant('timing', `On a specific future date: ${formattedDate}`);
              }}
              minDate={moment().add(4, 'days').toDate()}
            />
            <Form.Text class className="validation-message">
              {validationErrors?.find((e) => e.name === 'timing')?.message}
            </Form.Text>
          </div>
        )}
        <InputGroup>
          <Form.Check
            checked={isRecurring}
            onChange={handleRecurring}
            type="radio"
            value="recurring"
            id="grant-request-on-recurring-basis"
            htmlFor="grant-request-on-recurring-basis"
            name="timing"
            label="On a recurring basis"
          />
          {isRecurring && (
            <>
              <i className="bi bi-question-circle my-auto ml-2" data-tip />
              <ReactTooltip
                place="top"
                className="tooltip"
                effect="solid"
                event="click"
                globalEventOff="click"
                isCapture="true"
              >
                <div className="subtitle text-center py-2">How does recurrence work?</div>
                <ul>
                  <li>
                    <span className="recurrence-type">Choosing Monthly</span> recurrence, you will
                    be charged a specified amount every month. Money charge is performed on the
                    first day of recurrence for the selected month automatically.
                  </li>
                  <li className="mt-3">
                    <span className="recurrence-type">Choosing Quarterly</span> recurrence, you will
                    be charged a specified amount one time per quarter. Money charge is performed on
                    the first day of recurrence for the selected quarter automatically.
                  </li>
                  <li className="mt-3">
                    <span className="recurrence-type">Choosing Yearly</span> recurrence, you will be
                    charged a specified amount one time per year. Money charge is performed on the
                    first day of recurrence for the selected year automatically.
                  </li>
                </ul>
              </ReactTooltip>
            </>
          )}
        </InputGroup>

        {isRecurring && (
          <div className="text-left ml-4 my-2">
            <table>
              <tbody>
                <tr>
                  <td className="pr-3 subtitle" style={{ verticalAlign: 'top' }}>
                    Frequency*
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      {['Monthly', 'Quarterly', 'Yearly'].map((t) => {
                        return (
                          <Form.Check
                            key={t}
                            checked={grant.frequency === t}
                            type="radio"
                            value={t}
                            name="frequency"
                            id={`frequency${t}`}
                            htmlFor={`frequency${t}`}
                            label={t}
                            onChange={(e) => {
                              updateGrant('frequency', e.target.value);
                              setIsValidFrequency(true);
                            }}
                            className={isValidFrequency ? '' : 'frequency-required'}
                          />
                        );
                      })}
                      {!isValidFrequency && (
                        <Form.Text class className="validation-message__frequency">
                          Frequency required
                        </Form.Text>
                      )}
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </table>
            <Form.Row className="mt-3 mb-4">
              <InputGroup>
                <Form.Label className="my-auto subtitle">Starting On*</Form.Label>
                <DatePickerInput
                  id="grant-starting-on"
                  selected={grant.startDate}
                  className={`mt-1 transactions-datepicker ${
                    startDateValidationError ? 'invalid' : ''
                  }`}
                  onSelect={(e) => {
                    updateGrant('startDate', e);
                    setMinEndDate(e);
                  }}
                  minDate={moment().add(4, 'days').toDate()}
                  maxDate={maxStartDate}
                />
              </InputGroup>
              <Form.Text class className="validation-message__start-date">
                {startDateValidationError}
              </Form.Text>
            </Form.Row>
            <Form.Row className="mb-4">
              <Form.Check
                checked={grant.endDate && grant.endDate !== ''}
                onChange={(e) => {
                  setWithEndDate(e.target.checked);
                  if (!e.target.checked) {
                    updateGrant('endDate', null);
                    setMaxStartDate(null);
                  }
                }}
                type="checkbox"
                label="Select a date to stop recurring grant (optional)"
              />
            </Form.Row>
            {withEndDate && (
              <Form.Row>
                <InputGroup>
                  <Form.Label className="my-auto subtitle ending-on">Ending On</Form.Label>
                  <DatePickerInput
                    id="grant-ending-on"
                    selected={grant.endDate}
                    className="mt-1 transactions-datepicker"
                    onSelect={(e) => {
                      updateGrant('endDate', e);
                      setMaxStartDate(e);
                    }}
                    minDate={minEndDate}
                  />
                </InputGroup>
              </Form.Row>
            )}
          </div>
        )}
      </Form.Group>
    </Form>
  );
};

const AddTiming = ({ grant, updateGrant }) => {
  return (
    <>
      <div className="timing-popup">
        <Instructions hideStepCount isModal title={`Grant Timing: ${grant.granteeName} `}>
          <div className="text-left px-4 description">
            DonorsTrust will review your request and, if approved, will distribute the grant as soon
            as possible, unless you request otherwise. Future and recurring grants are reviewed
            again prior to distribution.
          </div>
        </Instructions>
        <div className="container">
          <div className="row">
            <div className="col">
              <TimingForm grant={grant} updateGrant={updateGrant} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTiming;
