import React from 'react';
import _ from 'lodash';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { PHONE_TYPES, greyColor } from '../../common/constants';

const getLabelById = (id) => {
  const [result] = PHONE_TYPES.filter((phone) => phone.id === id);
  return result.label;
};

const getCommunicationElection = (communicationPrimary) => {
  let result = '';
  if (communicationPrimary?.preferElectronicCommunication) {
    result += 'I would prefer only electronic communication concerning my account.\n';
  }
  if (communicationPrimary?.acceptMailingFromDonorsTrust) {
    result +=
      'I will accept mail/email from DonorsTrust concerning philanthropy and DonorsTrust generally.\n';
  }
  if (communicationPrimary?.sendStatementsAndGrantsMails) {
    result +=
      'I would like DonorsTrust to mail/email an additional copy of quarterly statements and copies of grant letters to the listed Co-Primary Advisor as well. (By default, these are sent only to the Primary Account Advisor.)';
  }
  return result;
};

const getCoPrimaryAdvisorText = (coPrimaryAdvisorData) => {
  if (coPrimaryAdvisorData) {
    let result = '';
    if (coPrimaryAdvisorData?.prefix) result += `${coPrimaryAdvisorData.prefix}`;
    if (coPrimaryAdvisorData?.firstName) result += ` ${coPrimaryAdvisorData.firstName}`;
    if (coPrimaryAdvisorData?.lastName) result += ` ${coPrimaryAdvisorData.lastName}`;
    if (coPrimaryAdvisorData?.suffix) {
      result += ` ${coPrimaryAdvisorData.suffix},`;
    } else {
      result += ',';
    }
    result += '\n';
    if (coPrimaryAdvisorData?.donorsTrustSalutation)
      result += `Preferred DonorTrust salutation: ${coPrimaryAdvisorData.donorsTrustSalutation}, \n`;
    if (coPrimaryAdvisorData?.granteeSalutation)
      result += `Preferred Grantee salutation: ${coPrimaryAdvisorData.granteeSalutation}, \n`;
    if (coPrimaryAdvisorData?.mailingAddress)
      result += `${coPrimaryAdvisorData.mailingAddress}, \n`;
    if (coPrimaryAdvisorData?.city) result += `${coPrimaryAdvisorData.city}`;
    if (coPrimaryAdvisorData?.state) result += ` ${coPrimaryAdvisorData.state},`;
    if (coPrimaryAdvisorData?.zip) result += ` ${coPrimaryAdvisorData.zip},`;
    result += '\n';
    if (coPrimaryAdvisorData?.preferredPhone) result += `${coPrimaryAdvisorData.preferredPhone},`;
    if (coPrimaryAdvisorData?.preferredPhoneType)
      result += ` ${getLabelById(coPrimaryAdvisorData.preferredPhoneType)},`;
    result += '\n';
    if (coPrimaryAdvisorData?.secondaryPhone) {
      if (coPrimaryAdvisorData?.secondaryPhone) result += `${coPrimaryAdvisorData.secondaryPhone},`;
      if (coPrimaryAdvisorData?.secondaryPhone)
        result += ` ${getLabelById(coPrimaryAdvisorData.secondaryPhoneType)},`;
      result += '\n';
    }
    if (coPrimaryAdvisorData?.email) result += `${coPrimaryAdvisorData.email}`;

    return result;
  }
  return '';
};

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
      padding: '0 10',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = ({
  firstName,
  lastName,
  prefix,
  suffix,
  donorsTrustSalutation,
  granteeSalutation,
  mailingAddress,
  city,
  state,
  zip,
  preferredPhone,
  preferredPhoneType,
  secondaryPhone,
  secondaryPhoneType,
  email,
  preferElectronicCommunication,
  acceptMailingFromDonorsTrust,
  coPrimaryAdvisor,
  communicationPrimary,
  communicationCoPrimaryAdvisor,
}) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>
            Primary advisors and default communication elections
          </Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>
            Primary and Co-Primary Advisor information. All correspondence will be sent to the
            primary advisor listed below.
          </Text>
          <Table>
            <TableRow>
              <TableCell color={greyColor}>Old</TableCell>
              <TableCell color={greyColor}>New</TableCell>
            </TableRow>
          </Table>
          {firstName && (
            <Table>
              <TableRow>
                <TableCell>Primary Advisor(First Name)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{firstName.old || 'N/A'}</TableCell>
                <TableCell>{firstName.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {lastName && (
            <Table>
              <TableRow>
                <TableCell>Primary Advisor(Last Name)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{lastName.old || 'N/A'}</TableCell>
                <TableCell>{lastName.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {prefix && (
            <Table>
              <TableRow>
                <TableCell>Prefix</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{prefix.old || 'N/A'}</TableCell>
                <TableCell>{prefix.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {suffix && (
            <Table>
              <TableRow>
                <TableCell>Suffix</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{suffix.old || 'N/A'}</TableCell>
                <TableCell>{suffix.new || 'N/A'}</TableCell>
              </TableRow>
            </Table>
          )}
          {donorsTrustSalutation && (
            <Table>
              <TableRow>
                <TableCell>Preferred DonorsTrust salutation</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{donorsTrustSalutation.old || 'N/A'}</TableCell>
                <TableCell>{donorsTrustSalutation.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {granteeSalutation && (
            <Table>
              <TableRow>
                <TableCell>Preferred Grantee salutation</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{granteeSalutation.old || 'N/A'}</TableCell>
                <TableCell>{granteeSalutation.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {mailingAddress && (
            <Table>
              <TableRow>
                <TableCell>Mailing address</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{mailingAddress.old || 'N/A'}</TableCell>
                <TableCell>{mailingAddress.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {city && (
            <Table>
              <TableRow>
                <TableCell>City</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{city.old || 'N/A'}</TableCell>
                <TableCell>{city.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {state && (
            <Table>
              <TableRow>
                <TableCell>State</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{state.old || 'N/A'}</TableCell>
                <TableCell>{state.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {zip && (
            <Table>
              <TableRow>
                <TableCell>Zip</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{zip.old || 'N/A'}</TableCell>
                <TableCell>{zip.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {preferredPhone && (
            <Table>
              <TableRow>
                <TableCell>Preferred phone</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{preferredPhone.old || 'N/A'}</TableCell>
                <TableCell>{preferredPhone.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {preferredPhoneType && (
            <Table>
              <TableRow>
                <TableCell>Phone type (Preferred phone)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {preferredPhoneType.old ? getLabelById(preferredPhoneType.old) : 'N/A'}
                </TableCell>
                <TableCell>{getLabelById(preferredPhoneType.new)}</TableCell>
              </TableRow>
            </Table>
          )}
          {secondaryPhone && (
            <Table>
              <TableRow>
                <TableCell>Secondary phone</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{secondaryPhone.old || 'N/A'}</TableCell>
                <TableCell>{secondaryPhone.new || 'N/A'}</TableCell>
              </TableRow>
            </Table>
          )}
          {secondaryPhoneType && (
            <Table>
              <TableRow>
                <TableCell>Phone type (Secondary phone)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {secondaryPhoneType.old ? getLabelById(secondaryPhoneType.old) : 'N/A'}
                </TableCell>
                <TableCell>
                  {secondaryPhoneType.new ? getLabelById(secondaryPhoneType.new) : 'N/A'}
                </TableCell>
              </TableRow>
            </Table>
          )}
          {email && (
            <Table>
              <TableRow>
                <TableCell>Email address</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{email.old || 'N/A'}</TableCell>
                <TableCell>{email.new}</TableCell>
              </TableRow>
            </Table>
          )}
          {(preferElectronicCommunication || acceptMailingFromDonorsTrust) && (
            <Table>
              <TableRow>
                <TableCell>Contact with Donors Trust</TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="left">
                  {getCommunicationElection(communicationPrimary?.old)}
                </TableCell>
                <TableCell textAlign="left">
                  {getCommunicationElection(communicationPrimary?.new)}
                </TableCell>
              </TableRow>
            </Table>
          )}
          {coPrimaryAdvisor && (
            <Table>
              <TableRow>
                <TableCell>Co-Primary Advisor</TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="left">
                  {getCoPrimaryAdvisorText(coPrimaryAdvisor.old)}
                </TableCell>
                <TableCell textAlign="left">
                  {getCoPrimaryAdvisorText(coPrimaryAdvisor.new)}
                </TableCell>
              </TableRow>
            </Table>
          )}
          {!_.isEqual(communicationCoPrimaryAdvisor?.old, communicationCoPrimaryAdvisor?.new) && (
            <Table>
              <TableRow>
                <TableCell>Contact with Donors Trust for Co-Primary Advisor</TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="left">
                  {getCommunicationElection(communicationCoPrimaryAdvisor?.old)}
                </TableCell>
                <TableCell textAlign="left">
                  {getCommunicationElection(communicationCoPrimaryAdvisor?.new)}
                </TableCell>
              </TableRow>
            </Table>
          )}
        </View>
      </Page>
    </Document>
  );
};
