import { AchContributionAllocationContext } from 'Providers/AchContributionAllocation';
import React, { useContext } from 'react';
import Instructions from 'components/Main/Grant/Request/Instructions';
import { AllocationInfo } from '../Common/AllocationInfo';

export const FinishInvestment = (props) => {
  const { fund, contributionAmount, fee, paymentLastDigits, fullName } = props;
  const { configuration } = useContext(AchContributionAllocationContext);

  return (
    <>
      <Instructions title="Finish investment" hideStepCount>
        Below are selected investments. To confirm investment, click <strong>Submit</strong> or hit{' '}
        <strong>Back</strong> to change your choise.
      </Instructions>
      <div className="container ach-contribution-allocation">
        <div className="row">
          <div className="col text-center">
            <div className="col mb-4 pt-3 pt-md-0">
              <strong className="h4 font-weight-bold">Confirm you investment choices</strong>
            </div>
            <AllocationInfo
              fund={fund}
              contributionAmount={contributionAmount}
              allocations={configuration.allocations}
              fee={fee}
              paymentLastDigits={paymentLastDigits}
              fullName={fullName}
            />
          </div>
        </div>
      </div>
    </>
  );
};
