/* eslint-disable react/no-danger */
import React from 'react';

const AlertComponent = ({ type, msg, removeCallback }) => {
  if (type && msg) {
    return (
      <div
        className={`py-4 alert alert-${type} fade mx-auto ${msg ? 'show' : ''}`}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 9999,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col col-md-8 offset-md-2 d-flex justify-content-between align-items-center">
              <div dangerouslySetInnerHTML={{ __html: msg }} />
              <button
                type="button"
                className="close ml-4"
                data-dismiss="alert"
                onClick={removeCallback}
                aria-label="Close"
                style={{ color: 'inherit' }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default AlertComponent;
