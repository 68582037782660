import React from 'react';

export const DownloadPdfSunsettingDaf = ({ blobPdf }) => {
  const fileURL = window.URL.createObjectURL(blobPdf);

  return (
    <a
      download="Sunsetting of donor-advised fund account and final distribution elections.pdf"
      href={fileURL}
    >
      <i className="bi bi-cloud-arrow-down-fill text-dark" />
    </a>
  );
};
