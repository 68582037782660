/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CurrencyInput from 'react-currency-input-field';
import { ApiContext } from 'components/ApiProvider';
import { AlertContext } from 'Providers/Alert';
import { formatErrors } from 'util/errors';

export const resolver = yupResolver(
  yup.object().shape({
    amount: yup.number().positive().required('Amount required').nullable(),
    isSubscription: yup.boolean().default(false),
    startDate: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    endDate: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    interval: yup.number().when('isSubscription', {
      is: true,
      then: yup.number().required(),
    }),
  })
);

const CreateContributionForm = ({ fundId }) => {
  const [isSubscription, setIsSubscription] = useState(false);
  const [amount, setAmount] = useState('');
  const [startDate, setStartDate] = useState(new Date().toISOString().substring(0, 10));
  const {
    register,
    formState: { errors },
    handleSubmit,
    unregister,
    setValue,
    reset,
  } = useForm({ resolver });
  const { addAlert } = useContext(AlertContext);
  const { createContribution } = useContext(ApiContext);

  const handleToggle = (e) => {
    const { value } = e.target;
    setIsSubscription(value === 'true');
  };

  const handleAmount = (values) => {
    setAmount(values);
    setValue('amount', values);
  };

  const handleDate = (e) => {
    setStartDate(e.target.value);
  };

  useEffect(() => {
    if (!isSubscription) {
      unregister(['startDate', 'endDate', 'interval']);
    }
  }, [isSubscription]);

  const submit = async (values) => {
    console.log(values);

    try {
      await createContribution(fundId, values);

      reset();
      setAmount('');
      addAlert('success', 'Contribution submitted!');
    } catch (e) {
      console.log(e);
      const msg = formatErrors(e.response.data);

      addAlert('error', msg);
    }
  };

  return (
    <Form autoComplete="false" className="form" onSubmit={handleSubmit((values) => submit(values))}>
      <Form.Group className="mb-4" controlId="contributionAmount">
        <Form.Label>Contribution Amount</Form.Label>
        <CurrencyInput
          isInvalid={errors.amount}
          customInput={Form.Control}
          allowNegativeValue={false}
          prefix="$ "
          decimalsLimit={2}
          decimalSeparator="."
          onValueChange={handleAmount}
          value={amount}
        />
        <Form.Control.Feedback type="invalid">{errors.amount?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-4" controlId="contributionIsRecurring">
        <Form.Label className={`${errors.isSubscription ? 'text-danger' : ''}`}>
          Is this a one-time or recurring contribution?
        </Form.Label>
        <div {...register('isSubscription')}>
          <Form.Check
            inline
            label="One-time"
            name="isSubscription"
            id="one-time"
            htmlFor="one-time"
            type="radio"
            value="false"
            onChange={handleToggle}
          />
          <Form.Check
            inline
            label="Recurring"
            name="isSubscription"
            id="contribute-recurring"
            htmlFor="contribute-recurring"
            type="radio"
            value="true"
            onChange={handleToggle}
          />
        </div>
      </Form.Group>
      {isSubscription && (
        <div>
          <Form.Group className="mb-4" controlId="contributionInterval">
            <Form.Label className={`${errors.interval ? 'text-danger' : ''}`}>
              How often do you want to contribute?
            </Form.Label>
            <div {...register('interval')}>
              <Form.Check
                inline
                label="Weekly"
                id="contribute-weekly"
                htmlFor="contribute-weekly"
                name="interval"
                type="radio"
                value={0}
              />
              <Form.Check
                inline
                label="Monthly"
                id="contribute-monthly"
                htmlFor="contribute-monthly"
                name="interval"
                type="radio"
                value={1}
              />
              <Form.Check
                inline
                label="Yearly"
                id="contribute-yearly"
                htmlFor="contribute-yearly"
                name="interval"
                type="radio"
                value={2}
              />
            </div>
          </Form.Group>
          <Form.Row className="mb-4">
            <Form.Group as={Col} controlId="contributionStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                {...register('startDate')}
                isInvalid={errors.startDate}
                value={startDate}
                onChange={handleDate}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="contributionEndDate">
              <Form.Label className="font-weight-bold">End Date</Form.Label>
              <Form.Control type="date" {...register('endDate')} isInvalid={errors.endDate} />
              <Form.Text id="endDateHelp" muted>
                Leave blank for indefinite subscription. You may cancel at any time.
              </Form.Text>
            </Form.Group>
          </Form.Row>
        </div>
      )}
      <Form.Group>
        <button className="button button--dark-blue px-3 py-2" type="submit">
          Submit
        </button>
      </Form.Group>
    </Form>
  );
};

export default CreateContributionForm;
