import React from 'react';
import { Link } from 'react-router-dom';

const RequestGrantLink = ({ fund, className, children }) => {
  return (
    <Link to={{ pathname: '/fund/grants/request', state: { fund } }} className={className}>
      {children}
    </Link>
  );
};

export default RequestGrantLink;
