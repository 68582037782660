import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';
import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { PHONE_VALIDATION_REGEX } from 'components/Main/constants';
import { PrefixRadiosModal } from './PrefixRadiosModal/PrefixRadiosModal';
import { PHONE_TYPES, regExp } from '../../common/constants';
import { InputWithValidation } from '../InputWithValidation/InputWithValidation';
import { InputWithoutValidation } from '../InputWithoutValidation/InputWithoutValidation';
import { NavigateButtons } from '../../common/NavigateButtons/NavigateButtons';
import CoPrimaryInstruction from './CoPrimaryInstruction/CoPrimaryInstruction';

const resolver = yupResolver(
  yup.object().shape({
    firstName: yup.string().required('Required field').nullable(true),
    lastName: yup.string().required('Required field').nullable(true),
    donorsTrustSalutation: yup.string().required('Required field').nullable(true),
    granteeSalutation: yup.string().required('Required field').nullable(true),
    mailingAddress: yup.string().required('Required field').nullable(true),
    email: yup
      .string()
      .email('Invalid email address ')
      .required('Required field')
      .max(60, 'Incorrect input')
      .nullable(true),
    preferredPhone: yup
      .string()
      .required('Required field')
      .matches(PHONE_VALIDATION_REGEX, {
        message: 'Please enter 10 digits phone number',
        excludeEmptyString: true,
      })
      .nullable(true),
    preferredPhoneType: yup.string().required('Required field').nullable(true),
    secondaryPhone: yup
      .string()
      .matches(PHONE_VALIDATION_REGEX, {
        message: 'Please enter 10 digits phone number',
        excludeEmptyString: true,
      })
      .nullable(true),
    secondaryPhoneType: yup
      .string()
      .when('secondaryPhone', {
        is: (secondaryPhone) => {
          return Boolean(secondaryPhone);
        },
        then: yup.string().required('Required field').nullable(true),
      })
      .nullable(true),
    prefix: yup.string().nullable(true),
    prefixOther: yup
      .string()
      .when('prefix', {
        is: (prefix) => {
          return !regExp.IS_PREFIX_OTHER.test(prefix);
        },
        then: yup.string().required('Required field'),
      })
      .nullable(true),
    city: yup.string().required('Required field').nullable(true),
    state: yup.string().required('Required field').nullable(true),
    zip: yup.string().required('Required field').max(10, 'Incorrect input').nullable(true),
    preferElectronicCommunication: yup.boolean(),
    acceptMailingFromDonorsTrust: yup.boolean(),
    sendStatementsAndGrantsMails: yup.boolean(),
  })
);

const CoPrimaryAdvisorModal = ({
  coprimaryAdvisor,
  cities,
  states,
  isCoPrimaryAdvisorModalOpened,
  closeModal,
  setCoprimaryAdvisor,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange ',
    resolver,
  });

  const [prefix, setPrefix] = useState();

  const [coprimaryAdvisorSettings, setCoprimaryAdvisorSettings] = useState(null);
  const [secondaryPhone, setSecondaryPhone] = useState(null);

  useEffect(() => {
    setPrefix(coprimaryAdvisor?.prefix);
    setSecondaryPhone(coprimaryAdvisor?.secondaryPhone);
    setCoprimaryAdvisorSettings({
      ...coprimaryAdvisor,
      prefixOther: regExp.IS_PREFIX_OTHER.test(coprimaryAdvisor?.prefix)
        ? ''
        : coprimaryAdvisor?.prefix,
    });
  }, []);

  useEffect(() => {
    reset(coprimaryAdvisorSettings);
  }, [coprimaryAdvisorSettings]);

  const submit = (data) => {
    setCoprimaryAdvisor(data);
    closeModal();
  };

  useEffect(() => {
    if (!secondaryPhone) {
      setValue('secondaryPhoneType', '');
      clearErrors('secondaryPhoneType');
    }
  }, [secondaryPhone]);

  useEffect(() => {
    if (regExp.IS_PREFIX_OTHER.test(prefix)) {
      setValue('prefixOther', '');
      clearErrors('prefixOther');
    }
  }, [prefix]);

  return (
    <Modal
      show={isCoPrimaryAdvisorModalOpened}
      className="grantee-history-modal"
      centered
      size="xl"
    >
      <Modal.Body>
        <div className="container daf-container">
          <CoPrimaryInstruction />
          <div className="my-4">
            <strong>Co-Primary Advisor:</strong>
          </div>
          <div className="row">
            <InputWithValidation
              register={register}
              name="firstName"
              errors={errors}
              id="daf-first-name-modal"
              label="First name: "
              labelWidth={4}
              inputWidth="auto"
            />
            <InputWithValidation
              register={register}
              name="lastName"
              errors={errors}
              id="daf-last-name-modal"
              label="Last name:"
              labelWidth={4}
              inputWidth="auto"
            />
            <InputWithoutValidation
              register={register}
              name="suffix"
              id="daf-suffix-modal"
              label="Suffix:"
              labelWidth={4}
              inputWidth="auto"
            />
          </div>
          <div className="my-3 d-flex daf-prefixes-container">
            <PrefixRadiosModal prefix={prefix} setPrefix={setPrefix} setValue={setValue} />
            <div className="w-25">
              <Controller
                name="prefixOther"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    className="p-1 w-100"
                    value={value}
                    disabled={regExp.IS_PREFIX_OTHER.test(prefix)}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setPrefix(e.target.value);
                      setValue('prefix', e.target.value);
                    }}
                    isInvalid={errors.prefixOther}
                  />
                )}
              />
              {errors.prefixOther && (
                <div className="validation-alert text-left " style={{ width: 120 }}>
                  {errors.prefixOther.message}
                </div>
              )}
            </div>
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="donorsTrustSalutation"
              errors={errors}
              id="daf-donortrust-salutation-modal"
              label="Preferred DonorsTrust salutation:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="granteeSalutation"
              errors={errors}
              id="daf-grantee-salutation-modal"
              label="Preferred Grantee salutation:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="mailingAddress"
              errors={errors}
              id="daf-mailing-address-modal"
              label="Mailing address:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="row my-3">
            <label
              htmlFor="daf-city-modal-input"
              className="col-4 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">City:</strong>
              <div className="ml-3 col-9">
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value: cityValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={cityValue}
                          {...inputProps}
                          isInvalid={!!errors.city}
                          id="daf-city-modal-input"
                        />
                      )}
                      minLength={3}
                      options={cities}
                      id="daf-city-modal"
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-city w-100"
                      onChange={([city]) => {
                        onChange(city);
                      }}
                    />
                  )}
                />
                {errors.city && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.city.message}
                  </div>
                )}
              </div>
            </label>
            <label
              htmlFor="daf-state-modal-input"
              className="col-2 mr-3 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">State:</strong>
              <div className="ml-3 col-7">
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { value: stateValue, onChange, ...field } }) => (
                    <Typeahead
                      {...field}
                      renderInput={({ value, ...inputProps }) => (
                        <TypeaheadInputSingle
                          value={stateValue}
                          {...inputProps}
                          isInvalid={!!errors.state}
                          maxLength="2"
                          id="daf-state-modal-input"
                        />
                      )}
                      options={states}
                      id="daf-state-modal"
                      filterBy={(option, props) =>
                        option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                      }
                      onInputChange={(text) => {
                        onChange(text);
                      }}
                      className="typeahead-state w-100"
                      onChange={([state]) => {
                        onChange(state);
                      }}
                    />
                  )}
                />
                {errors.state && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.state.message}
                  </div>
                )}
              </div>
            </label>
            <InputWithValidation
              register={register}
              name="zip"
              errors={errors}
              id="daf-zip-modal"
              label="Zip:"
              labelWidth={2}
              inputWidth={8}
            />
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="preferredPhone"
              errors={errors}
              id="daf-preferred-phone-modal"
              label="Preferred phone:"
              labelWidth={6}
              inputWidth={6}
              isPhoneInput
              control={control}
            />
            <label
              htmlFor="daf-preferred-phone-type-modal"
              className="col-6 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">Phone Type:</strong>
              <div className="ml-3 col-6">
                <Form.Control
                  as="select"
                  className="py-2 px-1 w-100"
                  id="daf-preferred-phone-type-modal"
                  {...register('preferredPhoneType')}
                  isInvalid={errors.preferredPhoneType}
                >
                  {PHONE_TYPES.map(({ label, id }) => (
                    <option key={id} value={id} hidden={id === ''}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
                {errors.preferredPhoneType && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.preferredPhoneType.message}
                  </div>
                )}
              </div>
            </label>
          </div>
          <div className="row my-3">
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Form.Label
                  htmlFor="daf-secondary-phone-modal"
                  className="col-6 no-gutters d-flex align-items-start"
                >
                  <strong className="col-auto mt-1">Secondary phone:</strong>
                  <div className="ml-3 col-6">
                    <NumberFormat
                      {...field}
                      value={value}
                      type="tel"
                      format="### ### ####"
                      onValueChange={(v) => {
                        onChange(v.formattedValue);
                        setSecondaryPhone(v.formattedValue);
                      }}
                      className={`form-control p-1 w-100 ${
                        errors.secondaryPhone ? 'is-invalid' : ''
                      }`}
                      id="daf-secondary-phone"
                    />
                    {errors.secondaryPhone && (
                      <div className="validation-alert text-left ">
                        {errors.secondaryPhone.message}
                      </div>
                    )}
                  </div>
                </Form.Label>
              )}
            />
            <label
              htmlFor="daf-secondary-phone-type-modal"
              className="col-6 no-gutters d-flex align-items-start"
            >
              <strong className="col-auto mt-1">Phone Type:</strong>
              <div className="ml-3 col-6">
                <Form.Control
                  as="select"
                  className="py-2 px-1 w-100"
                  disabled={!secondaryPhone}
                  {...register('secondaryPhoneType')}
                  id="daf-secondary-phone-type-modal"
                  isInvalid={errors.secondaryPhoneType}
                >
                  {PHONE_TYPES.map(({ label, id }) => (
                    <option key={id} value={id} hidden={id === ''}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
                {errors.secondaryPhoneType && (
                  <div className="validation-alert text-left " style={{ width: 120 }}>
                    {errors.secondaryPhoneType.message}
                  </div>
                )}
              </div>
            </label>
          </div>
          <div className="row my-3">
            <InputWithValidation
              register={register}
              name="email"
              errors={errors}
              id="daf-email-modal"
              label="Email address:"
              labelWidth={12}
              inputWidth={4}
            />
          </div>
          <div className="my-3">
            <Controller
              name="preferElectronicCommunication"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <div className="d-flex align-items-start">
                  <input
                    {...field}
                    type="checkbox"
                    id="prefer-electronic-communication-modal"
                    checked={value}
                    className="mt-1"
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                  <Form.Label htmlFor="prefer-electronic-communication-modal" className="ml-3 mb-0">
                    I would prefer only electronic communication concerning my account.
                  </Form.Label>
                </div>
              )}
            />
          </div>
          <div className="my-3">
            <Controller
              name="acceptMailingFromDonorsTrust"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <div className="d-flex align-items-start">
                  <input
                    {...field}
                    type="checkbox"
                    id="accept-mailing-from-donors-trust-modal"
                    checked={value}
                    className="mt-1"
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  />
                  <Form.Label
                    htmlFor="accept-mailing-from-donors-trust-modal"
                    className="ml-3 mb-0"
                  >
                    I will accept mail/email from DonorsTrust concerning philanthropy and
                    DonorsTrust generally.
                  </Form.Label>
                </div>
              )}
            />
            <div className="my-3">
              <Controller
                name="sendStatementsAndGrantsMails"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <div className="d-flex align-items-start">
                    <input
                      {...field}
                      type="checkbox"
                      id="send-statements-and-grants-mails"
                      className="mt-1"
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                    <Form.Label htmlFor="send-statements-and-grants-mails" className="ml-3 mb-0">
                      I would like DonorsTrust to mail/email an additional copy of quarterly
                      statements and copies of grant letters to the listed Co-Primary Advisor as
                      well. (By default, these are sent only to the Primary Account Advisor.)
                    </Form.Label>
                  </div>
                )}
              />
            </div>
            <div className="my-4">
              <NavigateButtons
                onClickCancel={() => {
                  setCoprimaryAdvisorSettings(null);
                  reset();
                  closeModal();
                }}
                onClickSubmit={handleSubmit(submit)}
                submitButtonText="Save"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CoPrimaryAdvisorModal;
