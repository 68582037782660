import { PoolChoicesContext } from 'Providers/PoolChoices';
import React, { useContext, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { ApiContext } from 'components/ApiProvider';
import { displayCurrency } from 'util/money';
import { Form } from 'react-bootstrap';
import Instructions from '../../Request/Instructions';

export const ChangeInvestments = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const { configuration, updateConfiguration, errorMessage } = useContext(PoolChoicesContext);
  const selecedPool = fund?.poolFundUnits.find((g) => g.poolId === configuration.poolId) || null;
  const isExchange = configuration.type === 'Exchange';
  const investedAmount =
    (configuration?.allocations?.length &&
      configuration?.allocations?.reduce((a, b) => a + b.amount, 0)) ||
    null;
  const { getPoolsForReallocation } = useContext(ApiContext);
  const isValid =
    configuration?.approve === 'Yes' &&
    (isExchange ? investedAmount === configuration?.amountToExchange : investedAmount === 100);

  useEffect(() => {
    if (configuration.allocations) updateConfiguration('allocations', []);
  }, [configuration.type, configuration.poolId]);

  useEffect(() => {
    async function getPools() {
      const pools = await getPoolsForReallocation(fund.id);
      updateConfiguration('pools', pools);
    }

    if (currentStep > 1 && !configuration.pools) {
      getPools();
    }
  }, [currentStep]);

  const updateAllocations = (poolId, name, amount) => {
    const exchangeArray = configuration.allocations;
    const pool = exchangeArray?.find((p) => p.poolId === poolId) || null;
    if (pool) {
      const index = exchangeArray?.findIndex((i) => i.poolId === poolId);

      if (amount > 0) exchangeArray.splice(index, 1, { poolId, name, amount });
      else exchangeArray.splice(index, 1);
    } else {
      exchangeArray.push({ poolId, name, amount });
    }
    updateConfiguration('allocations', exchangeArray);
  };

  return (
    <>
      <Instructions
        title={`${configuration.type} your investments`}
        currentStep={currentStep}
        totalSteps={totalSteps}
      >
        {isExchange ? (
          <>
            Make an exchange by entering a 100% amount in the
            <strong> New Investment Allocations</strong> fields. Once you complete, a Continue
            button will appear. To move on the process, click <strong>Continue</strong>
          </>
        ) : (
          <>
            Once you have set up all changes, you can see what changes will be applied to the chosen
            investment pools. To move the process, click <strong>Continue</strong>
          </>
        )}
      </Instructions>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex flex-column flex-md-row justify-content-between mb-3 mb-md-5">
              <div>
                {isExchange ? (
                  <div className="d-flex flex-column flex-md-row">
                    <span>Pool Name</span>{' '}
                    <div className="mt-2 mt-md-0">
                      <strong className="ml-0 ml-md-5">{selecedPool?.name}</strong>
                      <strong className="ml-4 ml-md-5">
                        ${displayCurrency(selecedPool?.poolFundValue - selecedPool?.pendingAmount)}
                      </strong>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-md-row">
                    <span>Fund Name</span>{' '}
                    <strong className="mt-2 mt-md-0 ml-0 ml-md-5">{fund?.name}</strong>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row">
                <strong className="mt-2 mt-md-0 mr-5">
                  <strong>You need to invest:</strong>
                  <div className="text-danger">
                    {isExchange
                      ? `$${displayCurrency(
                          configuration.amountToExchange?.toFixed(2) - investedAmount
                        )}`
                      : `${displayCurrency(100 - investedAmount)}%`}
                  </div>
                </strong>
                <strong className="mt-3 mt-md-0">
                  <strong>You invested:</strong>
                  <div className="text-success">
                    {isExchange
                      ? `$${displayCurrency(investedAmount || 0)}`
                      : `${displayCurrency(investedAmount || 0)}%`}
                  </div>
                </strong>
              </div>
            </div>
            {!isExchange && (
              <div className="mb-4 mb-md-2">
                Total fund amount:
                <strong> ${displayCurrency(fund.totalValue - fund.pendingGrantAmount)}</strong>
              </div>
            )}
            <div>
              <div className="funds-table-container">
                <table className="table funds-table">
                  <thead className="text-secondary">
                    <tr>
                      <th className="funds-table__investment-pool">Investment Pool</th>
                      <th className="funds-table__current-balance text-right">Current Balance</th>
                      <th className="funds-table__current-units text-right">Current Units</th>
                      {!isExchange && (
                        <th className="funds-table__current-allocation text-right text-md-right">
                          Current Allocations
                        </th>
                      )}
                      <th className="funds-table__investment-allocation text-right pr-md-3">
                        New Investment Allocations{' '}
                        <span className="d-inline d-md-none">({isExchange ? '$' : '%'})</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {configuration.pools
                      ?.sort((a, b) => b.value - a.value)
                      ?.map(
                        (pool) =>
                          (!isExchange || (isExchange && pool.poolId !== configuration.poolId)) && (
                            <tr key={pool.poolId}>
                              <td className="font-weight-bold">{pool.poolName}</td>
                              <td className="text-right">${displayCurrency(pool.value)}</td>
                              <td className="text-right">{displayCurrency(pool.numberOfUnits)}</td>
                              {!isExchange && (
                                <td className="text-right text-md-right">
                                  {(
                                    (pool.value / (fund.totalValue - fund.pendingGrantAmount)) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </td>
                              )}
                              <td className="text-right">
                                <NumberFormat
                                  className="mt-1 mr-2 text-right number-format"
                                  thousandSeparator
                                  allowNegative={false}
                                  value={
                                    configuration.allocations?.find(
                                      (val) => val.poolId === pool.poolId
                                    )?.amount || ''
                                  }
                                  onValueChange={(values) => {
                                    const val = values.floatValue || 0;
                                    updateAllocations(pool.poolId, pool.poolName, val);
                                  }}
                                />
                                <span className="d-none d-md-inline">{isExchange ? '$' : '%'}</span>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-md-row mt-4 mt-md-0">
              <div>
                <div>Would you like to apply these changes ?</div>
                <div className="d-flex mt-3 mt-md-1">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="approve"
                    id="investments-changes-yes"
                    htmlFor="investments-changes-yes"
                    className="mr-3 pool-choice__checkbox-exchange"
                    onChange={() => updateConfiguration('approve', 'Yes')}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    id="investments-changes-no"
                    htmlFor="investments-changes-no"
                    name="approve"
                    className="pool-choice__checkbox-exchange"
                    onChange={() => updateConfiguration('approve', 'No')}
                  />
                </div>
              </div>
              {errorMessage && !isValid && (
                <div className="col-12 col-md-3 mt-4 mt-md-0 grant-instructions p-2">
                  <small>
                    Please, ensure you entered correct amount and confirmed applying the changes.
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
