import moment from 'moment';

export const displayDate = (date) => {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }

  return null;
};

export const dateIsValid = (date) => {
  return date instanceof Date && !Number.isNaN(date);
};

export const getDateWithUtcOffset = (date) => {
  const utcOffset = +0;
  const tzOffsetInMs = (new Date().getTimezoneOffset() + utcOffset * 60) * 60 * 1000;
  const result = new Date(date.getTime() - tzOffsetInMs);

  return result;
};
