import React, { useContext } from 'react';
import { AuthenticationContext } from 'auth/AuthProvider';

export default () => {
  const { user } = useContext(AuthenticationContext);

  return (
    <div className="your-funds-greeting text-center">
      <h1 className="mb-3 text-extra-bold">Welcome back, {`${user.name}`}</h1>
      <p>
        To request a grant and review account activity, click the blue account name below. At the
        top of this page, you will find links to various forms and resources. You can also update
        your information, which we recommend you review from time to time.
      </p>
    </div>
  );
};
