/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelect, useMultipleSelection } from 'downshift';

const selectedItemsText = (selectedItems, allItems) => {
  if (!selectedItems?.length) {
    return 'Select subtype';
  }
  if (selectedItems?.length === 1) {
    return `${selectedItems[0].value}`;
  }
  if (selectedItems?.length === allItems?.length) {
    return `All selected (${selectedItems?.length})`;
  }
  if (selectedItems?.length > 1) {
    return `${selectedItems.length} selected`;
  }
  return null;
};

const itemToString = (item) => {
  return item ? item.value : '';
};

export const SelectWithSearchInDropdown = ({ className, defaultItems, setSelectedSubtypes }) => {
  const [searchValue, setSearchValue] = useState('');
  const [itemsArray, setItemsArray] = useState(defaultItems);

  const {
    selectedItems,
    getDropdownProps,
    getSelectedItemProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({
    itemToString,
  });

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, closeMenu } = useSelect({
    items: itemsArray,
    selectedItem: null,
    itemToString,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem) addSelectedItem(newSelectedItem);
    },
  });

  const selectAll = () =>
    itemsArray.forEach((item) => {
      if (!selectedItems.includes(item)) addSelectedItem(item);
    });

  const deselectAll = () => itemsArray.forEach((item) => removeSelectedItem(item));

  useEffect(() => {
    setItemsArray(defaultItems);
  }, [defaultItems]);

  useEffect(() => {
    setSelectedSubtypes(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (searchValue) {
      const foundItems = defaultItems.filter((item) =>
        item.value.toLowerCase().includes(searchValue.toLowerCase())
      );
      setItemsArray(foundItems);
    } else {
      setItemsArray(defaultItems);
    }
  }, [searchValue]);

  return (
    <div className={`downshift-wrapper ${className}`}>
      <button
        type="button"
        className={selectedItems?.length ? 'button select' : 'button select select__unselected'}
        {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
      >
        {selectedItemsText(selectedItems, defaultItems)}
      </button>

      <div className="menu" {...getMenuProps()} onMouseLeave={closeMenu}>
        {isOpen ? (
          <>
            <div className="p-1">
              <div className="position-relative">
                <input
                  type="text"
                  className={searchValue ? 'search-input' : 'search-input search-input__empty'}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                />
                <button
                  type="button"
                  className={searchValue ? 'button close-button' : 'd-none'}
                  onClick={() => setSearchValue('')}
                >
                  {' '}
                </button>
              </div>
              <div className="d-flex mt-1">
                <button type="button" className="button downshift-control" onClick={selectAll}>
                  Select all
                </button>
                <button
                  type="button"
                  className="button ml-1 downshift-control"
                  onClick={deselectAll}
                >
                  Deselect all
                </button>
              </div>
            </div>
            <div className="items-wrapper">
              {itemsArray.length ? (
                <div>
                  {itemsArray.map((item, index) => (
                    <>
                      {selectedItems.includes(item) ? (
                        <div
                          key={item.value}
                          {...getSelectedItemProps({ item, index })}
                          className={selectedItems.includes(item) ? 'item-selected item' : 'item'}
                          role="button"
                          aria-label="order"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeSelectedItem(item);
                          }}
                        >
                          {item.value}
                        </div>
                      ) : (
                        <div
                          key={item.value}
                          {...getItemProps({ item, index })}
                          role="button"
                          aria-label="order"
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            addSelectedItem(item);
                          }}
                          className={selectedItems.includes(item) ? 'item-selected item' : 'item'}
                        >
                          {item.value}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              ) : (
                <div className="no-matches">No matches</div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
