import React from 'react';
import _ from 'lodash';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { greyColor } from '../../common/constants';

const getSuccessorAdvisorsText = (successorAdvisors) => {
  if (successorAdvisors) {
    let result = '';
    if (successorAdvisors?.prefix) result += `${successorAdvisors.prefix}`;
    if (successorAdvisors?.firstName) result += ` ${successorAdvisors.firstName}`;
    if (successorAdvisors?.lastName) result += ` ${successorAdvisors.lastName}`;
    if (successorAdvisors?.suffix) {
      result += ` ${successorAdvisors.suffix},`;
    } else {
      result += ',';
    }
    result += '\n';
    if (successorAdvisors?.mailingAddress) result += `${successorAdvisors.mailingAddress}, \n`;
    if (successorAdvisors?.city) result += `${successorAdvisors.city}`;
    if (successorAdvisors?.state) result += ` ${successorAdvisors.state},`;
    if (successorAdvisors?.zip) {
      result += ` ${successorAdvisors.zip},`;
      result += '\n';
    }
    if (successorAdvisors?.preferredPhone) {
      result += `${successorAdvisors.preferredPhone},`;
      result += '\n';
    }
    if (successorAdvisors?.secondaryPhone) {
      result += `${successorAdvisors.secondaryPhone},`;
      result += '\n';
    }
    if (successorAdvisors?.email) result += `${successorAdvisors.email},`;
    result += '\n';
    if (successorAdvisors?.relationshipToPrimaryAdvisor)
      result += `${successorAdvisors.relationshipToPrimaryAdvisor} \n`;
    if (successorAdvisors?.jointlyHeldPercentage) {
      result += `Jointly held  ${successorAdvisors.jointlyHeldPercentage} % of account \n`;
    } else {
      result += 'Is not bound by Donor Intent Statement \n';
    }
    if (successorAdvisors?.continueHoldingAccount) result += 'Continue holding an account \n';
    return result;
  }
  return '';
};

const advisorsPlanText = (successorAdvisorOption, successorAdvisors) => {
  let result = '';
  if (successorAdvisorOption === '1') {
    result +=
      'Custom Plan. I have or am in the process of submitting a custom sunsetting plan with DonorsTrust. That plan deals with the issue of successor advisors or instructions to DonorsTrust on how to steward the account.';
  }
  if (successorAdvisorOption === '2' || successorAdvisors?.length) {
    result += 'I would like to name the following individuals as successor advisors: \n';
    successorAdvisors?.forEach((advisor) => {
      result += `${getSuccessorAdvisorsText(advisor)} \n`;
    });
  }
  if (successorAdvisorOption === '3') {
    result += 'I do not want to have a successor advisor.';
  }
  return result;
};

const Table = ({ children }) => (
  <View
    style={{
      marginTop: 8,
      marginBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </View>
);

const TableRow = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    {children}
  </View>
);

const TableCell = ({ textAlign, children, color }) => (
  <View
    style={{
      flex: 1,
      fontWeight: 1000,
      textAlign: textAlign || 'center',
      border: '1 solid #000',
      backgroundColor: color || 'white',
      padding: '0 10',
    }}
  >
    <Text>{children}</Text>
  </View>
);

export const Pdf = ({ successorAdvisorOption, successorAdvisors }) => {
  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 48,
          fontSize: 12,
        }}
      >
        <View>
          <Text style={{ textAlign: 'center', marginBottom: 10 }}>Successor advisors</Text>
          <Text style={{ textAlign: 'left', marginBottom: 10 }}>
            Successor Advisors. You may appoint successor advisors for an account. If you appoint
            successor advisors, you can request that we only allow successor advisors to make
            distributions to charities that fall within your mission statement, or you may give the
            successor advisor(s) free reign over the account (although, like all advisors, only
            advice for a grant that falls within DonorsTrust’s mission would be accepted). The
            account(s) must still sunset within the time limits. If you name more than one successor
            advisor, the account can be split, provided each advisor’s account will be funded with
            an amount of at least $10,000.00. If you don’t split the account, successor advisors
            must unanimously agree to any advice they provide. Please complete the following if you
            would like to name a successor advisor(s):
          </Text>
          <Table>
            <TableRow>
              <TableCell color={greyColor}>Old</TableCell>
              <TableCell color={greyColor}>New</TableCell>
            </TableRow>
          </Table>
          {(successorAdvisorOption ||
            !_.isEqual(successorAdvisors?.old, successorAdvisors?.new)) && (
            <Table>
              <TableRow>
                <TableCell>Successor advisors plan</TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="left">
                  {advisorsPlanText(successorAdvisorOption?.old, successorAdvisors?.old)}
                </TableCell>
                <TableCell textAlign="left">
                  {advisorsPlanText(successorAdvisorOption?.new, successorAdvisors?.new)}
                </TableCell>
              </TableRow>
            </Table>
          )}
        </View>
      </Page>
    </Document>
  );
};
