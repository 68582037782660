import React, { useContext } from 'react';
import PlaidLink from 'components/PlaidLink';
import { useHistory } from 'react-router-dom';
import { AlertContext } from 'Providers/Alert';

const OauthPlaidPage = () => {
  const history = useHistory();
  const { addAlert } = useContext(AlertContext);

  // if (authFinished) {
  //   return <Redirect to="/fund/contributions/add" />;
  // }

  const handleSuccessAuth = () => {
    history.push('/fund/contributions/add');
    addAlert('success', 'Successfully connected your bank account! You may now add contributions!');
  };

  const handleErrorAuth = () => {
    history.push('/fund/contributions/add');
    addAlert('error', 'An error ocurred. Please try again');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <PlaidLink successCallback={handleSuccessAuth} errorCallback={handleErrorAuth} />
        </div>
      </div>
    </div>
  );
};

export default OauthPlaidPage;
