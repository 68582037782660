import React, { useContext, useState, useEffect } from 'react';
import { GrantsContext } from 'Providers/Grants';
import FundTable from 'components/Main/YourFunds/components/FundTable';
import { displayCurrency } from 'util/money';
import Instructions from '../Instructions';

const allocations = (grants) => {
  const alloc = {};

  grants.forEach((grant) => {
    const grantAllocations = grant.allocations;

    grantAllocations.forEach((a) => {
      const currentAllocation = alloc[a.poolId];

      if (currentAllocation) {
        alloc[a.poolId] += a.amount;
      } else {
        alloc[a.poolId] = a.amount;
      }
    });
  });

  return alloc;
};

export const AllocatePool = (props) => {
  const { currentStep, totalSteps, fund } = props;
  const { grants, updateGrant, grantsTotal, validationErrors } = useContext(GrantsContext);
  const [notEnoughFundsPoolIds, setNotEnoughFundsPoolIds] = useState([]);

  useEffect(() => {
    setNotEnoughFundsPoolIds(validationErrors?.find((e) => e.name === 'allocation')?.poolIds);
  }, [validationErrors]);

  const totalToSubtract = grants
    .map((g) => g.allocations)
    .map((a) => a.reduce((acc, cur) => acc + Number(cur.amount), 0))
    .reduce((acc, x) => acc + x, 0);

  // calculate the allocations per grant using the pool value
  const distributeAllocations = (value, poolId) => {
    let amountAllocated = 0;

    grants.forEach((grant, index) => {
      const allocation = {
        poolId,
        // floating point arithmetic gets weird in JS, so use whole numbers
        // when possible
        amount: Number(
          (
            (((parseFloat(value) * 100) / (grantsTotal() * 100)) * grant.totalAmount * 100) /
            100
          ).toFixed(2)
        ),
      };
      amountAllocated += allocation.amount;

      // for any weird rounding, assign any leftover allocation to last
      // grantee to make sure pool value is completely allocated across
      // all grants
      if (index === grants.length - 1 && amountAllocated !== value) {
        const diff = Number((value - amountAllocated).toFixed(2));
        allocation.amount += diff;
        allocation.amount = Number(allocation.amount.toFixed(2));
      }
      const newAllocation = [
        ...grant.allocations.filter((pre) => pre.poolId !== poolId),
        allocation,
      ];
      updateGrant(grant.granteeId, 'allocations', newAllocation);
    });
  };

  const allocationRemaining = Number(((grantsTotal() * 10) / 10 - totalToSubtract).toFixed(2)) || 0;
  const allocated = Number(totalToSubtract.toFixed(2)) || 0;

  return (
    <>
      <Instructions
        title="Allocate Investment Pool"
        currentStep={currentStep}
        totalSteps={totalSteps}
      >
        Please enter the grant amount next to the funding pool from which the funds will be drawn.
        You may divide the total between multiple pools.{' '}
      </Instructions>
      <div className="container">
        <div className="row">
          <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <table className="table table-borderless review-table allocate-pool">
              <tbody>
                {grants.map((grant) => (
                  <tr key={grant.granteeId}>
                    <td className="text-left">{grant.granteeName}</td>
                    <td align="right" className="amount font-weight-bold">
                      ${displayCurrency(grant.totalAmount)}
                    </td>
                  </tr>
                ))}
                <tr className="total">
                  <td className="text-left font-weight-bold">Total grants value:</td>
                  <td align="right" className="amount font-weight-bold">
                    ${displayCurrency(grantsTotal())}
                  </td>
                </tr>
              </tbody>
            </table>
            <FundTable
              fundTotal={fund.totalValue - fund.pendingGrantAmount}
              pools={fund.poolFundUnits}
              allocate={distributeAllocations}
              allocations={allocations(grants)}
              notEnoughFundsPoolIds={notEnoughFundsPoolIds}
            />
            <div className="ml-auto mt-3 mt-md-0 text-left text-md-right">
              <span>
                Allocated: <span className="font-weight-bold">${displayCurrency(allocated)}</span>
              </span>
              <br />
              <span>
                Allocation Remaining:{' '}
                <span
                  className={`font-weight-bold ${
                    allocationRemaining !== 0 ? 'invalid-allocation' : String.empty
                  }`}
                >
                  ${displayCurrency(allocationRemaining)}
                </span>
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
