import React, { useState, useEffect } from 'react';
import marked from 'marked';

const Banner = ({ bannerId }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [banner, setBanner] = useState({});

  useEffect(() => {
    async function getBanner() {
      const res = await fetch(`https://donorstrust-cms.herokuapp.com/banners/${bannerId}`);

      if (res.status === 200) {
        const json = await res.json();
        const { display, title, message } = json;

        setBanner({ title, message });
        setShowBanner(display);
      }
    }
    getBanner();
  }, []);

  if (showBanner) {
    return (
      <div className="mb-3 banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="banner__inner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="banner__icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
                <div className="banner__content">
                  <h3 className="banner__title">{banner.title}</h3>
                  <div
                    className="banner__message"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: marked(banner.message) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Banner;
